import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  Controller,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import CreateEmployeeDto from "@/models/CreateEmployeeDto";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@/css/MobileScreen.css";
import API_URLS from "@/config";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import RoleForm from "../role/RoleForm";
import { Employee } from "@/types/EmployeeType";
import ShopForm from "../shop/ShopForm";
import { ShopType } from "@/types/ShopType";
import { RoleType } from "@/types/RoleType";
import DataFetcher from "@/provider/DataFetcher";
import "@/css/LoadingCreate.css";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import { OperatorCodeType } from "@/types/OpCodeType";
import { axiosInstance } from "@/lib/utils";

const EmployeeForm: React.FC<{ mode: "create" | "edit" }> = ({ mode }) => {
  const location = useLocation();
  const { id } = location.state || {};
  const EMPLOYEE_STORAGE_KEY = "employeeData";
  const [isRoleDialogOpen, setIsRoleDialogOpen] = useState(false);
  const [isShopDialogOpen, setIsShopDialogOpen] = useState(false);
  const { t } = useTranslation();
  const isSuperUser1 = localStorage.getItem("isSuperUser") === "true"; // Retrieve and check the value from localStorage
  const [showPassword, setShowPassword] = useState(false);  // State to control visibility for password

  const baseFormSchema = z.object({
    name: z
      .string()
      .min(1, { message: t("Name is required") })
      .max(100, { message: t("Name should be between 1 and 100 characters") }),
    username: z
      .string()
      .min(1, { message: t("Username is required") })
      .max(15, {
        message: t("Username should be between 4 and 15 characters"),
      }),
    email: z.string().optional().nullable(),
    salary: z.number().optional(),
    phoneNumber: z.string().optional().nullable(),
    roleId: z.number().optional().nullable(),
    shopId: z.number().optional().nullable(),
    opCode:z.string().optional().nullable(),
  });
  const formSchema = (mode: "create" | "edit") =>
    mode === "create"
      ? baseFormSchema.extend({
          password: z.string().optional().nullable(),
        })
      : baseFormSchema;

  type FormValues = {
    name: string;
    username: string;
    email: string | null;
    salary: number;
    password?: string | null;
    phoneNumber?: string | null;
    roleId?: number;
    shopId?: number;
        opCode?: string | null;

  };
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema(mode)),
    defaultValues: {
      name: "",
      username: "",
      email: "",
      salary: 0,
      password: "",
      phoneNumber: "",
      roleId: undefined,
      shopId: undefined,
      opCode: "",

    },
  });

  const SHOP_STORAGE_KEY = "shopData";
  const ROLE_STORAGE_KEY = "roleData";
    const Opcode_STORAGE_KEY = "OpcodeData";

  const [loading, setLoading] = useState(false); // Initialize loading state
  const [isSuperUser, setIsSuperUser] = useState(false);

  const [roles, setRoles] = useState<RoleType[]>([]);
    const [opcode, setOpcode] = useState<OperatorCodeType[]>([]);

  const [shops, setShops] = useState<ShopType[]>([]);

  const navigate = useNavigate();

  const handleCloseDialog = (newRole?: { id: number; name: string }) => {
    setIsRoleDialogOpen(false);
    if (newRole) {
      setRoles((prevRoles) => {
        const updatedRoles = [...prevRoles, newRole];
        methods.setValue("roleId", newRole.id);
        return updatedRoles;
      });
    }
  };

  const handleClose1Dialog = (newShop?: {
    id: number;
    name: string;
    address: string;
  }) => {
    setIsShopDialogOpen(false);
    if (newShop) {
      setShops((prevShops) => {
        const updatedShops = [...prevShops, newShop];
        methods.setValue("shopId", newShop.id);
        return updatedShops;
      });
    }
  };

  const handleResetPassword = async () => {
    if (!id) {
      toast.error(t("No employee selected"));
      return;
    }

    try {
      // Assuming you have an endpoint for resetting the password
      const response = await axiosInstance.patch(
        `${API_URLS.BASE_URL}${API_URLS.ResetPassword}/${id}`
      );
      console.log("Password reset successfully:", response.data);
      toast.success(t("Password reset successfully"));
      // Optionally, navigate to another page or perform other actions
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { data } = error.response;
        if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage =
              t(`errorEmployee.${err.errorMessage}`) ||
              t("errorEmployee.An error occurred");
            toast.error(translatedMessage);
          });
        } else {
          const defaultMessage = t("An error occurred");
          toast.error(defaultMessage);
        }
      } else {
        const unexpectedMessage = t("An unexpected error occurred");
        toast.error(unexpectedMessage);
      }
      console.error("Error resetting password:", error);
    }
  };
  

  useEffect(() => {
    const fetchRoles = async () => {
      const response = await DataFetcher.getData<RoleType[]>(
        `${API_URLS.BASE_URL}${API_URLS.ROLE}`,
        ROLE_STORAGE_KEY
      );
      if (response) {
        setRoles(response);
        localStorage.setItem(ROLE_STORAGE_KEY, JSON.stringify(response));
      }
    };

    fetchRoles();
  }, []);
    useEffect(() => {
    const fetchOpcode = async () => {
      const response = await DataFetcher.getData<OperatorCodeType[]>(
        `${API_URLS.BASE_URL}${API_URLS.OperatorCodeList}`,
        Opcode_STORAGE_KEY
      );
      if (response) {
        setOpcode(response);
        localStorage.setItem(Opcode_STORAGE_KEY, JSON.stringify(response));
      }
    };

    fetchOpcode();
  }, []);
  useEffect(() => {
    const fetchShops = async () => {
      const response = await DataFetcher.getData<ShopType[]>(
        `${API_URLS.BASE_URL}${API_URLS.SHOP}`,
        SHOP_STORAGE_KEY
      );
      if (response) {
        setShops(response);
        localStorage.setItem(SHOP_STORAGE_KEY, JSON.stringify(response));
      }
    };
    fetchShops();
  }, []);

 useEffect(() => {
  if (id) {
    localStorage.setItem("employeeId", id);
    const fetchEmployee = async () => {
      try {
        const response = await DataFetcher.getData<Employee>(
          `${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}/${id}`,
          EMPLOYEE_STORAGE_KEY
        );

        if (response) {
          // Check if opCode exists in the response
          const matchingOpCode = opcode.find(
            (op) => op.kodiNeTatime === response.opCode
          );

          // If we find the matching kodiNeTatime, set it as the opCode
          const updatedResponse = {
            ...response,
            opCode: matchingOpCode ? matchingOpCode.kodiNeTatime : "",
          };

          methods.reset({
            ...updatedResponse,
            password: null,
          });

          // Convert response.isSuperUser to boolean
          const superUserValue = response.isSuperUser;
          setIsSuperUser(
            typeof superUserValue === "boolean"
              ? superUserValue
              : superUserValue === "Yes"
          );
        } else {
          console.error("Employee not found or response is null");
        }
      } catch (error) {
        console.error("Error fetching employee:", error);
      }
    };

    fetchEmployee();
  }
}, [id, mode, methods, opcode]); // Add opcode as a dependency to ensure updates are tracked


  const updateLocalStorage = (updatedEmployee: Employee) => {
    const existingEmployees = JSON.parse(
      localStorage.getItem("employeeData") || "[]"
    );

    const employeesArray = Array.isArray(existingEmployees)
      ? existingEmployees
      : [];

    const updatedEmployees = employeesArray.map((employee: Employee) =>
      employee.id === updatedEmployee.id ? updatedEmployee : employee
    );

    localStorage.setItem("employeeData", JSON.stringify(updatedEmployees));
  };
  const handleCancel = () => {
    navigate(t("/emplistpath"));
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true); // Show loading spinner

    try {
      const employeeData = new CreateEmployeeDto({
        ...data,
        email: data.email === "" ? null : data.email, // Add this line to ensure email can be null
      });
      let successMessage = "";

      if (mode === "create") {
        const response = await axiosInstance.post(
          `${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}`,
          employeeData
        );
        console.log("Employee created successfully:", response.data);
        successMessage = t("Employee created successfully");

        const existingEmployees = JSON.parse(
          localStorage.getItem("employeeData") || "[]"
        );
        existingEmployees.push(response.data);
        localStorage.setItem("employeeData", JSON.stringify(existingEmployees));
      } else if (mode === "edit" && id) {
        const response = await axiosInstance.put(
          `${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}/${id}`,
          employeeData
        );
        console.log("Employee updated successfully:", response.data);
        successMessage = t("Employee updated successfully");

        updateLocalStorage(response.data);
      }

      methods.reset();
      localStorage.removeItem("employeeId");
      navigate(t("/emplistpath"));

      // Show toast after navigation
      setTimeout(() => {
        if (successMessage) {
          toast.success(successMessage);
        }
      }, 250);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;

        if (status === 403) {
          toast.error(t("You do not have permission to perform this action."));
        } else if (status === 500) {
          toast.error(t("A server error occurred. Please try again later."));
        } else if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage =
              t(`errorEmployee.${err.errorMessage}`) ||
              t("errorEmployee.An error occurred");
            toast.error(translatedMessage);
          });
        } else {
          toast.error(t("An error occurred"));
        }
      } else {
        toast.error(t("An unexpected error occurred"));
      }
      console.error("Error submitting employee:", error);
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="flex justify-center items-center">
        {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
        <div className="w-full max-w-5xl p-6 rounded shadow-md">
          <h1>{mode === "create" ? t("createmp") : t("editemp")}</h1>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
              }}
            >
              <FormItem>
                <FormLabel>
                  {t("name")} <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <FormControl>
                  <Input
                    {...methods.register("name")}
                    type="search"
                    placeholder={t("putname")}
                  />
                </FormControl>
                {methods.formState.errors.name && (
                  <FormMessage>
                    {methods.formState.errors.name.message}
                  </FormMessage>
                )}
              </FormItem>
              <FormItem>
                <FormLabel>
                  {t("username")}
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <FormControl>
                  <Input
                    {...methods.register("username")}
                    type="search"
                    placeholder={t("putusername")}
                  />
                </FormControl>
                {methods.formState.errors.username && (
                  <FormMessage>
                    {methods.formState.errors.username.message}
                  </FormMessage>
                )}
              </FormItem>


        <div className="flex items-center gap-2">
  <FormItem className="flex-1">
    <FormLabel>{t("opCode")}</FormLabel>
    <FormControl>
      <Controller
        name="opCode"
        control={methods.control}
        render={({ field }) => (
          <>
            {/* Log whenever the Select renders or updates */}
            {console.log("Rendering Select with value:", field.value)}

            <Select
              onValueChange={(value) => {
                console.log("Value changed to:", value); // Log the new value
                field.onChange(value); // Send kodiNeTatime as opCode
              }}
              value={field.value || ""} // Ensure value corresponds to kodiNeTatime
            >
              <SelectTrigger>
                <SelectValue>
                  {field.value
                    ? opcode.find((op) => op.kodiNeTatime === field.value)?.kodiNeTatime // Match based on kodiNeTatime
                    : t("Select Opcode")}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>{t("Opcode List")}</SelectLabel>
                  {opcode.map((op) => (
                    <SelectItem
                      key={op.id}
                      value={op.kodiNeTatime} // Send kodiNeTatime
                      onClick={() =>
                        console.log(
                          "SelectItem clicked:",
                          op.kodiNeTatime,
                          op.emri
                        )
                      } // Log the clicked item
                    >
                      {op.emri} - {op.kodiNeTatime}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </>
        )}
      />
    </FormControl>
    {methods.formState.errors.opCode && (
      <FormMessage>
        {methods.formState.errors.opCode.message}
      </FormMessage>
    )}
  </FormItem>
</div>




          <FormItem>
  <FormLabel>{t("email")}</FormLabel>
  <FormControl>
    <Input
      {...methods.register("email", {
        required: t("emailRequired"), // Custom required message
        pattern: {
          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // Email regex pattern
          message: t("invalidEmail") // Custom message for invalid format
        }
      })}
      type="email"
      placeholder={t("putemail")}
      disabled={isSuperUser} // Disable if the employee is a superuser
    />
  </FormControl>
  {methods.formState.errors.email && (
    <FormMessage>
      {methods.formState.errors.email.message}
    </FormMessage>
  )}
</FormItem>

              <div className="flex items-center gap-2">
                <FormItem className="flex-1">
                  <FormLabel>{t("selectrole")}</FormLabel>
                  <FormControl>
                    <Controller
                      name="roleId"
                      control={methods.control}
                      render={({ field }) => (
                        <Select
                          onValueChange={(value) =>
                            field.onChange(Number(value))
                          }
                          value={field.value?.toString() || ""}
                        >
                          <SelectTrigger>
                            <SelectValue>
                              {field.value
                                ? roles.find((role) => role.id === field.value)
                                    ?.name
                                : "Select Role"}
                            </SelectValue>
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              <SelectLabel>{t("selectrole")} </SelectLabel>
                              {roles.map((role: RoleType) => (
                                <SelectItem
                                  key={role.id}
                                  value={role.id.toString()}
                                >
                                  {role.name}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      )}
                    />
                  </FormControl>
                  {methods.formState.errors.roleId && (
                    <FormMessage>
                      {methods.formState.errors.roleId.message}
                    </FormMessage>
                  )}
                </FormItem>
                <Dialog
                  open={isRoleDialogOpen}
                  onOpenChange={setIsRoleDialogOpen}
                >
                  <DialogTrigger asChild>
                    <Button
                      type="button"
                      className="bg-blue-500 text-white  rounded"
                      style={{
                        marginTop: "31px",
                        height: "35px",
                        width: "35px",
                      }} // Adds space above the button
                    >
                      +
                    </Button>
                  </DialogTrigger>
                  <DialogContent
                    style={
                      window.innerWidth <= 768
                        ? {
                            maxWidth: "400px", // Style for mobile devices (<= 768px)
                            maxHeight: "80vh",
                            overflowY: "auto",
                          }
                        : window.innerWidth <= 999
                        ? {
                            maxWidth: "800px", // Style for tablet devices (769px - 999px)
                            maxHeight: "80vh",
                            overflowY: "auto",
                          }
                        : {
                            maxWidth: "800px", // Style for desktop devices (> 999px)
                            maxHeight: "60vh", // Smaller height for desktop
                            overflowY: "auto",
                          }
                    }
                  >
                    {" "}
                    <RoleForm
                      mode="create"
                      inDialog={true}
                      onClose={handleCloseDialog}
                    />
                  </DialogContent>
                </Dialog>
              </div>
                  
              <div className="flex items-center gap-2">
                <FormItem className="flex-1">
                  <FormLabel>{t("shopId")}</FormLabel>
                  <FormControl>
                    <Controller
                      name="shopId"
                      control={methods.control}
                      render={({ field }) => (
                        <Select
                          onValueChange={(value) =>
                            field.onChange(Number(value))
                          }
                          value={field.value?.toString() || ""}
                        >
                          <SelectTrigger>
                            <SelectValue>
                              {field.value
                                ? shops.find((shop) => shop.id === field.value)
                                    ?.name
                                : t("selectShop")}
                            </SelectValue>
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              <SelectLabel>{t("selectShop")} </SelectLabel>
                              {shops.map((shop: ShopType) => (
                                <SelectItem
                                  key={shop.id}
                                  value={shop.id.toString()}
                                >
                                  {shop.name}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      )}
                    />
                  </FormControl>
                  {methods.formState.errors.shopId && (
                    <FormMessage>
                      {methods.formState.errors.shopId.message}
                    </FormMessage>
                  )}
                </FormItem>
                <Dialog
                  open={isShopDialogOpen}
                  onOpenChange={setIsShopDialogOpen}
                >
                  <DialogTrigger asChild>
                    <Button
                      type="button"
                      className="bg-blue-500 text-white  rounded"
                      style={{
                        marginTop: "31px",
                        height: "35px",
                        width: "35px",
                      }} // Adds space above the button
                    >
                      +
                    </Button>
                  </DialogTrigger>
                  <DialogContent
                    style={
                      window.innerWidth <= 768
                        ? {
                            maxWidth: "300px", // Style for screens 768px or less
                            maxHeight: "80vh",
                            overflowY: "auto",
                          }
                        : window.innerWidth <= 999
                        ? {
                            maxWidth: "600px", // Style for screens between 769px and 999px
                            maxHeight: "80vh",
                            overflowY: "auto",
                          }
                        : {}
                    }
                  >
                    {" "}
                    <ShopForm
                      mode="create"
                      inDialog={true}
                      onClose={handleClose1Dialog}
                    />
                  </DialogContent>
                </Dialog>
              </div>
              <FormItem>
                <FormLabel>{t("salary")}</FormLabel>
                <FormControl>
                  <Input
                    {...methods.register("salary", {
                      valueAsNumber: true,
                    })}
                    type="number"
                                                                    pattern="\d*"

                    placeholder={t("putsalary")}
                  />
                </FormControl>
                {methods.formState.errors.salary && (
                  <FormMessage>
                    {methods.formState.errors.salary.message}
                  </FormMessage>
                )}
              </FormItem>
              {mode === "create" && (
                  <FormItem>
      <FormLabel>{t("password")}</FormLabel>
      <FormControl>
        <div className="relative">
          <Input
            {...methods.register("password")}
            type={showPassword ? "text" : "password"}  // Controlled visibility through state
            placeholder={t("putpassword")}
            className="pr-10"  // Extra padding for the icon
          />
          <button
            type="button"
            onClick={() => setShowPassword((prev: boolean) => !prev)}  // Toggle password visibility
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-gray-300"
          >
            {showPassword ? (
              <EyeIcon className="w-5 h-5" />  // Icon for visible password
            ) : (
              <EyeOffIcon className="w-5 h-5" />  // Icon for hidden password
            )}
          </button>
        </div>
      </FormControl>
      {methods.formState.errors.password && (
        <FormMessage>
          {methods.formState.errors.password.message}
        </FormMessage>
      )}
    </FormItem>
              )}

              <FormItem>
                <FormLabel>{t("telnumber")}</FormLabel>
                <FormControl>
                  <Input
                    {...methods.register("phoneNumber")}
                    type="number"
                                                                    pattern="\d*"

                    placeholder={t("puttelnumber")}
                  />
                </FormControl>
                {methods.formState.errors.phoneNumber && (
                  <FormMessage>
                    {methods.formState.errors.phoneNumber.message}
                  </FormMessage>
                )}
              </FormItem>
            </div>
            <div className="flex flex-col gap-2 sm:flex-row sm:gap-2 mt-5">
              {" "}
              {/* Adds gap for both mobile and larger screens */}
              <Button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                {mode === "create" ? t("savelbtn") : t("savelbtn")}
              </Button>
              <Button
                type="button"
                onClick={handleCancel}
                className="bg-gray-500 text-white px-4 py-2 rounded"
              >
                {mode === "create" ? t("canclelbtn") : t("canclelbtn")}
              </Button>
              {mode === "edit" && isSuperUser1 && (
                <Button
                  type="button"
                  onClick={handleResetPassword}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  {t("Reset Password")}
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </FormProvider>
  );
};

export default EmployeeForm;
