import React from "react";
import AuthProvider from "@/provider/authProvider";
import { SelectedProductsProvider } from "./provider/SelectedProductsContext";
import { DashboardProvider } from "./provider/DashboardContext";
import AppRoutes from "./routes";

const App: React.FC = () => {
  return (
    <SelectedProductsProvider>
      <AuthProvider>
        <DashboardProvider>
          <AppRoutes />
        </DashboardProvider>
      </AuthProvider>
    </SelectedProductsProvider>
  );
};

export default App;
