import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  Controller,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import InventoryDto from "@/models/CreateInventoryDto";
import { useNavigate, useLocation } from "react-router-dom";
import "@/css/MobileScreen.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import { Inventory } from "@/types/InventoryType";
import { Warehouse } from "@/interfaces/WarehouseInterface";
import { Product } from "@/interfaces/ProductInterfaces";
import { Lot } from "@/interfaces/LotInterfaces";
import { InventoryFormProps } from "@/interfaces/InventoryInterfaces";
import DataFetcher from "@/provider/DataFetcher";
import "@/css/LoadingCreate.css";
import { axiosInstance } from "@/lib/utils";



const WAREHOUSE_STORAGE_KEY = "warehouseData";
const PRODUCT_STORAGE_KEY = "productData";
const Lot_STORAGE_KEY = "lotData";

const InventoryForm: React.FC<InventoryFormProps> = ({ mode }) => {

  const { t } = useTranslation();


  const formSchema = z.object({
    warehouseId: z.string().optional().default(""),
    productId: z.string().min(1, { message: t("Product is required") }),
    lotId: z.string().optional().default(""),
    quantity: z.number().min(1, { message: t("Quantity is required") }),
  });
  
  type FormValues = z.infer<typeof formSchema>;
  const [loading, setLoading] = useState(false); // Initialize loading state


  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || { id: localStorage.getItem("inventoryId") };
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      warehouseId: "",
      productId: "",
      lotId: "",
      quantity: 0,
    },
  });

  [];
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [, setLots] = useState<Lot[]>([]);
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const [warehousesResponse, productsResponse, lotsResponse] =
          await Promise.all([
            DataFetcher.getData<Warehouse[]>(
              `${API_URLS.BASE_URL}${API_URLS.WAREHOUSE}`,
              WAREHOUSE_STORAGE_KEY
            ),
            DataFetcher.getData<Product[]>(
              `${API_URLS.BASE_URL}${API_URLS.PRODUCT}`,
              PRODUCT_STORAGE_KEY
            ),
            DataFetcher.getData<Lot[]>(
              `${API_URLS.BASE_URL}${API_URLS.LOT}`,
              Lot_STORAGE_KEY
            ),
          ]);
        setWarehouses(warehousesResponse || []);
        setProducts(productsResponse || []);
        setLots(lotsResponse || []);
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    if (id) {
      localStorage.setItem("inventoryId", id);
    }

    if (mode === "edit" && id) {
      const fetchInventory = async () => {
        try {
          const response = await axiosInstance.get(
            `${API_URLS.BASE_URL}${API_URLS.INVENTORY}/${id}`
          );
          methods.reset(response.data);
        } catch (error) {
          console.error("Error fetching inventory:", error);
        }
      };

      fetchInventory();
    }
  }, [id, mode, methods]);

  const updateLocalStorage = (updatedInventory: Inventory) => {
    const existingInventories = JSON.parse(
      localStorage.getItem("inventoryData") || "[]"
    );
    const updatedInventories = existingInventories.map((inventory: Inventory) =>
      inventory.id === updatedInventory.id ? updatedInventory : inventory
    );
    localStorage.setItem("inventoryData", JSON.stringify(updatedInventories));
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true); // Show loading spinner

    const inventoryData = new InventoryDto({
      warehouseId: parseInt(data.warehouseId),
      productId: parseInt(data.productId),
      lotId: parseInt(data.lotId),
      quantity: data.quantity,
    });
  
    try {
      let successMessage = "";
  
      if (mode === "create") {
        const response = await axiosInstance.post(
          `${API_URLS.BASE_URL}${API_URLS.INVENTORY}`,
          inventoryData
        );
        successMessage = t("Inventory created successfully");
  
        const existingInventories = JSON.parse(
          localStorage.getItem("inventoryData") || "[]"
        );
        existingInventories.push(response.data);
        localStorage.setItem(
          "inventoryData",
          JSON.stringify(existingInventories)
        );
      } else if (mode === "edit" && id) {
        const response = await axiosInstance.put(
          `${API_URLS.BASE_URL}${API_URLS.INVENTORY}/${id}`,
          inventoryData
        );
        successMessage = t("Inventory updated successfully");
  
        updateLocalStorage(response.data);
      }
  
      methods.reset();
      localStorage.removeItem("inventoryId");
      navigate(t("/inventorylistpath"));
  
      // Show toast after navigation
      setTimeout(() => {
        if (successMessage) {
          toast.success(successMessage);
        }
      }, 250);
  
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;
  
        if (status === 403) {
          toast.error(t("You do not have permission to perform this action."));
        } else if (status === 500) {
          toast.error(t("A server error occurred. Please try again later."));
        } else if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage =
              t(`errorInventory.${err.errorMessage}`) ||
              t("errorInventory.An error occurred");
            toast.error(translatedMessage);
          });
        } else {
          toast.error(t("An error occurred"));
        }
      } else {
        toast.error(t("An unexpected error occurred"));
      }
      console.error("Error submitting inventory:", error);
    } 
    finally {
      setLoading(false); // Hide loading spinner
    }
  };
  
  

  return (
    <FormProvider {...methods}>
      <div className="flex justify-center items-center">
      {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
        <div className="w-full max-w-7xl p-6 rounded shadow-md">
          <h1 className="text-xl font-bold mb-6">
            {mode === "create" ? t("createInventory") : t("editInventory")}
          </h1>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="grid grid-cols-2 gap-6">
              <FormItem>
                <FormLabel>{t("warehouseId")}</FormLabel>
                <FormControl>
                  <Controller
                    name="warehouseId"
                    control={methods.control}
                    render={({ field }) => (
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder={t("selectWarehouse")} />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectLabel>{t("warehouse")}</SelectLabel>
                            {warehouses.map((warehouse) => (
                              <SelectItem
                                key={warehouse.id}
                                value={warehouse.id.toString()}
                              >
                                {warehouse.name}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    )}
                  />
                </FormControl>
                {methods.formState.errors.warehouseId && (
                  <FormMessage>
                    {methods.formState.errors.warehouseId.message}
                  </FormMessage>
                )}
              </FormItem>
              <FormItem>
                <FormLabel>{t("productId")}<span style={{ color: "red" }}>*</span></FormLabel>
                <FormControl>
                  <Controller
                    name="productId"
                    control={methods.control}
                    render={({ field }) => (
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder={t("selectProduct")} />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectLabel>{t("product")}</SelectLabel>
                            {products.map((product) => (
                              <SelectItem
                                key={product.id}
                                value={product.id.toString()}
                              >
                                {product.name}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    )}
                  />
                </FormControl>
                {methods.formState.errors.productId && (
                  <FormMessage>
                    {methods.formState.errors.productId.message}
                  </FormMessage>
                )}
              </FormItem>
              {/* <FormItem>
                <FormLabel>{t("lotId")}</FormLabel>
                <FormControl>
                  <Controller
                    name="lotId"
                    control={methods.control}
                    render={({ field }) => (
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder={t("selectLot")} />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectLabel>{t("lot")}</SelectLabel>
                            {lots.map((lot) => (
                              <SelectItem
                                key={lot.id}
                                value={lot.id.toString()}
                              >
                                {lot.lotNumber}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    )}
                  />
                </FormControl>
                {methods.formState.errors.lotId && (
                  <FormMessage>
                    {methods.formState.errors.lotId.message}
                  </FormMessage>
                )}
              </FormItem> */}
              <FormItem>
                <FormLabel>{t("quantity")}<span style={{ color: "red" }}>*</span></FormLabel>
                <FormControl>
                  <Input
                    {...methods.register("quantity", { valueAsNumber: true })}
                    type="number"
                    placeholder={t("putQuantity")}
                    className="w-full"
                                            pattern="\d*"

                  />
                </FormControl>
                {methods.formState.errors.quantity && (
                  <FormMessage>
                    {methods.formState.errors.quantity.message}
                  </FormMessage>
                )}
              </FormItem>
            </div>
            <div className="flex justify-center gap-4 mt-6">
              <Button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                {t("savelbtn")}
              </Button>
              <Button
                type="button"
                onClick={() => navigate(t("/inventorylistpath"))}
                className="bg-gray-500 text-white px-4 py-2 rounded"
              >
                {t("canclelbtn")}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </FormProvider>
  );
};

export default InventoryForm;
