export class CreateSupplierDTO {
  name?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  contact?: string | null;
  idType?: number | null;
  idNumber?: string | null;
  address?: string | null;
  city?: string | null;
  country?: string | null;

  constructor(supplier: {
    name?: string | null;
    phoneNumber?: string | null;
    email?: string | null;
    contact?: string | null;
    idType?: number | null;
    idNumber?: string | null;
    address?: string | null;
    city?: string | null;
    country?: string | null;
  }) {
    this.name = supplier.name ?? null;
    this.phoneNumber = supplier.phoneNumber ?? null;
    this.email = supplier.email ?? null;
    this.contact = supplier.contact ?? null;
    this.idType = supplier.idType ?? 0;
    this.idNumber = supplier.idNumber ?? null;
    this.address = supplier.address ?? null;
    this.city = supplier.city ?? null;
    this.country = supplier.country ?? null;
  }
}
export default CreateSupplierDTO;
