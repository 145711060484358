import SalesStatus from "./SalesStatus";
class CreateSalesOrderDto {
  // orderNo: number;
  orderDateUtc: string;
  tcrCode: string;
  buCode: string;
  opCode: string;
  customerId: number | null;
  currencyId: number;
  totalAmount: number;
  amountDue: number;
  quantity: number;
  vatAmount: number;
  discountAmount: number;
  sameTaxes: number;
  shippingCharges: number;
  adjustedCharges: number;
  // voucherId: number;
  employeeId: number;
  // shopId: number;
  warehouseId: number | undefined;
  paymentTermsId: number | undefined;
  paymentMethodId: number;
  orderType: number;
  orderStatus?: SalesStatus;
  notes: string;
  cashAccountId: number;
  shiftId: number;
  PaymentCurrencyId: number | undefined;
  PaymentExchangeRate: number | undefined;
  PaymentAmount: number | undefined;
  ExchangeRate: number;
  allowNegativeInvetory: boolean;
  salesOrderLines: {
    productId: number;
    uoMId: number;
    quantity: number;
    lineNote: string;
  }[];

  constructor(order: {
    // orderNo: number;
    orderDateUtc: string;
    tcrCode: string;
    buCode: string;
    opCode: string;
    customerId: number | null;
    currencyId: number;
    totalAmount: number;
    amountDue: number;
    quantity: number;
    vatAmount: number;
    discountAmount: number;
    sameTaxes: number;
    shippingCharges: number;
    adjustedCharges: number;
    // voucherId: number;
    employeeId: number;
    // shopId: number;
    warehouseId: number | undefined;
    paymentTermsId: number | undefined;
    paymentMethodId: number;
    orderType: number;
    orderStatus?: SalesStatus;
    notes: string;
    cashAccountId: number;
    shiftId: number;
    PaymentCurrencyId: number | undefined;
    PaymentExchangeRate: number | undefined;
    PaymentAmount: number | undefined;
    ExchangeRate: number;
    allowNegativeInvetory: boolean;
    salesOrderLines: {
      productId: number;
      uoMId: number;
      quantity: number;
      lineNote: string;
    }[];
  }) {
    // this.orderNo = order.orderNo;
    this.orderDateUtc = order.orderDateUtc;
    this.tcrCode = order.tcrCode;
    this.buCode = order.buCode;
    this.opCode = order.opCode;
    this.customerId = order.customerId;
    this.currencyId = order.currencyId;
    this.totalAmount = order.totalAmount;
    this.amountDue = order.amountDue;
    this.quantity = order.quantity;
    this.vatAmount = order.vatAmount;
    this.discountAmount = order.discountAmount;
    this.sameTaxes = order.sameTaxes;
    this.shippingCharges = order.shippingCharges;
    this.adjustedCharges = order.adjustedCharges;
    // this.voucherId = order.voucherId;
    this.employeeId = order.employeeId;
    // this.shopId = order.shopId;
    this.warehouseId = order.warehouseId;
    this.paymentTermsId = order.paymentTermsId;
    this.paymentMethodId = order.paymentMethodId;
    this.orderType = order.orderType;
    this.orderStatus = order.orderStatus;
    this.notes = order.notes;
    this.cashAccountId = order.cashAccountId;
    this.shiftId = order.shiftId;
    this.PaymentAmount = order.PaymentAmount;
    this.PaymentCurrencyId = order.PaymentCurrencyId;
    this.PaymentExchangeRate = order.PaymentExchangeRate;
    this.ExchangeRate = order.ExchangeRate;
    this.allowNegativeInvetory = order.allowNegativeInvetory;
    this.salesOrderLines = order.salesOrderLines;
  }
}

export default CreateSalesOrderDto;
