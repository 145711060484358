import React, { useState } from "react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
    FormControl,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import "@/css/MobileScreen.css";
import { useLocation, useNavigate } from "react-router-dom";
import ChangePasswordDto from "@/models/ChangePasswordDto";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import { axiosInstance } from "@/lib/utils";

// Updated form schema without userType
const formSchema = z.object({
    oldPassword: z.string().min(1, { message: "Current Password is required" }),
    newPassword: z.string().min(1, { message: "New Password is required" }),
    confirmPassword: z.string().min(1, { message: "Confirm Password is required" })
}).refine(data => data.newPassword === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
});

type FormValues = z.infer<typeof formSchema>;

const ChangePasswordForm: React.FC = () => {
    const methods = useForm<FormValues>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
        },
    });

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const { id } = location.state || { id: localStorage.getItem("employeeId") };
const [showPassword, setShowPassword] = useState(false);
const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);  // State to control visibility

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        try {
            // Set userType directly in the changePasswordData
            const changePasswordData = new ChangePasswordDto({
                ...data,
                userType: "employee"
            });

            const response = await axiosInstance.put(
                `${API_URLS.BASE_URL}${API_URLS.ChangePassword}/${id}`,
                changePasswordData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );

            console.log("Password changed successfully:", response.data);
            toast.success(t("Password changed successfully"));

            methods.reset();
            // navigate(t("/emplistpath"));
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                const { data } = error.response;
                if (data && Array.isArray(data)) {
                    data.forEach((err: { errorMessage: string }) => {
                        const translatedMessage = t(`errorChangePassword.${err.errorMessage}`) || t("errorChangePassword.An error occurred");
                        toast.error(translatedMessage);
                    });
                } else {
                    toast.error(t("An error occurred"));
                }
            } else {
                toast.error(t("An unexpected error occurred"));
            }
            console.error("Error changing password:", error);
        }
    };

    const handleCancel = () => {
        navigate(t("/account"));
    };

    return (
        <FormProvider {...methods}>
            <div className="flex justify-center items-center">
                <div className="w-full max-w-3xl p-6 rounded shadow-md">
                    <h1>{t("changePassword")}</h1>
                    <div style={{ padding: "20px" }}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
<FormItem>
  <FormLabel>{t("currentPassword")}</FormLabel>
  <FormControl>
    <div className="relative">
      <Input
        {...methods.register("oldPassword")}
        type={showCurrentPassword ? "text" : "password"}  // Controlled visibility through state
        placeholder={t("enterCurrentPassword")}
        className="pr-10"  // Extra padding for the icon
      />
      <button
        type="button"
        onClick={() => setShowCurrentPassword((prev: boolean) => !prev)}  // Toggle password visibility
        className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-gray-300"
      >
        {showCurrentPassword ? (
          <EyeIcon className="w-5 h-5" />  // Custom icon when password is visible
        ) : (
          <EyeOffIcon className="w-5 h-5" />  // Custom icon when password is hidden
        )}
      </button>
    </div>
  </FormControl>
  {methods.formState.errors.oldPassword && (
    <FormMessage>
      {methods.formState.errors.oldPassword.message}
    </FormMessage>
  )}
</FormItem>


  
<FormItem>
  <FormLabel>{t("newPassword")}</FormLabel>
  <FormControl>
    <div className="relative">
      <Input
        {...methods.register("newPassword")}
        type={showPassword ? "text" : "password"}
        placeholder={t("enterNewPassword")}
        className="pr-10" /* Extra padding for the icon */
      />
      <button
        type="button"
        onClick={() => setShowPassword((prev: boolean) => !prev)}
        className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-gray-300"
      >
        {showPassword ? (
          <EyeIcon className="w-5 h-5" />
        ) : (
          <EyeOffIcon className="w-5 h-5" />
        )}
      </button>
    </div>
  </FormControl>
  {methods.formState.errors.newPassword && (
    <FormMessage>
      {methods.formState.errors.newPassword.message}
    </FormMessage>
  )}
</FormItem>

  
  <FormItem>
      <FormLabel>{t("confirmPassword")}</FormLabel>
      <FormControl>
        <div className="relative">
          <Input
            {...methods.register("confirmPassword")}
            type={showConfirmPassword ? "text" : "password"}  // Controlled visibility through state
            placeholder={t("enterConfirmPassword")}
            className="pr-10"  // Extra padding for the icon
          />
          <button
            type="button"
            onClick={() => setShowConfirmPassword((prev: boolean) => !prev)}  // Toggle password visibility
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-gray-300"
          >
            {showConfirmPassword ? (
              <EyeIcon className="w-5 h-5" />  // Icon for visible password
            ) : (
              <EyeOffIcon className="w-5 h-5" />  // Icon for hidden password
            )}
          </button>
        </div>
      </FormControl>
      {methods.formState.errors.confirmPassword && (
        <FormMessage>
          {methods.formState.errors.confirmPassword.message}
        </FormMessage>
      )}
    </FormItem>
</div>


                            <div
      style={{
        display: "flex",
        flexWrap: "wrap",          // Allows wrapping on smaller screens
        gap: "10px",
        marginTop: "50px",
        justifyContent: "center",  // Center the buttons on smaller screens
      }}
    >
                            <div style={{ display: "flex", gap: "10px", marginTop: "50px" }}>
                                <Button type="submit"  className="bg-blue-500 text-white px-4 py-2 rounded">
                                    {t("changePassword")}
                                </Button>
                                <Button type="button" onClick={handleCancel}  className="bg-blue-500 text-white px-4 py-2 rounded">
                                    {t("canclelbtn")}
                                </Button>
                            </div>
                            </div>
                        </form>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </FormProvider>
    );
};

export default ChangePasswordForm;
