import React, { useState, useEffect, useRef } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useAuth } from "../provider/authProvider";
import { useNavigate } from "react-router-dom";
import API_URLS from "@/config";
import { useTranslation } from "react-i18next";
import DataFetcher from "@/provider/DataFetcher";
import { Button } from "@/components/ui/button";
import { toast, ToastContainer } from "react-toastify";
import "@/css/loading.css";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { axiosInstance } from "@/lib/utils";

interface EmployeeData {
  id: number;
  name: string;
  username: string;
  email: string;
  roleId: number;
  shopId: number;
  phoneNumber: string;
  isSuperUser: boolean;
  roleName?: string;
  shopName?: string;
}

export type Role = {
  id: number;
  name: string;
};

export type Shop = {
  id: number;
  name: string;
};

const ROLE_STORAGE_KEY = "roleData";
const SHOP_STORAGE_KEY = "shopData";
const EMPLOYEE_STORAGE_KEY = "employeeData";

const Account: React.FC = () => {
  const { token, isSuperUser } = useAuth();
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState<EmployeeData | null>(null);
  const [, setRoles] = useState<Role[]>([]);
  const [, setShops] = useState<Shop[]>([]);
  const [, setError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false); // New state for edit mode
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [avatarSrc, setAvatarSrc] = useState<string | undefined>(undefined);
  useEffect(() => {
    const fetchEmployeeData = async () => {
      if (!token) {
        setError("No token provided");
        return;
      }

      try {
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        const employeeId = decodedToken.employeeId;
        console.log(employeeId);
        if (!employeeId) {
          setError("Invalid employeeId in token");
          return;
        }

        console.log("employeeId from Token:", employeeId);

        const [rolesData, shopsData, employeesData] = await Promise.all([
          DataFetcher.getData<Role[]>(
            `${API_URLS.BASE_URL}${API_URLS.ROLE}`,
            ROLE_STORAGE_KEY,
            true
          ),
          DataFetcher.getData<Shop[]>(
            `${API_URLS.BASE_URL}${API_URLS.SHOP}`,
            SHOP_STORAGE_KEY,
            true
          ),
          DataFetcher.getData<EmployeeData[]>(
            `${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}`,
            EMPLOYEE_STORAGE_KEY,
            true
          ),
        ]);

        if (!rolesData || !shopsData || !employeesData) {
          throw new Error("Failed to fetch necessary data");
        }

        const employee = employeesData.find(
          (emp) => emp.id === parseInt(employeeId)
        );

        if (!employee) {
          setError("No employee data found with the employeeId from token");
          return;
        }

        const role = rolesData.find((role) => role.id === employee.roleId);
        const shop = shopsData.find((shop) => shop.id === employee.shopId);

        setEmployeeData({
          ...employee,
          roleName: role ? role.name : "Unknown Role",
          shopName: shop ? shop.name : "Unknown Shop",
        });

        setRoles(rolesData);
        setShops(shopsData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Your Token has expired you need to log in please");
      }
      const savedAvatar = getAvatarFromLocalStorage();
      if (savedAvatar) {
        setAvatarSrc(savedAvatar);
      }
    };
    fetchEmployeeData();
  }, [token]);

  const getAvatarFromLocalStorage = (): string | null => {
    return localStorage.getItem("avatarSrc");
  };

  const handleChangePassword = () => {
    navigate(t("/changepassword"));
  };

  const handleEdit = () => {
    if (isEditing && employeeData) {
      // Save data to the API
      axiosInstance
        .put(
          `${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}/${employeeData.id}`,
          employeeData
        )
        .then(() => {
          console.log("Data saved successfully");
          const createEmployeeSuccess = t("Account updated successfully");
          toast.success(createEmployeeSuccess);
          setIsEditing(false); // Switch back to view mode
        })
        .catch((error) => {
          console.error("Error saving data:", error);
        });
    } else {
      setIsEditing(true); // Switch to edit mode
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (employeeData) {
      setEmployeeData({
        ...employeeData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        if (reader.result) {
          const avatarDataUrl = reader.result as string;
          setAvatarSrc(avatarDataUrl);
          localStorage.setItem("avatarSrc", avatarDataUrl); // Save to localStorage
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleAvatarClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  if (!employeeData) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="p-4 overflow-x-auto">
      <div className="flex flex-col items-center space-y-4">
        <div
          className="relative w-28 h-28 border-4 border-gray-300 flex items-center justify-center"
          style={{ cursor: "pointer" }}
          onClick={handleAvatarClick}
        >
          <Avatar className="w-24 h-24 rounded-full overflow-hidden">
            <AvatarImage
              src={avatarSrc || "https://github.com/shadcn.png"}
              alt="Company Logo"
            />
            <AvatarFallback>{employeeData?.name}</AvatarFallback>
          </Avatar>
          <PencilSquareIcon
            className="absolute bottom-1 right-1 w-7 h-7 text-gray-600 bg-white p-1 rounded-full border border-gray-300 shadow-lg"
            style={{
              cursor: "pointer",
            }}
          />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>
        <h1 className="text-xl font-bold mb-6">{t("account")}</h1>
        <div className="w-full grid grid-cols-1 gap-4 md:grid-cols-2">
          <div className="col-span-1">
            {employeeData?.name && (
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  {t("Employee Name")}
                </label>
                {isEditing ? (
                  <input
                    type="text"
                    name="name"
                    value={employeeData.name}
                    onChange={handleChange}
                    className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm ${
                      isEditing ? "edit-mode" : ""
                    }`}
                  />
                ) : (
                  <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
                    {employeeData.name}
                  </p>
                )}
              </div>
            )}

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("username")}
              </label>
              <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
                {employeeData.username}
              </p>
            </div>
            {employeeData.shopName &&
              employeeData.shopName !== "Unknown Shop" && (
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    {t("shopId")}
                  </label>
                  {isEditing ? (
                    <input
                      type="text"
                      name="shopName"
                      value={employeeData.shopName}
                      onChange={handleChange}
                      className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm ${
                        isEditing ? "edit-mode" : ""
                      }`}
                    />
                  ) : (
                    <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
                      {employeeData.shopName}
                    </p>
                  )}
                </div>
              )}
          </div>
          <div className="col-span-1">
            {isSuperUser !== true &&
              employeeData.roleName !== "Unknown Role" && (
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    {t("selectrole")}
                  </label>
                  <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
                    {employeeData.roleName}
                  </p>
                </div>
              )}

            {employeeData?.phoneNumber && (
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  {t("telnumber")}
                </label>
                {isEditing ? (
                  <input
                    type="text"
                    name="phoneNumber"
                    value={employeeData.phoneNumber}
                    onChange={handleChange}
                    className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm ${
                      isEditing ? "edit-mode" : ""
                    }`}
                  />
                ) : (
                  <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
                    {employeeData.phoneNumber}
                  </p>
                )}
              </div>
            )}

            {employeeData.email && (
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  {t("email")}
                </label>
                {isEditing && !isSuperUser ? (
                  <input
                    type="email"
                    name="email"
                    value={employeeData.email}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm edit-mode"
                  />
                ) : (
                  <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
                    {employeeData.email}
                  </p>
                )}
              </div>
            )}

            {isSuperUser !== false && (
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  {t("superuser")}
                </label>
                <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
                  {employeeData.isSuperUser ? "Yes" : "No"}
                </p>
              </div>
            )}
          </div>
          {isSuperUser !== true && isSuperUser !== false && (
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("superuser")}
              </label>
              <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
                {employeeData.isSuperUser ? "Yes" : "No"}
              </p>
            </div>
          )}
        </div>

        <div className="flex space-x-4 mt-4">
          <Button
            onClick={handleEdit}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            {isEditing ? t("savelbtn") : t("edit")}
          </Button>
          <Button
            onClick={handleChangePassword}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            {t("changePassword")}
          </Button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Account;
