import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  Controller,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import CreateCustomerCategoryDto from "@/models/CreateCategoryCustomerDto";
import { useNavigate, useLocation } from "react-router-dom";
import "@/css/MobileScreen.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import { CategoryCustomer } from "@/types/CustomerCategory";
import { CustomerCategoryFormProps } from "@/interfaces/CustomerCategoryInterfaces";
import DataFetcher from "@/provider/DataFetcher";

import "@/css/LoadingCreate.css";
import { axiosInstance } from "@/lib/utils";

const CustomerCategoryForm: React.FC<CustomerCategoryFormProps> = ({
  mode,
  inDialog = false,
  onClose,
}) => {
  const { t } = useTranslation();

  const formSchema = z.object({
    categoryName: z
      .string()
      .min(1, { message: t("Category Name is required") })
      .max(50, {
        message: t(
          "Name must not be empty and maximum number of characters is 50"
        ),
      }),
    parentCategoryId: z.number().optional(),
    description: z.string().optional(),
  });

  type FormValues = {
    categoryName: string;
parentCategoryId: number | undefined;
    description?: string;
    // image?: string;
  };
  const location = useLocation();
  const { id } = location.state || {
    id: localStorage.getItem("customerCategoryId"),
  };
  const navigate = useNavigate();
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      categoryName: "",
      parentCategoryId: undefined,
      description: "",
      // image: "",
    },
  });
  const CUSTOMERCAT_STORAGE_KEY = "customerCatData";
  const [loading, setLoading] = useState(false); // Initialize loading state

  const [parentCategories, setParentCategories] = useState<
    { id: number; name: string }[]
  >([]);

  useEffect(() => {
    const fetchParentCategories = async () => {
      try {
        const response = await DataFetcher.getData<
          { id: number; name: string }[]
        >(
          `${API_URLS.BASE_URL}${API_URLS.CUSTOMER_CATEGORY}`,
          CUSTOMERCAT_STORAGE_KEY
        );
 if (response) {
        setParentCategories(response); // Directly setting the response without the default item
      }
      } catch (error) {
        console.error("Error fetching parent categories:", error);
      }
    };

    fetchParentCategories();

    if (id) {
      localStorage.setItem("customerCategoryId", id);
    }

    if (mode === "edit" && id) {
      const fetchCategory = async () => {
        try {
          const response = await axiosInstance.get(
            `${API_URLS.BASE_URL}${API_URLS.CUSTOMER_CATEGORY}/${id}`
          );
          const categoryData = response.data;
          methods.reset({
            categoryName: categoryData.name,
            parentCategoryId: categoryData.parentCategoryId
  ? parseInt(categoryData.parentCategoryId.toString(), 10)
  : undefined,
            description: categoryData.description,
            // image: categoryData.image,
          });
        } catch (error) {
          console.error("Error fetching category:", error);
        }
      };

      fetchCategory();
    }
  }, [id, mode, methods]);

  const updateLocalStorage = (updatedCategory: CategoryCustomer) => {
    // Retrieve the existing categories from localStorage
    let existingCategories = JSON.parse(
      localStorage.getItem("customerCatData") || "[]"
    );

    // Check if the existingCategories is an array, otherwise set it as an empty array
    if (!Array.isArray(existingCategories)) {
      existingCategories = [];
    }

    // Update the matching category in the array
    const updatedCategories = existingCategories.map(
      (category: CategoryCustomer) =>
        category.id === updatedCategory.id ? updatedCategory : category
    );

    // Save the updated array back to localStorage
    localStorage.setItem("customerCatData", JSON.stringify(updatedCategories));
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true); // Show loading spinner

    try {
      
     

      const categoryData = new CreateCustomerCategoryDto({
        parentCategoryId: data.parentCategoryId,
        name: data.categoryName,
        description: data.description,
        // image: data.image,
      });

      let successMessage = "";

      if (mode === "create") {
        const response = await axiosInstance.post(
          `${API_URLS.BASE_URL}${API_URLS.CUSTOMER_CATEGORY}`,
          categoryData
        );
        console.log("Category created successfully:", response.data);
        successMessage = t("Category created successfully");

        const existingCustomerCategory = JSON.parse(
          localStorage.getItem("customerCatData") || "[]"
        );

        // Ensure it's an array
        if (Array.isArray(existingCustomerCategory)) {
          existingCustomerCategory.push(response.data);
        } else {
          // If it's not an array, create a new array
          const newCustomerCategory = [response.data];
          localStorage.setItem(
            "customerCatData",
            JSON.stringify(newCustomerCategory)
          );
        }

        if (inDialog && onClose) {
          onClose(response.data);
        }
      } else if (mode === "edit" && id) {
        const response = await axiosInstance.put(
          `${API_URLS.BASE_URL}${API_URLS.CUSTOMER_CATEGORY}/${id}`,
          categoryData
        );
        console.log("Category updated successfully:", response.data);
        successMessage = t("Category updated successfully");

        updateLocalStorage(response.data);

        if (inDialog && onClose) {
          onClose();
        }
      }

      methods.reset();
      localStorage.removeItem("customerCategoryId");

      if (!inDialog) {
        navigate(t("/categorylsitcustomer"));
      }

      // Show toast after navigation
      setTimeout(() => {
        if (successMessage) {
          toast.success(successMessage);
        }
      }, 250);
    } catch (error) {
  if (axios.isAxiosError(error) && error.response) {
    const { status, data } = error.response;

    if (status === 403) {
      toast.error(t("You do not have permission to perform this action."));
    } else if (status === 500) {
      toast.error(t("A server error occurred. Please try again later."));
    } else if (data && Array.isArray(data)) {
      data.forEach((err: { errorMessage: string }) => {
        const translatedMessage =
          t(`errorProductCategory.${err.errorMessage}`) ||
          t("errorProductCategory.An error occurred");
        toast.error(translatedMessage);
      });
    } else if (data && data.message === "Cycle detected") {
      // Handle the specific "Cycle detected" message
      toast.error(t("You can't put the same category for name and parent category"));
    } else {
      toast.error(t("An error occurred"));
    }
  } else {
    toast.error(t("An unexpected error occurred"));
  }
  console.error("Error submitting Product Category:", error);
} finally {
      setLoading(false); // Hide loading spinner
    }
  };

  const handleDialogSubmit = () => {
    methods.handleSubmit(onSubmit)();
  };

  return (
    <FormProvider {...methods}>
      <div className="flex justify-center items-center">
        {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
        <div className="w-full max-w-lg p-6 rounded shadow-md">
          <h1 className="text-xl font-bold mb-6">
            {mode === "create"
              ? t("createcustomercategory")
              : t("editcustomercategory")}
          </h1>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleDialogSubmit();
            }}
          >
            <div className="space-y-4">
              <FormItem>
                <FormLabel>
                  {t("categoryname")}
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <FormControl>
                  <Input
                    {...methods.register("categoryName")}
                    type="search"
                    placeholder={t("putcategoryname")}
                    className="w-full"
                  />
                </FormControl>
                {methods.formState.errors.categoryName && (
                  <FormMessage>
                    {methods.formState.errors.categoryName.message}
                  </FormMessage>
                )}
              </FormItem>
                <FormItem>
  <FormLabel>{t("parentcategory")}</FormLabel>
  <FormControl>
    <Controller
      name="parentCategoryId"
      control={methods.control}
      render={({ field }) => (
        <Select
          onValueChange={(value) => field.onChange(Number(value))}  // Convert string to number on change
          value={field.value?.toString()}  // Ensure value is a string when rendering
        >
          <SelectTrigger>
            <SelectValue placeholder={t("putparentcategory")} />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>{t("parentcategory")}</SelectLabel>
              {parentCategories.map((category) => (
                <SelectItem key={category.id} value={category.id.toString()}>
                  {category.name}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      )}
    />
  </FormControl>
  {methods.formState.errors.parentCategoryId && (
    <FormMessage>
      {methods.formState.errors.parentCategoryId.message}
    </FormMessage>
  )}
</FormItem>
              <FormItem>
                <FormLabel>{t("description")}</FormLabel>
                <FormControl>
                  <Input
                    {...methods.register("description")}
                    type="search"
                    placeholder={t("putdescription")}
                    className="w-full"
                  />
                </FormControl>
              </FormItem>
              {/* <FormItem>
                <FormLabel>{t("image")}</FormLabel>
                <FormControl>
                  <Input
                    {...methods.register("image")}
                    type="file"
                    accept="image/*"
                    className="w-full"
                  />
                </FormControl>
              </FormItem> */}
            </div>
            <div className="flex gap-4 mt-6">
              <Button
                type="button"
                onClick={handleDialogSubmit}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                {t("savelbtn")}
              </Button>
              <Button
                type="button"
                onClick={() =>
                  inDialog ? onClose?.() : navigate(t("/categorylsitcustomer"))
                }
                className="bg-gray-500 text-white px-4 py-2 rounded"
              >
                {t("canclelbtn")}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </FormProvider>
  );
};

export default CustomerCategoryForm;
