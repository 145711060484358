import React, { useEffect, useMemo, useState } from "react";
import { useDashboard } from "@/provider/DashboardContext";
import { useTranslation } from "react-i18next";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const ProfitChart: React.FC = () => {
  const { data, loading } = useDashboard();
  const { t } = useTranslation();
  const [profitData, setProfitData] = useState<
    { name: string; profit: number }[]
  >([]);

  const monthNames = useMemo(
    () => [
      t("Jan"),
      t("Feb"),
      t("Mar"),
      t("Apr"),
      t("May"),
      t("Jun"),
      t("Jul"),
      t("Aug"),
      t("Sep"),
      t("Oct"),
      t("Nov"),
      t("Dec"),
    ],
    [t]
  );

  useEffect(() => {
    if (!loading && data) {
      const salesData = data.salesByMonths.reduce<Record<number, number>>(
        (acc, item) => {
          acc[item.month] = item.amount;
          return acc;
        },
        {}
      );

      const purchaseData = data.purchasesByMonths.reduce<
        Record<number, number>
      >((acc, item) => {
        acc[item.month] = item.amount;
        return acc;
      }, {});

      const combinedData = Array.from({ length: 12 }, (_, index) => ({
        name: monthNames[index],
        profit: parseFloat(
          (
            (salesData[index + 1] || 0) - (purchaseData[index + 1] || 0)
          ).toFixed(2)
        ),
      }));

      setProfitData(combinedData);
    }
  }, [loading, data, monthNames]);

  if (loading) return <p>{t("Loading...")}</p>;
  if (!data) return <p>{t("No data available")}</p>;

  return (
    <div style={{ width: "100%", height: 300 }}>
      <h2>{t("Monthly Profit")}</h2>
      <ResponsiveContainer>
        <BarChart data={profitData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="profit" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ProfitChart;
