import React, { useEffect, useState } from "react";
import "@/css/thermalPrint.css";
import API_URLS from "@/config";
import { useTranslation } from "react-i18next";
// import axios from "axios";
import { useAuth } from "@/provider/authProvider";
import { useNavigate } from "react-router-dom";
import DataFetcher from "@/provider/DataFetcher";
import { UoM } from "@/interfaces/UomInterfaces";

interface CompanyData {
  name: string;
  id: number;
  tin: string;
  employees: number;
  additionalInfo: string;
  city: string;
  country: string;
  phoneNumber: string;
  email: string;
  fieldOfActivity: string;
  fiscalizationCertificate: string;
  isSeasonal: boolean;
  subscriptionStartDateUtc: string;
  subscriptionEndDateUtc: string;
}

type Product = {
  id: string;
  name: string;
  price: number;
  quantity: number;
  vatRateId: number;
  uoMId: number;
};

interface Employee {
  id: string;
  name: string;
}

interface ExchangeRate {
  currency1Id: number;
  currency2Id: number;
  rate: number;
}

type InvoiceProps = {
  orderProducts: Product[];
  totalAmountExVat: number;
  vatAmount: number;
  totalAmountIncVat: number;
  getVATRate: (vatRateId: number) => number;
  getVATRateName: (vatRateId: number) => string;
  calculateVATPerType: () => Record<number, number>;
  tableId: string; // Add tableId to InvoiceProps
  // employeeID: string;
  employeeId: string;
  tablename: string; // Add tableId to InvoiceProps
  orderNo: undefined;
  // fiscInvOrdNum: number | undefined;
  // buCode: string | undefined;
  // opCode: string | undefined;
  // tcrCode: string | undefined;
};
interface BusinessConfigs {
  allowNegativeInventory: boolean;
  baseCurrencyId: number;
  secondCurrencyId: number; // Added second currency ID
  printerType: number;
}

// Define TypeScript interface for a Currency
interface Currency {
  id: number;
  name: string;
  symbol: string;
}

const PosBAR = React.forwardRef<HTMLDivElement, InvoiceProps>((props, ref) => {
  const {
    orderProducts,
    totalAmountExVat,
    vatAmount,
    totalAmountIncVat,
    getVATRateName,
    calculateVATPerType = () => ({}),
    tablename,
    employeeId,
    // fiscInvOrdNum,
    // buCode,
    // opCode,
    // tcrCode,
  } = props;

  const ExchangeRate_STORAGE_KEY = "exchangerateData";
  const EMPLOYEE_STORAGE_KEY = "employeeSalesData";
  const Currency_STORAGE_KEY = "currencyData";
  const COMPANY_DATA_STORAGE_KEY = "businessData";
  const UOM_STORAGE_KEY = "uomData";
  const [businessData, setBusinessData] = useState<CompanyData | null>(null);

  const [exchangeRates, setExchangeRates] = useState<ExchangeRate[]>([]);
  const [employee, setEmployee] = useState<Employee | null>(null);
  const [printerType, setPrinterType] = useState();
  const [uoms, setUoms] = useState<UoM[]>([]);
  const { setToken, token } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [baseCurrencyName, setBaseCurrencyName] = useState("Lekë"); // Default currency

  const [baseCurrencySymbol, setBaseCurrencySymbol] = useState("L"); // Default currency symbol
  const [currencies, setCurrencies] = useState<Currency[]>([]); // Holds list of currencies

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        // Fetch data directly from the API
        const response = await DataFetcher.getData<CompanyData>(
          `${API_URLS.BASE_URL}${API_URLS.LOGGEDBUSINESS}`,
          COMPANY_DATA_STORAGE_KEY
        );

        // Set the fetched data directly to state
        if (response && typeof response === "object") {
          setBusinessData(response);
        } else {
          console.warn("Unexpected data format received:", response);
        }
      } catch (error) {
        console.error("Error during fetchCompanyData", error);
      }

      // Fetch avatar from localStorage
    };

    const fetchEmployeeData = async () => {
      const employeeData = await DataFetcher.getData<Employee>(
        `${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}/${employeeId}`,
        EMPLOYEE_STORAGE_KEY
      );

      if (employeeData) {
        setEmployee(employeeData);
      } else {
        throw new Error("Failed to fetch employee data");
      }
    };

    fetchCompanyData();
    fetchEmployeeData();
  }, [token, setToken, navigate, employeeId]);

  useEffect(() => {
    // Fetch available currencies using DataFetcher with caching
    const fetchCurrencyData = async () => {
      try {
        const response = await DataFetcher.getData<Currency[]>(
          `${API_URLS.BASE_URL}${API_URLS.Currency}`, // API endpoint
          Currency_STORAGE_KEY // LocalStorage key
        );

        setCurrencies(response || []); // Set fetched currencies, default to an empty array if none

        // Retrieve business configs from localStorage
        const businessConfigs: BusinessConfigs = JSON.parse(
          localStorage.getItem("businessConfigs") || "{}"
        );

        const baseCurrencyId = businessConfigs.baseCurrencyId ?? 1; // Default to 1

        const baseCurrency = response?.find(
          (currency) => currency.id === baseCurrencyId
        );

        setBaseCurrencyName(baseCurrency?.name || "Leke"); // Default to "Leke"
        setBaseCurrencySymbol(baseCurrency?.symbol || "L"); // Default to "L"

        // Fetch exchange rates and find the correct one
        const exchangeRatesResponse = await DataFetcher.getData<ExchangeRate[]>(
          `${API_URLS.BASE_URL}${API_URLS.ExchangeRates}`,
          ExchangeRate_STORAGE_KEY
        );

        if (exchangeRatesResponse && exchangeRatesResponse.length > 0) {
          // Set all exchange rates
          setExchangeRates(exchangeRatesResponse);
        } else {
          console.error("Exchange rates data is empty or null.");
        }
      } catch (error) {
        console.error("Error fetching currencies or exchange rates:", error);
      }
    };

    fetchCurrencyData();
  }, [t]);
  const calculateTotalInCurrency = (rate: number) => {
    const totalInLek = parseFloat(totalAmountIncVat.toString());
    return (totalInLek / rate).toFixed(2);
  };

  const getCurrencyNameById = (currencyId: number) => {
    const currency = currencies.find((currency) => currency.id === currencyId);
    return currency ? currency.name : "Unknown Currency";
  };
  useEffect(() => {
    const fetchUoms = async () => {
      try {
        const response = await DataFetcher.getData<UoM[]>(
          `${API_URLS.BASE_URL}${API_URLS.UOM}`,
          UOM_STORAGE_KEY
        );
        setUoms(response || []);
      } catch (error) {
        console.error("Error fetching UoMs:", error);
      }
    };

    fetchUoms();
  }, []);

  const getUoMName = (uoMId: number) => {
    const uom = uoms.find((u) => u.id === uoMId);
    return uom ? uom.name : t("Unknown UoM");
  };
  useEffect(() => {
    // Fetch the businessConfigs from localStorage
    const businessConfigs = JSON.parse(
      localStorage.getItem("businessConfigs") || "{}"
    );
    const storedPrinterType = businessConfigs?.printerType;
    setPrinterType(storedPrinterType);
    console.log(storedPrinterType);
  }, []);
  const invoiceClass =
    printerType === 1 ? "invoice-print-a4" : "invoice-print-thermal";
  const hasVat =
    JSON.parse(localStorage.getItem("businessConfigs") || "{}").hasVat ?? false;

  const printOthersExchRat =
    JSON.parse(localStorage.getItem("printerConfig") || "{}")
      .displayExchangeRates ?? false;
  const showCustomerInfo =
    JSON.parse(localStorage.getItem("printerConfig") || "{}")
      .displayCustomerInfo ?? false;
  // const showAllCashAccount =
  //   JSON.parse(localStorage.getItem("printerConfig") || "{}")
  //     .displayBankAccounts ?? false;
  const displayEmployeeName =
    JSON.parse(localStorage.getItem("printerConfig") || "{}")
      .displayEmployeeName ?? false;
  const invoiceHeader = JSON.parse(
    localStorage.getItem("printerConfig") || "{}"
  ).invoiceHeader;
  const invoiceFooter = JSON.parse(
    localStorage.getItem("printerConfig") || "{}"
  ).invoiceFooter;
  const displayHeader = true;
  const displayFooter = true;
  return (
    <div ref={ref} className={invoiceClass}>
      {printerType === 1 ? (
        <>
          {/* A4 Invoice Layout */}
          <div className="invoice-header-a4">
            {displayHeader && <h3>{invoiceHeader}</h3>}
            <div className="company-details-a4">
              <h2>{businessData ? businessData.name : t("Company Name")}</h2>
              <p>{businessData ? businessData.tin : t("tin")}</p>
              <p>
                {businessData ? businessData.city : t("AdrressCity")},{" "}
                {businessData ? businessData.country : t("AdrressCountry")}
              </p>
              <p>{businessData ? businessData.email : t("Email")}</p>
              <p>
                {businessData ? businessData.phoneNumber : t("phoneNumber")}
              </p>
            </div>
            <div className="invoice-details-a4">
              {/* <p>
                {fiscInvOrdNum}/{new Date().getFullYear()}/{tcrCode}
              </p>
              <p>
                {t("BusinessUnit")}: {buCode}
              </p>
              <p>
                {t("OpCode")}: {opCode}
              </p> */}
              <p>
                {t("Date")} : {new Date().toLocaleDateString()}
              </p>
            </div>
          </div>

          <div className="customer-details-a4">
            {showCustomerInfo && <p>{t("customer")}: Rasti</p>}
            {tablename &&
              tablename !== "Unknown Table" && ( // Hide if tablename is null, undefined, or equals "Unknown Table"
                <p>
                  {t("Table")}: {tablename}
                </p>
              )}
            {displayEmployeeName && (
              <p>
                {t("employeeName")}: {employee ? employee.name : t("loading")}
              </p>
            )}
          </div>

          <table className="invoice-items-a4">
            <thead>
              <tr>
                <th style={{ width: "20%" }}>{t("ITEM")}</th>
                <th style={{ width: "10%" }}>{t("QUANTITY")}</th>
                <th style={{ width: "10%" }}>{t("uom")}</th>
                <th style={{ width: "10%" }}>{t("UNITCOST")}</th>
                <th style={{ width: "10%" }}>{t("VAT Amount")}</th>
                <th style={{ width: "10%" }}>{t("Price no vat Amount")}</th>
                <th style={{ width: "10%" }}>{t("Value no Vat")}</th>
                <th style={{ width: "10%" }}>{t("VAT Amount Total")}</th>
                {/* {hasDiscount && (
                  <th style={{ width: "5%" }}>{t("DISCOUNT")}</th>
                )} */}
                <th style={{ width: "15%" }}>{t("TOTAL")}</th>
              </tr>
            </thead>
            <tbody>
              {orderProducts.map((product) => {
                const vatRate = props.getVATRate(product.vatRateId) || 0;
                const vatAmount =
                  (product.price - product.price / (1 + vatRate / 100)) *
                  product.quantity;
                const priceExclVAT =
                  product.price -
                  (product.price - product.price / (1 + vatRate / 100));
                const vat = product.price - product.price / (1 + vatRate / 100);
                const valueExclVAT = product.quantity * priceExclVAT;
                const totalValue = product.price * product.quantity;
                // const priceWithDiscount =
                //   discountType === "percentage"
                //     ? product.price * quantity * (1 - (discount || 0) / 100)
                //     : product.price * quantity - (discount || 0);

                return (
                  <tr key={product.id}>
                    <td className="invoice-td">{product.name}</td>
                    <td className="invoice-td">{product.quantity}</td>
                    <td className="invoice-td">{getUoMName(product.uoMId)}</td>
                    <td className="invoice-td">
                      {product.price.toFixed(2)} {baseCurrencySymbol}
                    </td>
                    <td className="invoice-td">
                      {vat.toFixed(2)} {baseCurrencySymbol}
                    </td>
                    <td className="invoice-td">
                      {priceExclVAT.toFixed(2)} {baseCurrencySymbol}
                    </td>
                    <td className="invoice-td">
                      {valueExclVAT.toFixed(2)} {baseCurrencySymbol}
                    </td>
                    <td className="invoice-td">
                      {vatAmount.toFixed(2)} {baseCurrencySymbol}
                    </td>
                    {/* {hasDiscount && (
                          <td className="invoice-td">
                            {discount !== undefined
                              ? `${discount} ${
                                  discountType === "percentage"
                                    ? "%"
                                    : baseCurrencySymbol
                                }`
                              : "0 " + baseCurrencySymbol}
                          </td>
                        )} */}
                    <td className="invoice-td">
                      {totalValue.toFixed(2)} {baseCurrencySymbol}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="invoice-row">
            <div className="invoice-code-section">
              {displayFooter && (
                <div className="invoice-footer-a4">
                  <p>{invoiceFooter}</p>
                  <p>{businessData ? businessData.name : t("Company Name")}</p>
                </div>
              )}
            </div>
            <div className="invoice-summary-section">
              {hasVat && (
                <table className="invoice-vats-a4">
                  <thead className="invoice-head-vats-a4">
                    <tr>
                      <th>{t("Vat Type")}</th>
                      <th>{t("Items")}</th>
                      <th>{t("Value no Vat")}</th>
                      <th>{t("VAT")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(calculateVATPerType()).map(
                      ([vatRateId, vatSum]) => {
                        const vatRateName =
                          getVATRateName(Number(vatRateId)) || t("Unknown VAT");
                        const vatItemCount = orderProducts.filter(
                          (product) => product.vatRateId === Number(vatRateId)
                        ).length;

                        const valueNoVatSum = orderProducts
                          .filter(
                            (product) => product.vatRateId === Number(vatRateId)
                          )
                          .reduce((sum, product) => {
                            const priceExclVAT =
                              product.price -
                              (product.price -
                                product.price /
                                  (1 +
                                    props.getVATRate(product.vatRateId) / 100));
                            return sum + priceExclVAT * product.quantity;
                          }, 0);

                        return (
                          <tr key={vatRateId}>
                            <th>
                              {t("VAT")} {vatRateName}
                            </th>
                            <td>{vatItemCount}</td>
                            <td>
                              {valueNoVatSum.toFixed(2)} {baseCurrencySymbol}
                            </td>
                            <td>
                              {Number(vatSum).toFixed(2)} {baseCurrencySymbol}
                            </td>
                          </tr>
                        );
                      }
                    )}
                    <tr>
                      <th>{t("VATTotal")}</th>
                      <td></td>
                      <td></td>
                      <td>
                        {vatAmount.toFixed(2)}
                        {baseCurrencySymbol}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
              <table className="invoice-totals-a4">
                <tbody>
                  {hasVat && (
                    <tr>
                      <th>{t("Subtotal")}:</th>
                      <td>
                        {totalAmountExVat.toFixed(2)} {baseCurrencySymbol}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th> {t("TOTALlek")}</th>{" "}
                    <td>
                      {totalAmountIncVat.toFixed(2)} {baseCurrencySymbol}
                    </td>
                  </tr>
                  {exchangeRates.map((rate, index) => {
                    const currency1Name = getCurrencyNameById(rate.currency1Id);
                    const calculatedTotal = calculateTotalInCurrency(rate.rate);
                    const currency1Symbol = currencies.find(
                      (currency) => currency.name === currency1Name
                    )?.symbol;

                    return (
                      <tr key={index}>
                        <th>
                          {t("TOTALlek")}
                          {currency1Name}
                          {`( Exch.rate:${rate.rate})`}
                        </th>
                        <td>
                          {Number(calculatedTotal).toFixed(2)} {currency1Symbol}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="invoice-header-thermal">
            {displayHeader && <h3>{invoiceHeader}</h3>}
            <div className="company-details-thermal">
              <h2>{businessData ? businessData.name : t("Company Name")}</h2>
              <p>{businessData ? businessData.tin : t("tin")}</p>
              <p>
                {businessData ? businessData.city : t("AdrressCity")},
                {businessData ? businessData.country : t("AdrressCountry")}
              </p>
              <p>{businessData ? businessData.email : t("Email")}</p>
              <p>
                {businessData ? businessData.phoneNumber : t("phoneNumber")}
              </p>
            </div>
            <div className="invoice-details-thermal">
              {/* <p>
                {fiscInvOrdNum}/{new Date().getFullYear()}/{tcrCode}
              </p>
              <p>
                {t("BusinessUnit")}: {buCode}
              </p>
              <p>
                {t("OpCode")}: {opCode}
              </p> */}
              <p>
                {t("Date")} : {new Date().toLocaleDateString()}
              </p>
              <h2>{t("Porosi")}</h2>
            </div>
          </div>
          <div className="customer-details-thermal">
            {showCustomerInfo && <p>{t("customer")}: Rasti</p>}
            {tablename &&
              tablename !== "Unknown Table" && ( // Hide if tablename is null, undefined, or equals "Unknown Table"
                <p>
                  {t("Table")}: {tablename}
                </p>
              )}
            {displayEmployeeName && (
              <p>
                {t("employeeName")}: {employee ? employee.name : t("loading")}
              </p>
            )}
          </div>

          <div className="invoice-items-thermal">
            {/* <div className="invoice-item-thermal header">
                <span>{t("ITEMSales")}</span>
                <span>{t("QUANTITY")}</span>
                <span>{t("UNITCOST")}</span>
                <span>{t("TOTAL")}</span>
              </div> */}
            {orderProducts.map((product) => (
              <React.Fragment key={product.id}>
                <div className="invoice-item-therma flex items-center justify-between">
                  <div className="flex items-center">
                    <span className="text-left">{product.quantity}</span>
                    <span className="text-left pl-1">
                      {getUoMName(product.uoMId)}
                    </span>
                    <span className="pl-1">x</span>
                    <span className="text-right pl-1 w-2/5">
                      {product.price.toFixed(2)}
                      {baseCurrencySymbol}
                    </span>
                  </div>
                  <div className="text-right w-3/5">
                    {(product.price * product.quantity).toFixed(2)}
                    {baseCurrencySymbol}
                  </div>
                </div>
                <div className="invoice-product-discount flex w-full">
                  <span className="w-1/2 product-name pl-3 text-left">
                    {product.name}
                  </span>
                </div>
              </React.Fragment>
            ))}
          </div>
          <div className="invoice-totals-thermal">
            {hasVat && (
              <div className="totals-row-thermal">
                <span>{t("Subtotal")}</span>
                <span>
                  {totalAmountExVat.toFixed(2)}
                  {baseCurrencySymbol}
                </span>
              </div>
            )}
            {hasVat &&
              Object.entries(calculateVATPerType()).map(
                ([vatRateId, vatSum]) => {
                  const vatRateName =
                    getVATRateName(Number(vatRateId)) || t("Unknown VAT");
                  return (
                    <div className="totals-row-thermal" key={vatRateId}>
                      <span>
                        <span>{t("VAT")}:</span>
                        {vatRateName}
                      </span>
                      <span>
                        {Number(vatSum).toFixed(2)} {baseCurrencySymbol}
                      </span>
                    </div>
                  );
                }
              )}
            {hasVat && (
              <div className="totals-row-thermal">
                <span>{t("VATTotal")}</span>
                <span>
                  {vatAmount.toFixed(2)}
                  {baseCurrencySymbol}
                </span>
              </div>
            )}
            <div className="totals-row-thermal">
              <span>
                <strong>
                  {t("TOTALlek")}
                  {baseCurrencyName}
                </strong>
              </span>
              <span>
                <strong>
                  {totalAmountIncVat.toFixed(2)}
                  {baseCurrencySymbol}
                </strong>
              </span>
            </div>
            {printOthersExchRat &&
              exchangeRates.map((rate, index) => {
                const currency1Name = getCurrencyNameById(rate.currency1Id);
                const calculatedTotal = calculateTotalInCurrency(rate.rate);
                const currency1Symbol = currencies.find(
                  (currency) => currency.name === currency1Name
                )?.symbol;

                return (
                  <div className="totals-row-thermal" key={index}>
                    <span>
                      <strong>
                        {t("TOTALlek")}
                        {currency1Name}
                      </strong>{" "}
                      {`( Exch.rate:${rate.rate})`}
                    </span>
                    <span>
                      <strong>
                        {Number(calculatedTotal).toFixed(2)} {currency1Symbol}
                      </strong>
                    </span>
                  </div>
                );
              })}
          </div>
          {displayFooter && (
            <div
              className="invoice-footer-thermal"
              style={{ paddingTop: "40px" }}
            >
              <p>{invoiceFooter}</p>
              <p>{businessData ? businessData.name : t("Company Name")}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
});

PosBAR.displayName = "PosBAR";

export default PosBAR;
