import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { axiosInstance } from "@/lib/utils";
import { Input } from "@/components/ui/input";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import "@/css/salesorder2.css";
import "@/css/MobileScreen.css";

import API_URLS from "@/config";

// import { ShoppingCart } from "lucide-react"; // Example icons for customer and payment method
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useReactToPrint } from "react-to-print";
import { useAuth } from "@/provider/authProvider";
import DataFetcher from "@/provider/DataFetcher";
import "@/css/fastsale.css";
import { Warehouse } from "@/types/WarehouseType";
import { CashAccount } from "@/types/CashAccountType";
import { Paymentmethods } from "@/types/PaymentMethdosType";
import { useNavigate } from "react-router-dom";
import { ProductFast } from "@/types/ProductType";
import { useFastSaleProducts } from "@/provider/SelectedProductsContext";
import FastOrderDto from "@/models/FastOrderDto";
import FastOrder from "@/componentsPos/FastOrderprint";
import { ExchangeRate } from "@/types/ExchangeRateType";
import { VATType } from "@/types/VatType";
import { Employee } from "@/types/EmployeeType";
import { Shop } from "@/types/ShopType";

// type Product = {
//   id: string;
//   name: string;
//   barcode: string;
//   price: number;
//   uoMId: number;
//   vatRateId: number;
//   categoryId: string;
//   quantity: number;
//   vatRate: number;
//   isRawMaterial: boolean;
// };

type ProductCategory = {
  id?: string;
  name: string;
};

type VatRate = {
  id: number;
  rate: number;
};

type Customer = {
  id: string;
  name: string;
};

type Table = {
  id: number;
  name: string;
};
interface BusinessConfigs {
  allowNegativeInventory: boolean;
  baseCurrencyId: number;
  secondCurrencyId: number; // Added second currency ID
  printerType: number;
}

// Define TypeScript interface for a Currency
interface Currency {
  id: number;
  name: string;
  symbol: string;
}

const FastSale: React.FC = () => {
  const PRODUCT_STORAGE_KEY = "productData";
  const CATEGORY_STORAGE_KEY = "categoryData";
  const VAT_STORAGE_KEY = "vatData";
  const CUSTOMER_STORAGE_KEY = "customerData";
  const CashAccount_STORAGE_KEY = "cashaccountData";
  const Currency_STORAGE_KEY = "currencyData";
  const WAREHOUSE_STORAGE_KEY = "warehouseData";
  const PAYMENT_METHOD_STORAGE_KEY = "paymentMethodData";
  const ExchangeRate_STORAGE_KEY = "exchangerateData";
  const EMPLOYEE_STORAGE_KEY = "employeeData";
  const SHOP_STORAGE_KEY = "shopData";

  const [productList, setProductList] = useState<ProductFast[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<ProductFast[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [warehouse, setWarehouse] = useState<number>();
  const { fastSaleProducts, setFastSaleProducts } = useFastSaleProducts();
  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [totalAmountExVat, setTotalAmountExVat] = useState<number>(0);
  // const [employeeID, setEmployeeID] = useState<number | null>(null);
  const { employeeId, isSuperUser } = useAuth();
  const [baseCurrencyName, setBaseCurrencyName] = useState("Lekë"); // Default currency
  const [baseCurrencySymbol, setBaseCurrencySymbol] = useState("L"); // Default currency symbol
  const [cashAccounts, setCashAccounts] = useState<CashAccount[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<Paymentmethods[]>([]);
  const [, setemp] = useState<Employee[]>([]);
  const [, setshop] = useState<Shop[]>([]);
  const [selectedTcrCode, setSelectedTcrCode] = useState<string | null>(null);
  const [, setCurrencies] = useState<Currency[]>([]); // Holds list of currencies
  const [vatAmount, setVatAmount] = useState<number>(0);
  const [, setCashAccountBalance] = useState<number>(0);
  const [, setTotalQuantity] = useState(0);

  const [totalAmountIncVat, setTotalAmountIncVat] = useState<number>(0);
  const navigate = useNavigate(); // Initialize useNavigate

  const [showCategories] = useState<boolean>(true);
  const [, setVatRates] = useState<VatRate[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]); // State for customers
  const { t } = useTranslation();
  const [tables] = useState<Table[]>([]);
  const [loading, setLoading] = useState(false); // Initialize loading state
  const [orderNo, setOrderNo] = useState();
  const [fiscInvOrdNum, setFiscInvOrdNum] = useState();
  const [buCode, setBuCode] = useState();
  const [opCode, setOpCode] = useState();
  const [tcrCode, setTcrCode] = useState();
  const [iic, setIic] = useState();
  const [fic, setFic] = useState();
  const [vatList, setVatList] = useState<VATType[]>([]);
  const [exchangeRate, setExchangeRate] = useState<number | null>(1);
  const [selectedTable] = useState<string>("");
  const [localStorageProducts] = useState<ProductFast[]>([]);

  const invoiceRef = useRef<HTMLDivElement>(null);

  const storedCertificate = JSON.parse(
    localStorage.getItem("certificate") || "{}"
  );
  const expiryDate = new Date(storedCertificate?.expiryDate);
  const today = new Date();

  useEffect(() => {
    const fetchVatRatesAndProducts = async () => {
      // First, fetch VAT rates
      const vatRatesResponse = await DataFetcher.getData<VatRate[]>(
        `${API_URLS.BASE_URL}${API_URLS.VAT}`,
        VAT_STORAGE_KEY
      );
      if (vatRatesResponse) {
        setVatRates(vatRatesResponse);

        // After VAT rates are fetched, fetch products
        const productsResponse = await DataFetcher.getData<ProductFast[]>(
          `${API_URLS.BASE_URL}${API_URLS.PRODUCT}`,
          PRODUCT_STORAGE_KEY,
          true
        );
        if (productsResponse) {
          const filteredProducts = productsResponse.filter(
            (product) => !product.isRawMaterial
          );

          if (filteredProducts) {
            setProductList(
              filteredProducts.map((product) => ({
                ...product,
                quantity: 0,
                vatRate:
                  vatRatesResponse.find((rate) => rate.id === product.vatRateId)
                    ?.rate || 0,
              }))
            );
          }
        }
      }
    };

    const fetchCategories = async () => {
      const response = await DataFetcher.getData<ProductCategory[]>(
        `${API_URLS.BASE_URL}${API_URLS.PRODUCT_CATEGORY}`,
        CATEGORY_STORAGE_KEY,
        true
      );
      // const noCategory = {} as ProductCategory;
      // noCategory.id = undefined;
      // noCategory.name = t("Pa Kateogori");

      // response?.push(noCategory);
      if (response) {
        setCategories(response);
      }
    };

    const fetchCustomers = async () => {
      const response = await DataFetcher.getData<Customer[]>(
        `${API_URLS.BASE_URL}${API_URLS.CUSTOMER}`,
        CUSTOMER_STORAGE_KEY
      );
      if (response) {
        setCustomers(response);
      }
    };

    const fetchWarehouse = async () => {
      const response = await DataFetcher.getData<Warehouse[]>(
        `${API_URLS.BASE_URL}${API_URLS.WAREHOUSE}`,
        WAREHOUSE_STORAGE_KEY
      );
      if (response && response.length > 0) {
        const firstWarehouseId = response[0].id;
        console.log(firstWarehouseId);
        setWarehouse(parseInt(firstWarehouseId));
      }
    };
    const fetchVatRates = async () => {
      const response = await DataFetcher.getData<VATType[]>(
        `${API_URLS.BASE_URL}${API_URLS.VAT}`,
        VAT_STORAGE_KEY
      );
      if (response) {
        setVatList(response);
      }
    };
    const fetchPaymentMethods = async () => {
      const response = await DataFetcher.getData<Paymentmethods[]>(
        `${API_URLS.BASE_URL}${API_URLS.PaymentMethod}`,
        PAYMENT_METHOD_STORAGE_KEY
      );
      if (response) {
        setPaymentMethods(response);
      }
    };
    const fetchEmployeesFromServer = async () => {
      const response = await DataFetcher.getData<Employee[]>(
        `${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}`,
        EMPLOYEE_STORAGE_KEY
      );
      if (response) {
        setemp(response);
      }
    };
    const fetchShopServer = async () => {
      const response = await DataFetcher.getData<Shop[]>(
        `${API_URLS.BASE_URL}${API_URLS.SHOP}`,
        SHOP_STORAGE_KEY
      );
      if (response) {
        setshop(response);
      }
    };

    // Call the fetching functions
    fetchVatRatesAndProducts();
    fetchPaymentMethods();
    fetchCategories();
    fetchCustomers();
    fetchWarehouse();
    fetchVatRates();
    fetchEmployeesFromServer();
    fetchShopServer();
  }, []);

  useEffect(() => {
    if (employeeId !== null) {
      const parsedEmployeeId = parseInt(employeeId, 10);
      if (!isNaN(parsedEmployeeId)) {
        const fetchCashAccounts = async () => {
          try {
            const response = await DataFetcher.getData<CashAccount[] | null>(
              `${API_URLS.BASE_URL}${API_URLS.CashAccount}`,
              CashAccount_STORAGE_KEY,
              true
            );

            if (response && Array.isArray(response)) {
              const employeeCashAccounts = isSuperUser
                ? response
                : response.filter((account) =>
                    account.owners.some(
                      (owner) => owner.id === parsedEmployeeId
                    )
                  );

              setCashAccounts(employeeCashAccounts);

              if (employeeCashAccounts.length > 0) {
                const firstCashAccount = employeeCashAccounts[0];
                setSelectedTcrCode(firstCashAccount.tcrCode || null);
              }
            }
          } catch (error) {
            toast.error("Failed to fetch cash accounts");
            console.error(error);
          }
        };

        fetchCashAccounts();
      }
    }
  }, [employeeId]);

  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
  });

  useEffect(() => {
    if (searchQuery === "") {
      setFilteredProducts([]);
    } else {
      const results = productList.filter(
        (product) =>
          product.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          product.barcode?.includes(searchQuery)
      );
      setFilteredProducts(results);
    }
  }, [searchQuery, productList]);

  useEffect(() => {
    // Fetch available currencies using DataFetcher with caching
    const fetchCurrencyData = async () => {
      try {
        const response = await DataFetcher.getData<Currency[]>(
          `${API_URLS.BASE_URL}${API_URLS.Currency}`, // API endpoint
          Currency_STORAGE_KEY // LocalStorage key
        );
        setCurrencies(response || []); // Set fetched currencies, default to an empty array if none

        // Find and set base currency name and symbol
        const businessConfigs: BusinessConfigs = JSON.parse(
          localStorage.getItem("businessConfigs") || "{}"
        );
        const baseCurrencyId = businessConfigs.baseCurrencyId ?? 1; // Default to 1 if not set
        const baseCurrency = response?.find(
          (currency) => currency.id === baseCurrencyId
        );

        setBaseCurrencyName(baseCurrency?.name || "Leke"); // Set base currency name, default to "Leke"
        setBaseCurrencySymbol(baseCurrency?.symbol || "L"); // Set base currency symbol, default to "L"
      } catch (error) {
        console.error("Error fetching currencies:", error);
        toast.error(t("Failed to load currencies"));
      }
    };

    fetchCurrencyData();
  }, [t]);
  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        const exchangeRates = await DataFetcher.getData<ExchangeRate[]>(
          `${API_URLS.BASE_URL}${API_URLS.ExchangeRates}`, // API endpoint
          ExchangeRate_STORAGE_KEY // LocalStorage key
        );
        const businessConfigs: BusinessConfigs = JSON.parse(
          localStorage.getItem("businessConfigs") || "{}"
        );
        const baseCurrencyId = businessConfigs.baseCurrencyId ?? 1; // Default to 1 if not set
        const secondCurrencyId: number = businessConfigs.secondCurrencyId;

        if (exchangeRates && exchangeRates.length > 0) {
          // Find the exchange rate between the base and second currency
          const rate = exchangeRates.find(
            (exchange) =>
              exchange.currency1Id === secondCurrencyId && // Swap the order here
              exchange.currency2Id === baseCurrencyId // Swap the order here
          );

          if (rate) {
            setExchangeRate(rate.rate);
          } else {
            console.error(
              "No exchange rate found between the selected currencies."
            );
          }
        }
      } catch (error) {
        console.error("Error fetching exchange rates:", error);
      }
    };

    fetchExchangeRate();
  }, []);

  const truncateString = (str: string, num: number): string => {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    }
    return str;
  };

  const hasVat =
    JSON.parse(localStorage.getItem("businessConfigs") || "{}").hasVat ?? false;
  useEffect(() => {
    calculateTotalAmount();
  }, [fastSaleProducts]);

  const calculateVATPerType = (): Record<number, number> => {
    const vatSums: Record<number, number> = {};
    fastSaleProducts.forEach((product) => {
      const vatRateId = product.vatRateId;
      const vatRate = vatList.find((v) => v.id === vatRateId)?.rate || 0;
      const price = product.price * product.quantity;
      const vatAmount = hasVat ? price - price / (1 + vatRate / 100) : 0;
      vatSums[vatRateId] = (vatSums[vatRateId] || 0) + vatAmount;
    });

    return vatSums;
  };
  const getVATRate = (vatRateId: number) => {
    const vatRate = vatList.find((v) => v.id === vatRateId);
    return vatRate ? vatRate.rate : 0;
  };
  const getVATRateName = (vatRateId: number) => {
    const vatRate = vatList.find((v) => v.id === vatRateId);
    return vatRate ? vatRate.name : "";
  };

  const calculateTotalAmount = () => {
    let totalExVat = 0;
    let vat = 0;
    let totalIncVat = 0;
    let subtotal = 0;

    fastSaleProducts.forEach((product) => {
      const priceExVat = product.price * product.quantity;
      const vatAmount = hasVat
        ? priceExVat - priceExVat / (1 + product.vatRate / 100)
        : 0; // Calculate VAT or set to 0
      const subtotalProduct = priceExVat - vatAmount; // Calculating subtotal as priceExVat - vatAmount

      totalExVat += priceExVat;
      vat += vatAmount;
      totalIncVat = totalExVat;
      subtotal += subtotalProduct;
    });

    setTotalAmountExVat(subtotal);
    setVatAmount(vat);
    setTotalAmountIncVat(totalIncVat);
  };

  const handleCategorySelect = (categoryId: string) => {
    if (selectedCategory === categoryId) {
      setSelectedCategory("");
      setFilteredProducts([]);
    } else {
      setSelectedCategory(categoryId);
      const filteredByCategory = productList.filter(
        (product) =>
          product.categoryId === (categoryId !== undefined ? categoryId : null)
      );
      setFilteredProducts(filteredByCategory);
    }
  };

  useEffect(() => {
    if (searchQuery === "") {
      // If there are zero or one categories, display all products
      if (categories.length <= 1) {
        setFilteredProducts(productList);
      } else if (selectedCategory) {
        // Otherwise, filter by the selected category
        const filteredByCategory = productList.filter(
          (product) => product.categoryId === selectedCategory
        );
        setFilteredProducts(filteredByCategory);
      }
    }
  }, [searchQuery, selectedCategory, productList, categories]);

  const addToOrder = (productToAdd: ProductFast) => {
    const existingStateProductIndex = fastSaleProducts.findIndex(
      (product) => product.id === productToAdd.id
    );

    let updatedOrderProducts;
    if (existingStateProductIndex !== -1) {
      // Product already exists in the state, update the quantity
      updatedOrderProducts = fastSaleProducts.map((product) =>
        product.id === productToAdd.id
          ? { ...product, quantity: product.quantity + 1 }
          : product
      );
    } else {
      // Product does not exist in the state, add it with quantity 1
      const productWithQuantity = { ...productToAdd, quantity: 1 };
      updatedOrderProducts = [...fastSaleProducts, productWithQuantity];
    }

    setFastSaleProducts(updatedOrderProducts);

    // Calculate and update the total quantity of products
    const totalQuantity = updatedOrderProducts.reduce(
      (total, product) => total + product.quantity,
      0
    );
    setTotalQuantity(totalQuantity);
  };

  const handleRowClick = (product: ProductFast) => {
    addToOrder(product);
  };
  //  useEffect(() => {
  //   if (searchQuery === '') {
  //     setSelectedCategory(null);

  //   }
  // }, [searchQuery]);

  //  const storedTableId = localStorage.getItem("selectedTableId");
  //     if (storedTableId) {
  //       setSelectedTable(storedTableId);
  //     }
  const removeFromOrder = (productId: string) => {
    setFastSaleProducts((prevProducts) =>
      prevProducts
        .map((product) =>
          product.id === productId
            ? { ...product, quantity: product.quantity - 1 }
            : product
        )
        .filter((product) => product.quantity > 0)
    );
  };

  // const editcashbalance = () => {
  //   navigate(t("/balance"));
  // };

  const selectedTableName =
    tables.find((table) => table.id.toString() === selectedTable)?.name ||
    "Unknown Table";

  const findOpCodeByEmployeeId = (employeeId: number): string | null => {
    const employeeData = JSON.parse(
      localStorage.getItem("employeeData") || "{}"
    );
    const employees: Employee[] = Array.isArray(employeeData.data)
      ? employeeData.data
      : [];

    const employee = employees.find((e) => e.id === employeeId);
    return employee?.opCode || null;
  };

  const handleCheckout = async () => {
    setLoading(true); // Show loading spinner

    if (expiryDate < today) {
      navigate(t("/companycertificate"), {
        state: {
          toastMessage: t(
            "Your certificate has expired, please upload a new one."
          ),
        },
      });
    }
    if (employeeId !== null) {
      const parsedEmployeeId = parseInt(employeeId, 10);
      const opCode = findOpCodeByEmployeeId(parsedEmployeeId);
      if (storedCertificate.isDemo === false) {
        //  const opCode = findOpCodeByEmployeeId(parsedEmployeeId);
        const employeeData = JSON.parse(
          localStorage.getItem("employeeData") || "{}"
        );

        // Ensure that employeeData has a "data" property and that it is an array of Employees
        const employeeSalesData: Employee[] = Array.isArray(employeeData.data)
          ? employeeData.data
          : [];

        // Check if at least one employee has a valid opCode
        const hasValidOpCode = employeeSalesData.some(
          (employee) => employee.opCode && employee.opCode !== ""
        );

        // If no employee has a valid opCode, proceed with the toast and navigation
        if (!hasValidOpCode) {
          // Delay displaying the toast message
          setTimeout(() => {
            toast.error(
              t(
                "You don't have an opcode to fisc the bill.Please put one opcode one of your employee"
              )
            );
          }, 200);

          // Navigate to a specific page to manage employees
          navigate(t("/emplistpath"));
          return;
        }

        const cashaccountData = JSON.parse(
          localStorage.getItem("cashaccountData") || "{}"
        );

        // Ensure that employeeData has a "data" property and that it is an array of Employees
        const cashData: CashAccount[] = Array.isArray(cashaccountData.data)
          ? cashaccountData.data
          : [];

        // Check if at least one employee has a valid opCode
        const hasValidtcrCode = cashData.some(
          (cashaccount) => cashaccount.tcrCode && cashaccount.tcrCode !== ""
        );

        // If no employee has a valid opCode, proceed with the toast and navigation
        if (!hasValidtcrCode) {
          // Delay displaying the toast message
          setTimeout(() => {
            toast.error(
              t(
                "You don't have an tcrcode to fisc the cash account .Please put one tcr code one of your cash accounts"
              )
            );
          }, 200);

          // Navigate to a specific page to manage employees
          navigate(t("/cashaccountlistpath"));
          return;
        }

        const shopData = JSON.parse(localStorage.getItem("shopData") || "{}");

        // Ensure that employeeData has a "data" property and that it is an array of Employees
        const shopsData: Shop[] = Array.isArray(shopData.data)
          ? shopData.data
          : [];

        // Check if at least one employee has a valid opCode
        const hasValidbuCode = shopsData.some(
          (shop) => shop.businessUnitCode && shop.businessUnitCode !== ""
        );

        // If no employee has a valid opCode, proceed with the toast and navigation
        if (!hasValidbuCode) {
          // Delay displaying the toast message
          setTimeout(() => {
            toast.error(
              t(
                "You don't have an businessUnitCode to fisc the bill .Please put one businessUnitCode code one of your shops"
              )
            );
          }, 200);

          // Navigate to a specific page to manage employees
          navigate(t("/shoplistpath"));
          return;
        }

        return;
      }

      // Fetch businessConfigs from localStorage and parse it
      const businessConfigs = JSON.parse(
        localStorage.getItem("businessConfigs") as string
      );

      // Directly access allowNegativeInventory since it's always present
      const allowNegativeInventory: boolean =
        businessConfigs.allowNegativeInventory;
      const baseCurrencyId: number = businessConfigs.baseCurrencyId;
      const secondCurrencyId: number = businessConfigs.secondCurrencyId;

      const firstCustomer =
        customers.length > 0 ? parseInt(customers[0].id, 10) : null;
      if (!firstCustomer) {
        toast.error(t("No customers found"));
        setLoading(false);
        return; // Exit if no customers are available
      }

      let buCode = "";
      try {
        const warehouses = await DataFetcher.getData<Warehouse[] | null>(
          `${API_URLS.BASE_URL}${API_URLS.WAREHOUSE}`,
          WAREHOUSE_STORAGE_KEY
        );

        if (!warehouses || warehouses.length === 0) {
          toast.error(t("No warehouses found."));
          setLoading(false);
          return;
        }

        const selectedWarehouse = warehouses.find(
          (warehouseItem) => Number(warehouseItem.id) === warehouse
        );

        if (!selectedWarehouse || !selectedWarehouse.shopId) {
          toast.error(t("Selected warehouse does not have a valid shopId."));
          setLoading(false);
          return;
        }

        const shops = await DataFetcher.getData<Shop[] | null>(
          `${API_URLS.BASE_URL}${API_URLS.SHOP}`,
          SHOP_STORAGE_KEY
        );

        if (!shops || shops.length === 0) {
          toast.error(t("No shops found."));
          setLoading(false);
          return;
        }

        const shop = shops.find(
          (s) => Number(s.id) === selectedWarehouse.shopId
        );

        if (shop && shop.businessUnitCode) {
          buCode = shop.businessUnitCode;
          console.log("Found buCode:", buCode);
        } else {
          toast.error(t("No valid businessUnitCode found for the shop."));
          setLoading(false);
          return;
        }
      } catch (error) {
        console.error("Error fetching warehouses or shops:", error);
        setLoading(false);
        return;
      }

      const firstCashAccount = cashAccounts.length > 0 ? cashAccounts[0] : null;
      if (!firstCashAccount) {
        toast.error(t("No cash accounts found"));
        setLoading(false);
        return; // Exit if no cash accounts are available
      }

      const { balance, lastOpenBalance, tcrCode } = firstCashAccount;
      setCashAccountBalance(balance);
      setSelectedTcrCode(tcrCode);
      // Save the first cash account to localStorage (if needed)
      // localStorage.setItem("firstCashAccount", JSON.stringify(firstCashAccount));

      // Get today's date in YYYY-MM-DD format
      const today = new Date().toISOString().split("T")[0];

      // Check if there is an open balance for today
      if (!lastOpenBalance || lastOpenBalance.split("T")[0] !== today) {
        navigate(t("/cashledger"), {
          state: {
            cashAccountId: firstCashAccount.id,
            transactionType: 5, // Assuming 5 is "Open Balance"
            toastMessage: t("You have to open balance every day before sale."), // Add toast message
          },
        });
        return; // Stop further execution since the user is navigated to CashLedger
      }

      const firstPaymentMethod =
        paymentMethods.length > 0 ? parseInt(paymentMethods[0].id, 10) : null;
      if (!firstPaymentMethod) {
        toast.error(t("No payment methods found"));
        setLoading(false);
        return; // Exit if no payment methods are available
      }
      if (!selectedTcrCode) {
        toast.error(t("Selected cash account does not have a tcrCode."));
        setLoading(false);
        return;
      }

      const dto = new FastOrderDto({
        orderNo: "ORD-" + Date.now(),
        orderDateUtc: new Date().toISOString(),
        tcrCode: selectedTcrCode,
        opCode: opCode || "",
        buCode: buCode,
        ExchangeRate: 1,
        customerId: firstCustomer,
        currencyId: baseCurrencyId, // Replace with actual currency ID logic
        totalAmount: totalAmountIncVat,
        quantity: fastSaleProducts.reduce(
          (total, { quantity }) => total + quantity,
          0
        ),
        vatAmount: parseFloat(vatAmount.toFixed(2)),
        discountAmount: 0, // Replace with actual discount logic if any
        sameTaxes: 0, // Replace with actual sameTaxes logic if any
        shippingCharges: 0,
        adjustedCharges: 0,
        employeeId: parsedEmployeeId || 0,
        // shopId: 1,
        tableId: selectedTable ? parseInt(selectedTable) : null,
        warehouseId: warehouse,
        paymentMethodId: firstPaymentMethod, // Replace with actual payment method ID logic if any
        paymentExchangeRate: exchangeRate,
        paymentCurrencyId: secondCurrencyId,
        orderType: 1,
        orderStatus: 0,
        notes: "",
        cashAccountId: parseInt(firstCashAccount.id, 10), // Convert ID to number
        shiftId: 1,
        allowNegativeInvetory: allowNegativeInventory,
        salesOrderLines: fastSaleProducts.map(
          ({ id, uoMId, quantity, vatRateId, vatRate, price }) => ({
            productId: parseInt(id),
            uoMId: uoMId,
            quantity: quantity,
            discountPercentage: 0,
            lineNote: "",
            lineVatRateId: vatRateId,
            lineVatRate: vatRate,
            lineTotal: price * quantity,
            lineVatAmount: parseFloat(
              (price - price / (1 + vatRate / 100)).toFixed(2)
            ),
          })
        ),
      });

      try {
        // Create the order
        const response = await axiosInstance.post(
          `${API_URLS.BASE_URL}${API_URLS.SALESORDER}`,
          dto
        );
        if (response.data.iic == null) {
          const iic = response.data.data.iic;
          setIic(iic);
          const fic = response.data.data.fic;
          setFic(fic);
          const orderNo = response.data.data.orderNo;
          setOrderNo(orderNo);
          const FiscInvOrdNum = response.data.data.fiscInvOrdNum;
          setFiscInvOrdNum(FiscInvOrdNum);
          const buCode = response.data.data.buCode;
          setBuCode(buCode);
          const opCode = response.data.data.opCode;
          setOpCode(opCode);
          const tcrCode = response.data.data.tcrCode;
          setTcrCode(tcrCode);
        } else {
          const iic = response.data.iic;
          setIic(iic);
          const fic = response.data.fic;
          setFic(fic);
          const orderNo = response.data.orderNo;
          setOrderNo(orderNo);
          const FiscInvOrdNum = response.data.fiscInvOrdNum;
          setFiscInvOrdNum(FiscInvOrdNum);
          const buCode = response.data.buCode;
          setBuCode(buCode);
          const opCode = response.data.opCode;
          setOpCode(opCode);
          const tcrCode = response.data.tcrCode;
          setTcrCode(tcrCode);
        }

        console.log("Order created successfully:", response.data);
        await updateCashAccountBalance(
          parsedEmployeeId || 0,
          totalAmountIncVat
        );

        handlePrint();

        setFastSaleProducts([]);
        setSelectedCategory("");
        // setFilteredProducts([]);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const { status, data } = error.response;

          if (status === 403) {
            toast.error(
              t("You do not have permission to perform this action.")
            );
          } else if (status === 500) {
            toast.error(t("A server error occurred. Please try again later."));
          } else if (data && Array.isArray(data)) {
            data.forEach((err: { errorMessage: string }) => {
              const translatedMessage =
                t(`errorSalesOrder.${err.errorMessage}`) ||
                t("errorSalesOrder.An error occurred");
              toast.error(translatedMessage);
            });
          } else if (data && typeof data.message === "string") {
            // Check for the specific error message
            if (data.message.includes("Insufficient total inventory")) {
              toast.error(t("Insufficient total inventory")); // Display the specific message
            } else {
              toast.error(t(data.message)); // Display other messages if necessary
            }
          } else {
            toast.error(t("An error occurred"));
          }
        } else {
          toast.error(t("An unexpected error occurred"));
        }
        console.error("Error creating order:", error);
      } finally {
        setLoading(false); // Hide loading spinner
      }
    }
  };

  const updateCashAccountBalance = async (
    employeeId: number,
    salesAmount: number
  ) => {
    try {
      const parsedEmployeeId = parseInt(employeeId.toString(), 10);
      if (isNaN(parsedEmployeeId)) {
        console.error("Invalid employee ID");
        return;
      }
      const response = await DataFetcher.getData<any>(
        `${API_URLS.BASE_URL}${API_URLS.CashAccount}`,
        CashAccount_STORAGE_KEY
      );
      console.log(response);
      const cashAccounts = response;

      if (cashAccounts && cashAccounts.length > 0) {
        const cashAccount = cashAccounts.find(
          (account: { owners: { id: number }[] }) =>
            account.owners.some((owner) => owner.id === parsedEmployeeId)
        );

        console.log(cashAccount);

        if (cashAccount) {
          const cashAccountId = cashAccount.id;
          console.log(cashAccountId);
          const updatedCashAccount = cashAccount;

          const updatedBalance = updatedCashAccount.balance + salesAmount;

          const response = await axiosInstance.put(
            `${API_URLS.BASE_URL}${API_URLS.CashAccount}/${cashAccountId}`,
            {
              ...updatedCashAccount,
              balance: updatedBalance,
            }
          );
          console.log(response);

          setCashAccountBalance(updatedBalance);
        } else {
          console.log("No cash account found for this employee");
        }
      } else {
        console.log("No cash accounts found");
      }
    } catch (error) {
      console.error("Error updating cash account balance:", error);
    }
  };

  return (
    <div className="sales-order-container">
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      <div className="select-container">
        <div className="select-with-icon">
          {/* <ShoppingCart className="icon" onClick={handleCheckout} /> */}

          <h3>
            {t("Order")}: {totalAmountIncVat.toFixed(2)}
            {baseCurrencySymbol}
          </h3>
        </div>
      </div>

      <div className="categories">
        <Input
          className="search-input"
          placeholder={t("Search products")}
          value={searchQuery}
          type="search"
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {categories.length > 1 && (
          <div className="category-header">
            <h2>{t("category")}</h2>
          </div>
        )}
        {showCategories && categories.length > 1 && (
          <div className="category-buttons">
            {categories.map((category) => (
              <Button
                key={category.id !== undefined ? category.id : category.name} // Use name if id is undefined for a unique key
                className={
                  selectedCategory === category.id ||
                  (selectedCategory === undefined && category.id === undefined)
                    ? "active"
                    : ""
                }
                onClick={() => handleCategorySelect(category.id as string)} // Non-null assertion here
                style={{
                  display:
                    (selectedCategory && selectedCategory !== category.id) ||
                    (selectedCategory === undefined &&
                      category.id !== undefined)
                      ? "none"
                      : "block",
                }}
              >
                {category.name}
              </Button>
            ))}
          </div>
        )}
      </div>
      <div className="products">
        <h2>{t("products")}</h2>
        {/* <h3>{t('Total')}: {totalAmountIncVat.toFixed(2)}L</h3> */}
        <div className="product-buttons">
          {filteredProducts.map((product) => (
            <Button key={product.id} onClick={() => handleRowClick(product)}>
              {truncateString(product.name, 15)}
            </Button>
          ))}
        </div>
      </div>
      <div className="order-products">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t("Product Info")}</TableHead>
              <TableHead>{t("quantity")}</TableHead>
              <TableHead>{t("price")}</TableHead>
              {/* <TableHead>{t("vat")}</TableHead> */}
              <TableHead>{t("total")}</TableHead>
              <TableHead>{t("action")}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {fastSaleProducts.map((product) => (
              <TableRow key={product.id}>
                <React.Fragment>
                  <TableCell>{product.name}</TableCell>
                  <TableCell>{product.quantity}</TableCell>
                  <TableCell>
                    {product.price} {baseCurrencyName}
                  </TableCell>
                  <TableCell>
                    {product.price * product.quantity} {baseCurrencyName}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => removeFromOrder(product.id)}>
                      Remove
                    </Button>
                  </TableCell>
                </React.Fragment>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <div className="relative">
        <div className="fixed bottom-4 right-4 md:bottom-4 md:right-4">
          <Button
            type="button"
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={handleCheckout}
          >
            +
          </Button>
        </div>
      </div>

      <div style={{ display: "none" }}>
        <div className="order-summary">
          <div className="total-amount">
            <h3>
              {t("SUBTOTAL")}: {totalAmountExVat.toFixed(2)}
              {baseCurrencySymbol}
            </h3>
            {fastSaleProducts.map((product) => (
              <h3 key={product.id}>
                {t("VAT")}: {product.vatRate}% -{" "}
                {(
                  product.price *
                  product.quantity *
                  (product.vatRate / 100)
                ).toFixed(2)}
                {baseCurrencySymbol}
              </h3>
            ))}{" "}
            {localStorageProducts.map((product) => (
              <h3 key={product.id}>
                {t("VAT")}: {product.vatRate}% -{" "}
                {(
                  product.price *
                  product.quantity *
                  (product.vatRate / 100)
                ).toFixed(2)}
                {baseCurrencySymbol}
              </h3>
            ))}
            <h3>
              {t("Total Amount (Incl. VAT)")}: {totalAmountIncVat.toFixed(2)}
              {baseCurrencySymbol}
            </h3>
          </div>
        </div>

        <FastOrder
          ref={invoiceRef}
          tablename={selectedTableName}
          tableId={selectedTable}
          // employeeID='3'
          employeeId={employeeId || ""}
          orderProducts={fastSaleProducts}
          getVATRate={getVATRate}
          getVATRateName={getVATRateName}
          totalAmountExVat={totalAmountExVat}
          vatAmount={vatAmount}
          totalAmountIncVat={totalAmountIncVat}
          calculateVATPerType={calculateVATPerType}
          orderNo={orderNo}
          fiscInvOrdNum={fiscInvOrdNum}
          buCode={buCode}
          opCode={opCode}
          tcrCode={tcrCode}
          iic={iic}
          fic={fic}
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default FastSale;
