import React, { useEffect, useState } from "react";
import { Box, Paper, Typography, Grid } from "@mui/material";
import { useDashboard } from "@/provider/DashboardContext";
import { useTranslation } from "react-i18next";
import DataFetcher from "@/provider/DataFetcher";
import API_URLS from "@/config";

interface BusinessConfigs {
  allowNegativeInventory: boolean;
  baseCurrencyId: number;
  secondCurrencyId: number;
  printerType: number;
}

const Statistics: React.FC = () => {
  const { data, loading } = useDashboard();
  const { t } = useTranslation();

  const [businessConfig, setBusinessConfig] = useState<BusinessConfigs | null>(
    null
  );
  const [baseCurrencySymbol, setBaseCurrencySymbol] = useState("L");

  useEffect(() => {
    const config = JSON.parse(localStorage.getItem("businessConfigs") || "{}");
    setBusinessConfig(config);
  }, []);

  useEffect(() => {
    const fetchCurrencies = async () => {
      if (!businessConfig?.baseCurrencyId) return;
      try {
        const response = await DataFetcher.getData<any[]>(
          `${API_URLS.BASE_URL}${API_URLS.Currency}`,
          "currencyData"
        );
        const baseCurrency = response?.find(
          (currency) => currency.id === businessConfig.baseCurrencyId
        );
        setBaseCurrencySymbol(baseCurrency?.symbol || "L");
      } catch (error) {
        console.error("Error fetching currencies:", error);
      }
    };

    fetchCurrencies();
  }, [businessConfig]);

  if (loading) return <p>{t("Loading...")}</p>;
  if (!data) return <p>{t("No data available")}</p>;

  const stats = [
    {
      label: t("Out of Stock"),
      value: data.outOfStockProductIdsBasedOnWarehouse.length,
      color: "#9c27b0",
    },
    {
      label: t("Customers"),
      value: data.monthlyCostumerIds.length,
      color: "#3f51b5",
    },

    {
      label: t("Today Sales"),
      value: `${data.todayRevenue.toFixed(2)} ${baseCurrencySymbol}`,
      color: "#4caf50",
    },
    {
      label: t("This Week Sales"),
      value: `${data.weeklyRevenue.toFixed(2)} ${baseCurrencySymbol}`,
      color: "#4caf50",
    },

    {
      label: t("This Month Sales"),
      value: `${data.monthlyRevenue.toFixed(2)} ${baseCurrencySymbol}`,
      color: "#4caf50",
    },
    {
      label: t("This Year Sales"),
      value: `${data.yearlyRevenue.toFixed(2)} ${baseCurrencySymbol}`,
      color: "#4caf50",
    },
    {
      label: t("This Month Purchases"),
      value: `${data.monthlyExpenseOnProductPurchases.toFixed(
        2
      )} ${baseCurrencySymbol}`,
      color: "#f44336",
    },
    {
      label: t("This Year Purchases"),
      value: `${data.yearlyExpenseOnProductPurchases.toFixed(
        2
      )} ${baseCurrencySymbol}`,
      color: "#f44336",
    },
  ];

  return (
    <Box>
      <Grid container spacing={2}>
        {stats.map((stat, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Paper
              style={{
                padding: "16px",
                backgroundColor: stat.color,
                color: "#fff",
              }}
            >
              <Typography variant="h6">{stat.label}</Typography>
              <Typography variant="h4">{stat.value}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Statistics;
