class CreateBusinessDto {
  name: string;
  image: string;
  tin: string;
  city: string;
  country: string;
  phoneNumber: string;
  fieldOfActivity: string | null;
  isSeasonal: boolean;
  allowNegativeInventory: boolean;
 hasTables: boolean;
  hasVat: boolean;

  user_Name:string;
  user_Username:string;
  user_Email:string;
  user_PhoneNumber:string;
  user_Password:string;
  baseCurrency:number;
  printerType:number;
  address:string;
subdomain:string
  constructor(business: {
    name?: string;
    tin?: string;
    city?: string;
    country?: string;
     
    phoneNumber?: string;
    fieldOfActivity?: string | null;
    isSeasonal?: boolean;
        allowNegativeInventory?: boolean;
subdomain:string
 hasTables?: boolean;
  hasVat?: boolean;

    user_Name?: string;
    user_Email?: string;
    user_PhoneNumber?:string;
    user_Password?:string;
    user_Username?:string;
      baseCurrency:number;
        printerType:number;
          image?: string;
            address?:string;







  }) {
    this.name = business.name ?? "";
        this.image = business.image ?? "";
        this.address = business.address ?? "";
        this.subdomain = business.subdomain ?? "";
    this.tin = business.tin ?? "";
    this.city = business.city ?? "";
    this.country = business.country ?? "";
    this.phoneNumber = business.phoneNumber ?? "";
    this.fieldOfActivity = business.fieldOfActivity ?? null;
    this.isSeasonal = business.isSeasonal ?? false;
    this.allowNegativeInventory = business.allowNegativeInventory ?? false;
    this.hasTables = business.hasTables ?? false;
    this.hasVat = business.hasVat ?? false;

    this.user_Name = business.user_Name ?? "";
    this.user_Email = business.user_Email ?? "";
    this.user_Username = business.user_Username ?? "";
    this.user_PhoneNumber = business.user_PhoneNumber ?? "";
    this.user_Password = business.user_Password ?? "";
    this.baseCurrency = business.baseCurrency;
        this.printerType = business.printerType;




  }
}

export default CreateBusinessDto;
