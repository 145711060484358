import React, { useEffect, useMemo, useState } from "react";
import { useDashboard } from "@/provider/DashboardContext";
import { useTranslation } from "react-i18next";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

// Define the type for the chart data
interface ChartData {
  name: string;
  sales: number;
}

const SalesChart: React.FC = () => {
  const { data, loading } = useDashboard();
  const { t } = useTranslation();
  const [chartData, setChartData] = useState<ChartData[]>([]);

  const monthNames = useMemo(
    () => [
      t("Jan"),
      t("Feb"),
      t("Mar"),
      t("Apr"),
      t("May"),
      t("Jun"),
      t("Jul"),
      t("Aug"),
      t("Sep"),
      t("Oct"),
      t("Nov"),
      t("Dec"),
    ],
    [t]
  );

  useEffect(() => {
    if (!loading && data) {
      const formattedData: ChartData[] = data.salesByMonths.map((item) => ({
        name: monthNames[item.month - 1],
        sales: parseFloat(item.amount.toFixed(2)),
      }));
      setChartData(formattedData);
    }
  }, [loading, data, monthNames]);

  if (loading) return <p>{t("Loading...")}</p>;
  if (!data) return <p>{t("No data available")}</p>;

  return (
    <div style={{ width: "100%", height: 300 }}>
      <h2>{t("Monthly Sales")}</h2>
      <ResponsiveContainer>
        <LineChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="sales" stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SalesChart;
