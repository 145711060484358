import { Disclosure } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Cog6ToothIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect, useRef } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { AiOutlineMenu } from "react-icons/ai";
// import SearchBar from "./SearchBar";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "@/components/ui/navigation-menu";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { cn } from "@/lib/utils";
import { useLocation } from "react-router-dom";
import ThemeSwitcher from "./ThemeSwitcher";
import LanguageSelector from "./LanguageSelector";
// import CompanyInfo from "./CompanyInfo";
import EmployeeInfo from "./EmployeeInfo";
import {
  Briefcase,
  Percent,
  User,
  Wallet,
  Monitor,
  Printer,
} from "lucide-react";

const StatusBar = () => {
  const [nav, setNav] = useState(false);
  const menuRef = useRef(null);
  const location = useLocation();
  const { t } = useTranslation();
  const [avatarSrc, setAvatarSrc] = useState<string | undefined>(undefined);
  const [isDemo, setIsDemo] = useState(false);
  const calculateRemainingDays = (expiryDate: string): number => {
    const today = new Date();
    const expiry = new Date(expiryDate);
    const timeDiff = expiry.getTime() - today.getTime();
    const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysRemaining >= 0 ? daysRemaining : 0;
  };

  const storedCertificate = JSON.parse(
    localStorage.getItem("certificate") || "{}"
  );
  const hasVat =
    JSON.parse(localStorage.getItem("businessConfigs") || "{}").hasVat ?? false;
  let expiryDate: string | null = storedCertificate?.expiryDate || null;

  if (isDemo) {
    const demoExpiryDate = new Date();
    demoExpiryDate.setDate(demoExpiryDate.getDate() + 30);
    expiryDate = demoExpiryDate.toISOString();
  }

  const savedRemainingDays = JSON.parse(
    localStorage.getItem("remainingDaysSubPos") || "0"
  );

  const remainingDays = isDemo
    ? savedRemainingDays
    : expiryDate
    ? calculateRemainingDays(expiryDate)
    : 0;

  const handleNav = () => {
    setNav(!nav);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      menuRef.current &&
      !(menuRef.current as HTMLDivElement).contains(event.target as Node)
    ) {
      setNav(false);
    }
  };

  useEffect(() => {
    // Fetch and parse the certificate data from local storage
    const certificateData = localStorage.getItem("certificate");
    if (certificateData) {
      try {
        const parsedCertificate = JSON.parse(certificateData);
        if (parsedCertificate.isDemo === true) {
          setIsDemo(true);
        }
      } catch (error) {
        console.error(
          "Error parsing certificate data from localStorage:",
          error
        );
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const getAvatarFromLocalStorage = (): string | null => {
    return localStorage.getItem("avatarSrc");
  };
  useEffect(() => {
    const savedAvatar = getAvatarFromLocalStorage();
    if (savedAvatar) {
      setAvatarSrc(savedAvatar);
    }
  }, []);

  const components = [
    { title: t("+ Add Employee"), href: t("/createemppath") },
    { title: t("+ Add Customer"), href: t("/createcustomerpath") },
    { title: t("+ Add Product"), href: t("/createprodpath") },
    { title: t("+ Add Role"), href: t("/createrolepath") },
    { title: t("+ Add Product Category"), href: t("/createProductCategory") },
    {
      title: t("+ Add Customer Category"),
      href: t("/createcustomercategorypath"),
    },

    { title: t("+ Add SalesOrder"), href: t("/salesorderpath") },
    { title: t("+ Add PurchaseOrder"), href: t("/purchaseorderpath") },
    { title: t("+ Add UOM"), href: t("/createruompath") },
    { title: t("+ Add VAT"), href: t("/createvatlistpath") },
    { title: t("+ Add Inventory"), href: t("/createinventorypath") },
    { title: t("+ Add Shop"), href: t("/createshoppath") },
    { title: t("+ Add Warehouse"), href: t("/createwarehousepath") },
    { title: t("+ Add Supplier"), href: t("/createwarehousepath") },
    { title: t("+ Add Payment Terms"), href: t("/createpaymentTerms") },
    { title: t("+ Add Payment Methods"), href: t("/createpaymentmethodpath") },
    { title: t("+ Add Currency"), href: t("/createcurrencypath") },
    { title: t("+ Add Cash Account"), href: t("/createcashaccount") },
  ];

  const getPageTitle = (pathname: string): string => {
    const titles: { [key: string]: string } = {
      [t("/createemppath")]: t("createmp"),
      [t("/listcustomerpath")]: t("customerlist"),
      [t("/prodlistpath")]: t("productlistpage"),
      [t("/emplistpath")]: t("emplistpage"),
      [t("/supplierlistpath")]: t("supplierlistpage"),
      [t("/createcustomerpath")]: t("createcustomer"),
      [t("/createcustomercategorypath")]: t("createCategoryCustomerpage"),
      [t("/createcproductcategorypath")]: t("createproductcategory"),
      [t("/createprodpath")]: t("createitem"),
      [t("/categorylsitcustomer")]: t("customercategorylist"),
      [t("/categorylsitproduct")]: t("productcategorylist"),
      [t("/createsupplierpath")]: t("createsupplier"),
      [t("/createrolepath")]: t("createtole"),
      [t("/rolelistpath")]: t("rolelist"),
      [t("/editrolepath")]: t("editrole"),
      [t("/editemppath")]: t("editemp"),
      [t("/editcutomerpath")]: t("editcustomer"),
      [t("/editsupplierpath")]: t("editsupplier"),
      [t("/editcategoryproductpath")]: t("editproductcategory"),
      [t("/editcustomatorcategorypath")]: t("editcustomercategory"),
      [t("/editproductpath")]: t("edititem"),
      [t("/uomlistpath")]: t("listuom"),
      [t("/createruompath")]: t("createuom"),
      [t("/editruompath")]: t("edituom"),
      [t("/vatlistpath")]: t("lisvat"),
      [t("/editvatlistpath")]: t("editvat"),
      [t("/createvatlistpath")]: t("createtvat"),
      [t("/lotlistpath")]: t("lotlist"),
      [t("/createlotpath")]: t("createlot"),
      [t("/editlotpath")]: t("editlot"),
      [t("/inventorylistpath")]: t("Inventorylist"),
      [t("/createinventorypath")]: t("createInventory"),
      [t("/editinventorypath")]: t("editInventory"),
      [t("/shoplistpath")]: t("Shoplist"),
      [t("/createshoppath")]: t("createShop"),
      [t("/editshoppath")]: t("editShop"),
      [t("/paymentlistpath")]: t("paymentlist"),
      [t("/createpaymentmethodpath")]: t("/createpaymentmethod"),
      [t("/editpaymentmethodpath")]: t("/editpaymentmethod"),
      [t("/salesorderpath")]: t("salesorder"),
      [t("/warehouselistpath")]: t("Warehouselist"),
      [t("/createwarehousepath")]: t("createWarehouse"),
      [t("/editwarehousepath")]: t("editWarehouse"),
      [t("/paymentTermslistpath")]: t("/paymentTermslist"),
      [t("/createpaymentTerms")]: t("/createpaymentTerm"),
      [t("/editpaymentTerms")]: t("/editpaymentTerm"),
      [t("/currencylistpath")]: t("/currencylist"),
      [t("/createcurrencypath")]: t("/createcurrency"),
      [t("/editcurrencypath")]: t("/editcurrency"),
      [t("/cashaccountlistpath")]: t("/cashaccountlist"),
      [t("/createcashaccount")]: t("createcashaccount"),
      [t("/editcashaccount")]: t("editcashaccount"),
      [t("/salesorder2path")]: t("BAR ORDER"),
      [t("/purchaseorderpath")]: t("purchaseorder"),
      [t("/posbarpath")]: t("POSBAR"),
      [t("/cashaccountownerlistpath")]: t("/cashaccountownerlist"),
      [t("/createcashaccountowner")]: t("createcashaccountowner"),
      [t("/editcashaccountowner")]: t("editcashaccountowner"),
      [t("/purchaseorderlistpath")]: t("purchaseOrderList"),
      [t("/salesorderlistpath")]: t("salesOrderList"),
      [t("/productcompositelistpath")]: t("/productcompositelist"),
      [t("/createproductcompositepath")]: t("/createproductcomposite"),
      [t("/editproductcompositepath")]: t("/editproductcomposite"),
      [t("/exchangeratelistpath")]: t("/exchangeratelist"),
      [t("/createexchangeratepath")]: t("/createexchangerate"),
      [t("/editexchangeratepath")]: t("/editexchangerate"),
      [t("/createbusinessregisterpath")]: t("/createbusinessregister"),
      [t("/editbusinessregisterpath")]: t("/editbusinessregister"),
      [t("/changepassword")]: t("changepassword"),
      [t("/balance")]: t("Cash Balance"),
      [t("/balanceSalesOrder")]: t("Update Cash Balance"),
      [t("/companyinfo")]: t("companyinfo"),
      [t("/importresult")]: t("importresult"),
      [t("/importcustomers")]: t("importcustomers"),
      [t("/importproduct")]: t("importproduct"),
      [t("/importsupplier")]: t("importsupplier"),
      [t("/importlot")]: t("importlot"),
      [t("/account")]: t("account"),
      [t("/fastsale")]: t("FAST ORDER"),
      [t("/accountTransfer")]: t("Tranfer Money"),
      [t("/cashledger")]: t("cashledger"),
      [t("/companyconfig")]: t("Company Config"),
      [t("/printersconfig")]: t("Printer Config"),

      [t("/cashledgerlist")]: t("Transactions"),
      [t("/companycertificate")]: t("Upload Certificate"),
      [t("/")]: t("Dashboard"),
    };

    return titles[pathname] || "Home Page";
  };
  return (
    <Disclosure as="nav" className="bg-gray-800">
      <div className="flex justify-between items-center h-16 w-full px-4 text-white">
        <div
          className="flex items-center"
          style={{ paddingLeft: window.innerWidth < 640 ? "90px" : "250px" }}
        >
          <h1 className="text-white">{getPageTitle(location.pathname)}</h1>
        </div>

        <div className="flex items-center space-x-4">
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <NavigationMenu>
              <NavigationMenuList>
                <NavigationMenuItem>
                  <NavigationMenuTrigger className="relative rounded-full bg-gray-800 p-1 text-white hover:text-white focus:outline-none hidden sm:block">
                    <div className="pt-1  pr-2">
                      {" "}
                      {/* Add top padding here */}
                      <PlusCircleIcon className="h-6 w-6" aria-hidden="true" />
                    </div>
                  </NavigationMenuTrigger>
                  <NavigationMenuContent>
                    <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
                      {components.map((component) => (
                        <ListItem
                          key={component.title}
                          title={component.title}
                          href={component.href}
                        ></ListItem>
                      ))}
                    </ul>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              </NavigationMenuList>
            </NavigationMenu>

            <Sheet>
              <div className="relative rounded-full bg-gray-800 p-1 text-white hover:text-white focus:outline-none hidden sm:block">
                <span className="absolute -inset-1.5" />
                <div className="pt-2">
                  {/* Add top padding here */}
                  <LanguageSelector aria-hidden="true" />
                </div>
              </div>

              <div className="relative rounded-full bg-gray-800 p-1 text-white hover:text-white focus:outline-none hidden sm:block">
                <span className="absolute -inset-1.5" />
                <ThemeSwitcher aria-hidden="true" />
              </div>
            </Sheet>

            <Sheet>
              <SheetTrigger className="relative rounded-full bg-gray-800 p-1 text-white hover:text-white focus:outline-none hidden sm:block">
                <span className="absolute -inset-1.5" />
                <Cog6ToothIcon className="h-6 w-6" aria-hidden="true" />
                {savedRemainingDays <= 30 && remainingDays <= 30 ? (
                  <span
                    className="absolute left-0 bottom-5 bg-red-500 text-white font-bold px-2 py-1 rounded-full"
                    style={{
                      fontSize: "7px",
                      width: "10px",
                      height: "10px",
                      padding: 0,
                    }}
                  />
                ) : (
                  (savedRemainingDays <= 30 || remainingDays <= 30) && (
                    <span
                      className="absolute left-0 bottom-5 bg-red-500 text-white font-bold px-2 py-1 rounded-full"
                      style={{ fontSize: "7px" }}
                    >
                      {savedRemainingDays <= 30
                        ? savedRemainingDays
                        : remainingDays}
                    </span>
                  )
                )}
              </SheetTrigger>

              <SheetContent className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-300 dark:border-gray-600 w-72 overflow-y-auto">
                <SheetHeader>
                  <div className="space-y-2">
                    {/* Business */}
                    <div className="flex items-center border-b border-gray-200 dark:border-gray-700 pb-2">
                      <Briefcase className="h-5 w-5 ml-4 mt-5 text-gray-500 dark:text-gray-400" />{" "}
                      {savedRemainingDays <= 30 && remainingDays <= 30 ? (
                        <>
                          <span className="absolute bg-red-500 mb-5 left-0 text-white text-xs font-bold px-2 py-1 rounded-full">
                            {remainingDays}
                          </span>
                          <span className="absolute left-0 mt-10 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                            {savedRemainingDays}
                          </span>
                        </>
                      ) : savedRemainingDays <= 30 ? (
                        <span className="absolute left-0 mt-5 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                          {savedRemainingDays}
                        </span>
                      ) : remainingDays <= 30 ? (
                        <span className="absolute left-0 mt-5 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                          {remainingDays}
                        </span>
                      ) : null}
                      <SheetTitle className="text-sm font-semibold text-gray-700 dark:text-gray-300 w-full">
                        <a
                          href={t("/companyinfo")}
                          className="block px-4 py-3 rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
                        >
                          Business
                        </a>
                      </SheetTitle>
                    </div>

                    {/* Account */}
                    <div className="flex items-center border-b border-gray-200 dark:border-gray-700 pb-2">
                      <User className="h-5 w-5 ml-4 mt-5 text-gray-500 dark:text-gray-400" />{" "}
                      <SheetTitle className="text-sm font-semibold text-gray-700 dark:text-gray-300 w-full">
                        <a
                          href={t("/account")}
                          className="block px-4 py-3 rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
                        >
                          {t("account")}
                        </a>
                      </SheetTitle>
                    </div>

                    {/* VAT */}
                    {hasVat && (
                      <div className="flex items-center border-b border-gray-200 dark:border-gray-700 pb-2">
                        <Percent className="h-5 w-5 ml-4 mt-5 text-gray-500 dark:text-gray-400" />
                        <SheetTitle className="text-sm font-semibold text-gray-700 dark:text-gray-300 w-full">
                          <a
                            href={t("/vatlistpath")}
                            className="block px-4 py-3 rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
                          >
                            {t("VAT")}
                          </a>
                        </SheetTitle>
                      </div>
                    )}
                    {/* Cash Balance */}
                    <div className="flex items-center border-b border-gray-200 dark:border-gray-700 pb-2">
                      <Wallet className="h-5 w-5 ml-4 mt-5 text-gray-500 dark:text-gray-400" />{" "}
                      <SheetTitle className="text-sm font-semibold text-gray-700 dark:text-gray-300 w-full">
                        <a
                          href={t("/balance")}
                          className="block px-4 py-3 rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
                        >
                          {t("Cash Balance")}
                        </a>
                      </SheetTitle>
                    </div>
                    <div className="flex items-center border-b border-gray-200 dark:border-gray-700 pb-2">
                      <Monitor className="h-5 w-5 ml-4 mt-5 text-gray-500 dark:text-gray-400" />{" "}
                      <SheetTitle className="text-sm font-semibold text-gray-700 dark:text-gray-300 w-full">
                        <a
                          href={t("/companyconfig")}
                          className="block px-4 py-3 rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
                        >
                          {t("Config")}
                        </a>
                      </SheetTitle>
                    </div>
                    <div className="flex items-center border-b border-gray-200 dark:border-gray-700 pb-2">
                      <Printer className="h-5 w-5 ml-4 mt-5 text-gray-500 dark:text-gray-400" />{" "}
                      <SheetTitle className="text-sm font-semibold text-gray-700 dark:text-gray-300 w-full">
                        <a
                          href={t("/printersconfig")}
                          className="block px-4 py-3 rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
                        >
                          {t("Printer")}
                        </a>
                      </SheetTitle>
                    </div>
                  </div>
                </SheetHeader>
              </SheetContent>
            </Sheet>

            <Sheet>
              <SheetTrigger className="relative rounded-full bg-gray-800 p-1 text-white hover:text-white focus:outline-none hidden sm:block">
                <Avatar className="h-10 w-10 sm:h-10 sm:w-10 md:h-8 md:w-8 lg:h-8 lg:w-8 xl:h-8 xl:w-8">
                  <AvatarImage
                    src={avatarSrc || "https://github.com/shadcn.png"}
                    alt="Company Logo"
                    className="rounded-full object-cover h-full w-full"
                  />
                </Avatar>
              </SheetTrigger>

              <SheetContent className="overflow-x-auto">
                <SheetHeader>
                  <SheetTitle>{t("Employee Profile")}</SheetTitle>
                  <EmployeeInfo />
                </SheetHeader>
              </SheetContent>
            </Sheet>
          </div>
        </div>
        <div onClick={handleNav} className="block sm:hidden">
          <AiOutlineMenu size={20} />
        </div>
        <ul
          ref={menuRef}
          className={
            nav
              ? "fixed md:hidden right-0 top-0 w-[55%] h-full border-l border-l-gray-900 bg-gray-800 ease-in-out duration-500 transform translate-x-0 z-10"
              : "ease-in-out duration-500 fixed top-0 bottom-0 right-0 w-[59%] transform translate-x-full z-10"
          }
        >
          <h1 className="w-full text-3xl font-bold text-white m-4">Menu</h1>
          <div className=" sm:ml-6">
            <div className="flex items-center space-x-4">
              {/* <SearchBar /> */}
            </div>
          </div>
          <div className="absolute right-0 mt-4 flex items-center sm:pr-0">
            <div className="pr-3">
              <ThemeSwitcher />
            </div>
            <div className="pr-2 pt-1.5">
              <LanguageSelector />
            </div>{" "}
            <Sheet>
              <SheetTrigger className="relative rounded-full bg-gray-800 p-1 text-white hover:text-white focus:outline-none">
                <PlusCircleIcon className="h-6 w-6" aria-hidden="true" />
              </SheetTrigger>
              <SheetContent side="right" className="w-full max-w-md">
                <SheetHeader>
                  <SheetTitle>{t("Quick Actions")}</SheetTitle>
                </SheetHeader>
                <div className="overflow-y-auto max-h-[80vh] p-4">
                  <ul className="grid w-full gap-3">
                    {components.map((component) => (
                      <li key={component.title} className="p-2">
                        <a
                          href={component.href}
                          className="block rounded-md p-3 leading-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
                        >
                          {component.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </SheetContent>
            </Sheet>
            {/* <Sheet>
              <SheetTrigger className="relative rounded-full bg-gray-800 p-1 text-white hover:text-white focus:outline-none hidden sm:block">
                <span className="absolute -inset-1.5" />
                <PlusCircleIcon className="h-6 w-6" aria-hidden="true" />
              </SheetTrigger>
              <SheetContent>
                <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
                  {components.map((component) => (
                    <ListItem
                      key={component.title}
                      title={component.title}
                      href={component.href}
                    ></ListItem>
                  ))}
                </ul>
              </SheetContent>
            </Sheet> */}
            <Sheet>
              <SheetTrigger className="relative rounded-full bg-gray-800 p-1 text-white hover:text-white focus:outline-none">
                <span className="absolute -inset-1.5" />
                <Cog6ToothIcon className="h-6 w-6" aria-hidden="true" />
                {savedRemainingDays <= 30 && remainingDays <= 30 ? (
                  <span
                    className="absolute left-0 bottom-5 bg-red-500 text-white font-bold px-2 py-1 rounded-full"
                    style={{
                      fontSize: "7px",
                      width: "10px",
                      height: "10px",
                      padding: 0,
                    }}
                  />
                ) : (
                  (savedRemainingDays <= 30 || remainingDays <= 30) && (
                    <span
                      className="absolute left-0 bottom-5 bg-red-500 text-white font-bold px-2 py-1 rounded-full"
                      style={{ fontSize: "7px" }}
                    >
                      {savedRemainingDays <= 30
                        ? savedRemainingDays
                        : remainingDays}
                    </span>
                  )
                )}
              </SheetTrigger>
              <SheetContent className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-300 dark:border-gray-600 w-72">
                <SheetHeader>
                  <div className="space-y-2">
                    {/* Business */}
                    <div className="flex items-center border-b border-gray-200 pb-2 pr-10">
                      <Briefcase className="h-5 w-5 ml-10 mt-5 text-gray-500 dark:text-white" />
                      {/* Icon for Business */}
                      {savedRemainingDays <= 30 && remainingDays <= 30 ? (
                        <>
                          <span className="absolute bg-red-500 mb-5 left-0 text-white text-xs font-bold px-2 py-1 rounded-full">
                            {remainingDays}
                          </span>
                          <span className="absolute left-0 mt-10 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                            {savedRemainingDays}
                          </span>
                        </>
                      ) : savedRemainingDays <= 30 ? (
                        <span className="absolute left-0 mt-5 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                          {savedRemainingDays}
                        </span>
                      ) : remainingDays <= 30 ? (
                        <span className="absolute left-0 mt-5 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                          {remainingDays}
                        </span>
                      ) : null}
                      <SheetTitle className="text-sm font-semibold text-gray-700 w-full">
                        <a
                          href={t("/companyinfo")}
                          className="block px-4 py-3 pl-2 rounded-md hover:bg-gray-200 transition-colors dark:text-white"
                        >
                          Business
                        </a>
                      </SheetTitle>
                    </div>

                    {/* Account */}
                    <div className="flex items-center border-b border-gray-200 pb-2 pr-10">
                      <User className="h-5 w-5 ml-10 mt-5 text-gray-500  dark:text-white" />
                      {/* Icon for Account */}
                      <SheetTitle className="text-sm font-semibold text-gray-700 w-full">
                        <a
                          href={t("/account")}
                          className="block px-4 py-3 pl-2 rounded-md hover:bg-gray-200 transition-colors dark:text-white"
                        >
                          {t("account")}
                        </a>
                      </SheetTitle>
                    </div>

                    {/* VAT */}
                    {hasVat && (
                      <div className="flex items-center border-b border-gray-200 pb-2 pr-12">
                        <Percent className="h-5 w-5 ml-10 mt-5 text-gray-500 dark:text-white" />
                        {/* Icon for VAT */}
                        <SheetTitle className="text-sm font-semibold text-gray-700 w-full">
                          <a
                            href={t("/vatlistpath")}
                            className="block px-4 py-3 pl-2 rounded-md hover:bg-gray-200 transition-colors dark:text-white"
                          >
                            {t("VAT")}
                          </a>
                        </SheetTitle>
                      </div>
                    )}

                    <div className="flex items-center border-b border-gray-200 pb-2 pr-12">
                      <Wallet className="h-5 w-5 ml-10 mt-5 text-gray-500  dark:text-white" />
                      {/* Icon for VAT */}
                      <SheetTitle className="text-sm font-semibold text-gray-700 w-full">
                        <a
                          href={t("/balance")}
                          className="block px-4 py-3 rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
                        >
                          {t("Cash Balance")}
                        </a>
                      </SheetTitle>
                    </div>
                    <div className="flex items-center border-b border-gray-200 pb-2 pr-12">
                      <Monitor className="h-5 w-5 ml-8 mt-5 text-gray-500  dark:text-white" />
                      {/* Icon for VAT */}
                      <SheetTitle className="text-sm font-semibold text-gray-700 w-full">
                        <a
                          href={t("/companyconfig")}
                          className="block px-4 py-3 ml-4 rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
                        >
                          {t("Config")}{" "}
                        </a>
                      </SheetTitle>
                    </div>
                    <div className="flex items-center border-b border-gray-200 pb-2 pr-12">
                      <Printer className="h-5 w-5 ml-8 mt-5 text-gray-500  dark:text-white" />
                      {/* Icon for VAT */}
                      <SheetTitle className="text-sm font-semibold text-gray-700 w-full">
                        <a
                          href={t("/printersconfig")}
                          className="block px-4 py-3 ml-4 rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
                        >
                          {t("Printer")}{" "}
                        </a>
                      </SheetTitle>
                    </div>
                  </div>
                </SheetHeader>
              </SheetContent>
            </Sheet>
            <Sheet>
              <SheetTrigger className="relative rounded-full bg-gray-800 p-1 text-white hover:text-white focus:outline-none">
                <Avatar className="h-8 w-8 sm:h-10 sm:w-10">
                  <AvatarImage
                    src={avatarSrc || "https://github.com/shadcn.png"}
                    alt="Company Logo"
                  />

                  <AvatarFallback>CN</AvatarFallback>
                </Avatar>
              </SheetTrigger>
              <SheetContent className="overflow-x-auto">
                <SheetHeader>
                  <SheetTitle>Employee Profile</SheetTitle>
                  <EmployeeInfo />
                </SheetHeader>
              </SheetContent>
            </Sheet>
          </div>
        </ul>
      </div>
    </Disclosure>
  );
};

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  );
});
ListItem.displayName = "ListItem";

export default StatusBar;
