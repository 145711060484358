import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import StatusBar from "@/componentsPos/StatusBar";
import Sidebar from "@/componentsPos/SideBar";
import { useTranslation } from "react-i18next";
import createRoutesConfig from "./routesConfig";
import { useAuth } from "../provider/authProvider";
import ProtectedRoute from "@/routes/route/ProtectedRoutes";
import LoginPage from "@/pages/login/LoginPage";
import CreateBusinessRegister from "@/pages/businessRegsiter/CreateBusinessRegister";
import ForgetUsernamePage from "@/pages/login/ForgetUsernamePage";
import ForgetPasswordPage from "@/pages/login/ForgetPasswordPage";
import ResetPasswordPage from "@/pages/login/ResetPasswordPage";

type PathMappings = Record<string, Record<string, string>>;

const pathMappings: PathMappings = {
  en: {
    "/CreateProd": "/CreateProd",
    "/CreateRole": "/CreateRole",
    "/RoleList": "/RoleList",
    "/EmployeeList": "/EmployeeList",
    "/ProductsList": "/ProductsList",
    "/CreateEmployee": "/CreateEmployee",
    "/CustomerList": "/CustomerList",
    "/CreateCustomer": "/CreateCustomer",
    "/CustomerCategoryList": "/CustomerCategoryList",
    "/CreateCategoryCustomer": "/CreateCategoryCustomer",
    "/ProductCategoryList": "/ProductCategoryList",
    "/CreateProductCategory": "/CreateProductCategory",
    "/SupplierList": "/SupplierList",
    "/CreateSupplier": "/CreateSupplier",
    "/CreateUom": "/CreateUom",
    "/UomList": "/UomList",
    "/EditRole": "/EditRole",
    "/EditEmployee": "/EditEmployee",
    "/EditCustomer": "/EditCustomer",
    "/EditSupplier": "/EditSupplier",
    "/EditProductCategory": "/EditProductCategory",
    "/EditCustomerCategory": "/EditCustomerCategory",
    "/EditProducts": "/EditProducts",
    "/EditUom": "/EditUom",
    "/ListVAT": "/ListVAT",
    "/EditVat": "/EditVat",
    "/CreateVAT": "/CreateVAT",
    "/SalesOrder": "/SalesOrder",
    "/LotList": "/LotList",
    "/CreateLot": "/CreateLot",
    "/EditLot": "/EditLot",
    "/InventoryList": "/InventoryList",
    "/CreateInventory": "/CreateInventory",
    "/EditInventory": "/EditInventory",
    "/ShopList": "/ShopList",
    "/CreateShop": "/CreateShop",
    "/EditShop": "/EditShop",
    "/PaymentMethodList": "/PaymentMethodList",
    "/CreatePaymentMethod": "/CreatePaymentMethod",
    "/EditPaymentMethod": "/EditPaymentMethod",
    "/WarehouseList": "/WarehouseList",
    "/CreateWarehouse": "/CreateWarehouse",
    "/EditWarehouse": "/EditWarehouse",
    "/PaymentTermslistpath": "/PaymentTermslistpath",
    "/CreatePaymentTerms": "/CreatePaymentTerms",
    "/EditPaymentTerms": "/EditPaymentTerms",
    "/CurrencyList": "/CurrencyList",
    "/CreateCurrency": "/CreateCurrency",
    "/EditCurrency": "/EditCurrency",
    "/CashAccountList": "/CashAccountList",
    "/CreateCashAccount": "/CreateCashAccount",
    "/EditCashAccount": "/EditCashAccount",
    "/SalesOrder2": "/SalesOrder2",
    "/PurchaseOrder": "/PurchaseOrder",
    "/PosBar": "/PosBar",
    "/CreateCashAccountOwner": "/CreateCashAccountOwner",
    "/EditCashAccountOwner": "/EditCashAccountOwner",
    "/CashAccountOwnerList": "/CashAccountOwnerList",
    "/PurchaseOrderList": "/PurchaseOrderList",
    "/SalesOrderList": "/SalesOrderList",
    "/CompositeProuductList": "/CompositeProuductList",
    "/CreateCompositeProduct": "/CreateCompositeProduct",
    "/EditCompositeProduct": "/EditCompositeProduct",
    "/ExchangeRateList": "/ExchangeRateList",
    "/CreateExchangeRate": "/CreateExchangeRate",
    "/EditExchangeRate": "/EditExchangeRate",
    "/EditBusiness": "/EditBusiness",
    "/Balance": "/Balance",
    "/BalanceSalesOrder": "/BalanceSalesOrder",
    "/Companyinfo": "/Companyinfo",
    "/ImportEmployee": "/ImportEmployee",
    "/ImportCustomers": "/ImportCustomers",
    "/ImportProduct": "/ImportProduct",
    "/ImportSupplier": "/ImportSupplier",
    "/ImportLot": "/ImportLot",
    "/Account": "/Account",
    "/FastSale": "/FastSale",
    "/AccountTransfer": "/AccountTransfer",
    "/CashLedger": "/CashLedger",
    "/CompanyConfig": "/CompanyConfig",
    "/PrinterConfiguration":"/PrinterConfiguration",
    "/Transaction": "/Transaction",
    "/CompanyCertificate": "/CompanyCertificate",
  },
  alb: {
    "/CreateProd": "/KrijoProdukt",
    "/CreateRole": "/KrijoRol",
    "/RoleList": "/ListaRoleve",
    "/EmployeeList": "/ListaPunojseve",
    "/ProductsList": "/ListaProdukteve",
    "/CreateEmployee": "/KrijoPunojesin",
    "/CustomerList": "/ListaKlienteve",
    "/CreateCustomer": "/KrijoKlient",
    "/CustomerCategoryList": "/ListaKategoriseklientit",
    "/CreateCategoryCustomer": "/KrijoKategorineKlientit",
    "/ProductCategoryList": "/ListaKategoriseProduktit",
    "/CreateProductCategory": "/KrijoKategorineProduktit",
    "/SupplierList": "/ListaFurnizuesve",
    "/CreateSupplier": "/KrijoFurnizues",
    "/CreateUom": "/KrijoNjesiMatese",
    "/UomList": "/ListaNjesiseMatese",
    "/EditRole": "/NdryshoRolin",
    "/EditEmployee": "/NdryshoPunojesin",
    "/EditCustomer": "/NdryshoKlientin",
    "/EditSupplier": "/NdryshoFurnizuesin",
    "/EditProductCategory": "/NdryshoKategorineProduktit",
    "/EditCustomerCategory": "/NdryshoKategorineKlientit",
    "/EditProducts": "/NdryshoProduktin",
    "/EditUom": "/NdryshoNjesiMatese",
    "/SalesOrder": "/FaqjaShitjes",
    "/ListVAT": "/ListaTVSH",
    "/EditVat": "/NdryshoTVSh",
    "/CreateVAT": "/KrijoTVSh",
    "/LotList": "/ListaLoteve",
    "/CreateLot": "/KrijoLotin",
    "/EditLot": "/NdryshoLotin",
    "/InventoryList": "/ListaInventarit",
    "/CreateInventory": "/KrijoInventarin",
    "/EditInventory": "/NdryshoInventarin",
    "/ShopList": "/ListaDyqaneve",
    "/CreateShop": "/KrijoDyqan",
    "/EditShop": "/NdryshoDyqanin",
    "/PaymentMethodList": "/ListaMenyravePageses",
    "/CreatePaymentMethod": "/KrijoMenyrenPageses",
    "/EditPaymentMethod": "/NdryshoMenyrenPageses",
    "/WarehouseList": "/ListaMagazinave",
    "/CreateWarehouse": "/KrijoMagazin",
    "/EditWarehouse": "/NdryshoMagazinen",
    "/PaymentTermslistpath": "/ListakushtevePageses",
    "/CreatePaymentTerms": "/KrijoKushtetPageses",
    "/EditPaymentTerms": "/NdryshoKushtetPageses",
    "/CurrencyList": "/ListaValutes",
    "/CreateCurrency": "/KrijoValuten",
    "/EditCurrency": "/NdryshoValuten",
    "/CashAccountList": "/ListaLlogariseParave",
    "/CreateCashAccount": "/KrijoLlogrineParave",
    "/EditCashAccount": "/NdryshoLlogarinParave",
    "/SalesOrder2": "/FaqjaShitjes2",
    "/PurchaseOrder": "/BejPorosi",
    "/PosBar": "/PosBar",
    "/CreateCashAccountOwner": "/KrijoLlogarinePunojesit",
    "/EditCashAccountOwner": "/NdryshoLlogarinePunojesit",
    "/CashAccountOwnerList": "/ListaLlogarisePunojesit",
    "/PurchaseOrderList": "/ListaPorosive",
    "/SalesOrderList": "/ListaShitjeve",
    "/CompositeProuductList": "/ListaProduktevePerbere",
    "/CreateCompositeProduct": "/KrijoProduktinPerbere",
    "/EditCompositeProduct": "/NdryshoProduktinPerbere",
    "/ExchangeRateList": "/ListaKovertimit",
    "/CreateExchangeRate": "/KrijoKonvertimin",
    "/EditExchangeRate": "/NdryshoKonvertimin",
    "/EditBusiness": "/NdryshoBisnesin",
    "/Balance": "/Balanca",
    "/BalanceSalesOrder": "/BalancaShitje",
    "/Companyinfo": "/InformacionetKompanise",
    "/ImportEmployee": "/ImportPunojesin",
    "/ImportCustomers": "/ImportKlientet",
    "/ImportProduct": "/ImportProduktin",
    "/ImportSupplier": "/ImportFurnitorin",
    "/ImportLot": "/ImportLotin",
    "/Account": "/Llogaria",
    "/FastSale": "/Shit",
    "/AccountTransfer": "/TransfertLlogarie",
    "/CashLedger": "/RegjisterPagesave",
    "/CompanyConfig": "/KonfigurimetKompansise",
    "/Transaction": "/Transaksione",
    "/CompanyCertificate": "/CertifikataKompanise",
        "/PrinterConfiguration":"/KonfigurimetPrinterit",

  },
};

const translatePath = (path: string, language: string): string => {
  // Mappings for the current language
  const mappedPaths = pathMappings[language] || {};

  // Reverse mappings for translation from other languages to English
  const reverseMappedPaths: Record<string, string> = Object.entries(
    pathMappings
  ).reduce((acc, [, paths]) => {
    Object.entries(paths).forEach(([enPath, langPath]) => {
      acc[langPath] = enPath;
    });
    return acc;
  }, {} as Record<string, string>);

  // Check if the path is already in the correct language
  if (Object.values(mappedPaths).includes(path)) {
    // The path is already translated for the current language
    return path;
  }

  // Attempt to translate path from the current language mappings
  const translatedPath = mappedPaths[path];

  // If no direct translation is found, check reverse mappings
  if (!translatedPath) {
    const reversedPath = reverseMappedPaths[path];
    if (reversedPath) {
      // Translate the reversed path back to the current language
      return mappedPaths[reversedPath] || reversedPath;
    }
  }

  // Return the original path if no translation is found
  return translatedPath || path;
};

const AppRoutes: React.FC = () => {
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [activeAccordionItem, setActiveAccordionItem] = useState<string[]>([]);
  const [routesConfig, setRoutesConfig] = useState(createRoutesConfig(t));
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [initialPathTranslated, setInitialPathTranslated] =
    useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth >= 999);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setRoutesConfig(createRoutesConfig(t));
  }, [t]);
  useEffect(() => {
    const currentPath = location.pathname;
    const translatedPath = translatePath(currentPath, currentLanguage);

    // Avoid infinite loop: Only navigate if the translated path is different from the current path
    if (
      translatedPath &&
      currentPath !== translatedPath &&
      !initialPathTranslated
    ) {
      navigate(translatedPath, { replace: true });
      setInitialPathTranslated(true); // Mark that we've handled the initial translation
    } else if (currentPath === translatedPath && initialPathTranslated) {
      // Reset initialPathTranslated when the translated path matches the current path
      setInitialPathTranslated(false);
    }
  }, [location.pathname, currentLanguage, navigate, initialPathTranslated]);

  useEffect(() => {
    const handleLanguageChange = () => {
      const language = i18n.language;

      // Update the language state only when it's actually changed
      if (currentLanguage !== language) {
        setCurrentLanguage(language);
        setInitialPathTranslated(false); // Allow translation when language changes
      }
    };

    // Add listener for language changes
    handleLanguageChange(); // Run initially
    i18n.on("languageChanged", handleLanguageChange);

    // Cleanup: remove the listener on unmount
    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [currentLanguage]);

  useEffect(() => {
    const hostname = window.location.hostname;

    // Check if the current hostname includes 'rregjistrohu' as a part of it
    if (hostname.includes("rregjistrohu")) {
      // Allow navigation to /RegisterBusiness route
      navigate("/RegisterBusiness");
    }
  }, [navigate]);

  const handleIconClick = (items: string[]) => {
    setIsSidebarOpen(true);
    setActiveAccordionItem(items);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    if (!isSidebarOpen) {
      setActiveAccordionItem([]);
    }
  };
  return (
    <div className="flex flex-col h-screen">
      {token && <StatusBar />}
      <div className="flex flex-1">
        {token && (
          <Sidebar
            isOpen={isSidebarOpen}
            activeItem={activeAccordionItem}
            onIconClick={handleIconClick}
            onToggle={toggleSidebar}
          />
        )}
        <div
          className={`flex-1 transition-all duration-300 overflow-hidden ${
            token && isSidebarOpen ? "p-10 ml-64" : token ? "p-10 ml-10" : ""
          }`}
        >
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/RecoveryUsername" element={<ForgetUsernamePage />} />
            <Route path="/RecoveryPassword" element={<ForgetPasswordPage />} />
            <Route
              path="/reset-password/:token/:employeeId"
              element={<ResetPasswordPage />}
            />
            <Route
              path="/RegisterBusiness"
              element={<CreateBusinessRegister />}
            />
            <Route element={<ProtectedRoute />}>
              {routesConfig.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
            </Route>
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default AppRoutes;
