import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  Controller,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/provider/authProvider";
import DataFetcher from "@/provider/DataFetcher";
import { axiosInstance } from "@/lib/utils";

// Updated form schema
const formSchema = z.object({
  balance: z.number().min(0, { message: "Balance must be a positive number" }),
  currencyId: z.string().min(1, { message: "Currency is required" }),
  name: z.string().min(1, { message: "Name is required" }),
  iban: z.string().optional().nullable(),
  swift: z.string().optional().nullable(),
  cashAccountId: z.string().min(1, { message: "Cash account is required" }), // Updated field for cash account ID
});

type FormValues = z.infer<typeof formSchema>;

interface Currency {
  id: string;
  name: string;
  symbol: string;
}
interface CashAccount {
  id: number; // Change type to number
  balance: number;
  currencyId: string;
  name: string;
  iban?: string | null;
  swift?: string | null;
  owners: { id: number }[]; // Assume this structure for owners
}

const CashBalanceEditSalesOrder: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { employeeId, isSuperUser } = useAuth();

  // State variables
  const Currency_STORAGE_KEY = "currencyData";
  const CashAccount_STORAGE_KEY = "cashaccountData";
  const [currentBalance, setCurrentBalance] = useState<number | null>(null);
  const [currency, setCurrency] = useState<Currency | null>(null);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [parsedEmployeeId, setParsedEmployeeId] = useState<number | null>(null);
  const [cashAccounts, setCashAccounts] = useState<CashAccount[]>([]);
  const [cashAccountId, setCashAccountId] = useState<number | null>(null); // Change to number
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      currencyId: "",
      name: "",
      iban: "",
      swift: "",
      cashAccountId: "", // Initialize cash account ID as number

      balance: 0,
    },
  });

  useEffect(() => {
    // Parse and set the employeeId when the component mounts
    setParsedEmployeeId(employeeId ? parseInt(employeeId, 10) : null);
  }, [employeeId]);

  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous location
  };

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const currenciesResponse = await DataFetcher.getData<Currency[]>(
          `${API_URLS.BASE_URL}${API_URLS.Currency}`,
          Currency_STORAGE_KEY
        );
        setCurrencies(currenciesResponse || []);
      } catch (error) {
        console.error("Error fetching currencies:", error);
      }
    };

    fetchCurrencies();
  }, []);

  useEffect(() => {
    const fetchCashAccounts = async () => {
      try {
        const response = await DataFetcher.getData<CashAccount[]>(
          `${API_URLS.BASE_URL}${API_URLS.CashAccount}`,
          CashAccount_STORAGE_KEY,
          true
        );

        if (response) {
          // If isSuperUser, show all cash accounts, otherwise filter by employeeId
          const employeeCashAccounts = isSuperUser
            ? response
            : response.filter((account) =>
                account.owners.some((owner) => owner.id === parsedEmployeeId)
              );

          setCashAccounts(employeeCashAccounts);

          // Automatically select the first account, if available
          if (employeeCashAccounts.length > 0) {
            const firstAccount = employeeCashAccounts[0];
            setCashAccountId(firstAccount.id);
            methods.setValue("cashAccountId", firstAccount.id.toString()); // Set the first account's ID as default in form state
            setCurrentBalance(firstAccount.balance); // Set the current balance
            methods.setValue("currencyId", firstAccount.currencyId); // Populate currency
            methods.setValue("name", firstAccount.name); // Populate name
            methods.setValue("iban", firstAccount.iban); // Populate IBAN
            methods.setValue("swift", firstAccount.swift); // Populate SWIFT
          }
        }
      } catch (error) {
        console.error("Error fetching cash accounts:", error);
      }
    };

    if (parsedEmployeeId !== null || isSuperUser) {
      fetchCashAccounts();
    }
  }, [parsedEmployeeId, isSuperUser]);
  

  const handleCashAccountChange = (selectedValue: string) => {
    const selectedId = Number(selectedValue); // Parse selected value to a number
    const selectedAccount = cashAccounts.find(
      (account) => account.id === selectedId
    );

    if (selectedAccount) {
      setCashAccountId(selectedId); // Set cash account ID as a number
      setCurrentBalance(selectedAccount.balance); // Set current balance from selected account
      methods.setValue("currencyId", selectedAccount.currencyId); // Populate currency
      methods.setValue("name", selectedAccount.name); // Populate name
      methods.setValue("iban", selectedAccount.iban); // Populate IBAN
      methods.setValue("swift", selectedAccount.swift); // Populate SWIFT
    } else {
      setCashAccountId(null); // Reset if no account is found
      setCurrentBalance(null); // Reset balance
    }
  };
  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await axiosInstance.put(
        `${API_URLS.BASE_URL}${API_URLS.CashAccount}/${cashAccountId}`,
        {
          balance: data.balance,
          currencyId: data.currencyId,
          name: data.name,
          iban: data.iban,
          swift: data.swift,
        }
      );
      const updatedAccount = {
        id: cashAccountId,
        balance: data.balance,
        currencyId: parseInt(data.currencyId),
        name: data.name,
        iban: data.iban,
        swift: data.swift,
      };

      localStorage.setItem(
        "selectedCashAccount",
        JSON.stringify(updatedAccount)
      );
      toast.success(t("CashAccount updated successfully"));
      setCurrentBalance(data.balance);
      setCurrency(
        currencies.find((currency) => currency.id === data.currencyId) || null
      );
      methods.reset();
    } catch (error) {
      toast.error(t("Error updating cash balance"));
      console.log("Error updating cash balance", error);
    }
  };
const [showCashAccountMessage, setShowCashAccountMessage] = useState(false);

  useEffect(() => {
    if (currentBalance === null) {
      const timer = setTimeout(() => {
        setShowCashAccountMessage(true);
      }, 5000); // 5 seconds timeout

      // Cleanup the timeout on unmount or if currentBalance changes
      return () => clearTimeout(timer);
    } else {
      setShowCashAccountMessage(false); // Reset the message when balance is not null
    }
  }, [currentBalance]);
  return (
    <FormProvider {...methods}>
      <div className="flex justify-center items-center">
        <div className="w-full max-w-lg p-6 rounded shadow-md">
          <div className="cash-balance-editor">
            <ToastContainer />
            <h2>{t("Update Cash Balance")}</h2>
          <p>
      {t("Current Balance")}:
      {currentBalance !== null ? (
        <>
          {currentBalance} {currency?.symbol || ""}
        </>
      ) : (
        <>
          {showCashAccountMessage ? (
            <span className="text-red-500">
              {t("You don't have a cash account.")}
            </span>
          ) : (
            t("Loading...")
          )}
        </>
      )}
    </p>
            <FormItem>
              <FormLabel>{t("Select Cash Account")}</FormLabel>
              <FormControl>
                <Controller
                  name="cashAccountId"
                  control={methods.control}
                  render={({ field }) => (
                    <Select
                      onValueChange={(value) => {
                        field.onChange(value); // Update form state
                        handleCashAccountChange(value); // Update balance and other values
                      }}
                      value={field.value.toString()} // Convert to string for Select
                    >
                      <SelectTrigger>
                        <SelectValue placeholder={t("Select cash account")} />
                      </SelectTrigger>
                      <SelectContent>
                        {cashAccounts.map((account) => (
                          <SelectItem
                            key={account.id}
                            value={account.id.toString()}
                          >
                            {" "}
                            {/* Convert to string for SelectItem */}
                            {account.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  )}
                />
              </FormControl>
              {methods.formState.errors.cashAccountId && (
                <FormMessage>
                  {methods.formState.errors.cashAccountId.message}
                </FormMessage>
              )}
            </FormItem>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <FormItem>
                <FormLabel>{t("New Balance")}</FormLabel>
                <FormControl>
                  <Input
                    {...methods.register("balance", { valueAsNumber: true })}
                    type="number"
                    placeholder={t("Enter new balance")}
                                              pattern="\d*"

                  />
                </FormControl>
                {methods.formState.errors.balance && (
                  <FormMessage>
                    {methods.formState.errors.balance.message}
                  </FormMessage>
                )}
              </FormItem>

              <FormItem>
                <FormLabel>{t("Currency")}</FormLabel>
                <FormControl>
                  <Controller
                    name="currencyId"
                    control={methods.control}
                    render={({ field }) => (
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                        disabled
                      >
                        <SelectTrigger disabled>
                          <SelectValue placeholder={t("Select currency")} />
                        </SelectTrigger>
                        <SelectContent>
                          {currencies.map((currency) => (
                            <SelectItem
                              key={currency.id}
                              value={currency.id.toString()}
                            >
                              {currency.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    )}
                  />
                </FormControl>
                {methods.formState.errors.currencyId && (
                  <FormMessage>
                    {methods.formState.errors.currencyId.message}
                  </FormMessage>
                )}
              </FormItem>
              <FormItem>
                <FormLabel>{t("Name")}</FormLabel>
                <FormControl>
                  <Input
                    {...methods.register("name")}
                    type="text"
                    placeholder={t("Enter name")}
                    readOnly
                  />
                </FormControl>
                {methods.formState.errors.name && (
                  <FormMessage>
                    {methods.formState.errors.name.message}
                  </FormMessage>
                )}
              </FormItem>
              {methods.getValues("iban") && ( // Check if IBAN has a value
                <FormItem>
                  <FormLabel>{t("IBAN")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("iban")}
                      type="text"
                      placeholder={t("Enter IBAN")}
                      readOnly
                    />
                  </FormControl>
                  {methods.formState.errors.iban && (
                    <FormMessage>
                      {methods.formState.errors.iban.message}
                    </FormMessage>
                  )}
                </FormItem>
              )}
              {methods.getValues("swift") && ( // Check if SWIFT has a value
                <FormItem>
                  <FormLabel>{t("SWIFT")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("swift")}
                      type="text"
                      placeholder={t("Enter SWIFT")}
                      readOnly
                    />
                  </FormControl>
                  {methods.formState.errors.swift && (
                    <FormMessage>
                      {methods.formState.errors.swift.message}
                    </FormMessage>
                  )}
                </FormItem>
              )}
              <div className="flex gap-4 mt-6">
                <Button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  {t("Save")}
                </Button>
                <Button
                  type="button"
                  onClick={handleGoBack}
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                >
                  {t("Cancel")}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default CashBalanceEditSalesOrder;
