import React, { useEffect, useState } from "react";
import {
  useForm,
  FormProvider,
  Controller,
  SubmitHandler,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "@/provider/authProvider";
import DataFetcher from "@/provider/DataFetcher";
import { useTranslation } from "react-i18next";
import CreateTransactionDto from "@/models/CreateCashLedgerDTO";
import { axiosInstance } from "@/lib/utils";
import axios from "axios";

// Form schema using zod
const formSchema = z
  .object({
    transactionType: z.number().min(3).max(6),
    amount: z.number(),
    cashAccountId: z.string(),
    note: z.string().optional(),
  })
  .superRefine((data, ctx) => {
    if (data.transactionType === 5 && data.amount < 0) {
      ctx.addIssue({
        code: "custom",
        path: ["amount"],
        message: "Amount must be 0 or more for Open Balance",
      });
    }
    if (data.transactionType !== 5 && data.amount <= 0) {
      ctx.addIssue({
        code: "custom",
        path: ["amount"],
        message: "Amount must be greater than 0",
      });
    }
  });

type FormValues = z.infer<typeof formSchema>;

interface CashAccount {
  id: number;
  balance: number;
  currencyId: string;
  name: string;
  iban?: string | null;
  swift?: string | null;
  owners: { id: number }[];
}

const CashLedger: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { employeeId,isSuperUser } = useAuth();
  const location = useLocation(); // Get location to retrieve navigation state

  const CashAccount_STORAGE_KEY = "cashaccountData";
  const [employeeCashAccounts, setEmployeeCashAccounts] = useState<
    CashAccount[]
  >([]);
  const [parsedEmployeeId, setParsedEmployeeId] = useState<number | null>(null);
  const { cashAccountId, transactionType } = location.state || {}; // Get the passed state
  // Initialize form methods
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      transactionType: undefined,
      amount: 0,
      cashAccountId: cashAccountId?.toString() || undefined,
      note: "",
    },
  });

  useEffect(() => {
    setParsedEmployeeId(employeeId ? parseInt(employeeId, 10) : null);
  }, [employeeId]);

  // Preselect cash account and transaction type from navigation state
  useEffect(() => {
    if (cashAccountId && transactionType) {
      console.log(
        "Navigated with cashAccountId:",
        cashAccountId,
        "and transactionType:",
        transactionType
      );

      // Set form values when the component is mounted
      methods.setValue("cashAccountId", cashAccountId.toString());
      methods.setValue("transactionType", transactionType);
    }
  }, [cashAccountId, transactionType, methods]);

  // Handle case where navigating from SalesOrder
  useEffect(() => {
    if (location.state?.fromSalesOrder) {
      const { cashAccountId, transactionType = 5 } = location.state; // Default to "Open Balance"
      methods.setValue("cashAccountId", cashAccountId?.toString());
      methods.setValue("transactionType", transactionType);
    }
  }, [location.state, methods]);
  useEffect(() => {
    // Check if there's a toastMessage in the location state
    if (location.state?.toastMessage) {
      // Show the toast with the message passed in the state
      toast.info(location.state.toastMessage);
    }
  }, [location.state]);

  // Fetch employee-specific cash accounts
 useEffect(() => {
  if (parsedEmployeeId !== null) {
    const fetchEmployeeCashAccounts = async () => {
      try {
        const response = await DataFetcher.getData<CashAccount[]>(
          `${API_URLS.BASE_URL}${API_URLS.CashAccount}`,
          CashAccount_STORAGE_KEY,
          true
        );

        if (response) {
          const employeeAccounts = isSuperUser
            ? response // Superuser gets all cash accounts
            : response.filter((account) =>
                account.owners.some((owner) => owner.id === parsedEmployeeId)
              ); // Regular user gets specific accounts

          setEmployeeCashAccounts(employeeAccounts);
        }
      } catch (error) {
        console.error("Error fetching employee-specific cash accounts:", error);
      }
    };

    fetchEmployeeCashAccounts();
  }
}, [parsedEmployeeId, isSuperUser]);


  // Preselect cash account and transaction type from SalesOrder
  useEffect(() => {
    if (location.state?.fromSalesOrder) {
      const { cashAccountId, transactionType = 5 } = location.state; // Default to "Open Balance"
      methods.setValue("cashAccountId", cashAccountId.toString());
      methods.setValue("transactionType", transactionType);
    }
  }, [location.state, methods]);

  const cashAccount = JSON.parse(
    localStorage.getItem("selectedCashAccount") || "{}"
  );

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const { transactionType, amount, cashAccountId, note } = data;

    try {
      const transactionDto = new CreateTransactionDto({
        transactionType,
        amount,
        cashAccountId: parseInt(cashAccountId, 10),
        note: note || null,
      });

      await axiosInstance.post(
        `${API_URLS.BASE_URL}${API_URLS.CASHLEGDER}`,
        transactionDto
      );
      if (transactionType == 5 && cashAccountId === String(cashAccount.id)) {
        const updatedAccount = { ...cashAccount, balance: amount };
        localStorage.setItem(
          "selectedCashAccount",
          JSON.stringify(updatedAccount)
        );
      } else if (
        transactionType == 3 ||
        (transactionType == 6 && cashAccountId === String(cashAccount.id))
      ) {
        const updatedAccount = {
          ...cashAccount,
          balance: cashAccount.balance + Number(amount),
        };
        localStorage.setItem(
          "selectedCashAccount",
          JSON.stringify(updatedAccount)
        );
      } else if (
        transactionType == 4 &&
        cashAccountId === String(cashAccount.id)
      ) {
        const updatedAccount = {
          ...cashAccount,
          balance: cashAccount.balance - Number(amount),
        };
        localStorage.setItem(
          "selectedCashAccount",
          JSON.stringify(updatedAccount)
        );
      }

      toast.success("Transaction completed successfully!");
      methods.reset();
    }catch (error) {
  console.error("Upload error:", error); // Log the error details

 if (axios.isAxiosError(error)) {
  if (error.response) {
    const errorMessage = error.response.data; // Directly get the error message

    // Check for specific error messages and display appropriate toast notifications
    if (errorMessage === "You cannot open balance twice in the same day") {
      toast.error(t("You cannot open balance twice in the same day")); // Specific toast message
    } 
  } else {
    toast.error(t("An unexpected error occurred.")); // Network or unknown error
  }
}
 else {
    toast.error("An unexpected error occurred."); // Non-Axios error
  }
}
  };

  return (
    <FormProvider {...methods}>
      <div className="flex justify-center items-center">
        <div className="w-full max-w-lg p-6 rounded shadow-md">
          <ToastContainer />
          <h2>{t("Record Cash Transaction")}</h2>

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {/* Transaction Type */}
            <FormItem>
              <FormLabel>
                {t("Transaction Type")} <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <FormControl>
                <Controller
                  name="transactionType"
                  control={methods.control}
                  render={({ field }) => (
                    <Select
                      onValueChange={(value) => field.onChange(Number(value))}
                      value={field.value?.toString() || ""}
                    >
                      <SelectTrigger>
                        <SelectValue
                          placeholder={t("Select transaction type")}
                        />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="3">{t("Receivable")}</SelectItem>
                        <SelectItem value="4">{t("Payable")}</SelectItem>
                        <SelectItem value="5">{t("Open Balance")}</SelectItem>
                        <SelectItem value="6">{t("Other")}</SelectItem>
                      </SelectContent>
                    </Select>
                  )}
                />
              </FormControl>
              {methods.formState.errors.transactionType && (
                <FormMessage>
                  {methods.formState.errors.transactionType.message}
                </FormMessage>
              )}
            </FormItem>

            {/* Amount */}
            <FormItem>
              <FormLabel>
                {t("Transfer Amount")} <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <FormControl>
                <Input
                  {...methods.register("amount", { valueAsNumber: true })}
                  type="number"
                  pattern="\d*"
                  placeholder={t("Enter amount")}
                />
              </FormControl>
              {methods.formState.errors.amount && (
                <FormMessage>
                  {methods.formState.errors.amount.message}
                </FormMessage>
              )}
            </FormItem>

            {/* Cash Account */}
            <FormItem>
              <FormLabel>
                {t("Cash Account")} <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <FormControl>
                <Controller
                  name="cashAccountId"
                  control={methods.control}
                  render={({ field }) => (
                    <Select
                      onValueChange={(value) => field.onChange(value)}
                      value={field.value}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder={t("Select to account")} />
                      </SelectTrigger>
                      <SelectContent>
                        {employeeCashAccounts.map((account) => (
                          <SelectItem
                            key={account.id}
                            value={account.id.toString()}
                          >
                            {account.name} - {account.balance}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  )}
                />
              </FormControl>
              {methods.formState.errors.cashAccountId && (
                <FormMessage>
                  {methods.formState.errors.cashAccountId.message}
                </FormMessage>
              )}
            </FormItem>

            {/* Note */}
            <FormItem>
              <FormLabel>{t("Note")}</FormLabel>
              <FormControl>
                <Input
                  {...methods.register("note")}
                  type="search"
                  placeholder={t("Enter note")}
                />
              </FormControl>
            </FormItem>

            {/* Submit button */}
            <div className="flex gap-4 mt-6">
              <Button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                {t("Submit")}
              </Button>
              <Button
                type="button"
                onClick={() => navigate(-1)}
                className="bg-gray-500 text-white px-4 py-2 rounded"
              >
                {t("Cancel")}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </FormProvider>
  );
};

export default CashLedger;
