import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { t } from "i18next";
import { axiosInstance } from "@/lib/utils";


class DataFetcher {


  private authToken: string | null = null;
  private readonly CACHE_EXPIRATION_TIME = 30 * 60 * 1000; // 30 minutes in milliseconds
  private readonly MAX_RETRIES = 3;
  private readonly REQUEST_TIMEOUT = 5000; // 5 seconds
  private axiosInstance = axiosInstance;

  constructor() {
    // this.axiosInstance = axios.create({
    //   timeout: this.REQUEST_TIMEOUT, // 5 seconds timeout
    // });

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 401) { // <== this can loop forever if the refresh token fails. TODO: Add a check to prevent infinite loop
            // Handle token refresh
            console.log("Token expired. Refreshing token...");
            await this.refreshToken();
            // Retry the original request
            if (this.authToken && error.config) {
              error.config.headers[
                "Authorization"
              ] = `Bearer ${this.authToken}`;
              return this.axiosInstance.request(error.config);
            }
          }
        }
        return Promise.reject(error);
      }
    );
  }

  setAuthToken(token: string) { // <== what does this do?
    this.authToken = token;
  }

  private async refreshToken() {
    // Implement your token refresh logic here
    // For example, make a request to your auth server to get a new token
    try {
      console.log("Refreshing token...");
      const response = await axios.post("/auth/refresh-token", {
        // Include necessary data for token refresh
      });
      if (response.status === 200) {
        this.authToken = response.data.token;
        if (this.authToken) {
          localStorage.setItem("token", this.authToken);
          console.log("Token refreshed:", this.authToken);
        }
      } else {
        console.error("Failed to refresh token:", response.data);
      }
    } catch (error) {
      console.error("Failed to refresh token:", error);
      this.authToken = null;
    }
  }

  private isExpired(timestamp: number, cacheExpirationTime: number): boolean {
    const currentTime = Date.now();
    return currentTime - timestamp > cacheExpirationTime;
  }

  private async retryRequest<T>(
    config: AxiosRequestConfig,
    retries: number
  ): Promise<T> {
    try {
      const response = await this.axiosInstance.request<T>(config);
      return response.data;
    } catch (error) {
      if (
        retries > 0 &&
        axios.isAxiosError(error) &&
        (error.code === "ECONNABORTED" || !error.response)
      ) {
        console.warn(
          `Retrying request... (${this.MAX_RETRIES - retries + 1}/${this.MAX_RETRIES
          })`
        );
        config.timeout = (config.timeout ?? this.REQUEST_TIMEOUT) * 2; // Double the timeout on subsequent retries
        return this.retryRequest<T>(config, retries - 1);
      }
      throw error;
    }
  }

  async getData<T>(
    url: string,
    cacheKey: string,
    noCache: boolean = false,
    cacheExpirationTime: number = this.CACHE_EXPIRATION_TIME,
    requestTimeout: number = this.REQUEST_TIMEOUT
  ): Promise<T | null> {
    try {
      // Attempt to retrieve data from cache
      if (!noCache) {
        const cachedData = localStorage.getItem(cacheKey);
        if (cachedData) {
          const { data, timestamp } = JSON.parse(cachedData);
          console.log(`Cache hit for key ${cacheKey}:`, data);
          if (!this.isExpired(timestamp, cacheExpirationTime)) {
            if (data !== undefined) {
              return data as T;
            } else {
              console.log(`Data in cache for key ${cacheKey} is undefined.`);
            }
          } else {
            console.log(`Cache expired for key ${cacheKey}.`);
          }
        } else {
          console.log(`Cache miss for key ${cacheKey}.`);
        }
      }

      console.log(`Fetching new data for key ${cacheKey} from URL: ${url}`);

      const token = localStorage.getItem("token");

      const response = await this.retryRequest<T>(
        {
          url,
          method: "get",
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
          timeout: requestTimeout,
        } as AxiosRequestConfig,
        this.MAX_RETRIES
      );

      if (response) {
        const dataToCache = {
          data: response, // <== po sikur te jete bosh kjo?
          timestamp: Date.now(),
        };
        localStorage.setItem(cacheKey, JSON.stringify(dataToCache));
      }
      return response;

    } catch (error) {
      if (axios.isAxiosError(error)) {

        if (error.response) {
          // Handle specific status codes
          switch (error.response.status) {
            case 400:
              console.error("Bad Request:", error.response.data);
              toast.error(t(`Bad Request`));

              break;
            case 401:
              console.error("Unauthorized:", error.response.data);
              toast.error(t(`Unauthorized`));


              break;
            case 403:
              console.error("Forbidden:", error.response.data);
              toast.error(t(`Access denied`));

              break;
            case 404:
              console.error("Not Found:", error.response.data);
              toast.error(t(`Not Found`));

              break;
            case 500:
              console.error("Internal Server Error:", error.response.data);
              toast.error(t(`Internal Server Error`));

              break;
            default:
              console.error("Error:", error.response.data);
          }
        } else if (error.code === "ECONNABORTED") {
          console.error("Request timed out:", error);
        } else {
          console.error("Network error:", error);
        }
      } else {
        console.error("Unexpected error:", error);
      }
      return null; // or throw error if you want to propagate it
    }
  }
}

// // Usage example
// const API_URL = `${API_URLS.BASE_URL}${API_URLS.PRODUCT}`;

// // Set the authorization token
// DataFetcher.setAuthToken('your-auth-token-here');

// await DataFetcher.getData<Product[]>(API_URL, 'ProductData').then(data => {
//     if (data) {
//         console.log(data);
//     } else {
//         console.log('Failed to fetch data');
//     }
// }
export default new DataFetcher();
