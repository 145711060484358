import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider, SubmitHandler, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import "@/css/MobileScreen.css";
import CreateShopDto from "@/models/CreateShopDto";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataFetcher from "@/provider/DataFetcher";
import API_URLS from "@/config";
import { Shop } from "@/types/ShopType";
import { ShopFormProps } from "@/interfaces/ShopInterfaces";
import "@/css/LoadingCreate.css";
import { ShopCodeType } from "@/types/ShopCodeType";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { axiosInstance } from "@/lib/utils";
const ShopForm: React.FC<ShopFormProps> = ({
  mode,
  inDialog = false,
  onClose,
}) => {
  const { t } = useTranslation();

  const formSchema = z.object({
    name: z
      .string()
      .min(1, { message: t("Name is required") })
      .max(50, { message: t("Max length is 50 characters for shop name") }),
    address: z.string().optional(),
    businessUnitCode:z.string().optional(),
  });

  type FormValues = z.infer<typeof formSchema>;
  const [loading, setLoading] = useState(false); // Initialize loading state
    const Shopcode_STORAGE_KEY = "ShopcodeData";
    const [shopcode, setshopcode] = useState<ShopCodeType[]>([]);

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || { id: localStorage.getItem("shopId") };
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      address: "",
      businessUnitCode:""
    },
  });

  useEffect(() => {
  if (id) {
    localStorage.setItem("shopId", id);
  }

  if (mode === "edit" && id) {
   const fetchShop = async () => {
    try {
      const response = await axiosInstance.get(
        `${API_URLS.BASE_URL}${API_URLS.SHOP}/${id}`
      );

      if (response.data) {
        // Match shopcode.businessUnitCode with response.data.buCode
        const matchingBuCode = shopcode.find(
          (bu) => bu.businessUnitCode === response.data.buCode
        );

        // Update the response with matched businessUnitCode or empty string
        const updatedResponse = {
          ...response.data,
          businessUnitCode: matchingBuCode
            ? matchingBuCode.businessUnitCode
            : "",
        };

        // Reset the form with the updated response
        methods.reset(updatedResponse);
      } else {
        console.error("Shop not found or response is null");
      }
    } catch (error) {
      console.error("Error fetching Shop:", error);
    }
  };

  fetchShop();
  }
}, [id, mode, methods, shopcode]); // Add buCode as a dependency


     useEffect(() => {
    const fetchOpcode = async () => {
      const response = await DataFetcher.getData<ShopCodeType[]>(
        `${API_URLS.BASE_URL}${API_URLS.SHOPCODE}`,
        Shopcode_STORAGE_KEY
      );
      if (response) {
        setshopcode(response);
        localStorage.setItem(Shopcode_STORAGE_KEY, JSON.stringify(response));
      }
    };

    fetchOpcode();
  }, []);

  const updateLocalStorage = (updatedShop: Shop) => {
    const existingShops = JSON.parse(localStorage.getItem("shopData") || "[]");
    const updatedShops = existingShops.map((shop: Shop) =>
      shop.id === updatedShop.id ? updatedShop : shop
    );
    localStorage.setItem("shopData", JSON.stringify(updatedShops));
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true); // Show loading spinner

    const shopData = new CreateShopDto({
      name: data.name,
      address: data.address,
      businessUnitCode:data.businessUnitCode,

    });

    try {
      let successMessage = "";

      if (mode === "create") {
        const response = await axiosInstance.post(
          `${API_URLS.BASE_URL}${API_URLS.SHOP}`,
          shopData
        );
        successMessage = t("Shop created successfully");

        const existingShops = JSON.parse(
          localStorage.getItem("shopData") || "[]"
        );
        existingShops.push(response.data);
        localStorage.setItem("shopData", JSON.stringify(existingShops));

        if (inDialog && onClose) {
          onClose(response.data);
        }
      } else if (mode === "edit" && id) {
        const response = await axiosInstance.put(
          `${API_URLS.BASE_URL}${API_URLS.SHOP}/${id}`,
          shopData
        );
        successMessage = t("Shop updated successfully");

        updateLocalStorage(response.data);

        if (inDialog && onClose) {
          onClose();
        }
      }

      methods.reset();
      localStorage.removeItem("shopId");

      if (!inDialog) {
        navigate(t("/shoplistpath"));
      }

      // Show toast after navigation
      setTimeout(() => {
        if (successMessage) {
          toast.success(successMessage);
        }
      }, 250);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;

        if (status === 403) {
          toast.error(t("You do not have permission to perform this action."));
        } else if (status === 500) {
          toast.error(t("A server error occurred. Please try again later."));
        } else if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage =
              t(`errorShop.${err.errorMessage}`) ||
              t("errorShop.An error occurred");
            toast.error(translatedMessage);
          });
        } else {
          toast.error(t("An error occurred"));
        }
      } else {
        toast.error(t("An unexpected error occurred"));
      }
      console.error("Error submitting Shop:", error);
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  const handleDialogSubmit = () => {
    methods.handleSubmit(onSubmit)();
  };

  return (
    <FormProvider {...methods}>
      <div className="flex justify-center items-center">
        {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
        <div className="w-full max-w-3xl p-6 rounded shadow-md">
          <div style={{ padding: "20px" }}>
            <h1>{mode === "create" ? t("createshop") : t("editshop")}</h1>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleDialogSubmit();
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "20px",
                }}
              >
                <FormItem>
                  <FormLabel>
                    {t("name")}
                    <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("name")}
                      type="search"
                      placeholder={t("putname")}
                    />
                  </FormControl>
                  {methods.formState.errors.name && (
                    <FormMessage>
                      {methods.formState.errors.name.message}
                    </FormMessage>
                  )}
                </FormItem>
                <FormItem>
                  <FormLabel>{t("address")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("address")}
                      type="search"
                      placeholder={t("putaddress")}
                    />
                  </FormControl>
                  {methods.formState.errors.address && (
                    <FormMessage>
                      {methods.formState.errors.address.message}
                    </FormMessage>
                  )}
                </FormItem>
              </div>
<FormItem style={{ maxWidth: "333px" }}>
  <FormLabel>{t("businessUnitCode")}</FormLabel>
  <FormControl>
    <Controller
      name="businessUnitCode"
      control={methods.control}
      render={({ field }) => (
        <Select
          onValueChange={field.onChange}
          value={field.value || ""}
        >
          <SelectTrigger>
            <SelectValue>
              {field.value
                ? shopcode.find((shop) => shop.businessUnitCode === field.value)
                    ?.businessUnitCode || t("Unknown")
                : t("Select Opcode")}
            </SelectValue>
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>{t("businessUnitCode")}</SelectLabel>
              {shopcode
                .filter((shop) => shop.name && shop.businessUnitCode) // Validate data
                .map((shop) => (
                  <SelectItem
                    key={shop.businessUnitCode}
                    value={shop.businessUnitCode}
                  >
                    {shop.name} - {shop.businessUnitCode}
                  </SelectItem>
                ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      )}
    />
  </FormControl>
  {methods.formState.errors.businessUnitCode && (
    <FormMessage>
      {methods.formState.errors.businessUnitCode.message}
    </FormMessage>
  )}
</FormItem>

              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginTop: "50px",
                }}
              >
                <Button
                  type="button"
                  onClick={handleDialogSubmit}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  {t("savelbtn")}
                </Button>
                <Button
                  type="button"
                  onClick={() =>
                    inDialog ? onClose?.() : navigate(t("/shoplistpath"))
                  }
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                >
                  {t("canclelbtn")}
                </Button>
              </div>
            </form>
          </div>
          <ToastContainer />
        </div>
      </div>
    </FormProvider>
  );
};

export default ShopForm;
