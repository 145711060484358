class CreateSalesOrderDto2 {
  customerId: number;
  currencyId: number;
  tableId: number | null;

  totalAmount: number;
  quantity: number;
  vatAmount: number;
  discountAmount: number;
  tcrCode: string;
  buCode: string;
  opCode: string;
  // voucherId: number;
  employeeId: number;
  // shopId: number;
  warehouseId: number | undefined;

  notes: string;
  // cashAccountId: number;
  allowNegativeInvetory: boolean;
  tableOrderLines: {
    productId: number;
    uoMId: number;
    discountAmount: number;
    discountPercentage: number;
    quantity: number;
    lineNote: string;
  }[];

  constructor(order: {
    customerId: number;
    currencyId: number;
    totalAmount: number;
    quantity: number;
    tableId: number | null;
    vatAmount: number;
    discountAmount: number;
    tcrCode: string;
    buCode: string;
    opCode: string;
    // voucherId: number;
    employeeId: number;
    // shopId: number;
    warehouseId: number | undefined;

    notes: string;
    // cashAccountId: number;
    allowNegativeInvetory: boolean;
    tableOrderLines: {
      productId: number;
      uoMId: number;
      quantity: number;
      lineNote: string;
      discountAmount: number;
      discountPercentage: number;
    }[];
  }) {
    this.customerId = order.customerId;
    this.currencyId = order.currencyId;
    this.totalAmount = order.totalAmount;
    this.quantity = order.quantity;
    this.vatAmount = order.vatAmount;
    this.discountAmount = order.discountAmount;
    this.tcrCode = order.tcrCode;
    this.buCode = order.buCode;
    this.opCode = order.opCode;
    this.tableId = order.tableId ?? null;

    // this.voucherId = order.voucherID;
    this.employeeId = order.employeeId;
    // this.shopId = order.shopId;
    this.warehouseId = order.warehouseId;
    this.discountAmount = order.discountAmount;
    // this.discountPercentage =order.discountPercentage;

    this.notes = order.notes;
    // this.cashAccountId = order.cashAccountId;
    this.allowNegativeInvetory = order.allowNegativeInvetory;
    this.tableOrderLines = order.tableOrderLines;
  }
}

export default CreateSalesOrderDto2;
