import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider, SubmitHandler, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
    FormControl,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import "@/css/MobileScreen.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import "@/css/LoadingCreate.css";

import CreateBusinessDto from "@/models/CreateBusinessRegisterDto";
import Logo from "/assets/logo.png"
import ThemeSwitcher from "@/componentsPos/ThemeSwitcher";
import LanguageSelector from "@/componentsPos/LanguageSelector";
import VerifyDto from "@/models/VerifyDto";
import debounce from "lodash/debounce";


interface BusinessRegisterFormProps {
    mode: "create" | "edit";
}

const BusinessRegisterForm: React.FC<BusinessRegisterFormProps> = ({ mode }) => {
    const location = useLocation();
    const { id } = location.state || { id: localStorage.getItem("businessId") };
        const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
   const [newEmail, setNewEmail] = useState("");

const baseFormSchema = z.object({
name: z.string()
  .min(1, { message: t("Name is required") })
  .max(50, { message: t("Name cannot exceed 50 characters") }),
    tin: z.string().min(10, { message: t("TIN should have 10 characters") }).max(10, { message: t("Tin cannot exceed 10 characters") }),
    city: z.string().min(1, { message: t("City is required") }).max(30, { message: t("City cannot exceed 30 characters") }),
    country: z.string(),

    phoneNumber: z.string().min(1, { message: t("Phone number is required") }).max(30, { message: t("Phone number cannot exceed 30 characters") }),
    fieldOfActivity: z.string().min(1, { message: t("Field Activity is required") })
  .max(50, { message: t("Field Activity  cannot exceed 50 characters") }),
   isSeasonal: z.boolean().optional(),
    user_Name: z.string().min(1, { message: t("Name is required") }).max(50, { message: t("Name cannot exceed 50 characters") }),
    user_Username: z.string().min(4, { message: t("Username is required.Minimum 4  characters") }).max(15, { message: t("Username cannot exceed 15 characters") }),
user_Email: z.string().min(1, { message: t("Email is required") }).email({ message: "Invalid email format .Example:somone@gmail.com" }),
    user_PhoneNumber: z.string().min(1, { message: t("Phone number is required") }).max(30, { message: t("Phone number cannot exceed 30 characters") }),
    user_Password: z.string().min(4, { message: t("Password is required.Minimum 4 char characters") }).max(22, { message: t("Password cannot exceed 22 characters") }),
    // image: z.string().min(1, { message: "Name is required" }),
    address: z.string().min(1, { message: t("Address is required") }).max(50, { message: t("Address cannot exceed 50 characters") }),
    baseCurrency: z.number().min(1, { message: t("Currency is required") }),
    printerType: z.number().min(1, { message: t("Print  is required") }),
    allowNegativeInventory: z.boolean().optional(),
        hasTables: z.boolean().optional(),
        hasVat: z.boolean().optional(),

    subdomain:z.string().min(1, { message: t("Subdoname is required") }).max(25, { message: t("Subdoname  cannot exceed 25 characters") }),



});
const baseFormSchema1 = z.object({

code:z.number(),




});




const formSchema = baseFormSchema;
const formSchema1 = baseFormSchema1;

type FormValues = z.infer<typeof formSchema>;
type FormValues1 = z.infer<typeof formSchema1>;
// Function to refresh newEmail from localStorage
const refreshEmail = () => {
    const storedEmail = localStorage.getItem("user_Email");
    setNewEmail(storedEmail || ""); // Update newEmail with the latest storedEmail
};

    const methods = useForm<FormValues>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: "",
            tin: "",
            address: "",
            // CodefiscalizationCertificate:"",
            city: "",
            country: "ALB",
            phoneNumber: "",
            fieldOfActivity:"",
    // fiscalizationCertificate: "", // Set to null initially
            isSeasonal: false,
            allowNegativeInventory: false,
            hasVat:false,
            hasTables:false,
            printerType: 0,
            baseCurrency: 0,
            subdomain:"",
           
            user_Name: "",
            user_Username: "",
            user_PhoneNumber: "",
            user_Email: "",
            user_Password: "",
            // image: ""

        },
    });
    const methods1 = useForm<FormValues1>({
  resolver: zodResolver(formSchema1),
  defaultValues: {
    code: undefined, // Corrected to "undefined"
  },
});
   
   

    // State to manage form steps
    const [step, setStep] = useState(1);

    useEffect(() => {
        if (id) {
            localStorage.setItem("businessId", id);
        }


    }, [id, mode, methods]);
    // Function to handle verification logic
  

     

    // const updateLocalStorage = (updatedBusiness: CompanyData) => {
    //     // Retrieve existing business data from localStorage
    //     const existingBusinesses = JSON.parse(localStorage.getItem("businessData") || "[]");

    //     // Ensure existingBusinesses is an array
    //     const businessesArray = Array.isArray(existingBusinesses)
    //         ? existingBusinesses
    //         : [];

    //     // Update the specific business or keep the existing ones
    //     const updatedBusinesses = businessesArray.map((business: CompanyData) =>
    //         business.id === updatedBusiness.id ? updatedBusiness : business
    //     );

    //     // Save the updated array back to localStorage
    //     localStorage.setItem("businessData", JSON.stringify(updatedBusinesses));
    // };


    // Function to fetch business by subdomain
    // Function to fetch business by subdomain
  // Function to fetch business by subdomain
const fetchBusinesses = async (subdomain: string) => {
    setLoading(true);
    try {
        const response = await axios.get(`${API_URLS.BASE_URL}${API_URLS.idBySubdomain}`, {
            params: { subdomain }
        });

        const businessId = response.data;

        if (typeof businessId === 'number' && businessId > 0) {
            // Set the error for subdomain if it already exists
            methods.setError("subdomain", {
                type: "manual",
                message: t("This subdomain already exists.Try new one")
            });
        } else {
            setStep(2);
        }
    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
            setStep(2); // Subdomain not found, proceed
        } else {
            methods.setError("subdomain", {
                type: "manual",
                message: t("Error fetching business data.")
            });
        }
    } finally {
        setLoading(false);
    }
};









const onSubmit: SubmitHandler<FormValues> = async (data) => {
    console.log("Form data submitted:", data);
    setLoading(true);

    try {
        if (step === 2) {
            const businessData = new CreateBusinessDto({
                ...data,
                country: "ALB"  // Add country directly
            });

            const response = await axios.post(
                `${API_URLS.BASE_URL}${API_URLS.BUSINESSREGISTER}`,
                businessData
            );


            // Save email and username to localStorage
            localStorage.setItem("user_Email", data.user_Email);
            localStorage.setItem("username", data.user_Username); // Assuming username is in data
            localStorage.setItem("password", "******"); // Save obscured password

            localStorage.setItem("subdomain", data.subdomain);

            // Safely parse localStorage business data
            const storedData = JSON.parse(localStorage.getItem("businessData") || "[]");
            const existingBusinesses = Array.isArray(storedData?.data)
                ? storedData.data
                : [];

            // Add new business to the array and save back to localStorage
            existingBusinesses.push(response.data);
            localStorage.setItem("businessData", JSON.stringify({ data: existingBusinesses }));

            // Move to step 3 after successful creation
            setStep(3);

            // Reset form and remove businessId from localStorage
            console.log("Resetting form");
            methods.reset();
            localStorage.removeItem("businessId");
        }
    } catch (error) {
        console.error("Error submitting business:", error);

        // Handle Axios errors
        if (axios.isAxiosError(error) && error.response) {
            const { data } = error.response;
            if (Array.isArray(data)) {
                data.forEach((err: { errorMessage: string }) => {
                    toast.error(
                        t(`errorBusiness.${err.errorMessage}`) || 
                        t("errorBusiness.An error occurred")
                    );
                });
            } else {
                toast.error(t("An error occurred"));
            }
        } else {
            toast.error(t("An unexpected error occurred"));
        }
    } finally {
              refreshEmail();

        setLoading(false); // Stop loading
        
    }
};



//     try {
//         // Retrieve business data from localStorage
//         const storedData = JSON.parse(localStorage.getItem("businessData") || "[]");
//         const businesses = Array.isArray(storedData?.data) ? storedData.data : [];

//         if (businesses.length === 0) {
//             console.error("No business data found in localStorage.");
//             toast.error("No business data found to update.");
//             return;
//         }

//         // Get the last added business
//         const lastBusiness = businesses[businesses.length - 1];

//         // Construct the payload for updating the business
//         const updatedBusiness = {
//             name: lastBusiness.name,
//             tin: lastBusiness.tin,
//             subdomain: lastBusiness.subdomain,
//             image: lastBusiness.image,
//             city: lastBusiness.city,
//             country: lastBusiness.country,
//             address: lastBusiness.address,
//             phoneNumber: lastBusiness.phoneNumber,
//             fieldOfActivity: lastBusiness.fieldOfActivity,
//             isSeasonal: lastBusiness.isSeasonal,
//             user_Name: lastBusiness.superUser.name,           // Required field
//             user_Username: lastBusiness.superUser.username,   // Required field
//             user_Email: newEmail,                             // New email to update
//             user_PhoneNumber: lastBusiness.superUser.phoneNumber || "", // Optional field, use empty string if null
//             user_Password: "",                                // Set as needed if required
//             baseCurrency: 1,                                  // Set default or existing value as needed
//             printerType: 1,                                   // Set default or existing value as needed
//             allowNegativeInventory: true                      // Set default or existing value as needed
//         };

//         // Send PUT request to update the business
//         const response = await axios.put(
//             `${API_URLS.BASE_URL}${API_URLS.BUSINESSREGISTER}/${lastBusiness.id}`,
//             updatedBusiness
//         );

//         // Update the business data in localStorage
//         businesses[businesses.length - 1] = response.data;
//         localStorage.setItem("businessData", JSON.stringify({ data: businesses }));

//         // Also update the email in localStorage under a separate key
//         localStorage.setItem("user_Email", newEmail);

//         console.log("Business data updated:", response.data);
//     } catch (error) {
//         console.error("Error updating business:", error);

//         // Handle Axios errors
//         if (axios.isAxiosError(error) && error.response) {
//             const { data } = error.response;
//             if (Array.isArray(data)) {
//                 data.forEach((err: { errorMessage: string }) => {
//                     toast.error(
//                         t(`errorBusiness.${err.errorMessage}`) ||
//                         t("errorBusiness.An error occurred")
//                     );
//                 });
//             } else {
//                 toast.error(t("An error occurred"));
//             }
//         } else {
//             toast.error(t("An unexpected error occurred"));
//         }
//     }
// };




    // Function to move to the next step
 const handleNext = async () => {
    const subdomain = getValues("subdomain"); // Get the subdomain value from the form

    // Trigger validation for specific fields
    const isValid = await methods.trigger(["name", "tin", "address", "city", "country","subdomain","baseCurrency","printerType","phoneNumber","fieldOfActivity"]); // Add fields you want to validate

    if (subdomain && isValid) {
        fetchBusinesses(subdomain); // Call fetchBusinesses with the subdomain if validation passes
    } else {
        toast.error(t("Please complete all required fields.")); // Show error if fields are incomplete
    }
};
 const handlePrevious = () => setStep((prev) => Math.max(prev - 1, 1));



  const {  watch, setValue,getValues  } = methods;


  // Watch the "name" and "subdomain" fields to detect changes
    const nameValue: string = watch("name") ?? ""; // Ensure nameValue is always a string
  const subdomainValue: string = watch("subdomain") ?? ""; // Ensure subdomainValue is always a string

  // Debounced function to update subdomain if it is empty
  const updateSubdomain = debounce(() => {
    if (subdomainValue.trim() === "") {
      const firstWord = nameValue.trim().split(" ")[0];
      if (firstWord) {
        const validSubdomain = firstWord.replace(/[^a-zA-Z0-9-]/g, '');
        setValue("subdomain", validSubdomain.toLowerCase());
      }
    }
  }, 4000); // Adjust debounce time as needed

  // Only call updateSubdomain when name changes and subdomain is empty
  useEffect(() => {
    updateSubdomain();
  }, [nameValue, subdomainValue]);
// Handles email and code verification
 const handleVerification = async (email: string, code: number,subdomain:string) => {
  try {
    // Create a new instance of VerifyDto using the email and code.
     // 
    const verificationData = new VerifyDto({ email, code ,subdomain});

    // Send a POST request with the verification data.
    const response = await axios.post(`${API_URLS.BASE_URL}${API_URLS.VerifyEmail}`, verificationData);
    // Check if the verification was successful and show a toast notification.
  if (response) {
      toast.success(t("Email verified successfully"));
            setStep(4);

     
      // Display the message in a toast notification.
     
    }
  } catch (error) {
    console.error("Verification error:", error); // Log error details.

    // Handle Axios errors with meaningful messages.
    if (axios.isAxiosError(error) && error.response) {
      const { message } = error.response.data;
      toast.error(message || "Failed to verify email.");
    } else {
      toast.error("An unexpected error occurred.");
    }
  }
};

const resendVerificationCode = async (email: string) => {
    try {
        // Retrieve the subdomain from localStorage
        const subdomain = localStorage.getItem('subdomain');

        // Constructing the URL without query parameters
        const url = `${API_URLS.BASE_URL}${API_URLS.ResendVerificationEmail}`;

        // Setting up the request body with subdomain and newEmail
        const requestBody = {
            subdomain: subdomain || '',  // Fallback to an empty string if subdomain is not found
            newEmail: email
        };

        // Sending the POST request with the body
        const response = await axios.put(url, requestBody);
        localStorage.setItem('user_Email', email);

        if (response.status === 200) {
            console.log("Verification code resent:", response.data);
        } else {
            toast.error("Failed to resend verification code. Please try again.");
        }
        
    } catch (error) {
        console.error("Error resending verification code:", error);

        // Handle Axios errors
        if (axios.isAxiosError(error) && error.response) {
            const { data } = error.response;
            toast.error(data?.message || "An error occurred while resending the code.");
        } else {
            toast.error("An unexpected error occurred.");
        }
    }
};


  


 const handleEditClick = async () => {
    if (!newEmail) {
        toast.error("Please enter a valid email.");
        return;
    }

    try {
        // Update business with the new email
        // await editBusiness(newEmail);

        // After successfully updating the email, resend the verification code
        await resendVerificationCode(newEmail);
        
        // Optionally, you can show a success message after sending the code
        toast.success(t("A verification code has been sent to the new email."));

    } catch (error) {
        console.error("Error updating email or resending code:", error);
        toast.error("An error occurred while updating the email or resending the code.");
    } finally {
    }
};





    return (
        <FormProvider {...methods}>
             {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
              <div className="bg-[#1a202c] h-[85px] w-full ">

                

            <div className="relative  "> {/* Wrapper to position the image */}
                <img
                    src={Logo}
                    alt="Logo"
                    className="absolute top-0 left-0 w-26 h-26" // Adjust size and positioning as needed
                />
                </div>
                                            </div>

              {/* Theme Switcher aligned to top-right */}
<div className="absolute top-4 right-4 flex space-x-4 z-50">
  <div className="rounded-full p-1 text-white hover:text-white focus:outline-none">
    <ThemeSwitcher aria-hidden="false" />
  </div>
  <div className="rounded-full p-1 pt-2 text-white hover:text-white focus:outline-none">
    <LanguageSelector aria-hidden="false" />
  </div>
</div>


                <form onSubmit={methods.handleSubmit(onSubmit)}>
                   {step !== 3 && step !== 4 && step !== 2 && ( 
    <h1 className="text-xl font-bold mb-6 text-center text-bold pt-10">
        {t("createbusiness")}
    </h1>
)}

<div className="col-span-1">
  {step === 1 && (
    <div className="p-5">
      <h2 className="text-lg font-semibold text-gray-800 mb-5 dark:text-gray-300">
        {t("Business Information")}
      </h2>
   <fieldset className="w-full grid grid-cols-1 gap-4 pt-5">
  <div className="flex flex-col md:flex-row">
    {/* Left Section */}
    <div className="w-full md:w-1/2 md:pr-2 space-y-4">
      <FormItem>
        <FormLabel className="block text-sm font-medium text-gray-700 pl-12 sm:pl-16 dark:text-gray-300">
          {t("Business Name")} <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <FormControl>
          <div className="flex justify-center items-center">
            <Input
              {...methods.register("name", { required: t("Name is required") })}
              type="search"
              placeholder={t("putname")}
              className="mt-1 block w-3/4 md:w-5/6 px-3 py-2 border border-gray-300 rounded-md shadow-sm"
            />
          </div>
        </FormControl>
        {methods.formState.errors.name && (
          <FormMessage>{methods.formState.errors.name.message}</FormMessage>
        )}
      </FormItem>

      <FormItem>
        <FormLabel className="block text-sm font-medium text-gray-700 pl-12 sm:pl-16 dark:text-gray-300">
          {t("tin")} <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <FormControl>
          <div className="flex justify-center items-center">
            <Input
              {...methods.register("tin")}
              type="search"
              placeholder={t("puttin")}
              className="mt-1 block w-3/4 md:w-5/6 px-3 py-2 border border-gray-300 rounded-md shadow-sm"
            />
          </div>
        </FormControl>
        {methods.formState.errors.tin && (
          <FormMessage>{methods.formState.errors.tin.message}</FormMessage>
        )}
      </FormItem>

      <FormItem>
        <FormLabel className="block text-sm font-medium text-gray-700 pl-12 sm:pl-16 dark:text-gray-300">
          {t("address")} <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <FormControl>
          <div className="flex justify-center items-center">
            <Input
              {...methods.register("address")}
              type="search"
              placeholder={t("address")}
              className="mt-1 block w-3/4 md:w-5/6 px-3 py-2 border border-gray-300 rounded-md shadow-sm"
            />
          </div>
        </FormControl>
        {methods.formState.errors.address && (
          <FormMessage>{methods.formState.errors.address.message}</FormMessage>
        )}
      </FormItem>

      <FormItem>
        <FormLabel className="block text-sm font-medium text-gray-700 pl-12 sm:pl-16 dark:text-gray-300">
          {t("city")} <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <FormControl>
          <div className="flex justify-center items-center">
            <Input
              {...methods.register("city")}
              type="search"
              placeholder={t("putcity")}
              className="mt-1 block w-3/4 md:w-5/6 px-3 py-2 border border-gray-300 rounded-md shadow-sm"
            />
          </div>
        </FormControl>
        {methods.formState.errors.city && (
          <FormMessage>{methods.formState.errors.city.message}</FormMessage>
        )}
      </FormItem>
    
    </div>

    {/* Right Section */}
    <div className="w-full md:w-1/2 md:pl-2 space-y-4">
     

      <FormItem>
        <FormLabel className="block text-sm font-medium text-gray-700 pl-12 sm:pl-16 dark:text-gray-300">
          {t("phoneNumber")} <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <FormControl>
          <div className="flex justify-center items-center">
            <Input
              {...methods.register("phoneNumber")}
              type="number"
              placeholder={t("putphoneNumber")}
              className="mt-1 block w-3/4 md:w-5/6 px-3 py-2 border border-gray-300 rounded-md shadow-sm"
            />
          </div>
        </FormControl>
        {methods.formState.errors.phoneNumber && (
          <FormMessage>{methods.formState.errors.phoneNumber.message}</FormMessage>
        )}
      </FormItem>

      <FormItem>
        <FormLabel className="block text-sm font-medium text-gray-700 pl-12 sm:pl-16 dark:text-gray-300">
          {t("fieldOfActivity")} <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <FormControl>
          <div className="flex justify-center items-center">
            <Input
              {...methods.register("fieldOfActivity")}
              type="search"
              placeholder={t("fieldOfActivity")}
              className="mt-1 block w-3/4 md:w-5/6 px-3 py-2 border border-gray-300 rounded-md shadow-sm"
            />
          </div>
        </FormControl>
        {methods.formState.errors.fieldOfActivity && (
          <FormMessage>{methods.formState.errors.fieldOfActivity.message}</FormMessage>
        )}
      </FormItem>

      <FormItem>
        <FormLabel className="block text-sm font-medium text-gray-700 pl-16 sm:pl-16 dark:text-gray-300">
          {t("Subdomain")} <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <FormControl>
          <div className="flex justify-center items-center pl-6">
            <Input
              {...methods.register("subdomain", { required: t("Subdomain is required") })}
              type="search"
              placeholder={t("Subdomain")}
              className="mt-1 block w-3/4 md:w-5/6 px-3 py-2 border border-gray-300 rounded-md shadow-sm"
              onChange={(e) => {
                const lowercaseSubdomain = e.target.value
                  .toLowerCase()
                  .replace(/[^a-z0-9-]/g, '');
                e.target.value = lowercaseSubdomain;
              }}
            />
            <span className="text-gray-500">.iva.al</span>
          </div>
        </FormControl>
        {methods.formState.errors.subdomain && (
          <FormMessage>{methods.formState.errors.subdomain.message}</FormMessage>
        )}
      </FormItem>

      <FormItem className="pt-2 sm:pt-6">
        <FormLabel className="text-sm font-medium text-gray-700 pl-12 sm:pl-16 dark:text-gray-300">
          {t("isSeasonal")}
        </FormLabel>
        <FormControl>
          <input
            {...methods.register("isSeasonal")}
            type="checkbox"
            className="w-4 h-4 transform scale-150 ml-4"
          />
        </FormControl>
        {methods.formState.errors.isSeasonal && (
          <FormMessage>{methods.formState.errors.isSeasonal.message}</FormMessage>
        )}
      </FormItem>
    </div>
  </div>
</fieldset>


      <div className="col-span-2 mt-8">
    <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-5">
      {t("Business Configuration")}
    </h2>
    
    {/* Printer Type Field */}
   <FormItem>
  <div className="flex items-center space-x-4 sm:pl-16 pt-2">
    <FormLabel className="text-sm sm:text-lg font-medium text-gray-700 dark:text-gray-300">
      {t("PrinterType")} <span style={{ color: "red" }}>*</span>
    </FormLabel>
    <FormControl>
      <div className="flex space-x-4">
        <Controller
          name="printerType"
          control={methods.control}
          render={({ field }) => (
            <div className="flex space-x-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  value="1"
                  checked={field.value === 1}
                  onChange={() => field.onChange(1)}
                  className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                />
                <span className="ml-1 dark:text-gray-200">{t("A4")}</span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  value="2"
                  checked={field.value === 2}
                  onChange={() => field.onChange(2)}
                  className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                />
                <span className="ml-1 dark:text-gray-200">{t("80MM")}</span>
              </label>
            </div>
          )}
        />
      </div>
    </FormControl>
  </div>
  {methods.formState.errors.printerType && (
    <FormMessage>{methods.formState.errors.printerType.message}</FormMessage>
  )}
</FormItem>


    {/* Base Currency Field */}
   <FormItem>
  <div className="flex items-center space-x-4 sm:pl-16 pt-4">
    <FormLabel className="text-sm sm:text-lg font-medium text-gray-700 dark:text-gray-300">
      {t("BaseCurrency")} <span style={{ color: "red" }}>*</span>
    </FormLabel>
    <FormControl>
      <div className="flex space-x-4">
        <Controller
          name="baseCurrency"
          control={methods.control}
          render={({ field }) => (
            <div className="flex space-x-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  value="1"
                  checked={field.value === 1}
                  onChange={() => field.onChange(1)}
                  className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                />
                <span className="ml-1 dark:text-gray-200">{t("Lekë")}</span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  value="2"
                  checked={field.value === 2}
                  onChange={() => field.onChange(2)}
                  className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                />
                <span className="ml-1 dark:text-gray-200">{t("Euro")}</span>
              </label>
            </div>
          )}
        />
      </div>
    </FormControl>
  </div>
  {methods.formState.errors.baseCurrency && (
    <FormMessage>{methods.formState.errors.baseCurrency.message}</FormMessage>
  )}
</FormItem>


    {/* Allow Negative Inventory Field */}
   <FormItem>
  <div className="flex items-center space-x-4 sm:pl-16 pt-4">
    <FormLabel className="text-sm sm:text-lg font-medium text-gray-700 dark:text-gray-300">
      {t("AllowNegativeInventory")}
    </FormLabel>
    <FormControl>
      <input
        {...methods.register("allowNegativeInventory")}
        type="checkbox"
        className="w-4 h-4 transform scale-150"
      />
    </FormControl>
  </div>
  {methods.formState.errors.allowNegativeInventory && (
    <FormMessage>{methods.formState.errors.allowNegativeInventory.message}</FormMessage>
  )}
</FormItem>
  <FormItem>
  <div className="flex items-center space-x-4 sm:pl-16 pt-4">
    <FormLabel className="text-sm sm:text-lg font-medium text-gray-700 dark:text-gray-300">
      {t("Do you have tables?")}
    </FormLabel>
    <FormControl>
      <input
        {...methods.register("hasTables")}
        type="checkbox"
        className="w-4 h-4 transform scale-150"
      />
    </FormControl>
  </div>
  {methods.formState.errors.hasTables && (
    <FormMessage>{methods.formState.errors.hasTables.message}</FormMessage>
  )}
</FormItem>
<FormItem>
  <div className="flex items-center space-x-4 sm:pl-16 pt-4">
    <FormLabel className="text-sm sm:text-lg font-medium text-gray-700 dark:text-gray-300">
      {t("Do you use vat?")}
    </FormLabel>
    <FormControl>
      <input
        {...methods.register("hasVat")}
        type="checkbox"
        className="w-4 h-4 transform scale-150"
      />
    </FormControl>
  </div>
  {methods.formState.errors.hasTables && (
    <FormMessage>{methods.formState.errors.hasTables.message}</FormMessage>
  )}
</FormItem>
  </div>

    </div>
    
    
  )}
  
</div>
 <div className="flex justify-center pt-5">
  {/* Step 4 - Displaying user details from local storage */}
  {step === 4 && (
    <div className="w-full md:w-1/2 lg:w-1/3">
      <h1 className="text-xl font-bold mb-6 text-center pt-10">
        {t("Congratulations!")}
      </h1>
       <h2 className="text-lg text-center mb-6 font-bold">
        {t("Your registration is complete successfully. Here is the info to login.")}
      </h2>

      <div className="space-y-4">
        {/* User Info Container */}
        <fieldset className="border p-4 rounded-md shadow-md bg-white dark:bg-gray-900">
          <legend className="text-lg font-semibold">{t("User Info")}</legend>
         <FormItem>
  <FormLabel className="block text-sm font-medium text-gray-700 text-center dark:text-gray-300">
    {t("Subdomain")}
  </FormLabel>
  <FormControl>
    <div className="flex justify-center items-center">
      <a 
        href={`http://${localStorage.getItem("subdomain") || ""}.iva.al`} 
        target="_blank" 
        rel="noopener noreferrer"
        className="text-blue-500 underline hover:text-blue-700"
      >
        {localStorage.getItem("subdomain") || t("No subdomain set")}.iva.al
      </a>
    </div>
  </FormControl>
</FormItem>


          <FormItem>
            <FormLabel className="block text-sm font-medium text-gray-700 text-center dark:text-gray-300">
              {t("Username")}
            </FormLabel>
            <FormControl>
              <div className="flex justify-center items-center">
                <span className="text-gray-800 dark:text-gray-300">
                  {localStorage.getItem("username") || t("No username set")}
                </span>
              </div>
            </FormControl>
          </FormItem>
          <FormItem>
            <FormLabel className="block text-sm font-medium text-gray-700 text-center dark:text-gray-300">
              {t("Password")}
            </FormLabel>
            <FormControl>
              <div className="flex justify-center items-center">
                <span className="text-gray-800 dark:text-gray-300">
                  {localStorage.getItem("password") || t("No password set")}
                </span>
              </div>
            </FormControl>
          </FormItem>
        </fieldset>
      </div>
    </div>
  )}
</div>



<div className="col-span-1">
       {step === 2 && ( // Conditionally render the <h1> element when step is 3
                <h1 className="text-xl font-bold mb-6 text-center text-bold pt-10">
                    {t("Register User")}
                </h1>
            )}
    {step === 2 && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-5">
                    
            {/* User Login Info Container */}
            <fieldset className="border p-4 rounded-md shadow-md bg-white dark:bg-gray-900">
                <legend className="text-lg font-semibold">{t("User Login Info")}</legend>
                <FormItem>
                    <FormLabel className="block text-sm font-medium text-gray-700 pl-12 sm:pl-16 dark:text-gray-300">
                        {t("Admin Username")} <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <FormControl>
                        <div className="flex justify-center items-center">
                            <Input
                                {...methods.register("user_Username")}
                                type="search"
                                placeholder={t("Admin Username")}
                                className="mt-1 block w-3/4 md:w-5/6 px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                                onChange={(e) => {
                                    const validSubdomain = e.target.value.replace(/[^a-zA-Z0-9-]/g, '');
                                    e.target.value = validSubdomain;
                                }}   
                            />
                        </div>
                    </FormControl>
                    {methods.formState.errors.user_Username && (
                        <FormMessage>{methods.formState.errors.user_Username.message}</FormMessage>
                    )}
                </FormItem>
                <FormItem>
                    <FormLabel className="block text-sm font-medium text-gray-700 pl-12 sm:pl-16 dark:text-gray-300">
                        {t("Admin Password")} <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <FormControl>
                        <div className="flex justify-center items-center">
                            <Input
                                {...methods.register("user_Password")}
                                type="password"
                                placeholder={t("putpassword")}
                                className="mt-1 block w-3/4 md:w-5/6 px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                            />
                        </div>
                    </FormControl>
                    {methods.formState.errors.user_Password && (
                        <FormMessage>{methods.formState.errors.user_Password.message}</FormMessage>
                    )}
                </FormItem>
            </fieldset>

            {/* User Info Container */}
            <fieldset className="border p-4 rounded-md shadow-md bg-white dark:bg-gray-900">
                <legend className="text-lg font-semibold">{t("User Info")}</legend>
                <FormItem>
                    <FormLabel className="block text-sm font-medium text-gray-700 pl-12 sm:pl-16 dark:text-gray-300">
                        {t("Admin Name")} <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <FormControl>
                        <div className="flex justify-center items-center">
                            <Input
                                {...methods.register("user_Name")}
                                type="search"
                                placeholder={t("Admin Name")}
                                className="mt-1 block w-3/4 md:w-5/6 px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                            />
                        </div>
                    </FormControl>
                    {methods.formState.errors.user_Name && (
                        <FormMessage>{methods.formState.errors.user_Name.message}</FormMessage>
                    )}
                </FormItem>
                <FormItem>
                    <FormLabel className="block text-sm font-medium text-gray-700 pl-12 sm:pl-16 dark:text-gray-300">
                        {t("email")} <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <FormControl>
                        <div className="flex justify-center items-center">
                            <Input
                                {...methods.register("user_Email", {
                                    
                                    pattern: {
                                        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                        message: t("Invalid email address"),
                                    },
                                })}
                                type="email" // Use type="text" for email input
                                placeholder={t("Admin Email")}
                                className="mt-1 block w-3/4 md:w-5/6 px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                                onChange={(e) => {
                                    e.target.value = e.target.value.toLowerCase().replace(/\s+/g, '');
                                }}
                            />
                        </div>
                    </FormControl>
                    {methods.formState.errors.user_Email && (
                        <FormMessage>{methods.formState.errors.user_Email.message}</FormMessage>
                    )}
                </FormItem>
                <FormItem>
                    <FormLabel className="block text-sm font-medium text-gray-700 pl-12 sm:pl-16 dark:text-gray-300">
                        {t("Admin Number")} <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <FormControl>
                        <div className="flex justify-center items-center">
                            <Input
                                {...methods.register("user_PhoneNumber")}
                                type="number"
                                placeholder={t("putphoneNumber")}
                                className="mt-1 block w-3/4 md:w-5/6 px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                            />
                        </div>
                    </FormControl>
                    {methods.formState.errors.user_PhoneNumber && (
                        <FormMessage>{methods.formState.errors.user_PhoneNumber.message}</FormMessage>
                    )}
                </FormItem>
            </fieldset>
        </div>
    )}
</div>


                    <div className="col-span-1">
                         {step === 3 && ( // Conditionally render the <h1> element when step is 3
                <h1 className="text-xl font-bold mb-6 text-center text-bold pt-10">
                    {t("Verify Email")}
                </h1>
            )}
    {step === 3 && (
        <fieldset className="w-full grid grid-cols-1 gap-4 md:grid-cols-2 pt-5">

       
<FormItem>
    <FormLabel className="block text-sm font-medium text-gray-700 pl-12 sm:pl-16 dark:text-gray-300">
        {t("email")} <span style={{ color: "red" }}>*</span>
    </FormLabel>
    <div className="relative flex justify-center items-center">
        <Input
            type="email"
            placeholder="Enter new email"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
            className="mt-1 block w-3/4 md:w-5/6 pr-10 py-2 border border-gray-300 rounded-md shadow-sm" // Add pr-10 for right padding
        />
       
    </div>
</FormItem>
          



{/* Code Field */}
<FormItem>
    <FormLabel className="block text-sm font-medium text-gray-700 pl-12 sm:pl-16 dark:text-gray-300">
        {t("Code")} <span style={{ color: "red" }}>*</span>
    </FormLabel>
    <FormControl>
        <div className="flex justify-center items-center">
            <Input
                {...(step === 3
                    ? methods1.register("code", {
                          required: t("Code is required"),
                          valueAsNumber: true, // Automatically converts input to number
                      })
                    : {})}
                type="number"
                placeholder={t("code")}
                className="mt-1 block w-3/4 md:w-5/6 px-3 py-2 border border-gray-300 rounded-md shadow-sm"
            />
        </div>
    </FormControl>
    {methods1.formState.errors.code && (
        <FormMessage>{methods1.formState.errors.code.message}</FormMessage>
    )}
</FormItem>
 
     





        </fieldset>
        
    )}
    
</div>



                   <div className="flex justify-center mt-4 space-x-4">
{step > 1  && step !== 3 && step !== 4 && (
    <Button type="button" onClick={handlePrevious}>
        {t("Previous")}
    </Button>
)}



    {step < 2 && (
        <Button type="button" onClick={handleNext}>
            {t("Next")}
        </Button>
    )}
    {step === 2 && (
        <Button type="submit" onClick={methods.handleSubmit(onSubmit)} className="ml-auto">
            {t("savelbtn")}
        </Button>
    )}
{step === 3 && (
    <>
        <div className="flex flex-col md:flex-row gap-3">
            <Button
                type="button"
                onClick={() => {
                    const user_Email = localStorage.getItem("user_Email") || ""; // Get email from localStorage
                    const code = methods1.getValues("code"); // Get the code value from the form
                    const subdomain = localStorage.getItem("subdomain") || ""; // Get email from localStorage

                    handleVerification(user_Email, code,subdomain); // Call handleVerification with email and code
                }}
                className="w-full md:w-auto" // Full width on mobile, auto width on larger screens
            >
                {t("Verify")}
            </Button>

            <Button
                type="button"
                onClick={handleEditClick}
                className="w-full md:w-auto ml-auto" // Full width on mobile, auto width on larger screens
            >
                {t("Resend Code")}
            </Button>
           
        </div>
    </>
)}




    
    </div>
     

                </form>




                <ToastContainer />
        </FormProvider>

    );
};

export default BusinessRegisterForm;

