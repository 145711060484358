class CustomerDto {
  name: string;
  phoneNumber?: string | null;
  email?: string | null;
  dateOfBirth: Date;
  username: string | null;
  // password: string | null;
  customerCategoryId: number | null;
  loyaltyCard?: string | null;
  discount: number;
  loyaltyPoints: number | null;
  idNumber: string | null;
  idType?: number | null;
  address: string | null;
  city: string | null;
  country: string | null;
  constructor(customer: {
    name?: string;
    phoneNumber?: string | null;
    email?: string | null;
    dateOfBirth?: string;
    username?: string | null;
    // password?: string | null;
    customerCategoryId?: number | null;
    loyaltyCard?: string | null;
    discount?: number;
    loyaltyPoints?: number | null;
    idNumber?: string | null;
    idType?: number | null;
    address?: string | null;
    city?: string | null;
    country?: string | null;
  }) {
    this.name = customer.name || "";
    this.phoneNumber = customer.phoneNumber ?? null;
    this.email = customer.email ?? null;
    this.dateOfBirth = customer.dateOfBirth
      ? new Date(customer.dateOfBirth)
      : new Date();
    this.username = customer.username ?? null;
    // this.password = customer.password || null;
    this.customerCategoryId = customer.customerCategoryId ?? null;
    this.loyaltyCard = customer.loyaltyCard ?? null;
    this.discount = customer.discount || 0;
    this.loyaltyPoints = customer.loyaltyPoints ?? null;
    this.idNumber = customer.idNumber ?? null;
    this.idType = customer.idType ?? 0;
    this.address = customer.address ?? null;
    this.city = customer.city ?? null;
    this.country = customer.country ?? null;
  }
}
export default CustomerDto;
