import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import { useAuth } from "@/provider/authProvider";
import { axiosInstance } from "@/lib/utils";


// Define TypeScript interface for the configuration object
interface PrinterConfigs {
  displayBankAccounts: boolean;
  invoiceHeader: string;
  invoiceFooter: string; // Added second currency ID
  displayExchangeRates: boolean;
  displayEmployeeName: boolean;
  displayCustomerInfo: boolean;
}

// Define TypeScript interface for a Currency

const PrinterConfig: React.FC = () => {
  const { t } = useTranslation();
  const { isSuperUser } = useAuth();

  // State to hold configuration settings
  const [displayEmployeeName, setdisplayEmployeeName] =
    useState<boolean>(false);
  const [displayExchangeRates, setdisplayExchangeRates] = useState<boolean>(false);

  const [displayBankAccounts, setdisplayBankAccounts] = useState<boolean>(false);
   const [displayCustomerInfo, setdisplayCustomerInfo] = useState<boolean>(false);

const [invoiceHeader, setinvoiceHeader] = useState<string>("");
const [invoiceFooter, setinvoiceFooter] = useState<string>("");

 

   useEffect(() => {
 

    // Load saved configurations from localStorage
    const printerConfigs: PrinterConfigs = JSON.parse(
      localStorage.getItem("printerConfig") || "{}"
    );

    setdisplayBankAccounts(printerConfigs.displayBankAccounts ?? false);
    setdisplayExchangeRates(printerConfigs.displayExchangeRates ?? false);
    setdisplayEmployeeName(printerConfigs.displayEmployeeName ?? false);
    setinvoiceHeader(printerConfigs.invoiceHeader);
     setinvoiceFooter(printerConfigs.invoiceFooter);
        setdisplayCustomerInfo(printerConfigs.displayCustomerInfo ?? false);


   
  }, [t]);
 

  // Handler for saving settings using API (PUT request) and localStorage
  const handleSave = async () => {
    const configs: PrinterConfigs = {
        displayBankAccounts,
        displayExchangeRates,
        displayEmployeeName,
        invoiceHeader,
        invoiceFooter,
        displayCustomerInfo
    };

    try {
      // Send PUT request to update the configuration via API
      await axiosInstance.put(
        `${API_URLS.BASE_URL}${API_URLS.PrinterConfigs}`,
        configs
      ); // Replace with your actual API endpoint

      // Save the updated configuration to localStorage
      localStorage.setItem("printerConfig", JSON.stringify(configs));

      // toast.success(t("Printer Configuration saved successfully!"));
    } catch (error) {
      console.error("Error saving settings:", error);
      toast.error(t("Failed to save settings"));
    }
    window.location.reload();
  };

  return (
    <div className="company-config-container p-6 rounded shadow-md">
      <h2 className="text-xl font-semibold mb-4">{t("Printer Config")}</h2>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div className="mb-4">
          <label className="block font-medium mb-1">{t("Display Bank Accounts")}</label>
          <select
            value={displayBankAccounts ? "yes" : "no"}
            onChange={(e) => setdisplayBankAccounts(e.target.value === "yes")}
            className="block p-2 border rounded w-full bg-white text-black dark:bg-gray-800 dark:text-white dark:border-gray-600"
          >
            <option value="yes">{t("Yes")}</option>
            <option value="no">{t("No")}</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block font-medium mb-1">
            {t("Display Employee Name")}
          </label>
          <select
            value={displayEmployeeName ? "yes" : "no"}
            onChange={(e) =>
              setdisplayEmployeeName(e.target.value === "yes")
            }
            className="block p-2 border rounded w-full bg-white text-black dark:bg-gray-800 dark:text-white dark:border-gray-600"
          >
            <option value="yes">{t("Yes")}</option>
            <option value="no">{t("No")}</option>
          </select>
        </div>

      <div className="mb-4">
  <label className="block font-medium mb-1">
    {t("invoiceHeader")}:
  </label>
  <textarea
    value={invoiceHeader}
    onChange={(e) => setinvoiceHeader(e.target.value)}
    placeholder={t("Enter Invoice Header")}
    maxLength={500} // Limits the input to 500 characters

    rows={5} // Ensure rows is a number, not a string
    className="block p-2 border rounded w-full bg-white text-black dark:bg-gray-800 dark:text-white dark:border-gray-600 resize-none"
  />
</div>
   <div className="mb-4">
  <label className="block font-medium mb-1">
    {t("invoiceFooter")}:
  </label>
  <textarea
    value={invoiceFooter}
    onChange={(e) => setinvoiceFooter(e.target.value)}
    placeholder={t("Enter Invoice Footer")}
    maxLength={500} // Limits the input to 500 characters

    rows={5} // Ensure rows is a number, not a string
    className="block p-2 border rounded w-full bg-white text-black dark:bg-gray-800 dark:text-white dark:border-gray-600 resize-none"
  />
</div>

    

        <div className="mb-4">
          <label className="block font-medium mb-1">
            {t("Display Exchange Rate")}
          </label>
          <select
            value={displayExchangeRates ? "yes" : "no"}
            onChange={(e) => setdisplayExchangeRates(e.target.value === "yes")}
            className="block p-2 border rounded w-full bg-white text-black dark:bg-gray-800 dark:text-white dark:border-gray-600"
          >
            <option value="yes">{t("Yes")}</option>
            <option value="no">{t("No")}</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block font-medium mb-1">
            {t("Display Customer Info")}
          </label>
          <select
            value={displayCustomerInfo ? "yes" : "no"}
            onChange={(e) =>
              setdisplayCustomerInfo(e.target.value === "yes")
            }
            className="block p-2 border rounded w-full bg-white text-black dark:bg-gray-800 dark:text-white dark:border-gray-600"
          >
            <option value="yes">{t("Yes")}</option>
            <option value="no">{t("No")}</option>
          </select>
        </div>
      </div>

      {isSuperUser && (
        <div className="mt-6 flex space-x-2">
          <Button onClick={handleSave}>{t("savelbtn")}</Button>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default PrinterConfig;
