import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { axiosInstance } from "@/lib/utils";
import { Input } from "@/components/ui/input";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import "@/css/salesorder2.css";
import "@/css/MobileScreen.css";

import API_URLS from "@/config";
import { CircleCheckBig, CircleDollarSign, Coins, Grid2X2 } from "lucide-react";
// import { ShoppingCart } from "lucide-react"; // Example icons for customer and payment method
import { useNavigate } from "react-router-dom";
import CreateSalesOrderDto2 from "@/models/CreateSalesOrderDto2";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PosBAR from "@/componentsPos/PrintBar";
import { useReactToPrint } from "react-to-print";
import LocalPrint from "@/componentsPos/localprint";
import { useAuth } from "@/provider/authProvider";
import DataFetcher from "@/provider/DataFetcher";
import "@/css/LoadingCreate.css";
import { Warehouse } from "@/types/WarehouseType";
import { CashAccount } from "@/types/CashAccountType";
import { Paymentmethods } from "@/types/PaymentMethdosType";
import CloseTabelDto from "@/models/CloseTableDto";
import { VATType } from "@/types/VatType";
import { Shop } from "@/types/ShopType";
import { Employee } from "@/types/EmployeeType";

type Product = {
  id: string;
  barcode: string;
  price: number;
  uoMId: number;
  vatRateId: number;
  categoryId: string;
  vatRate: number;
  isRawMaterial: boolean;
  productId: number;
  name: string;
  quantity: number;
  unitPrice: number;
  total: number; // This is
};
interface TableOrderLine {
  productName: string;
  productId: number;
  tableOrderId: number;
  uoMId: number;
  vatRateId: number;
  baseUoMId: number;
  // serviceAreaId: number | null;
  discountId: number | null;
  discountAmount: number;
  discountPercentage: number;
  lineVatRateId: number | null;
  lineVatRate: number | null;
  lineVatAmount: number | null;
  quantity: number;
  originalUnitPrice: number | null;
  unitPrice: number;
  lineTotal: number;
  lineNote: string;
  id: number;
  createDateUtc: string;
  createdBy: number;
  updatedBy: number | null;
  updateDateUtc: string;
  isDeleted: boolean;
  isActive: boolean;
  tenantId: number;
  tenant: any | null;
}

type ProductCategory = {
  id?: string;
  name: string;
};
type Order = {
  tableOrderLines: TableOrderLine[]; // Use the defined type here
  vatAmount: number;
  tableId: number;
  totalAmount: number;
  salesOrderId: number;
};

type VatRate = {
  id: number;
  rate: number;
};

type Customer = {
  id: string;
  name: string;
};

type Table = {
  id: number;
  name: string;
  totalAmount: number;
  // other properties...
  products?: Product[]; // Add this line to include products
};
interface BusinessConfigs {
  allowNegativeInventory: boolean;
  baseCurrencyId: number;
  secondCurrencyId: number; // Added second currency ID
  printerType: number;
}

// Define TypeScript interface for a Currency
interface Currency {
  id: number;
  name: string;
  symbol: string;
}

const SalesOrder2: React.FC = () => {
  const PRODUCT_STORAGE_KEY = "productData";
  const CATEGORY_STORAGE_KEY = "categoryData";
  const VAT_STORAGE_KEY = "vatData";
  const EMPLOYEE_STORAGE_KEY = "employeeData";
  const SHOP_STORAGE_KEY = "shopData";
  const CUSTOMER_STORAGE_KEY = "customerData";
  const TABLE_STORAGE_KEY = "tableData";
  const Currency_STORAGE_KEY = "currencyData";
  const WAREHOUSE_STORAGE_KEY = "warehouseData";
  const PAYMENT_METHOD_STORAGE_KEY = "paymentMethodData";
  const [productsToPrint, setProductsToPrint] = useState<Product[]>([]);

  // const CashAccountOwner_STORAGE_KEY = "cashaccountownerData";
  const CashAccount_STORAGE_KEY = "cashaccountData";
  const [productList, setProductList] = useState<Product[]>([]);
  const [, setProductList1] = useState<Product[]>([]);

  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [orderProducts, setOrderProducts] = useState<Product[]>([]);

  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [totalAmountExVat, setTotalAmountExVat] = useState<number>(0);

  // const [employeeID, setEmployeeID] = useState<number | null>(null);
  const { employeeId, isSuperUser } = useAuth();
  const [baseCurrencyName, setBaseCurrencyName] = useState("Lekë"); // Default currency
  const [baseCurrencySymbol, setBaseCurrencySymbol] = useState("L"); // Default currency symbol
  const [, setCurrencies] = useState<Currency[]>([]); // Holds list of currencies
  const [cashAccounts, setCashAccounts] = useState<CashAccount[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<Paymentmethods[]>([]);
  const [vatList, setVatList] = useState<VATType[]>([]);
  const [vatAmount, setVatAmount] = useState<number>(0);

  const [, setCashAccountBalance] = useState<number>(0);
  const [, setTotalQuantity] = useState(0);

  const [totalAmountIncVat, setTotalAmountIncVat] = useState<number>(0);
  const [totalAmountIncVat1, setTotalAmountIncVat1] = useState<number>(0);
  const [selectedTcrCode, setSelectedTcrCode] = useState<string | null>(null);
  const [productsMap, setProductsMap] = useState<{ [key: string]: string }>({});

  const [showCategories] = useState<boolean>(true);
  const [, setVatRates] = useState<VatRate[]>([]);
  const [warehouse, setWarehouse] = useState<number>();
  const [customers, setCustomers] = useState<Customer[]>([]); // State for customers
  const { t } = useTranslation();
  const navigate = useNavigate(); // Initialize useNavigate
  const [tables, setTables] = useState<Table[]>([]);
  const [loading, setLoading] = useState(false); // Initialize loading state
  const [orderNo, setOrderNo] = useState();
  const [fiscInvOrdNum, setFiscInvOrdNum] = useState();
  const [buCode, setBuCode] = useState();
  const [opCode, setOpCode] = useState();
  const [tcrCode, setTcrCode] = useState();
  const [iic, setIic] = useState();
  const [fic, setFic] = useState();
  const [, setemp] = useState<Employee[]>([]);
  const [, setshop] = useState<Shop[]>([]);
  const [selectedTable, setSelectedTable] = useState<string>("");

  const invoiceRef = useRef<HTMLDivElement>(null);
  const invoiceRef1 = useRef<HTMLDivElement>(null);

  const storedCertificate = JSON.parse(
    localStorage.getItem("certificate") || "{}"
  );
  const expiryDate = new Date(storedCertificate?.expiryDate);
  const today = new Date();

  useEffect(() => {
    const fetchVatRatesAndProducts = async () => {
      // First, fetch VAT rates
      const vatRatesResponse = await DataFetcher.getData<VatRate[]>(
        `${API_URLS.BASE_URL}${API_URLS.VAT}`,
        VAT_STORAGE_KEY,
      );
      if (vatRatesResponse) {
        setVatRates(vatRatesResponse);

        // After VAT rates are fetched, fetch products
        const productsResponse = await DataFetcher.getData<Product[]>(
          `${API_URLS.BASE_URL}${API_URLS.PRODUCT}`,
          PRODUCT_STORAGE_KEY,
          true
        );
        if (productsResponse) {
          const filteredProducts = productsResponse.filter(
            (product) => !product.isRawMaterial
          );
          setProductList1(productsResponse)
          if (filteredProducts) {
            setProductList(
              filteredProducts.map((product) => ({
                ...product,
                quantity: 0,
                vatRate:
                  vatRatesResponse.find((rate) => rate.id === product.vatRateId)
                    ?.rate || 0,
              }))
            );
            console.log("sttivi",filteredProducts)

            //   //  // Create a map of productId to productName for easy lookups
            const productsById: { [key: string]: string } = {};
            filteredProducts.forEach((product) => {
              productsById[product.id] = product.name;
            });
            setProductsMap(productsById);
          }
        }
      }
    };

    const fetchCategories = async () => {
      const response = await DataFetcher.getData<ProductCategory[]>(
        `${API_URLS.BASE_URL}${API_URLS.PRODUCT_CATEGORY}`,
        CATEGORY_STORAGE_KEY
      );
      const noCategory = {} as ProductCategory;
      noCategory.id = undefined;
      noCategory.name = t("Pa Kateogori");

      response?.push(noCategory);
      if (response) {
        setCategories(response);
      }
    };

    const fetchVatRates = async () => {
      const response = await DataFetcher.getData<VATType[]>(
        `${API_URLS.BASE_URL}${API_URLS.VAT}`,
        VAT_STORAGE_KEY
      );
      if (response) {
        setVatList(response);
      }
    };

    const fetchCustomers = async () => {
      const response = await DataFetcher.getData<Customer[]>(
        `${API_URLS.BASE_URL}${API_URLS.CUSTOMER}`,
        CUSTOMER_STORAGE_KEY
      );
      if (response) {
        setCustomers(response);
      }
    };
    const fetchEmployeesFromServer = async () => {
      const response = await DataFetcher.getData<Employee[]>(
        `${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}`,
        EMPLOYEE_STORAGE_KEY
      );
      if (response) {
        setemp(response);
      }
    };
    const fetchShopServer = async () => {
      const response = await DataFetcher.getData<Shop[]>(
        `${API_URLS.BASE_URL}${API_URLS.SHOP}`,
        SHOP_STORAGE_KEY
      );
      if (response) {
        setshop(response);
      }
    };

    const formatDate = (date: Date): string => {
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
      const day = String(date.getDate()).padStart(2, "0");
      const year = date.getFullYear();
      return `${month}-${day}-${year}`;
    };

    const fetchTablesAndOrders = async () => {
      try {
        // Retrieve selectedTable from localStorage
        const savedSelectedTable =
          localStorage.getItem("selectedTableId") || "";

        // If selectedTable is retrieved from localStorage, update the state

        // Fetch data and filter orders as you've done
        const today = new Date();
        const firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        );
        const fromDate = formatDate(firstDayOfMonth);
        const toDate = formatDate(today);

        const tables = await DataFetcher.getData<Table[]>(
          `${API_URLS.BASE_URL}${API_URLS.Tables}`,
          TABLE_STORAGE_KEY,
          true
        );
        if (tables) {
          setTables(tables);
        }

        const ordersResponse = await axiosInstance.get(
          `${API_URLS.BASE_URL}${API_URLS.TabelOrder}?pageNumber=1&take=10&fromDate=${fromDate}&toDate=${toDate}`
        );
         const productsResponse = await DataFetcher.getData<Product[]>(
          `${API_URLS.BASE_URL}${API_URLS.PRODUCT}`,
          PRODUCT_STORAGE_KEY,
          true
        );

        const orders: Order[] = Array.isArray(ordersResponse.data.tableOrders)
          ? ordersResponse.data.tableOrders
          : [];

        const filteredOrders = orders.filter(
          (order) => order.salesOrderId === null
        );

        const tableTotals = filteredOrders.reduce((acc, order) => {
          const totalAmount = order.totalAmount; // Total including VAT
          const vatAmount = order.vatAmount; // VAT amount provided
          const subtotal = totalAmount - vatAmount; // Calculate subtotal (total without VAT)

          // Accumulate totals for each table
          if (acc[order.tableId]) {
            // If table already exists in the accumulator, add to totals
            acc[order.tableId].total += totalAmount;
            acc[order.tableId].subtotal += subtotal;
            acc[order.tableId].vatAmount += vatAmount;
          } else {
            // If table doesn't exist in the accumulator, create it
            acc[order.tableId] = {
              total: totalAmount,
              subtotal: subtotal,
              vatAmount: vatAmount,
              products: [], // Initialize the products array
            };
          }

         
        const productList = productsResponse || []; 
order.tableOrderLines.forEach((line: TableOrderLine) => {

  const {
    productId,
    productName = "Unknown Product",
    quantity,
    unitPrice,
    lineTotal,
    uoMId,
    vatRateId,
  } = line;

  // Fetch the table accumulator for the current order
  const table = acc[order.tableId];

  // Find the product details from the fetched product list
  const productDetails = productList.find(
    (product) => Number(product.id) === productId
  );
  console.log("Product Details:", productDetails);
  console.log("Product List:", productList);

  // Use the `vatRateId` from the product details if it's not present in the line
  const resolvedVatRateId = vatRateId ?? productDetails?.vatRateId;
  console.log("Resolved VAT Rate ID:", resolvedVatRateId);

  // Check if the product already exists in the table's product list
  const existingProduct = table.products.find(
    (product) => product.productId === productId
  );

  if (existingProduct) {
    // If the product exists, update its quantity and total
    existingProduct.quantity += quantity;
    existingProduct.total += lineTotal;
  } else {
    // If the product doesn't exist, add a new entry
    table.products.push({
      productId,
      name: productName,
      quantity,
      unitPrice,
      total: lineTotal,
      uoMId,
      vatRateId: resolvedVatRateId,
    });
  }
});



          return acc;
        }, {} as { [tableId: number]: { total: number; subtotal: number; vatAmount: number; products: any[] } });

        // Log tableTotals to debug its structure
        console.log("Table Totals:", tableTotals);

        // Log selectedTable to check if it's set correctly

        // Check if selectedTable is valid and retrieve products
        const productsToPrint = savedSelectedTable
          ? tableTotals[parseInt(savedSelectedTable, 10)]?.products || []
          : [];
          

        // Log the products that will be printed
        console.log("Products to print:", productsToPrint);
        console.log("tableTotals:", tableTotals);
        // Calculate totals for selected table
        const calculateTotalAmount = () => {
          let totalExVat1 = 0; // Subtotal excluding VAT
          let vat = 0; // VAT amount
          let totalIncVat = 0; // Total including VAT
          let subtotal = 0; // Subtotal

          // Loop through the products to print and calculate the totals

          // Log accumulated totals after the loop

          // Include the overall table totals into the final amounts
          const selectedTableTotal =
            tableTotals[parseInt(savedSelectedTable, 10)] || {};
          console.log("Selected Table Total:", selectedTableTotal); // Log selected table total

          // Add table-level totals (if any) into the final calculation
          totalExVat1 += selectedTableTotal.subtotal || 0;
          vat += selectedTableTotal.vatAmount || 0;
          subtotal += selectedTableTotal.subtotal || 0;
          totalIncVat += selectedTableTotal.total || 0;

          // Log the final total values

          // Update state variables with the final calculated totals
          setTotalAmountExVat(subtotal); // Set the subtotal (excluding VAT)
          setVatAmount(vat); // Set the VAT amount
          setTotalAmountIncVat1(totalIncVat); // Set the total including VAT
        };

        // Call the calculation and set the products to print
        setProductsToPrint(productsToPrint);
        calculateTotalAmount();

        // Update tables as previously
        const updatedTables =
          tables?.map((table) => ({
            ...table,
            totalAmount: tableTotals[table.id]?.total || 0,
            products: tableTotals[table.id]?.products || [],
          })) || [];
        setTables(updatedTables);
      } catch (error) {
        console.error("Error fetching tables and orders:", error);
        toast.error("Error fetching tables and orders");
      }
    };

    const storedTableId = localStorage.getItem("selectedTableId");
    if (storedTableId) {
      setSelectedTable(storedTableId);
    }

    const fetchWarehouse = async () => {
      const response = await DataFetcher.getData<Warehouse[]>(
        `${API_URLS.BASE_URL}${API_URLS.WAREHOUSE}`,
        WAREHOUSE_STORAGE_KEY,
        true
      );
      if (response && response.length > 0) {
        const firstWarehouseId = response[0].id;
        console.log(firstWarehouseId);
        setWarehouse(parseInt(firstWarehouseId));
      }
    };
    const fetchPaymentMethods = async () => {
      const response = await DataFetcher.getData<Paymentmethods[]>(
        `${API_URLS.BASE_URL}${API_URLS.PaymentMethod}`,
        PAYMENT_METHOD_STORAGE_KEY
      );
      if (response) {
        setPaymentMethods(response);
      }
    };

    // Call the fetching functions
    fetchVatRatesAndProducts();
        fetchTablesAndOrders();
    fetchCategories();
    fetchPaymentMethods();
    fetchVatRates();
    fetchCustomers();
    fetchWarehouse();
    fetchEmployeesFromServer();
    fetchShopServer();
  }, []);
  const truncateString = (str: string, num: number): string => {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    }
    return str;
  };

  const [containerHeight, setContainerHeight] = useState("150vh");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= 900 && width <= 1000) {
        setContainerHeight("1000vh"); // Set height to 300vh for width between 900px and 1000px
      } else {
        setContainerHeight("150vh"); // Default height
      }
    };

    // Set height on initial load
    handleResize();

    // Add event listener to handle window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (employeeId !== null) {
      const parsedEmployeeId = parseInt(employeeId, 10);
      if (!isNaN(parsedEmployeeId)) {
        const fetchCashAccounts = async () => {
          try {
            const response = await DataFetcher.getData<CashAccount[] | null>(
              `${API_URLS.BASE_URL}${API_URLS.CashAccount}`,
              CashAccount_STORAGE_KEY,
              true
            );

            if (response && Array.isArray(response)) {
              const employeeCashAccounts = isSuperUser
                ? response
                : response.filter((account) =>
                    account.owners.some(
                      (owner) => owner.id === parsedEmployeeId
                    )
                  );

              setCashAccounts(employeeCashAccounts);

              if (employeeCashAccounts.length > 0) {
                const firstCashAccount = employeeCashAccounts[0];
                setSelectedTcrCode(firstCashAccount.tcrCode || null);
              }
            }
          } catch (error) {
            toast.error("Failed to fetch cash accounts");
            console.error(error);
          }
        };

        fetchCashAccounts();
      }
    }
  }, [employeeId]);

  useEffect(() => {
    // Fetch available currencies using DataFetcher with caching
    const fetchCurrencyData = async () => {
      try {
        const response = await DataFetcher.getData<Currency[]>(
          `${API_URLS.BASE_URL}${API_URLS.Currency}`, // API endpoint
          Currency_STORAGE_KEY // LocalStorage key
        );
        setCurrencies(response || []); // Set fetched currencies, default to an empty array if none

        // Find and set base currency name and symbol
        const businessConfigs: BusinessConfigs = JSON.parse(
          localStorage.getItem("businessConfigs") || "{}"
        );
        const baseCurrencyId = businessConfigs.baseCurrencyId ?? 1; // Default to 1 if not set
        const baseCurrency = response?.find(
          (currency) => currency.id === baseCurrencyId
        );

        setBaseCurrencyName(baseCurrency?.name || "Leke"); // Set base currency name, default to "Leke"
        setBaseCurrencySymbol(baseCurrency?.symbol || "L"); // Set base currency symbol, default to "L"
      } catch (error) {
        console.error("Error fetching currencies:", error);
        toast.error(t("Failed to load currencies"));
      }
    };

    fetchCurrencyData();
  }, [t]);

  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
  });

  const handlePrint1 = useReactToPrint({
    content: () => invoiceRef1.current,
  });

  useEffect(() => {
    if (!productList || productList.length === 0) {
      setFilteredProducts([]);
      return;
    }

    if (searchQuery === "") {
      setFilteredProducts([]);
    } else {
      const results = productList.filter(
        (product) =>
          product.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          product.barcode?.includes(searchQuery) // Optional chaining here
      );
      setFilteredProducts(results);
    }
  }, [searchQuery, productList]);

  useEffect(() => {
    const fetchTables = async () => {
      try {
        const response = await DataFetcher.getData<Table[]>(
          `${API_URLS.BASE_URL}${API_URLS.Tables}`,
          TABLE_STORAGE_KEY
        );
        if (Array.isArray(response)) {
          setTables(response);
        } else {
          console.error("Expected an array but got:", response);
          setTables([]);
        }
      } catch (error) {
        console.error("Error fetching tables:", error);
        setTables([]); // Handle error by setting to an empty array
      }
    };
    fetchTables();
  }, []);
  const hasVat =
    JSON.parse(localStorage.getItem("businessConfigs") || "{}").hasVat ?? false;

  useEffect(() => {
    calculateTotalAmount();
  }, [orderProducts]);

  const calculateTotalAmount = () => {
    let totalIncVat = 0; // Total amount before VAT
    let vat = 0; // Total VAT amount
    let subtotal = 0; // Subtotal of products (adjusted for VAT)

    orderProducts.forEach((product) => {
      const priceIncVat = product.price * product.quantity; // Total price before VAT for this product
      const vatAmount = hasVat
        ? priceIncVat - priceIncVat / (1 + product.vatRate / 100)
        : 0; // Calculate VAT or set to 0
      const subtotalProduct = priceIncVat - vatAmount; // Calculate subtotal for this product

      totalIncVat += priceIncVat; // Add to total before VAT
      vat += vatAmount; // Accumulate VAT
      subtotal += subtotalProduct; // Accumulate subtotal
    });

    // Update state or variables with the calculated values
    setTotalAmountIncVat(totalIncVat);
    setVatAmount(vat);
    setTotalAmountExVat(subtotal);
  };

  const calculateVATPerType = (): Record<number, number> => {
    const vatSums: Record<number, number> = {};
    orderProducts.forEach((product) => {
      const vatRateId = product.vatRateId;
      const vatRate = vatList.find((v) => v.id === vatRateId)?.rate || 0;
      const price = product.price * product.quantity;
      const vatAmount = hasVat ? price - price / (1 + vatRate / 100) : 0;
      vatSums[vatRateId] = (vatSums[vatRateId] || 0) + vatAmount;
    });

    return vatSums;
  };
    const calculateVATPerType1 = (): Record<number, number> => {
    const vatSums: Record<number, number> = {};
    productsToPrint.forEach((product) => {
      const vatRateId = product.vatRateId;
      const vatRate = vatList.find((v) => v.id === vatRateId)?.rate || 0;
      const price = product.unitPrice * product.quantity;
      const vatAmount = hasVat ? price - price / (1 + vatRate / 100) : 0;
      vatSums[vatRateId] = (vatSums[vatRateId] || 0) + vatAmount;
      console.log("VAT Calculation Output:", calculateVATPerType());

    });

    return vatSums;
  };

  const getVATRate = (vatRateId: number) => {
    const vatRate = vatList.find((v) => v.id === vatRateId);
     

    return vatRate ? vatRate.rate : 0;
  };
  const getVATRateName = (vatRateId: number) => {
    const vatRate = vatList.find((v) => v.id === vatRateId);
    return vatRate ? vatRate.name : "";
  };

  const handleCategorySelect = (categoryId: string) => {
    if (selectedCategory === categoryId) {
      setSelectedCategory("");
      setFilteredProducts([]);
    } else {
      setSelectedCategory(categoryId);
      const filteredByCategory = productList.filter(
        (product) =>
          product.categoryId === (categoryId !== undefined ? categoryId : null)
      );
      setFilteredProducts(filteredByCategory);
    }
  };
  useEffect(() => {
    if (searchQuery === "") {
      if (selectedCategory) {
        const filteredByCategory = productList.filter(
          (product) => product.categoryId === selectedCategory
        );
        setFilteredProducts(filteredByCategory);
      }
    }
  }, [searchQuery, selectedCategory, productList]);

  const addToOrder = (productToAdd: Product) => {
    // Retrieve the current list of products from local storage
    // const storedOrder = localStorage.getItem(`table-${selectedTable}`);
    // let storedProducts: Product[] = storedOrder ? JSON.parse(storedOrder) : [];

    // // Check if the product already exists in the stored list
    // const existingStoredProductIndex = storedProducts.findIndex(product => product.id === productToAdd.id);

    // if (existingStoredProductIndex !== -1) {
    //   // Product already exists in the stored order, update the quantity
    //   storedProducts[existingStoredProductIndex].quantity += 1;
    // } else {
    //   // Product does not exist in the stored order, add it with quantity 1
    //   const productWithQuantity = { ...productToAdd, quantity: 1 };
    //   storedProducts.push(productWithQuantity);
    // }

    // Save the updated stored list back to local storage
    // localStorage.setItem(`table-${selectedTable}`, JSON.stringify(storedProducts));

    // Update the state with the new product list
    const existingStateProductIndex = orderProducts.findIndex(
      (product) => product.id === productToAdd.id
    );

    let updatedOrderProducts;
    if (existingStateProductIndex !== -1) {
      // Product already exists in the state, update the quantity
      updatedOrderProducts = orderProducts.map((product) =>
        product.id === productToAdd.id
          ? { ...product, quantity: product.quantity + 1 }
          : product
      );
    } else {
      // Product does not exist in the state, add it with quantity 1
      const productWithQuantity = { ...productToAdd, quantity: 1 };
      updatedOrderProducts = [...orderProducts, productWithQuantity];
    }

    setOrderProducts(updatedOrderProducts);

    // Calculate and update the total quantity of products
    const totalQuantity = updatedOrderProducts.reduce(
      (total, product) => total + product.quantity,
      0
    );
    setTotalQuantity(totalQuantity);
  };

  const handleRowClick = (product: Product) => {
    addToOrder(product);
  };
  //  useEffect(() => {
  //   if (searchQuery === '') {
  //     setSelectedCategory(null);

  //   }
  // }, [searchQuery]);

  //  const storedTableId = localStorage.getItem("selectedTableId");
  //     if (storedTableId) {
  //       setSelectedTable(storedTableId);
  //     }
  const removeFromOrder = (productId: string) => {
    setOrderProducts((prevProducts) =>
      prevProducts
        .map((product) =>
          product.id === productId
            ? { ...product, quantity: product.quantity - 1 }
            : product
        )
        .filter((product) => product.quantity > 0)
    );
  };

  // useEffect to load the total amount including VAT from local storage when the component mounts or selectedTable changes
  //mos te duhet per nje moment te 2
  // useEffect(() => {
  //     const storedTotalAmount = localStorage.getItem(`totalAmountIncVat_${selectedTable}`);
  //     if (storedTotalAmount) {
  //         setTotalAmountIncVat(parseFloat(storedTotalAmount));
  //     }
  // }, [selectedTable]);

  // const clearTotalAmountLocalStorage2 = () => {
  //   if (selectedTable) {
  //     // toast.success(t("Table is closed"));

  //     handleCheckout1();
  //   } else {
  //     toast.error(t("Total amount is not paid"));
  //   }
  // };

  const editcashbalance = () => {
    navigate(t("/balance"));
  };

  const handleTableSelect = (tableId: string) => {
    console.log("Table selected:", tableId); // Log to check the selected table
    setSelectedTable(tableId); // Set the selected table
    localStorage.setItem("selectedTableId", tableId); // Store it in localStorage
  };
  const selectedTableName =
    tables.find((table) => table.id.toString() === selectedTable)?.name ||
    "Unknown Table";

  const findOpCodeByEmployeeId = (employeeId: number): string | null => {
    const employeeData = JSON.parse(
      localStorage.getItem("employeeData") || "{}"
    );
    const employees: Employee[] = Array.isArray(employeeData.data)
      ? employeeData.data
      : [];

    const employee = employees.find((e) => e.id === employeeId);
    return employee?.opCode || null;
  };
  const handleCheckout = async () => {
    setLoading(true); // Show loading spinner

    if (expiryDate < today) {
      navigate(t("/companycertificate"), {
        state: {
          toastMessage: t(
            "Your certificate has expired, please upload a new one."
          ),
        },
      });
    }

    if (employeeId !== null) {
      const parsedEmployeeId = parseInt(employeeId, 10);
      const opCode = findOpCodeByEmployeeId(parsedEmployeeId);
      // Fetch businessConfigs from localStorage and parse it
      const businessConfigs = JSON.parse(
        localStorage.getItem("businessConfigs") as string
      );

      // Directly access allowNegativeInventory since it's always present
      const allowNegativeInventory: boolean =
        businessConfigs.allowNegativeInventory;
      const baseCurrencyId: number = businessConfigs.baseCurrencyId;
      const firstCustomer =
        customers.length > 0 ? parseInt(customers[0].id, 10) : null;
      if (!firstCustomer) {
        toast.error(t("No customers found"));
        setLoading(false);
        return; // Exit if no customers are available
      }
      const firstCashAccount = cashAccounts.length > 0 ? cashAccounts[0] : null;

      if (!firstCashAccount) {
        toast.error(t("No cash accounts found"));
        setLoading(false);
        return; // Exit if no cash accounts are available
      }

      const { balance, lastOpenBalance, tcrCode } = firstCashAccount;
      console.log(firstCashAccount);
      setCashAccountBalance(balance);
      setSelectedTcrCode(tcrCode || null);

      // Save the first cash account to localStorage (if needed)
      // localStorage.setItem("firstCashAccount", JSON.stringify(firstCashAccount));

      // Get today's date in YYYY-MM-DD format
      const today = new Date().toISOString().split("T")[0];

      // Check if there is an open balance for today
      if (!lastOpenBalance || lastOpenBalance.split("T")[0] !== today) {
        navigate(t("/cashledger"), {
          state: {
            cashAccountId: firstCashAccount.id,
            transactionType: 5, // Assuming 5 is "Open Balance"
            toastMessage: t("You have to open balance every day before sale."), // Add toast message
          },
        });
        return; // Stop further execution since the user is navigated to CashLedger
      }

      // Additional logic to proceed if balance is open for today...

      // Select the first payment method dynamically and convert to a number
      const firstPaymentMethod =
        paymentMethods.length > 0 ? parseInt(paymentMethods[0].id, 10) : null;
      if (!firstPaymentMethod) {
        toast.error(t("No payment methods found"));
        setLoading(false);
        return; // Exit if no payment methods are available
      }

      let buCode = "";
      try {
        const warehouses = await DataFetcher.getData<Warehouse[] | null>(
          `${API_URLS.BASE_URL}${API_URLS.WAREHOUSE}`,
          WAREHOUSE_STORAGE_KEY
        );

        if (!warehouses || warehouses.length === 0) {
          toast.error(t("No warehouses found."));
          setLoading(false);
          return;
        }

        const selectedWarehouse = warehouses.find(
          (warehouseItem) => Number(warehouseItem.id) === warehouse
        );

        if (!selectedWarehouse || !selectedWarehouse.shopId) {
          toast.error(t("Selected warehouse does not have a valid shopId."));
          setLoading(false);
          return;
        }

        const shops = await DataFetcher.getData<Shop[] | null>(
          `${API_URLS.BASE_URL}${API_URLS.SHOP}`,
          SHOP_STORAGE_KEY
        );

        if (!shops || shops.length === 0) {
          toast.error(t("No shops found."));
          setLoading(false);
          return;
        }

        const shop = shops.find(
          (s) => Number(s.id) === selectedWarehouse.shopId
        );

        if (shop && shop.businessUnitCode) {
          buCode = shop.businessUnitCode;
          console.log("Found buCode:", buCode);
        } else {
          toast.error(t("No valid businessUnitCode found for the shop."));
          setLoading(false);
          return;
        }
      } catch (error) {
        console.error("Error fetching warehouses or shops:", error);
        setLoading(false);
        return;
      }
      if (!selectedTcrCode) {
        toast.error(t("Selected cash account does not have a tcrCode."));
        setLoading(false);
        return;
      }
      const dto = new CreateSalesOrderDto2({
        customerId: firstCustomer,
        currencyId: baseCurrencyId, // Replace with actual currency ID logic
        totalAmount: totalAmountIncVat,
        quantity: orderProducts.reduce(
          (total, { quantity }) => total + quantity,
          0
        ),
        vatAmount: vatAmount,
        discountAmount: 0, // Replace with actual discount logic if any
        tcrCode: selectedTcrCode,
        opCode: opCode || "",
        buCode: buCode,
        employeeId: parsedEmployeeId || 0,
        // shopId: 1,
        tableId: selectedTable ? parseInt(selectedTable) : null,
        warehouseId: warehouse,
        // paymentMethodId: firstPaymentMethod, // Replace with actual payment method ID logic if any

        notes: "",
        // cashAccountId: parseInt(firstCashAccount.id, 10), // Convert ID to number
        allowNegativeInvetory: allowNegativeInventory,
        tableOrderLines: orderProducts.map(
          ({ id, uoMId, quantity, vatRateId, vatRate, price }) => ({
            productId: parseInt(id),
            uoMId: uoMId,
            quantity: quantity,
            discountAmount: 0,
            discountPercentage: 0,
            lineNote: "",
            lineVatRateId: vatRateId,
            lineVatRate: vatRate,
            lineTotal: price * quantity,
            lineVatAmount: parseFloat(
              (price - price / (1 + vatRate / 100)).toFixed(2)
            ),
          })
        ),
      });

      try {
        // Create the order
        const response = await axiosInstance.post(
          `${API_URLS.BASE_URL}${API_URLS.TabelOrder}`,
          dto
        );
        // const orderNo = response.data.data.orderNo;
        // setOrderNo(orderNo);
        // const FiscInvOrdNum = response.data.data.fiscInvOrdNum;
        // setFiscInvOrdNum(FiscInvOrdNum);
        // const buCode = response.data.data.buCode;
        // setBuCode(buCode);
        // const opCode = response.data.data.opCode;
        // setOpCode(opCode);
        // const tcrCode = response.data.data.tcrCode;
        // setTcrCode(tcrCode);

        console.log("Order created successfully:", response.data);
        await updateCashAccountBalance(
          parsedEmployeeId || 0,
          totalAmountIncVat
        );

        handlePrint();

        // Clear the table selection and remove item from local storage
        localStorage.removeItem("selectedTableId");

        // Navigate to the next path
        navigate(t("/posbarpath"));
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const { status, data } = error.response;

          if (status === 403) {
            toast.error(
              t("You do not have permission to perform this action.")
            );
          } else if (status === 500) {
            toast.error(t("A server error occurred. Please try again later."));
          } else if (data && Array.isArray(data)) {
            data.forEach((err: { errorMessage: string }) => {
              const translatedMessage =
                t(`errorSalesOrder.${err.errorMessage}`) ||
                t("errorSalesOrder.An error occurred");
              toast.error(translatedMessage);
            });
          } else if (data && typeof data.message === "string") {
            // Check for the specific error message
            if (data.message.includes("Insufficient total inventory")) {
              toast.error(t("Insufficient total inventory")); // Display the specific message
            } else {
              toast.error(t(data.message)); // Display other messages if necessary
            }
          } else {
            toast.error(t("An error occurred"));
          }
        } else {
          toast.error(t("An unexpected error occurred"));
        }
        console.error("Error creating order:", error);
      } finally {
        setLoading(false); // Hide loading spinner
      }
    }
  };

  const CloseTabel = async () => {
    setLoading(true); // Show loading spinner

    if (expiryDate < today) {
      navigate(t("/companycertificate"), {
        state: {
          toastMessage: t(
            "Your certificate has expired, please upload a new one."
          ),
        },
      });
    }
    const firstCustomer =
      customers.length > 0 ? parseInt(customers[0].id, 10) : null;
    if (!firstCustomer) {
      toast.error(t("No customers found"));
      setLoading(false);
      return; // Exit if no customers are available
    }

    if (storedCertificate.isDemo === false) {
      // Fetch employee data from localStorage and parse it
      const employeeData = JSON.parse(
        localStorage.getItem("employeeData") || "{}"
      );

      // Ensure that employeeData has a "data" property and that it is an array of Employees
      const employeeSalesData: Employee[] = Array.isArray(employeeData.data)
        ? employeeData.data
        : [];

      // Check if at least one employee has a valid opCode
      const hasValidOpCode = employeeSalesData.some(
        (employee) => employee.opCode && employee.opCode !== ""
      );

      // If no employee has a valid opCode, proceed with the toast and navigation
      if (!hasValidOpCode) {
        // Delay displaying the toast message
        setTimeout(() => {
          toast.error(
            t(
              "You don't have an opcode to fisc the bill.Please put one opcode one of your employee"
            )
          );
        }, 200);

        // Navigate to a specific page to manage employees
        navigate(t("/emplistpath"));
        return;
      }

      const cashaccountData = JSON.parse(
        localStorage.getItem("cashaccountData") || "{}"
      );

      // Ensure that employeeData has a "data" property and that it is an array of Employees
      const cashData: CashAccount[] = Array.isArray(cashaccountData.data)
        ? cashaccountData.data
        : [];

      // Check if at least one employee has a valid opCode
      const hasValidtcrCode = cashData.some(
        (cashaccount) => cashaccount.tcrCode && cashaccount.tcrCode !== ""
      );

      // If no employee has a valid opCode, proceed with the toast and navigation
      if (!hasValidtcrCode) {
        // Delay displaying the toast message
        setTimeout(() => {
          toast.error(
            t(
              "You don't have an tcrcode to fisc the cash account .Please put one tcr code one of your cash accounts"
            )
          );
        }, 200);

        // Navigate to a specific page to manage employees
        navigate(t("/cashaccountlistpath"));
        return;
      }

      const shopData = JSON.parse(localStorage.getItem("shopData") || "{}");

      // Ensure that employeeData has a "data" property and that it is an array of Employees
      const shopsData: Shop[] = Array.isArray(shopData.data)
        ? shopData.data
        : [];

      // Check if at least one employee has a valid opCode
      const hasValidbuCode = shopsData.some(
        (shop) => shop.businessUnitCode && shop.businessUnitCode !== ""
      );

      // If no employee has a valid opCode, proceed with the toast and navigation
      if (!hasValidbuCode) {
        // Delay displaying the toast message
        setTimeout(() => {
          toast.error(
            t(
              "You don't have an businessUnitCode to fisc the bill .Please put one businessUnitCode code one of your shops"
            )
          );
        }, 200);

        // Navigate to a specific page to manage employees
        navigate(t("/shoplistpath"));
        return;
      }

      return;
    }

    if (employeeId !== null) {
      const parsedEmployeeId = parseInt(employeeId, 10);
      const opCode = findOpCodeByEmployeeId(parsedEmployeeId);
      // Fetch businessConfigs from localStorage and parse it
      const businessConfigs = JSON.parse(
        localStorage.getItem("businessConfigs") as string
      );

      // Directly access allowNegativeInventory since it's always present

      const baseCurrencyId: number = businessConfigs.baseCurrencyId;

      const firstCashAccount = cashAccounts.length > 0 ? cashAccounts[0] : null;

      if (!firstCashAccount) {
        toast.error(t("No cash accounts found"));
        setLoading(false);
        return; // Exit if no cash accounts are available
      }

      const { balance, lastOpenBalance, tcrCode } = firstCashAccount;
      console.log(firstCashAccount);
      setCashAccountBalance(balance);
      setSelectedTcrCode(tcrCode || null);

      // Save the first cash account to localStorage (if needed)
      // localStorage.setItem("firstCashAccount", JSON.stringify(firstCashAccount));

      // Get today's date in YYYY-MM-DD format
      const today = new Date().toISOString().split("T")[0];

      // Check if there is an open balance for today
      if (!lastOpenBalance || lastOpenBalance.split("T")[0] !== today) {
        navigate(t("/cashledger"), {
          state: {
            cashAccountId: firstCashAccount.id,
            transactionType: 5, // Assuming 5 is "Open Balance"
            toastMessage: t("You have to open balance every day before sale."), // Add toast message
          },
        });
        return; // Stop further execution since the user is navigated to CashLedger
      }

      // Additional logic to proceed if balance is open for today...

      // Select the first payment method dynamically and convert to a number
      const firstPaymentMethod =
        paymentMethods.length > 0 ? parseInt(paymentMethods[0].id, 10) : null;
      if (!firstPaymentMethod) {
        toast.error(t("No payment methods found"));
        setLoading(false);
        return; // Exit if no payment methods are available
      }
      let buCode = "";
      try {
        const warehouses = await DataFetcher.getData<Warehouse[] | null>(
          `${API_URLS.BASE_URL}${API_URLS.WAREHOUSE}`,
          WAREHOUSE_STORAGE_KEY
        );

        if (!warehouses || warehouses.length === 0) {
          toast.error(t("No warehouses found."));
          setLoading(false);
          return;
        }

        const selectedWarehouse = warehouses.find(
          (warehouseItem) => Number(warehouseItem.id) === warehouse
        );

        if (!selectedWarehouse || !selectedWarehouse.shopId) {
          toast.error(t("Selected warehouse does not have a valid shopId."));
          setLoading(false);
          return;
        }

        const shops = await DataFetcher.getData<Shop[] | null>(
          `${API_URLS.BASE_URL}${API_URLS.SHOP}`,
          SHOP_STORAGE_KEY
        );

        if (!shops || shops.length === 0) {
          toast.error(t("No shops found."));
          setLoading(false);
          return;
        }

        const shop = shops.find(
          (s) => Number(s.id) === selectedWarehouse.shopId
        );

        if (shop && shop.businessUnitCode) {
          buCode = shop.businessUnitCode;
          console.log("Found buCode:", buCode);
        } else {
          toast.error(t("No valid businessUnitCode found for the shop."));
          setLoading(false);
          return;
        }
      } catch (error) {
        console.error("Error fetching warehouses or shops:", error);
        setLoading(false);
        return;
      }
      if (!selectedTcrCode) {
        toast.error(t("Selected cash account does not have a tcrCode."));
        setLoading(false);
        return;
      }
      const dto = new CloseTabelDto({
        currencyId: baseCurrencyId, // Replace with actual currency ID logic
        tcrCode: selectedTcrCode,
        opCode: opCode || "",
        buCode: buCode,
        exchangeRate: 0,
        amountDue: 0,
        customerId: firstCustomer,

        // shopId: 1,
        tableId: selectedTable ? parseInt(selectedTable) : null,
        warehouseId: warehouse,
        paymentMethodId: firstPaymentMethod, // Replace with actual payment method ID logic if any

        cashAccountId: parseInt(firstCashAccount.id, 10), // Convert ID to number
      });

      try {
        // Create the order
        const response = await axiosInstance.post(
          `${API_URLS.BASE_URL}${API_URLS.CloseTabel}`,
          dto
        );
        if (response.data.iic == null) {
          const iic = response.data.data.iic;
          setIic(iic);
          const fic = response.data.data.fic;
          setFic(fic);
          const orderNo = response.data.data.orderNo;
          setOrderNo(orderNo);
          const FiscInvOrdNum = response.data.data.fiscInvOrdNum;
          setFiscInvOrdNum(FiscInvOrdNum);
          const buCode = response.data.data.buCode;
          setBuCode(buCode);
          const opCode = response.data.data.opCode;
          setOpCode(opCode);
          const tcrCode = response.data.data.tcrCode;
          setTcrCode(tcrCode);
        } else {
          const iic = response.data.iic;
          setIic(iic);
          const fic = response.data.fic;
          setFic(fic);
          const orderNo = response.data.orderNo;
          setOrderNo(orderNo);
          const FiscInvOrdNum = response.data.fiscInvOrdNum;
          setFiscInvOrdNum(FiscInvOrdNum);
          const buCode = response.data.buCode;
          setBuCode(buCode);
          const opCode = response.data.opCode;
          setOpCode(opCode);
          const tcrCode = response.data.tcrCode;
          setTcrCode(tcrCode);
        }

        console.log("Order created successfully:", response.data);
        await updateCashAccountBalance(
          parsedEmployeeId || 0,
          totalAmountIncVat
        );

        handlePrint();

        if (selectedTable) {
          setSelectedTable(""); // Assuming setSelectedTable is your state update function
          localStorage.removeItem("selectedTableId");
          handlePrint1();

          navigate(t("/posbarpath"));
        } else {
          toast.error(t("Total amount is not paid"));
        }

        // Navigate to the next path
        navigate(t("/posbarpath"));
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const { status, data } = error.response;

          if (status === 403) {
            toast.error(
              t("You do not have permission to perform this action.")
            );
          } else if (status === 500) {
            toast.error(t("A server error occurred. Please try again later."));
          } else if (data && Array.isArray(data)) {
            data.forEach((err: { errorMessage: string }) => {
              const translatedMessage =
                t(`errorSalesOrder.${err.errorMessage}`) ||
                t("errorSalesOrder.An error occurred");
              toast.error(translatedMessage);
            });
          } else if (data && typeof data.message === "string") {
            // Check for the specific error message
            if (data.message.includes("Insufficient total inventory")) {
              toast.error(t("Insufficient total inventory")); // Display the specific message
            } else {
              toast.error(t(data.message)); // Display other messages if necessary
            }
          } else {
            toast.error(t("An error occurred"));
          }
        } else {
          toast.error(t("An unexpected error occurred"));
        }
        console.error("Error creating order:", error);
      } finally {
        setLoading(false); // Hide loading spinner
      }
    }
  };

  const updateCashAccountBalance = async (
    employeeId: number,
    salesAmount: number
  ) => {
    try {
      const parsedEmployeeId = parseInt(employeeId.toString(), 10);
      if (isNaN(parsedEmployeeId)) {
        console.error("Invalid employee ID");
        return;
      }
      const response = await DataFetcher.getData<any>(
        `${API_URLS.BASE_URL}${API_URLS.CashAccount}`,
        CashAccount_STORAGE_KEY
      );
      console.log(response);
      const cashAccounts = response;

      if (cashAccounts && cashAccounts.length > 0) {
        const cashAccount = cashAccounts.find(
          (account: { owners: { id: number }[] }) =>
            account.owners.some((owner) => owner.id === parsedEmployeeId)
        );

        console.log(cashAccount);

        if (cashAccount) {
          const cashAccountId = cashAccount.id;
          console.log(cashAccountId);
          const updatedCashAccount = cashAccount;

          const updatedBalance = updatedCashAccount.balance + salesAmount;

          const response = await axiosInstance.put(
            `${API_URLS.BASE_URL}${API_URLS.CashAccount}/${cashAccountId}`,
            {
              ...updatedCashAccount,
              balance: updatedBalance,
            }
          );
          console.log(response);

          setCashAccountBalance(updatedBalance);
        } else {
          console.log("No cash account found for this employee");
        }
      } else {
        console.log("No cash accounts found");
      }
    } catch (error) {
      console.error("Error updating cash account balance:", error);
    }
    console.log(orderProducts);
  };

  return (
    <div className="sales-order-container" style={{ height: containerHeight }}>
      {" "}
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      <div className="select-container">
        <div className="select-with-icon">
          {/* <ShoppingCart className="icon" onClick={handleCheckout} /> */}

          <h3>
            {t("Order")}: {totalAmountIncVat.toFixed(2)}
            {baseCurrencySymbol}
          </h3>
        </div>
        <div className="select-icon">
          <div className="select-icon">
            <select
              value={selectedTable}
              onChange={(e) => handleTableSelect(e.target.value)}
            >
              <option value="">{t("Select Table")}</option>
              {tables.map((table) => (
                <option key={table.id} value={table.id}>
                  {table.name}
                </option>
              ))}
            </select>
          </div>

          <div
            className="select-icon"
            onClick={() => navigate(t("/posbarpath"))}
          >
            <Grid2X2 size={50} />
          </div>
        </div>
      </div>
      <div className="categories">
        <Input
          className="search-input"
          placeholder={t("Search products")}
          value={searchQuery}
          type="search"
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div className="category-header">
          <h2>{t("category")}</h2>
        </div>
        {showCategories && (
          <div className="category-buttons">
            {categories.map((category) => (
              <Button
                key={category.id !== undefined ? category.id : category.name} // Use name if id is undefined for a unique key
                className={
                  selectedCategory === category.id ||
                  (selectedCategory === undefined && category.id === undefined)
                    ? "active"
                    : ""
                }
                onClick={() => handleCategorySelect(category.id as string)} // Non-null assertion here
                style={{
                  display:
                    (selectedCategory && selectedCategory !== category.id) ||
                    (selectedCategory === undefined &&
                      category.id !== undefined)
                      ? "none"
                      : "block",
                }}
              >
                {category.name}
              </Button>
            ))}
          </div>
        )}
      </div>
      <div className="products">
        <h2>{t("products")}</h2>
        {/* <h3>{t('Total')}: {totalAmountIncVat.toFixed(2)}L</h3> */}
        <div className="product-buttons">
          {filteredProducts.map((product) => (
            <Button key={product.id} onClick={() => handleRowClick(product)}>
              {truncateString(product.name, 15)}
            </Button>
          ))}
        </div>
      </div>
      <div className="order-products ">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t("Product Info")}</TableHead>
              <TableHead>{t("quantity")}</TableHead>
              <TableHead>{t("price")}</TableHead>
              {/* <TableHead>{t("vat")}</TableHead> */}

              <TableHead>{t("total")}</TableHead>
              <TableHead>{t("action")}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {orderProducts.map((product) => (
              <TableRow key={product.id}>
                <>
                  <TableCell>
                    {truncateString(product.name, 15)}
                    {/* Truncate to 15 characters */}
                  </TableCell>
                  <TableCell>{product.quantity}</TableCell>
                  <TableCell>
                    {product.price} {baseCurrencyName}
                  </TableCell>
                  {/* <TableCell>{product.vatRate}%</TableCell> */}
                  <TableCell>
                    {product.price * product.quantity} {baseCurrencyName}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => removeFromOrder(product.id)}>
                      {t("Remove")}
                    </Button>
                  </TableCell>
                </>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className="order-products" style={{ paddingBottom: "100px" }}>
        <h2>{t("Ordered Products")}</h2>

        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t("Product Info")}</TableHead>
              <TableHead>{t("quantity")}</TableHead>
              <TableHead>{t("price")}</TableHead>

              <TableHead>{t("total")}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {/* Check if a table is selected and find its data */}
            {selectedTable && (
              <React.Fragment>
                {/* Display table details */}

                {/* Find the selected table's products */}
                {(
                  tables.find((table) => table.id.toString() === selectedTable)
                    ?.products || []
                ).map((product) => (
                  <TableRow key={product.productId}>
                    <TableCell>
                      {truncateString(
                        productsMap[product.productId] || "Unknown Product",
                        15
                      )}
                    </TableCell>
                    <TableCell>{product.quantity}</TableCell>
                    <TableCell>
                      {product.unitPrice} {baseCurrencyName}
                    </TableCell>
                    <TableCell>
                      {product.unitPrice * product.quantity} {baseCurrencyName}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="summary">
        <div className="amount">
          {tables.map(
            (table) =>
              table.id.toString() === selectedTable && (
                <div key={table.id} className="amount">
                  <h2>
                    {t("Total Table")}:{" "}
                    {table.totalAmount?.toFixed(2) || "0.00"}{" "}
                    {baseCurrencySymbol}
                  </h2>
                </div>
              )
          )}
        </div>
        <div className="icon-container">
          <div className="select-icon2">
            <CircleCheckBig
              color="blue"
              size={50}
              className="icon"
              onClick={handleCheckout}
            />
            <CircleDollarSign size={50} onClick={CloseTabel} color="#13178b" />
            <Coins size={50} color="green" onClick={editcashbalance} />
          </div>
        </div>
      </div>
      {/* <div className="order-summary">
        <div className="total-amount">
          <h3>
            {t("SUBTOTAL")}: {totalAmountExVat1.toFixed(2)}L
          </h3>

          <h3>
            {t("Total Amount (Incl. VAT)")}: {totalAmountIncVat1.toFixed(2)}L
          </h3>
        </div>
        <div className="cash-account-balance">
          <h3>
            {t("CashAccountBalance")}: {cashAccountBalance.toFixed(2)}L
          </h3>
        </div>
      </div> */}
      <div style={{ display: "none" }}>
        <div className="order-summary">
          <div className="total-amount">
            <h3>
              {t("SUBTOTAL")}: {totalAmountExVat.toFixed(2)}
              {baseCurrencySymbol}
            </h3>
            {orderProducts.map((product) => (
              <h3 key={product.id}>
                {t("VAT")}: {product.vatRate}% -{" "}
                {(
                  product.price *
                  product.quantity *
                  (product.vatRate / 100)
                ).toFixed(2)}
                {baseCurrencySymbol}
              </h3>
            ))}{" "}
          </div>
        </div>

        <PosBAR
          ref={invoiceRef}
          tablename={selectedTableName}
          tableId={selectedTable}
          // employeeID='3'
          employeeId={employeeId || ""}
          orderProducts={orderProducts}
          totalAmountExVat={totalAmountExVat}
          vatAmount={vatAmount}
          getVATRate={getVATRate}
          getVATRateName={getVATRateName}
          calculateVATPerType={calculateVATPerType}
          totalAmountIncVat={totalAmountIncVat}
          orderNo={orderNo}
          // fiscInvOrdNum={fiscInvOrdNum}
          // buCode={buCode}
          // opCode={opCode}
          // tcrCode={tcrCode}
        />

        {/* LocalPrint usage with productsToPrint */}
        {selectedTable && (
          <LocalPrint
            ref={invoiceRef1}
            tablename={selectedTableName}
            tableId={selectedTable}
            employeeId={employeeId || ""}
            orderProducts={productsToPrint} // Pass productsToPrint here
            totalAmountExVat={totalAmountExVat} // Use the state variable
            vatAmount={vatAmount}
            getVATRate={getVATRate}
            getVATRateName={getVATRateName}
            calculateVATPerType={calculateVATPerType1}
            totalAmountIncVat={totalAmountIncVat1} // Use the state variable
            orderNo={orderNo}
            fiscInvOrdNum={fiscInvOrdNum}
            buCode={buCode}
            opCode={opCode}
            tcrCode={tcrCode}
            iic={iic}
            fic={fic}
          />
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default SalesOrder2;
