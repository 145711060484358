import { axiosInstance } from "@/lib/utils";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  ReactNode,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

interface AuthContextType {
  token: string | null;
  setToken: (token: string | null) => void;
  employeeId: string | null;
  setEmployeeId: (id: string | null) => void;
  roleId: string | null;
  setRoleId: (roleId: string | null) => void;
  isSuperUser: boolean | null;
  setIsSuperUser: (isSuperUser: boolean | null) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken_] = useState<string | null>(
    localStorage.getItem("token")
  );
  const [employeeId, setEmployeeId_] = useState<string | null>(
    localStorage.getItem("employeeId")
  );
  const [roleId, setRoleId_] = useState<string | null>(
    localStorage.getItem("roleId")
  );
  const [isSuperUser, setIsSuperUser_] = useState<boolean | null>(
    localStorage.getItem("isSuperUser") === "true"
  );
  const navigate = useNavigate();
  const location = useLocation();

  const setToken = (newToken: string | null) => {
    setToken_(newToken);
  };

  const setEmployeeId = (newId: string | null) => {
    setEmployeeId_(newId);
  };

  const setRoleId = (newRoleId: string | null) => {
    setRoleId_(newRoleId);
  };

  const setIsSuperUser = (newIsSuperUser: boolean | null) => {
    setIsSuperUser_(newIsSuperUser);
  };


  useEffect(() => {
    if (token) {
      axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
      localStorage.setItem("token", token);
    } else {
      delete axiosInstance.defaults.headers.common["Authorization"];
      localStorage.removeItem("token");
    }
  }, [token]);

  useEffect(() => {
    if (employeeId) {
      localStorage.setItem("employeeId", employeeId);
    } else {
      localStorage.removeItem("employeeId");
    }
  }, [employeeId]);

  useEffect(() => {
    if (roleId) {
      localStorage.setItem("roleId", roleId);
    } else {
      localStorage.removeItem("roleId");
    }
  }, [roleId]);

  useEffect(() => {
    if (isSuperUser !== null) {
      localStorage.setItem("isSuperUser", String(isSuperUser));
    } else {
      localStorage.removeItem("isSuperUser");
    }
  }, [isSuperUser]);

  // Helper function to check if a route is public
  const isPublicRoute = (pathname: string) => {
    const publicRoutes = [
      "/login",
      "/RegisterBusiness",
      "/RecoveryUsername",
      "/RecoveryPassword",
    ];
    const resetPasswordPattern = /^\/reset-password\/[^/]+\/[^/]+$/;
    return (
      publicRoutes.includes(pathname) || resetPasswordPattern.test(pathname)
    );
  };
  const isTokenExpired = (token: string | null): boolean => {
    if (!token) return true;
    const decodedToken = decodeJWT(token);
    if (decodedToken && decodedToken.exp) {
      const expirationTime = decodedToken.exp * 1000;
      const currentTime = Date.now();
      return currentTime > expirationTime;
    }
    return true;
  };

  useEffect(() => {
    if (token) {
      if (isTokenExpired(token)) {
        // Token is expired, clear everything and redirect to login
        setToken(null);
        setEmployeeId(null);
        setRoleId(null);
        setIsSuperUser(null);
        localStorage.removeItem("token"); // do hiqet kur te behte refreshing token
        window.location.href = "/login"; // do hiqet kur te behte refreshing token
        navigate("/login", { replace: true });
        return;
      }

      const decodedToken = decodeJWT(token);
      const employeeIdFromToken = decodedToken?.employeeId;
      const roleIdFromToken = decodedToken?.roleId;
      const isSuperUserFromToken = decodedToken?.isSuperUser === "True";

      if (employeeIdFromToken) setEmployeeId(employeeIdFromToken);
      if (roleIdFromToken) setRoleId(roleIdFromToken);
      if (typeof isSuperUserFromToken === "boolean")
        setIsSuperUser(isSuperUserFromToken);

      // If already on a public route, redirect to home
      if (isPublicRoute(location.pathname)) {
        navigate("/", { replace: true });
      }
    } else {
      // If no token, allow access to public routes
      if (!isPublicRoute(location.pathname)) {
        navigate("/login", { replace: true });
      }
    }
  }, [token, navigate, location.pathname]);

  const decodeJWT = (token: string) => {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join("")
      );
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error("Failed to decode token", error);
      return null;
    }
  };

  const contextValue = useMemo(
    () => ({
      token,
      setToken,
      employeeId,
      setEmployeeId,
      roleId,
      setRoleId,
      isSuperUser,
      setIsSuperUser,
    }),
    [token, employeeId, roleId, isSuperUser]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export default AuthProvider;
