import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  // DropdownMenuLabel,
  // DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import "@/css/loading.css";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ArrowUpDown, ChevronDown, Filter } from "lucide-react";
import { Square3Stack3DIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import * as ExcelJS from "exceljs";
import DataFetcher from "@/provider/DataFetcher";
import { EmployeeType } from "@/types/EmployeeType";
import { CashLedger } from "@/types/CashLedgerType";
import { Owner } from "@/types/CashAccountType";
import { DateRange } from "react-day-picker";
import { format } from "date-fns";
import { DatePickerWithRange } from "@/components/ui/DatePickerWithRange";
import { isSameDay, isWithinInterval } from "date-fns";
import { CurrencyType } from "@/types/CurrencyType";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";

interface BusinessConfigs {
  allowNegativeInventory: boolean;
  baseCurrencyId: number;
  secondCurrencyId: number; // Added second currency ID
  printerType: number;
}

// Define the transaction type mapping
// Mapping transaction types to names

const EMPLOYEE_STORAGE_KEY = "employeeData";
const CashLedger_KEY = "CashLedger";
const CashAccount_STORAGE_KEY = "cashAccountData";
const Currency_STORAGE_KEY = "currencyData";

const CashLedgerList = () => {
  const [data, setData] = useState<CashLedger[]>([]);
  const [loading, setLoading] = useState(true);
  const [sorting, setSorting] = useState<SortingState>([
    { id: "id", desc: true },
  ]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    id: false,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  type RowSelection = Record<string, boolean>;
  const location = useLocation();
  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  const [originalData, setOriginalData] = useState<CashLedger[]>([]);
  const [take, setTake] = useState(10); // Rows per page
  const [pageNumber, setPageNumber] = useState(1); // Current page number
  const [totalCount, setTotalCount] = useState(0); // Total number of records
  const [rowSelection, setRowSelection] = useState<RowSelection>({});
  const [cashAccountId, setCashAccountId] = useState<number | undefined>();
  const [cashAccounts, setCashAccounts] = useState<Owner[]>([]);
  const [locale] = useState<string>("sq-AL"); // Default locale
  const [currencies, setCurrencies] = useState<CurrencyType[]>([]); // Holds list of currencies
  const [, setBaseCurrencyName] = useState("Lekë"); // Default currency
  const [baseCurrencyId, setBaseCurrencyId] = useState<number>(1); // Default base currency
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentDate = new Date();
    const firstDayOfCurrentMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    setDateRange({ from: firstDayOfCurrentMonth, to: currentDate });
  }, []);

  useEffect(() => {
    const fetchCashLedgerData = async (
      take: number,
      pageNumber: number,
      fromDate: Date,
      toDate: Date,
      cashAccountId?: number // Add cashAccountId as a parameter
    ) => {
      try {
        const formattedFromDate = format(fromDate, "MM-dd-yyyy");
        const formattedToDate = format(toDate, "MM-dd-yyyy");
        const url = `${API_URLS.BASE_URL}${
          API_URLS.CASHLEGDER
        }?pageNumber=${pageNumber}&take=${take}&fromDate=${formattedFromDate}&toDate=${formattedToDate}${
          cashAccountId ? `&cashAccountId=${cashAccountId}` : ""
        }`;

        const response = await DataFetcher.getData<{
          cashLedgers: CashLedger[];
          totalCount: number;
        }>(url, CashLedger_KEY, true);

        if (response) {
          setTotalCount(response.totalCount);
          return response.cashLedgers || [];
        }
        return [];
      } catch (error) {
        console.error("Error fetching cash ledgers:", error);
        toast.error(t("Failed to fetch cash ledgers."));
        return [];
      }
    };

    const fetchEmployees = async (): Promise<EmployeeType[]> => {
      try {
        return (
          (await DataFetcher.getData<EmployeeType[]>(
            `${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}`,
            EMPLOYEE_STORAGE_KEY
          )) || []
        );
      } catch (error) {
        console.error("Error fetching employees:", error);
        return [];
      }
    };

    const fetchCashAccounts = async (): Promise<Owner[]> => {
      try {
        const accounts =
          (await DataFetcher.getData<Owner[]>(
            `${API_URLS.BASE_URL}${API_URLS.CashAccount}`,
            CashAccount_STORAGE_KEY,
            true
          )) || [];
        setCashAccounts(accounts); // Set fetched cash accounts
        return accounts;
      } catch (error) {
        console.error("Error fetching cash accounts:", error);
        return [];
      }
    };

    const loadData = async () => {
      setLoading(true);
      if (!dateRange || !dateRange.from || !dateRange.to) return;
      try {
        const [cashLedgers, employees, cashAccounts] = await Promise.all([
          fetchCashLedgerData(
            take,
            pageNumber,
            dateRange.from,
            dateRange.to,
            cashAccountId // Pass cashAccountId here
          ),
          fetchEmployees(),
          fetchCashAccounts(),
        ]);

        const employeeMap = employees.reduce<Record<number, string>>(
          (acc, employee) => {
            acc[employee.id] = employee.name;
            return acc;
          },
          {}
        );

        const cashAccountMap = cashAccounts.reduce<Record<number, string>>(
          (acc, cashAccount) => {
            acc[cashAccount.id] = cashAccount.name;
            return acc;
          },
          {}
        );

        const updatedCashLedgers = cashLedgers.map((ledger) => ({
          ...ledger,
          createdByName: ledger.createdBy
            ? employeeMap[ledger.createdBy] || ""
            : "",
          NameAccount: ledger.cashAccountId
            ? cashAccountMap[ledger.cashAccountId] || ""
            : "",
            
        }));

        setData(updatedCashLedgers);
        setOriginalData(updatedCashLedgers);
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [take, pageNumber, dateRange, cashAccountId, t]);

  const handleTakeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTake(Number(e.target.value));
    setPageNumber(1); // Reset to first page when rows per page is changed
  };

  const handleNextPage = () => {
    if (pageNumber * take < totalCount) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };
  useEffect(() => {
    if (dateRange) {
      const filteredData = originalData.filter((order) => {
        const orderDate = new Date(order.createDateUtc);

        if (dateRange.from && dateRange.to) {
          // Handle range filtering
          return isWithinInterval(orderDate, {
            start: dateRange.from,
            end: dateRange.to,
          });
        } else if (dateRange.from) {
          // Handle single day filtering
          return isSameDay(orderDate, dateRange.from);
        } else {
          // No date range provided, return all data
          return true;
        }
      });

      setData(filteredData);
    } else {
      setData(originalData);
    }
  }, [dateRange, originalData]);

  useEffect(() => {
    // Fetch available currencies using DataFetcher with caching
    const fetchCurrencyData = async () => {
      try {
        const response = await DataFetcher.getData<CurrencyType[]>(
          `${API_URLS.BASE_URL}${API_URLS.Currency}`, // API endpoint
          Currency_STORAGE_KEY // LocalStorage key
        );
        setCurrencies(response || []); // Set fetched currencies, default to an empty array if none

        // Find and set base currency name and symbol
        const businessConfigs: BusinessConfigs = JSON.parse(
          localStorage.getItem("businessConfigs") || "{}"
        );
        const baseCurrencyId = businessConfigs.baseCurrencyId ?? 1; // Default to 1 if not set
        const baseCurrency = response?.find(
          (currency) => currency.id === baseCurrencyId
        );

        setBaseCurrencyName(baseCurrency?.name || "Leke"); // Set base currency name, default to "Leke"
        setBaseCurrencyId(businessConfigs.baseCurrencyId ?? 1);
      } catch (error) {
        console.error("Error fetching currencies:", error);
        toast.error(t("Failed to load currencies"));
      }
    };

    fetchCurrencyData();
  }, [t]);

  const transactionTypeNames: Record<number, string> = {
    0: t("Account Transfer"),
    1: t("Sale"),
    2: t("Purchase"),
    3: t("Receivable for Sale"),
    4: t("Payable for Purchase"),
    5: t("Open Balance"),
    6: t("Other"),
  };

  useEffect(() => {
    if (!loading && location.state?.showToast) {
      toast.success(location.state.message);
    }
  }, [loading, location.state]);

  const exportToExcel = async () => {
    // Get the selected rows or use the full data if no rows are selected
    const selectedRows = table
      .getFilteredSelectedRowModel()
      .rows.map((row) => row.original);
    const rowsToExport = selectedRows.length > 0 ? selectedRows : data;

    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Shops");

    // Define columns based on the data structure
    worksheet.columns = [
      // { header: 'ID', key: 'id', width: 10 },
      { header: t("Type"), key: "transactionType", width: 30 },
      { header: t("amount"), key: "amount", width: 30 },
      { header: t("Note"), key: "note", width: 30 },
    ];

    // Map rowsToExport to match the worksheet columns
    const worksheetData = rowsToExport.map((shop) => ({
  transactionType: transactionTypeNames[shop.transactionType] || t("Unknown"), // Mapping ID to name
      amount: shop.amount,
      note: shop.note,
    }));

    // Add rows to worksheet
    worksheet.addRows(worksheetData);

    // Generate Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const url = window.URL.createObjectURL(
      new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "transaction.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleAdd = () => {
    navigate(t("/cashledger"));
  };
  const isMobile = window.innerWidth <= 999; // Adjust the breakpoint if needed
  const isDesktop = window.innerWidth > 999; // Adjust the breakpoint if needed

  const columns: ColumnDef<CashLedger>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "id",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("ID")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("id")}</div>,
    },

    {
      accessorKey: "transactionType",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("Type")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => {
        const transactionType = row.getValue("transactionType") as number; // ensure it's a number
        return <div>{transactionTypeNames[transactionType] || "Unknown"}</div>;
      },
    },
    {
      accessorKey: "note",
      header: t("Note"),
      cell: ({ row }) => {
        const note = row.getValue("note") as string | null;
        const orderNoMatch = note ? note.match(/Nr Prosie: (\d+)/) : null;
        const orderNo = orderNoMatch ? orderNoMatch[1] : null;

        // Assuming transaction type is available in row data
        const transactionType = row.getValue("transactionType");

        return (
          <div>
            {note || ""}
            {orderNo && (
              <Button
                variant="link"
                onClick={() =>
                  navigate(
                    transactionType === 4
                      ? t("/purchaseorderlistpath")
                      : t("/salesorderlistpath"),
                    { state: { selectedOrderNo: orderNo } }
                  )
                }
                className="text-blue-600 underline"
              >
                {` (Shko tek porosia Nr ${orderNo})`}
              </Button>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: "amount",
      header: t("amount"),
      cell: ({ row }) => {
        const totalcost = parseFloat(row.getValue("amount"));

        // Find the currency name from the currencies array based on baseCurrencyId
        const baseCurrency = currencies.find(
          (currency) => currency.id === baseCurrencyId
        );

        const formattedPrice = new Intl.NumberFormat(locale, {
          style: "decimal", // Format as a regular number (without currency symbol)
        }).format(totalcost);

        return (
          <div>
            {formattedPrice} {baseCurrency?.name || t("Unknown Currency")}{" "}
            {/* Append currency name */}
          </div>
        );
      },
    },
    {
      accessorKey: "NameAccount",
      header: t("CashAccount"),
      cell: ({ row }) => <div>{row.getValue("NameAccount")}</div>,
    },

 
    {
      accessorKey: "createdByName",
      header: t("createdBy"),
      cell: ({ row }) => <div>{row.getValue("createdByName")}</div>,
    },
        {
  accessorKey: "fic",
  header: t("Statusi Fiskalizimt"),
  cell: ({ row }) => {
    const value = row.getValue("fic");
    const note = row.getValue("note"); // Get the salesOrderId from the row
        const salesOrderId = row.original.salesOrderId; // Access salesOrderId from the original row

     if (salesOrderId !== null || note === "SaleOrder " || note === "Purchase") {
      return null; // Return nothing if salesOrderId is not null or specific note values
    }
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
        {value ? (
          <CheckCircleIcon className="text-green-500 h-6 w-6" /> // Success icon
        ) : (
          <XCircleIcon className="text-red-500 h-6 w-6" /> // Error icon
        )}
        {/* <span>{value ? t("Yes") : t("No")}</span> */}
      </div>
    );
  },
},

    // {
    //   id: "actions",
    //   enableHiding: false,
    //   cell: () => (
    //     <DropdownMenu>
    //       <DropdownMenuTrigger asChild>
    //         <Button variant="ghost" className="h-8 w-8 p-0">
    //           <span className="sr-only">Open menu</span>
    //           <MoreHorizontal className="h-4 w-4 icon" />
    //         </Button>
    //       </DropdownMenuTrigger>
    //       <DropdownMenuContent align="end">
    //         <DropdownMenuLabel>{t("action")}</DropdownMenuLabel>

    //         <DropdownMenuSeparator />
    //       </DropdownMenuContent>
    //     </DropdownMenu>
    //   ),
    // },
  ];

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pagination: {
        pageIndex: 0,
        pageSize: take,
      },
    },
  });

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="w-full">
 {!isDesktop && (
  <>
    <DatePickerWithRange
      className="pb-8"
      onSelect={setDateRange}
      selectedRange={dateRange}
    />
    <select
      onChange={(e) => setCashAccountId(Number(e.target.value))}
      value={cashAccountId || ""}
      className="mr-4 border rounded p-2 w-full bg-white text-black dark:bg-black dark:text-white"
    >
      <option value="" className="bg-white text-black dark:bg-black dark:text-white">
        {t("All Cash Accounts")}
      </option>
      {cashAccounts.map((account) => (
        <option
          key={account.id}
          value={account.id}
          className="bg-white text-black dark:bg-black dark:text-white"
        >
          {account.name}
        </option>
      ))}
    </select>
  </>
)}

      <div className="flex flex-wrap items-center py-4">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="mr-10 flex items-center">
              <Square3Stack3DIcon className="h-5 w-5" />
              <span className="hidden sm:flex sm:items-center">
                {t("action")} <ChevronDown className="ml-2 h-4 w-4" />
              </span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={exportToExcel}>
              {t("export")}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <Input
          placeholder={t("filtername")}
          value={
            (table.getColumn("note")?.getFilterValue() as string) ??
            ""
          }
          onChange={(event) =>
            table
              .getColumn("note")
              ?.setFilterValue(event.target.value)
          }
          style={{
            width: window.innerWidth <= 999 ? "45%" : "100%",
             fontSize: "16px"
          }}
          className="max-w-sm"
        />
        {!isMobile && (
          <>
          <select
  onChange={(e) => setCashAccountId(Number(e.target.value))}
  value={cashAccountId || ""}
  className="ml-4 border rounded p-2 bg-white text-black dark:bg-black dark:text-white"
>
  <option value="" className="bg-white text-black dark:bg-black dark:text-white">{t("All Cash Accounts")}</option>
  {cashAccounts.map((account) => (
    <option key={account.id} value={account.id} className="bg-white text-black dark:bg-black dark:text-white">
      {account.name}
    </option>
  ))}
</select>


            <DatePickerWithRange
              className="ml-4"
              onSelect={setDateRange}
              selectedRange={dateRange}
            />
          </>
        )}{" "}
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              className="ml-auto flex items-center justify-center"
            >
              <span className="hidden md:block ">{t("columns")}</span>
              <Filter className="md:ml-2 h-4 w-4 mx-auto" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => (
                <DropdownMenuCheckboxItem
                  key={column.id}
                  className="capitalize"
                  checked={column.getIsVisible()}
                  onCheckedChange={(value) => column.toggleVisibility(!!value)}
                >
                  {column.id}
                </DropdownMenuCheckboxItem>
              ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  style={{ cursor: "pointer" }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  {t("noresult")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex justify-end mb-4">
        <label className="mr-2">{t("Rows per page")}:</label>
        <select
          value={take}
          onChange={handleTakeChange}
          className="border rounded-md p-1"
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="text-sm">
          {table.getFilteredSelectedRowModel().rows.length} of{" "}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div>
        <div className="flex space-x-2">
          <Button
            variant="outline"
            onClick={handlePreviousPage}
            disabled={pageNumber === 1}
          >
            {t("previous")}
          </Button>
          <Button
            variant="outline"
            onClick={handleNextPage}
            disabled={pageNumber * take >= totalCount}
          >
            {t("next")}
          </Button>
        </div>
      </div>
      <div className="fixed bottom-4 right-4 md:bottom-4 md:right-4">
        <Button
          type="button"
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={handleAdd}
        >
          +
        </Button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CashLedgerList;
