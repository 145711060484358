import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "@/css/loading.css";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ArrowUpDown, ChevronDown, Filter, MoreHorizontal } from "lucide-react";
import { Square3Stack3DIcon } from "@heroicons/react/24/outline";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import API_URLS from "@/config";
import CreateEmployeeDto from "@/models/CreateEmployeeDto";
import * as ExcelJS from "exceljs";
import { Employee } from "@/types/EmployeeType";
import { ShopType } from "@/types/ShopType";
import { RoleType } from "@/types/RoleType";
import DataFetcher from "@/provider/DataFetcher";
import { axiosInstance } from "@/lib/utils";

interface BusinessConfigs {
  allowNegativeInventory: boolean;
  baseCurrencyId: number;
  secondCurrencyId: number; // Added second currency ID
  printerType: number;
}

const EmployeeList: React.FC = () => {
  const [data, setData] = useState<Employee[]>([]);
  const EMPLOYEE_STORAGE_KEY = "employeeData";
  const ROLE_STORAGE_KEY = "roleData";
  const SHOP_STORAGE_KEY = "shopData";

  const [loading, setLoading] = useState(true);
  const [sorting, setSorting] = useState<SortingState>([
    { id: "id", desc: true },
  ]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    id: false,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  type RowSelection = Record<string, boolean>;

  const [rowSelection, setRowSelection] = useState<RowSelection>({});
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  
  const [, setCurrencyList] = useState<any[]>([]);
  const [, setBaseCurrency] = useState<any>(null);
  const [baseCurrencySymbol, setBaseCurrencySymbol] = useState("L");

 

  
  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const response = await DataFetcher.getData<any[]>(
          `${API_URLS.BASE_URL}${API_URLS.Currency}`,
          "currencyData"
        );
        setCurrencyList(response ?? []);

        // Retrieve business configs from localStorage
        const businessConfigs: BusinessConfigs = JSON.parse(
          localStorage.getItem("businessConfigs") || "{}"
        );

        const baseCurrencyId = businessConfigs.baseCurrencyId ?? 1; // Default to 1

        const baseCurrency = response?.find(
          (currency) => currency.id === baseCurrencyId
        );

        setBaseCurrencySymbol(baseCurrency?.symbol || "L"); // Default to "L"

        // Find the currency matching baseCurrencyId if response is available
        if (response && response.length > 0 && baseCurrencyId) {
          const foundBaseCurrency = response.find(
            (currency) => currency.id === baseCurrencyId
          );

          if (foundBaseCurrency) {
            setBaseCurrency(foundBaseCurrency); // Set baseCurrency in state
          } else {
            console.warn(
              "Base currency not found in the fetched currency list"
            );
          }
        }
      } catch (error) {
        console.error("Error fetching currencies:", error);
      }
    };

    fetchCurrencies();
  }, []);



  useEffect(() => {
    const fetchEmployeesFromServer = async (): Promise<Employee[]> => {
      try {
        const response = await DataFetcher.getData<Employee[]>(
          `${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}`,
          EMPLOYEE_STORAGE_KEY,
          true
        );
        const employees = response;
        localStorage.setItem(EMPLOYEE_STORAGE_KEY, JSON.stringify(employees));
        return response || [];
      } catch (error) {
        console.error("Error fetching employees:", error);
        return [];
      }
    };
    const fetchRolesFromServer = async (): Promise<RoleType[]> => {
      try {
        return (
          (await DataFetcher.getData<RoleType[]>(
            `${API_URLS.BASE_URL}${API_URLS.ROLE}`,
            ROLE_STORAGE_KEY
          )) || []
        );
      } catch (error) {
        console.error("Error fetching roles:", error);
        return [];
      }
    };

    const fetchShopsFromServer = async (): Promise<ShopType[]> => {
      try {
        return (
          (await DataFetcher.getData<ShopType[]>(
            `${API_URLS.BASE_URL}${API_URLS.SHOP}`,
            SHOP_STORAGE_KEY
          )) || []
        );
      } catch (error) {
        console.error("Error fetching shops:", error);
        return [];
      }
    };

    const updateStateWithRoles = (
      employees: Employee[],

      roles: RoleType[],
      shopes: ShopType[]
    ) => {
      const roleMap = roles.reduce<Record<number, string>>((acc, role) => {
        acc[role.id] = role.name;
        return acc;
      }, {});
      const shopMap = shopes.reduce<Record<number, string>>((acc, shop) => {
        acc[shop.id] = shop.name;
        return acc;
      }, {});
      const employeeMap = Array.isArray(employees)
        ? employees.reduce<Record<number, string>>((acc, employee) => {
            acc[employee.id] = employee.name;
            return acc;
          }, {})
        : {};

      const updatedEmployees = employees.map((employee) => ({
        ...employee,
        roleName: roleMap[employee.roleId] || "",
        shopName: shopMap[employee.shopId] || "",
        createdByName: employee.createdBy
          ? employeeMap[employee.createdBy] || ""
          : "",
        updatedByName: employee.updatedBy
          ? employeeMap[employee.updatedBy] || ""
          : "",
      }));
      setData(updatedEmployees);
    };





    const fetchData = async () => {
      try {
        const [employees, roles, shopes] = await Promise.all([
          fetchEmployeesFromServer(),
          fetchRolesFromServer(),
          fetchShopsFromServer(),
        ]);

        updateStateWithRoles(employees, roles, shopes);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleEdit = (employeeId: number) => {
    navigate(t("/editemppath"), { state: { id: employeeId } });
  };

  useEffect(() => {
    if (!loading && location.state?.showToast) {
      toast.success(location.state.message);
    }
  }, [loading, location.state]);

  
  const handleDelete = async (id: number) => {
    try {
      await axiosInstance.delete(`${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}/${id}`);
      const updatedData = data.filter((employee) => employee.id !== id);
      setData(updatedData);
      localStorage.setItem(EMPLOYEE_STORAGE_KEY, JSON.stringify(updatedData));
      toast.success(t("Employee deleted successfully"));
    } catch (error) {
      console.error("Error deleting employee:", error);
      toast.error(t("Error deleting employee"));
    }
  };

  const handleDeleteSelected = async (): Promise<void> => {
    // Get selected row IDs from rowSelection
    const selectedRowIds = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    // Extract employee IDs to delete based on selected rows
    const employeeIdsToDelete: string[] = data
      .filter((_item, index) => selectedRowIds.includes(index.toString()))
      .map((item) => item.id.toString()); // Ensure item.id is converted to string

    // Check if any records are selected for deletion
    if (employeeIdsToDelete.length === 0) {
      toast.error(t("Please select records to delete."));
      return;
    }

    // Delete selected employees and handle results
    const deleteResults = await Promise.all(
      employeeIdsToDelete.map(async (id: string) => {
        try {
          await axiosInstance.delete(`${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}/${id}`);
          return { id, success: true }; // Keep id as string here
        } catch (error) {
          console.error(`Failed to delete record with ID ${id}:`, error);
          return { id, success: false }; // Keep id as string here
        }
      })
    );
    // Separate successful and failed deletions
    const successfullyDeletedIds = deleteResults
      .filter((result) => result.success)
      .map((result) => result.id); // This is fine since we're keeping it as string

    const failedToDeleteIds = deleteResults
      .filter((result) => !result.success)
      .map((result) => result.id); // This is fine since we're keeping it as string

    // Update state and local storage only for successfully deleted records
    const updatedData = data.filter(
      (item) => !successfullyDeletedIds.includes(item.id.toString()) // Ensure item.id is compared as string
    );

    setData(updatedData);
    // Clear the row selection to prevent automatic selection of nearby rows
    setRowSelection({});

    // Update local storage with the updated data
    localStorage.setItem(EMPLOYEE_STORAGE_KEY, JSON.stringify(updatedData));

    // Show success messages for successfully deleted records
    if (successfullyDeletedIds.length > 0) {
      toast.success(t("Selected records deleted successfully."));
    }

    // Optionally show error messages for failed deletions
    if (failedToDeleteIds.length > 0) {
    }
  };

  const importEmployees = async (employees: Employee[]) => {
    try {
      // Fetch roles and shops from local storage
      const roles = fetchRolesFromLocalStorage();
      const shops = fetchShopsFromLocalStorage();
      const roleMap = createLookupMap(roles);
      const shopMap = createLookupMap(shops);

      // Create a set of unique role names from the employees
      const uniqueRoleNames = new Set(employees.map((e) => e.roleName));

      // Check for missing roles and create them if necessary
      for (const roleName of uniqueRoleNames) {
        if (!roleMap[roleName]) {
          try {
            const newRoleDto = { name: roleName }; // Customize as needed
            const response = await axiosInstance.post(
              `${API_URLS.BASE_URL}${API_URLS.ROLE}`,
              newRoleDto
            );
            const newRole = response.data;

            // Add the new role to local storage and update the lookup map
            roleMap[roleName] = newRole.id;
            roles.push(newRole);
            localStorage.setItem("roleData", JSON.stringify(roles));
          } catch (error) {
            console.error(`Error creating role '${roleName}':`, error);
            // Optionally handle error or continue
          }
        }
      }

      // Format each employee using CreateEmployeeDto
      const formattedEmployees = employees.map((e) => {
        // Ensure phone numbers and passwords are treated as strings
        e.phoneNumber = e.phoneNumber ? String(e.phoneNumber) : "";
        e.password = e.password ? String(e.password) : "";

        // Map role and shop names to their IDs
        const roleId = e.roleName ? roleMap[e.roleName] : undefined;
        const shopId = e.shopName ? shopMap[e.shopName] : undefined;

        // Create the DTO for the employee
        return new CreateEmployeeDto({
          ...e,
          roleId,
          shopId,
        });
      });

      // Initialize arrays to track successful and failed responses
      const successfulResponses = [];
      const failedResponses = [];

      // Retrieve existing employees from local storage
      const existingEmployees = JSON.parse(
        localStorage.getItem("employeeData") || "[]"
      );

      // Upload employees to the API and retrieve the responses
      for (const employee of formattedEmployees) {
        try {
          const response = await axiosInstance.post(
            `${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}`,
            employee
          );
          successfulResponses.push({
            id: response.data.id,
            name: response.data.name,
            status: "Success",
          });

          // Add the successfully added employee to the local storage array
          existingEmployees.push(response.data);
        } catch (error) {
          let formattedErrors = [];
          if (axios.isAxiosError(error)) {
            if (error.response) {
              const statusCode = error.response.status;

              // Handle 400 errors (validation errors)
              if (statusCode === 400 && Array.isArray(error.response.data)) {
                formattedErrors = error.response.data.map((err: any) => ({
                  propertyName: err.propertyName,
                  errorMessage: err.errorMessage,
                  attemptedValue: err.attemptedValue,
                }));
              }
              // Handle 500 errors (Internal Server Error) and others
              else if (statusCode === 500 || statusCode >= 500) {
                formattedErrors.push({
                  errorMessage: t(
                    "Internal Server Error. Please try again later."
                  ),
                });
              } else {
                // Handle other potential errors
                formattedErrors.push({
                  errorMessage: `An error occurred: ${statusCode}. Please try again.`,
                });
              }
            } else {
              // Handle cases where there is no response, possibly due to network issues
              formattedErrors.push({
                errorMessage:
                  "Network error. Please check your connection and try again.",
              });
            }
          } else {
            // Handle any unexpected errors
            formattedErrors.push({
              errorMessage: "An unknown error occurred. Please try again.",
            });
          }

          failedResponses.push({
            name: employee.name,
            status: "Failed",
            errors: formattedErrors,
          });

          console.error(`Error importing employee '${employee.name}':`, error);
        }
      }

      // Save the updated employee list to local storage
      localStorage.setItem("employeeData", JSON.stringify(existingEmployees));

      // Save only the last import result to local storage
      const lastResult = [...successfulResponses, ...failedResponses];
      localStorage.setItem("importResults", JSON.stringify(lastResult));

      // Show success message
      toast.success(t("Employees imported successfully"));

      // Navigate to the results page
      navigate(t("/importresult"));
    } catch (error) {
      // Handle errors that occur outside of the main loop, e.g., network or critical errors
      if (axios.isAxiosError(error) && error.response) {
        const { data } = error.response;
        if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage =
              t(`errorEmployee.${err.errorMessage}`) ||
              t("errorEmployee.An error occurred");
            toast.error(translatedMessage);
          });
        } else {
          const defaultMessage = t("An error occurred");
          toast.error(defaultMessage);
        }
      } else {
        const unexpectedMessage = t("An unexpected error occurred");
        toast.error(unexpectedMessage);
      }
      console.error("Error importing employees:", error);
    }
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target?.result as ArrayBuffer);
          const workbook = new ExcelJS.Workbook();
          await workbook.xlsx.load(data);

          // Get the first worksheet
          const worksheet = workbook.worksheets[0];
          const employees: Employee[] = [];

          // Extract data from worksheet
          worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
            if (rowNumber > 1) {
              // Skip header row
              const id = row.getCell(11).text; // Assuming the twelfth column is id
              const name = row.getCell(1).text; // Assuming the first column is name
              const username = row.getCell(2).text; // Assuming the second column is username
              const email = row.getCell(3).text; // Assuming the third column is email
              const phoneNumber = row.getCell(5).text; // Assuming the sixth column is phoneNumber
              const salaryText = row.getCell(6).text; // Assuming the seventh column is salary
              const roleName = row.getCell(7).text; // Assuming the eighth column is roleName
              const shopName = row.getCell(8).text; // Assuming the ninth column is shopName
              const accessToken = row.getCell(9).text; // Assuming the tenth column is accessToken
              const password = row.getCell(4).text; // Assuming the fifth column is password

              // Convert salary to number
              const salary = salaryText ? parseFloat(salaryText) : 0;
              const numericId = id ? parseInt(id, 10) : 0; // Convert to number, default to 0 if empty
              // Create employee object
              const employee: Employee = {
                id: numericId, // Use the numericId here
                name,
                username,
                email,
                phoneNumber,
                salary,
                roleName, // Keep as name for mapping in importEmployees
                shopName, // Keep as name for mapping in importEmployees
                accessToken,
                password,
                roleId: 0,
                shopId: 0,
                createdBy: 0,
                updatedBy: 0,
                createdByName: "",
                updatedByName: "",
                isSuperUser: false,
                opCode: ""
              };

              employees.push(employee);
            }
          });

          // Call importEmployees with the parsed employee data
          importEmployees(employees);
        };
        reader.readAsArrayBuffer(file);
      } catch (error) {
        console.error("Error reading Excel file:", error);
      }
    }
  };

  // Fetch role and shop data
  const fetchRolesFromLocalStorage = () => {
    const roles = JSON.parse(localStorage.getItem("roleData") || "[]");
    return roles.data; // Assume roles is an array of objects with { id, name }
  };

  const fetchShopsFromLocalStorage = () => {
    const shops = JSON.parse(localStorage.getItem("shopData") || "[]");
    return shops.data; // Assume shops is an array of objects with { id, name }
  };

  // Create lookup maps
  const createLookupMap = (items: { id: number; name: string }[]) =>
    items.reduce((map, item) => {
      map[item.name] = item.id;
      return map;
    }, {} as Record<string, number>);

  // Helper function to update local storage (if needed)

  const handleAdd = () => {
    navigate(t("/createemppath"));
  };
  const exportToExcel = async () => {
    // Get the selected rows or use the full data if no rows are selected
    const selectedRows = table
      .getFilteredSelectedRowModel()
      .rows.map((row) => row.original);
    const rowsToExport = selectedRows.length > 0 ? selectedRows : data;

    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Employees");

    // Define columns based on the data structure
    worksheet.columns = [
      // { header: 'ID', key: 'id', width: 10 },
      { header: t("name"), key: "name", width: 30 },
      { header: t("username"), key: "username", width: 30 },
      { header: t("Email"), key: "email", width: 30 },
      { header: t("Password"), key: "password", width: 30 },
      { header: t("phoneNumber"), key: "phoneNumber", width: 20 },
      { header: t("salary"), key: "salary", width: 15 },
      { header:t("selectrole"), key: "roleName", width: 15 },
      { header:  t("shopId"), key: "shopName", width: 15 },
      // { header: "Access Token", key: "accessToken", width: 30 },
      { header: t("superuser"), key: "isSuperUser", width: 15 },
    ];

    // Map rowsToExport to match the worksheet columns
    const worksheetData = rowsToExport.map((employee) => ({
      name: employee.name,
      username: employee.username,
      email: employee.email,
      salary: employee.salary,
      phoneNumber: employee.phoneNumber,
      roleName: employee.roleName,
      shopName: employee.shopName,
      password: employee.password,
      // accessToken: employee.accessToken,
      isSuperUser: employee.isSuperUser ? "Yes" : "No",
    }));

    // Add rows to worksheet
    worksheet.addRows(worksheetData);

    // Generate Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const url = window.URL.createObjectURL(
      new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "employees.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columns: ColumnDef<Employee>[] = [
    {
      id: "select",
      header: ({ table }) => {
        const isAllSelected = table.getIsAllPageRowsSelected();
        const isSomeSelected = table.getIsSomePageRowsSelected();

        // Check if there are rows to select
        const totalRows = table.getRowModel().rows.length;
        const rowsSelectable = totalRows > 1; // Enable select all only if more than 3 rows exist

        return (
          <Checkbox
            checked={
              rowsSelectable &&
              (isAllSelected || (isSomeSelected && "indeterminate"))
            }
            onCheckedChange={(value) => {
              if (rowsSelectable) {
                table.toggleAllPageRowsSelected(!!value);
              }
            }}
            aria-label="Select all"
            disabled={!rowsSelectable} // Disable checkbox if there are 3 or fewer rows
          />
        );
      },
      cell: ({ row }) => {
        const rowIndex = row.index; // Get the current row index

        // Remove checkbox for the first three rows
        if (rowIndex < 1) {
          return <div />; // Render an empty div for the first three rows
        }

        return (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
          />
        );
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "id",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("ID")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("id")}</div>,
    },
    {
      accessorKey: "name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("name")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("name")}</div>,
    },
    {
      accessorKey: "username",
      header: t("username"),
      cell: ({ row }) => <div>{row.getValue("username")}</div>,
    },
       {
      accessorKey: "opCode",
      header: t("opCode"),
      cell: ({ row }) => <div>{row.getValue("opCode")}</div>,
    },
    {
      accessorKey: "email",
      header: "Email",
      cell: ({ row }) => (
        <div className="lowercase">{row.getValue("email")}</div>
      ),
    },
    {
      accessorKey: "salary",
      header: () => <div className="text-right">{t("salary")}</div>,
      cell: ({ row }) => {
        const salary = parseFloat(row.getValue("salary"));
        const formatted = new Intl.NumberFormat("sq-Al", {
          style: "currency",
          currency: "LEK",
          currencyDisplay: "narrowSymbol",
        })
          .format(salary)
          .replace("LEK", baseCurrencySymbol);
        return <div className="text-right font-medium">{formatted}</div>;
      },
    },
    {
      accessorKey: "roleName",
      header: t("selectrole"),
      cell: ({ row }) => <div>{row.getValue("roleName")}</div>,
    },
    {
      accessorKey: "shopName",
      header: t("shopId"),
      cell: ({ row }) => <div>{row.getValue("shopName")}</div>,
    },
     {
      accessorKey: "isSuperUser",
      header: t("superuser"),
      cell: ({ row }) => (
        <div>{row.getValue("isSuperUser") ? "Yes" : "No"}</div>
      ),
    },
    {
      accessorKey: "createdByName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("createdBy")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("createdByName")}</div>,
    },
    {
      accessorKey: "updatedByName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("updatedBy")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("updatedByName")}</div>,
    },
   
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const rowIndex = row.index; // Get the current row index

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4 icon" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>{t("action")}</DropdownMenuLabel>
              <DropdownMenuItem onClick={() => handleEdit(row.original.id)}>
                {t("edit")}
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              {/* Disable delete for the first row only */}
              <DropdownMenuItem
                onClick={() => handleDelete(row.original.id)}
                disabled={rowIndex === 0} // Disable delete for the first row
              >
                {t("delete")}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }
  return (
    <div className="w-full">
      <div className="flex flex-wrap items-center py-4">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="mr-10 flex items-center">
              <Square3Stack3DIcon className="h-5 w-5" />
              <span className="hidden sm:flex sm:items-center">
                {t("action")} <ChevronDown className="ml-2 h-4 w-4" />
              </span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              onClick={() => document.getElementById("file-input")?.click()}
            >
              {t("Import")}
            </DropdownMenuItem>
            <DropdownMenuItem onClick={exportToExcel}>
              {t("export")}
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleDeleteSelected}>
              {t("delete")}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <Input
          placeholder={t("filtername")}
          value={(table.getColumn("username")?.getFilterValue() as string) ?? ""}
          onChange={(event) =>
            table.getColumn("username")?.setFilterValue(event.target.value)
          }
          style={{ width: window.innerWidth <= 999 ? "45%" : "100%" , fontSize: "16px"}}
          
          className="max-w-sm"
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              className="ml-auto flex items-center justify-center"
            >
              <span className="hidden md:block ">{t("columns")}</span>
              <Filter className="md:ml-2 h-4 w-4 mx-auto" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => (
                <DropdownMenuCheckboxItem
                  key={column.id}
                  className="capitalize"
                  checked={column.getIsVisible()}
                  onCheckedChange={(value) => column.toggleVisibility(!!value)}
                >
                  {column.id}
                </DropdownMenuCheckboxItem>
              ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  onDoubleClick={() => handleEdit(row.original.id)}
                  style={{ cursor: "pointer" }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  {t("noresult")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of{" "}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {t("previous")}
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {t("next")}
          </Button>
        </div>
      </div>
      <div className="relative">
        <div className="fixed bottom-4 right-4 md:bottom-4 md:right-4">
          <Button
            type="button"
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={handleAdd}
          >
            +
          </Button>
        </div>
      </div>

      <ToastContainer />
      <Input
        id="file-input"
        type="file"
        accept=".xlsx"
        style={{ display: "none" }}
        onChange={handleFileUpload}
      />
    </div>
  );
};

export default EmployeeList;
