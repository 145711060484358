import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import "@/css/loading.css";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ArrowUpDown, ChevronDown, Filter, MoreHorizontal } from "lucide-react";
import { Square3Stack3DIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import { useReactToPrint } from "react-to-print";
import InvoicePrint from "@/componentsPos/InvoicePrint";
// import PurchaseStatus from "@/models/PurchaseStatus";
import { isSameDay, isWithinInterval } from "date-fns";
import { DateRange } from "react-day-picker";
import { DatePickerWithRange } from "@/components/ui/DatePickerWithRange";
import { PurchaseOrder } from "@/types/PurchaseOrerType";
import { ProductType } from "@/types/ProductType";
import { ShopType } from "@/types/ShopType";
import { EmployeeType } from "@/types/EmployeeType";
import { VATType } from "@/types/VatType";
import { SupplierType } from "@/types/SupplierType";
import * as ExcelJS from "exceljs";
import DataFetcher from "@/provider/DataFetcher";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import NumericKeypad from "@/componentsPos/numericKeyboard";
import { axiosInstance } from "@/lib/utils";

const PURCHASEORDER_STORAGE_KEY = "purchaseOrderData";
const SHOP_STORAGE_KEY = "shopData";
const SUPPLIER_STORAGE_KEY = "supplierData";
const VAT_STORAGE_KEY = "vatData";
const PRODUCT_STORAGE_KEY = "productData";
const EMPLOYEE_STORAGE_KEY = "employeeData";
const Currency_STORAGE_KEY = "currencyData";

interface Currency {
  id: number;
  name: string;
  symbol: string;
}
interface BusinessConfigs {
  allowNegativeInventory: boolean;
  baseCurrencyId: number;
  secondCurrencyId: number; // Added second currency ID
  printerType: number;
}

const PurchaseOrderList = () => {
  const [data, setData] = useState<PurchaseOrder[]>([]);
  const [originalData, setOriginalData] = useState<PurchaseOrder[]>([]);
  const [products, setProducts] = useState<ProductType[]>([]);
  const [vatRates, setVatRates] = useState<VATType[]>([]);
  const [loading, setLoading] = useState(true);
  const [sorting, setSorting] = useState<SortingState>([
    { id: "deliveryDateUtc", desc: true },
  ]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    deliveryDateUtc: false,
    updatedByName: false,
    createdByName: false,
  });
  type RowSelection = Record<string, boolean>;

  const [take, setTake] = useState(10); // Rows per page
  const [pageNumber, setPageNumber] = useState(1); // Current page number
  const [totalCount, setTotalCount] = useState(0); // Total number of records
  const [rowSelection, setRowSelection] = useState<RowSelection>({});
  const [currencies, setCurrencies] = useState<Currency[]>([]); // Holds list of currencies
  const [, setBaseCurrencyName] = useState("Lekë"); // Default currency
  const [baseCurrencySymbol, setBaseCurrencySymbol] = useState("L");
  const [baseCurrencyId, setBaseCurrencyId] = useState<number>(1);
  const [locale] = useState<string>("sq-AL"); // Default locale
  const [selectedInvoice, setSelectedInvoice] = useState<PurchaseOrder | null>(
    null
  );
  const [selectedCurrencyDetails, setSelectedCurrencyDetails] = useState<{
    id: number;
    name: string;
    symbol: string;
  } | null>(null);
  const { t } = useTranslation();
  const [amountReceived, setAmountReceived] = useState<string>(""); // Amount entered by the user
  const [showDialog, setShowDialog] = useState<boolean>(false); // Toggle NumericKeypad visibility
  const [isPayDisabled, setIsPayDisabled] = useState<boolean>(true);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] =
    useState<PurchaseOrder | null>(null);
  const [showUnpaidOnly, setShowUnpaidOnly] = useState(false);
  const navigate = useNavigate();
  const printRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const selectedOrderNo = location.state?.selectedOrderNo;
  console.log(selectedOrderNo);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const handlePayClick = (purchaseOrder: PurchaseOrder) => {
    setSelectedPurchaseOrder(purchaseOrder);
    setShowDialog(true); // Show NumericKeypad when Pay is clicked
  };

  const handleNumericInput = (value: string) => {
    const newAmount = amountReceived + value;
    const amountAsNumber = parseFloat(newAmount);

    if (
      selectedPurchaseOrder &&
      amountAsNumber <= selectedPurchaseOrder.amountDue
    ) {
      setAmountReceived(newAmount);
      setIsPayDisabled(false);
    } else {
      toast.error("The entered amount cannot exceed the due amount");
    }
  };

  const handleClear = () => {
    setAmountReceived("");
    setIsPayDisabled(true);
  };

  const handleBackspace = () => {
    setAmountReceived((prev) => prev.slice(0, -1));
    if (amountReceived.length <= 1) {
      setIsPayDisabled(true);
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    const firstDayOfCurrentMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );

    setDateRange({ from: firstDayOfCurrentMonth, to: currentDate });
  }, []);

  useEffect(() => {
    const fetchPurchaseOrdersFromServer = async (
      take: number,
      pageNumber: number,
      fromDate: Date,
      toDate: Date
    ): Promise<PurchaseOrder[]> => {
      try {
        const formattedFromDate = format(fromDate, "MM-dd-yyyy");
        const formattedToDate = format(toDate, "MM-dd-yyyy");

        const purchaseOrderResponse = await DataFetcher.getData<{
          purchaseOrders: PurchaseOrder[];
          totalCount: number;
        }>(
          `${API_URLS.BASE_URL}${API_URLS.PURCHASEORDER}?pageNumber=${pageNumber}&take=${take}&fromDate=${formattedFromDate}&toDate=${formattedToDate}`,
          PURCHASEORDER_STORAGE_KEY,
          true
        );
        if (purchaseOrderResponse) {
          const purchaseOrders = purchaseOrderResponse.purchaseOrders || [];
          console.log(purchaseOrders);
          setTotalCount(purchaseOrderResponse.totalCount);
          return purchaseOrders || [];
        } else {
          return [];
        }
      } catch (error) {
        console.error("Error fetching purchase orders:", error);
        toast.error(t("Failed to fetch purchase orders."));
        return [];
      }
    };

    const fetchSuppliers = async (): Promise<SupplierType[]> => {
      return (
        (await DataFetcher.getData<SupplierType[]>(
          `${API_URLS.BASE_URL}${API_URLS.SUPPLIER}`,
          SUPPLIER_STORAGE_KEY
        )) || []
      );
    };

    const fetchShops = async (): Promise<ShopType[]> => {
      return (
        (await DataFetcher.getData<ShopType[]>(
          `${API_URLS.BASE_URL}${API_URLS.SHOP}`,
          SHOP_STORAGE_KEY
        )) || []
      );
    };

    const fetchEmployees = async (): Promise<EmployeeType[]> => {
      try {
        return (
          (await DataFetcher.getData<EmployeeType[]>(
            `${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}`,
            EMPLOYEE_STORAGE_KEY
          )) || []
        );
      } catch (error) {
        console.error("Error fetching employees:", error);
        return [];
      }
    };

    const fetchProducts = async (): Promise<ProductType[]> => {
      const productResponse = await DataFetcher.getData<ProductType[]>(
        `${API_URLS.BASE_URL}${API_URLS.PRODUCT}`,
        PRODUCT_STORAGE_KEY
      );
      const products = productResponse;
      setProducts(products || []);
      return products || [];
    };

    const fetchVatRates = async (): Promise<VATType[]> => {
      return (
        (await DataFetcher.getData<VATType[]>(
          `${API_URLS.BASE_URL}${API_URLS.VAT}`,
          VAT_STORAGE_KEY
        )) || []
      );
    };

    const updateStateWithPurchaseOrders = (
      purchaseOrders: PurchaseOrder[],
      shops: ShopType[],
      suppliers: SupplierType[],
      employees: EmployeeType[]
    ) => {
      const supplierMap = suppliers.reduce<Record<number, string>>(
        (acc, supplier) => {
          acc[supplier.id] = supplier.name;
          return acc;
        },
        {}
      );
      const shopMap = shops.reduce<Record<number, string>>((acc, shop) => {
        acc[shop.id] = shop.name;
        return acc;
      }, {});
      const employeeMap = Array.isArray(employees)
        ? employees.reduce<Record<number, string>>((acc, employee) => {
            acc[employee.id] = employee.name;
            return acc;
          }, {})
        : {};

      const updatedPurchaseOrders = purchaseOrders.map((purchaseOrder) => ({
        ...purchaseOrder,
        shopName: purchaseOrder.shopId
          ? shopMap[purchaseOrder.shopId] || ""
          : "",
        supplierName: purchaseOrder.supplierId
          ? supplierMap[purchaseOrder.supplierId] || ""
          : "",
        createdByName: purchaseOrder.createdBy
          ? employeeMap[purchaseOrder.createdBy] || ""
          : "",
        updatedByName: purchaseOrder.updatedBy
          ? employeeMap[purchaseOrder.updatedBy] || ""
          : "",
      }));

      setData(updatedPurchaseOrders);
      setOriginalData(updatedPurchaseOrders);
    };

    const fetchData = async () => {
      if (!dateRange || !dateRange.from || !dateRange.to) return;
      try {
        const [purchaseOrders, shops, suppliers, vatRates, employees] =
          await Promise.all([
            fetchPurchaseOrdersFromServer(
              take,
              pageNumber,
              dateRange.from,
              dateRange.to
            ),
            fetchShops(),
            fetchSuppliers(),
            fetchVatRates(),
            fetchEmployees(),
            fetchProducts(),
          ]);

        updateStateWithPurchaseOrders(
          purchaseOrders,
          shops,
          suppliers,
          employees
        );
        setVatRates(vatRates);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [take, pageNumber, dateRange, t]);
  useEffect(() => {
    // Fetch available currencies using DataFetcher with caching
    const fetchCurrencyData = async () => {
      try {
        const response = await DataFetcher.getData<Currency[]>(
          `${API_URLS.BASE_URL}${API_URLS.Currency}`, // API endpoint
          Currency_STORAGE_KEY // LocalStorage key
        );
        setCurrencies(response || []); // Set fetched currencies, default to an empty array if none

        // Find and set base currency name and symbol
        const businessConfigs: BusinessConfigs = JSON.parse(
          localStorage.getItem("businessConfigs") || "{}"
        );
        const baseCurrencyId = businessConfigs.baseCurrencyId ?? 1; // Default to 1 if not set
        const baseCurrency = response?.find(
          (currency) => currency.id === baseCurrencyId
        );

        setBaseCurrencyName(baseCurrency?.name || "Leke"); // Set base currency name, default to "Leke"
        setBaseCurrencySymbol(baseCurrency?.symbol || "L"); // Set base currency symbol, default to "L"
        setBaseCurrencyId(businessConfigs.baseCurrencyId ?? 1);
      } catch (error) {
        console.error("Error fetching currencies:", error);
        toast.error(t("Failed to load currencies"));
      }
    };

    fetchCurrencyData();
  }, [t]);

  const handleTakeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTake(Number(e.target.value));
    setPageNumber(1); // Reset to first page when rows per page is changed
  };

  const handleNextPage = () => {
    if (pageNumber * take < totalCount) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  useEffect(() => {
    if (dateRange) {
      const filteredData = originalData.filter((order) => {
        const orderDate = new Date(order.deliveryDateUtc);

        if (dateRange.from && dateRange.to) {
          // Handle range filtering
          return isWithinInterval(orderDate, {
            start: dateRange.from,
            end: dateRange.to,
          });
        } else if (dateRange.from) {
          // Handle single day filtering
          return isSameDay(orderDate, dateRange.from);
        } else {
          // No date range provided, return all data
          return true;
        }
      });

      setData(filteredData);
    } else {
      setData(originalData);
    }
  }, [dateRange, originalData]);
  // const handleDeleteSelected = async () => {
  //   // Get selected row IDs from rowSelection
  //   const selectedRowIds = Object.keys(rowSelection).filter(
  //     (key) => rowSelection[key]
  //   );

  //   // Extract employee IDs to delete based on selected rows
  //   const purchaseorderIdsToDelete = data
  //     .filter((_item, index) => selectedRowIds.includes(index.toString())) // Adjust this if rowSelection keys are different
  //     .map((item) => item.id); // Assuming `item.id` is the employee ID

  //   if (purchaseorderIdsToDelete.length === 0) {
  //     toast.error(t("Please select records to delete."));
  //     return;
  //   }

  //   try {
  //     // Delete selected employees from the API
  //     await Promise.all(
  //       purchaseorderIdsToDelete.map((id) =>
  //         axiosInstance.delete(`${API_URLS.BASE_URL}${API_URLS.PURCHASEORDER}/${id}`)
  //       )
  //     );

  //     // Remove deleted employees from the state
  //     const updatedData = data.filter(
  //       (item) => !purchaseorderIdsToDelete.includes(item.id)
  //     );
  //     setData(updatedData);
  //     setRowSelection({});

  //     // Update local storage with the updated data
  //     localStorage.setItem(
  //       PURCHASEORDER_STORAGE_KEY,
  //       JSON.stringify(updatedData)
  //     );

  //     toast.success(t("Selected records deleted successfully."));
  //   } catch (error) {
  //     console.error("Failed to delete records:", error);
  //     toast.error(t("Failed to delete selected records."));
  //   }
  // };
  const totalAmountSum = data.reduce((acc, order) => acc + order.totalCost, 0);
  const totalAmountDueSum = data.reduce(
    (acc, order) => acc + order.amountDue,
    0
  );

  const exportToExcel = async () => {
    // Get the selected rows or use the full data if no rows are selected
    const selectedRows = table
      .getFilteredSelectedRowModel()
      .rows.map((row) => row.original);
    const rowsToExport = selectedRows.length > 0 ? selectedRows : data;

    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("SaleOrderList");

    // Define columns based on the data structure
    worksheet.columns = [
      // { header: 'ID', key: 'id', width: 10 },
      { header: t("id"), key: "id", width: 30 },
      { header: t("supplierName"), key: "supplierName", width: 30 },
      { header: t("shopName"), key: "shopName", width: 30 },
      { header: t("totalcost"), key: "totalCost", width: 30 },
      { header: t("deliveryDateUtc"), key: "deliveryDateUtc", width: 30 },
      { header: t("amountDue"), key: "amountDue", width: 30 },

      // { header: "Status", key: "purchaseStatus", width: 30 },
    ];
    const orderStatusMap = {
      0: "Draft",
      1: "Processing",
      2: "Shipped",
      3: "Delivered",
      4: "Canceled",
    };

    // Map rowsToExport to match the worksheet columns
    const worksheetData = rowsToExport.map((purchaseorderlist) => {
      const status = purchaseorderlist.purchaseStatus;

      return {
        supplierName: purchaseorderlist.supplierName,
        totalCost: purchaseorderlist.totalCost,
        shopName: purchaseorderlist.shopName,
        deliveryDateUtc: purchaseorderlist.deliveryDateUtc,
        id: purchaseorderlist.id,
        purchaseStatus:
          status !== undefined && status in orderStatusMap
            ? orderStatusMap[status]
            : "Unknown", // Default to 'Unknown' if status is undefined or not in the map
      };
    });

    // Add rows to worksheet
    worksheet.addRows(worksheetData);

    // Generate Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const url = window.URL.createObjectURL(
      new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "purchaseorderlist.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // const handleDelete = async (id: string) => {
  //   try {
  //     await axiosInstance.delete(`${API_URLS.BASE_URL}${API_URLS.PURCHASEORDER}/${id}`);
  //     const updatedData = data.filter(
  //       (purchaseOrder) => purchaseOrder.id !== id
  //     );
  //     setOriginalData(updatedData);
  //     setData(updatedData);
  //     localStorage.setItem(
  //       PURCHASEORDER_STORAGE_KEY,
  //       JSON.stringify(updatedData)
  //     );
  //     toast.success(t("PurchaseOrder deleted successfully"));
  //   } catch (error) {
  //     console.error("Error deleting shop:", error);
  //     toast.error(t("Error deleting shop"));
  //   }
  // };

  const handlePrintClick = (invoice: PurchaseOrder) => {
    setSelectedInvoice(invoice);
    setTimeout(() => {
      handlePrint();
    }, 500); // Ensure the component is rendered
  };
  useEffect(() => {
    if (selectedInvoice && selectedInvoice.paymentCurrencyId) {
      // Find the currency based on PaymentCurrencyId
      const selectedCurrency = currencies.find(
        (currency) => currency.id === selectedInvoice.paymentCurrencyId
      );
      console.log(selectedInvoice.paymentCurrencyId);
      console.log(selectedCurrency);
      if (selectedCurrency) {
        // Pass the currency details to selectedCurrencyDetails
        setSelectedCurrencyDetails({
          id: selectedCurrency.id,
          name: selectedCurrency.name,
          symbol: selectedCurrency.symbol,
        });
      }
    }
  }, [selectedInvoice, currencies]);
  const handlePay = async () => {
    if (selectedPurchaseOrder && amountReceived) {
      try {
        const amountToPay = parseFloat(amountReceived);

        const cashAccountResponse = await axiosInstance.get(
          `${API_URLS.BASE_URL}${API_URLS.CashAccount}/${selectedPurchaseOrder.cashAccountId}`
        );
        const cashAccount = cashAccountResponse.data;
        const currentBalance = cashAccount.balance;
        const newBalance = currentBalance - amountToPay;

        const cashLedgerData = {
          transactionType: 4,
          amount: amountToPay,
          cashAccountId: selectedPurchaseOrder.cashAccountId,
          purchaseOrderId: selectedPurchaseOrder.id,
          note: `Paguar per porsine me, Nr Prosie: ${selectedPurchaseOrder.id}`,
        };

        await axiosInstance.post(
          `${API_URLS.BASE_URL}${API_URLS.CASHLEGDER}`,
          cashLedgerData
        );

        await axiosInstance.put(
          `${API_URLS.BASE_URL}${API_URLS.CashAccount}/${selectedPurchaseOrder.cashAccountId}`,
          {
            ...cashAccount,
            balance: newBalance,
          }
        );
        const updatedAccount = { ...cashAccount, balance: newBalance };
        localStorage.setItem(
          "selectedCashAccountPurchase",
          JSON.stringify(updatedAccount)
        );
        toast.success(
          "Payment processed and cash account balance updated successfully"
        );
        setShowDialog(false);
        setAmountReceived("");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } catch (error) {
        console.error("Error processing payment and updating balance:", error);
        toast.error(
          "Failed to process payment and update cash account balance"
        );
      }
    }
  };

  const handleAdd = () => {
    navigate(t("/purchaseorderpath"));
  };

  const getProductById = (id: number) => {
    return (
      products.find((product) => product.id === id) || {
        name: "",
        price: 0,
        uoMId: 0,
        barcode: "",
        vatRateId: 0,
      }
    );
  };

  const getVATRate = (vatRateId: number) => {
    const vatRate = vatRates.find((rate) => rate.id === vatRateId);
    console.log(`VAT Rate for ID ${vatRateId}:`, vatRate);
    return vatRate ? vatRate.rate : 0;
  };

  // const handleStatusChange = async (id: string, newStatus: PurchaseStatus) => {
  //   try {
  //     const purchaseOrder = data.find((order) => order.id === id);
  //     if (!purchaseOrder) {
  //       throw new Error("Purchase order not found");
  //     }

  //     const updatedPurchaseOrder = {
  //       ...purchaseOrder,
  //       purchaseStatus: newStatus,
  //     };

  //     const response = await axios.put(
  //       `${API_URLS.BASE_URL}${API_URLS.PURCHASEORDER}/${id}`,
  //       updatedPurchaseOrder
  //     );

  //     if (response.status === 200) {
  //       setData((prevData) =>
  //         prevData.map((order) =>
  //           order.id === id ? updatedPurchaseOrder : order
  //         )
  //       );

  //       toast.success(t("Purchase order status updated successfully"));
  //       window.location.reload();
  //     } else {
  //       console.error("Error updating purchase order status:", response.data);
  //       toast.error(t("Error updating purchase order status"));
  //     }
  //   } catch (error) {
  //     console.error("Error updating purchase order status:", error);
  //     toast.error(t("Error updating purchase order status"));
  //   }
  // };

  const handleUnpaidFilterChange = () => {
    setShowUnpaidOnly(!showUnpaidOnly);
  };

  useEffect(() => {
    const filteredData = showUnpaidOnly
      ? originalData.filter((order) => order.amountDue > 0)
      : originalData;

    setData(filteredData);
  }, [showUnpaidOnly, originalData]);

  const columns: ColumnDef<PurchaseOrder>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "id",
      header: t("id"),
      cell: ({ row }) => <div>{row.getValue("id")}</div>,
    },
    {
      accessorKey: "supplierName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("suppliername")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("supplierName")}</div>,
    },
    {
      accessorKey: "totalCost",
      header: t("totalcost"),
      cell: ({ row }) => {
        const totalcost = parseFloat(row.getValue("totalCost"));

        // Find the currency name from the currencies array based on baseCurrencyId
        const baseCurrency = currencies.find(
          (currency) => currency.id === baseCurrencyId
        );

        const formattedPrice = new Intl.NumberFormat(locale, {
          style: "decimal", // Format as a regular number (without currency symbol)
        }).format(totalcost);

        return (
          <div>
            {formattedPrice} {baseCurrency?.name || t("Unknown Currency")}{" "}
            {/* Append currency name */}
          </div>
        );
      },
    },
    {
      accessorKey: "amountDue",
      header: t("amountDue"),
      cell: ({ row }) => {
        const amountDue = parseFloat(row.getValue("amountDue"));

        // Find the currency name from the currencies array based on baseCurrencyId
        const baseCurrency = currencies.find(
          (currency) => currency.id === baseCurrencyId
        );

        const formattedPrice = new Intl.NumberFormat(locale, {
          style: "decimal", // Format as a regular number (without currency symbol)
        }).format(amountDue);

        return (
          <div>
            {formattedPrice} {baseCurrency?.name || t("Unknown Currency")}{" "}
            {/* Append currency name */}
          </div>
        );
      },
    },
    {
      accessorKey: "shopName",
      header: t("shopname"),
      cell: ({ row }) => <div>{row.getValue("shopName")}</div>,
    },
    {
      accessorKey: "createdByName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("createdBy")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("createdByName")}</div>,
    },
    {
      accessorKey: "updatedByName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("updatedBy")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("updatedByName")}</div>,
    },
    // {
    //   accessorKey: "purchaseStatus",
    //   header: ({ column }) => (
    //     <Button
    //       variant="ghost"
    //       onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
    //     >
    //       {t("Status")}
    //       <ArrowUpDown className="ml-2 h-4 w-4 icon" />
    //     </Button>
    //   ),
    //   cell: ({ row }) => (
    //     <select
    //       value={row.getValue("purchaseStatus")}
    //       onChange={(e) =>
    //         handleStatusChange(
    //           row.original.id,
    //           parseInt(e.target.value, 10) as PurchaseStatus
    //         )
    //       }
    //     >
    //       <option value={PurchaseStatus.Completed}>{t("Completed")}</option>
    //       <option value={PurchaseStatus.Ordered}>{t("Ordered")}</option>
    //       <option value={PurchaseStatus.Received}>{t("Received")}</option>
    //       <option value={PurchaseStatus.Canceled}>{t("Canceled")}</option>
    //     </select>
    //   ),
    // },
    {
      accessorKey: "deliveryDateUtc",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("deliverydate")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => {
        const date = new Date(row.getValue("deliveryDateUtc"));
        const formattedDate = date.toLocaleDateString();
        const formattedTime = date.toLocaleTimeString();
        return (
          <div>
            {formattedDate} {formattedTime}
          </div>
        );
      },
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4 icon" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>{t("action")}</DropdownMenuLabel>
            {row.original.amountDue > 0 && (
              <DropdownMenuItem onClick={() => handlePayClick(row.original)}>
                {t("Pay")}
              </DropdownMenuItem>
            )}
            <DropdownMenuItem onClick={() => handlePrintClick(row.original)}>
              {t("Print")}
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            {/* <DropdownMenuItem onClick={() => handleDelete(row.original.id)}>
              {t("delete")}
            </DropdownMenuItem> */}
          </DropdownMenuContent>
        </DropdownMenu>
      ),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pagination: {
        pageIndex: 0,
        pageSize: take,
      },
    },
  });

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }
  return (
    <div className="w-full">
      <div>
        <span className=" p-2 rounded-lg text-white bg-gradient-to-r from-cyan-950 to-cyan-500 ">
          {t("Total Amount")}: {totalAmountSum.toFixed(2)}
          {baseCurrencySymbol}
        </span>
        <span className="p-2 rounded-lg text-white bg-gradient-to-r from-cyan-950 to-cyan-500 sm:ml-4 sm:mt-0 mt-2">
          {t("Total Amount Due")}: {totalAmountDueSum.toFixed(2)}
          {baseCurrencySymbol}
        </span>
      </div>
      <div className="flex flex-wrap items-center py-4">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="mr-10">
              <Square3Stack3DIcon className="h-5 w-5" />
              {t("action")} <ChevronDown className="ml-2 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={exportToExcel}>
              {t("export")}
            </DropdownMenuItem>
            {/* <DropdownMenuItem onClick={handleDeleteSelected}>
              {t("delete")}
            </DropdownMenuItem>{" "} */}
          </DropdownMenuContent>
        </DropdownMenu>
        <Input
          placeholder={t("filtersuppliername")}
          value={
            (table.getColumn("supplierName")?.getFilterValue() as string) ?? ""
          }
          onChange={(event) =>
            table.getColumn("supplierName")?.setFilterValue(event.target.value)
          }
          style={{
            width: window.innerWidth <= 768 ? "55%" : "100%",
          }}
          className="max-w-sm"
        />
        <DatePickerWithRange
          className="ml-4"
          onSelect={setDateRange}
          selectedRange={dateRange}
        />
        <div className="ml-4 flex items-center">
          <Checkbox
            id="unpaidFilter"
            checked={showUnpaidOnly}
            onCheckedChange={handleUnpaidFilterChange}
          />
          <label htmlFor="unpaidFilter" className="ml-4">
            {t("Show Unpaid Sales")}
          </label>
        </div>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto">
              {t("columns")} <Filter className="ml-2 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => (
                <DropdownMenuCheckboxItem
                  key={column.id}
                  className="capitalize"
                  checked={column.getIsVisible()}
                  onCheckedChange={(value) => column.toggleVisibility(!!value)}
                >
                  {column.id}
                </DropdownMenuCheckboxItem>
              ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div
        className={`rounded-md border ${
          take > 10 ? "max-h-[800px] overflow-y-auto" : ""
        }`}
      >
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => {
                const isSelected =
                  row.getValue("id") === Number(selectedOrderNo);
                const rowClassName = isSelected ? "bg-blue-400" : "";

                return (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    style={{ cursor: "pointer" }}
                    className={rowClassName}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  {t("noresult")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex justify-end mb-4 mt-5">
        <label className="mr-2">{t("Rows per page")}:</label>
        <select
          value={take}
          onChange={handleTakeChange}
          className="border rounded-md p-1 bg-white text-black dark:bg-black dark:text-white"
        >
          <option
            value={10}
            className="bg-white text-black dark:bg-black dark:text-white"
          >
            10
          </option>
          <option
            value={20}
            className="bg-white text-black dark:bg-black dark:text-white"
          >
            20
          </option>
          <option
            value={50}
            className="bg-white text-black dark:bg-black dark:text-white"
          >
            50
          </option>
          <option
            value={100}
            className="bg-white text-black dark:bg-black dark:text-white"
          >
            100
          </option>
        </select>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {t("Showing")} {table.getRowModel().rows.length} {t("of")}{" "}
          {totalCount} {t("records")}.
        </div>
        <div className="flex-1 text-sm text-muted-foreground">
          {t("Page")} {pageNumber} {t("of")} {Math.ceil(totalCount / take)}
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            onClick={handlePreviousPage}
            disabled={pageNumber === 1} // Disable button on the first page
          >
            {t("Previous")}
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={handleNextPage}
            disabled={pageNumber * take >= totalCount} // Disable button when on the last page
          >
            {t("Next")}
          </Button>
        </div>
      </div>
      <div className="relative">
        <div className="fixed bottom-4 right-4 hidden md:block">
          <Button
            type="button"
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={handleAdd}
          >
            +
          </Button>
        </div>
      </div>
      <Dialog open={showDialog} onOpenChange={setShowDialog}>
        <DialogContent className="w-auto">
          {selectedPurchaseOrder && (
            <div className="amount-due-section">
              <p className="amount-due-text">
                {t("amountDue")} {selectedPurchaseOrder.amountDue}{" "}
                {baseCurrencySymbol}
              </p>{" "}
            </div>
          )}

          <NumericKeypad
            amountReceived={amountReceived}
            onInput={handleNumericInput}
            onClear={handleClear}
            onBackspace={handleBackspace}
            onEnter={handlePay}
            isPayDisabled={isPayDisabled}
          />
        </DialogContent>
      </Dialog>
      <div style={{ display: "none" }}>
        {selectedInvoice && (
          <InvoicePrint
            ref={printRef}
            orderProducts={selectedInvoice.purchaseOrderLines.map((line) => ({
              product: {
                id: line.productId.toString(),
                name: getProductById(line.productId).name || "",
                cost: line.cost,
                uoMId: line.uoMId,
                barcode: getProductById(line.productId).barcode || "",
                vatRateId: line.lineVatRateId || 0,
              },
              quantity: line.quantity,
            }))}
            customers={data.map((order) => ({
              id: order.supplierID || 0,
              name: order.supplierName || "Unknown Supplier",
              idNumber: "",
              address: "",
              city: "",
              country: "",
              phoneNumber: "",
              email: "",
            }))}
            selectedCustomer={(selectedInvoice.supplierID || "").toString()}
            calculateSubtotalAndDiscount={() => ({
              subtotal: (selectedInvoice.totalCost || 0).toFixed(2),
            })}
            calculateVAT={() => (selectedInvoice.vatAmount || 0).toFixed(2)}
            calculateTotal={() => (selectedInvoice.totalCost || 0).toFixed(2)}
            getVATRateName={() => ""}
            getVATRate={getVATRate}
            printerType={0}
            selectedCurrencyDetails={selectedCurrencyDetails}
            exchangeRate={selectedInvoice.paymentExchangeRate}
            calculateVATPerType={() => ({})}
            date={new Date(selectedInvoice.deliveryDateUtc).toLocaleString()}
            selectedCashAccount={selectedInvoice.cashAccountId}
          />
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default PurchaseOrderList;
