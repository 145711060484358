import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  Controller,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import CustomerDto from "@/models/CreateCustomerDto";
import "@/css/MobileScreen.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import CustomerCategoryForm from "../customerCategory/CustomerCategoryForm";
import { Customer } from "@/types/CustomerType";
import { CustomerFormProps } from "@/interfaces/CustomerInterfaces";
import { Category } from "@/interfaces/CustomerCategoryInterfaces";
import DataFetcher from "@/provider/DataFetcher";
import "@/css/LoadingCreate.css";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import { axiosInstance } from "@/lib/utils";

interface Country {
  name: string;
  code: string;
}

const CustomerForm: React.FC<CustomerFormProps> = ({
  mode,
  inDialog = false,
  onClose,
}) => {
  const { t } = useTranslation();
  const [countriesList, setCountriesList] = useState<Country[]>([]);

  const baseFormSchema = z.object({
    name: z
      .string()
      .min(1, { message: t("Name is required") })
      .max(200, { message: t("Name should be between 1 and 200 characters") }),
    phoneNumber: z.string().optional().nullable(),
    email: z.string().nullable().optional(), // Change here
    dateOfBirth: z.string().optional().nullable(),
    username: z.string().optional().nullable(),
    customerCategoryId: z.number().optional().nullable(),
    // loyaltyCard: z.string().optional(),
    // discount: z.number().optional(),
    // loyaltyPoints: z.number().optional().nullable(),
    idNumber: z.string().optional().nullable(),
    idType: z.number().optional().nullable(),
    address: z.string().optional(),
    city: z.string().optional(),
    country: z.string().optional(),
  });
  const formSchema = (mode: "create" | "edit") =>
    mode === "create"
      ? baseFormSchema.extend({
          // password: z.string().optional().nullable(),
        })
      : baseFormSchema;

  type FormValues = {
    // password?: string | null;
    name: string;
    phoneNumber?: string | null;
    email?: string | null;
    dateOfBirth: string;
    username: string;
    customerCategoryId?: number | null;
    // loyaltyCard?: string | null;
    // discount: number;
    // loyaltyPoints?: number;
    idNumber?: string | null;
    idType: number;
    address?: string | null;
    city?: string | null;
    country?: string | null;
  };

  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(false); // Initialize loading state

  const CUSTOMER_STORAGE_KEY = "customerData";
  const [isCustomerCategoryDialogOpen, setIsCustomerCategoryDialogOpen] =
    useState(false);
  const location = useLocation();
  const { id } = location.state || { id: localStorage.getItem("customerId") };
  const navigate = useNavigate();
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema(mode)),
    defaultValues: {
      name: "",
      phoneNumber: "",
      email: "",
      dateOfBirth: "",
      username: "",
      // password: "",
      customerCategoryId: undefined,
      // loyaltyCard: "",
      // discount: 0,
      // loyaltyPoints: 0,
      idNumber: "",
      idType: 0,
      city: "",
      country: "ALB",
    },
  });
  const CUSTOMERCAT_STORAGE_KEY = "customerCatData";

  // const { setValue, getValues } = methods;
  // const setDefaultValues = () => {
  //   if (categories.length > 0 && !getValues("customerCategoryId")) {
  //     setValue("customerCategoryId", null);
  //   }
  // };
  // useEffect(() => {
  //   setDefaultValues();
  // }, []);

  const handleCloseCustomerCategoryDialog = (newCategory?: {
    id: number;
    name: string;
  }) => {
    setIsCustomerCategoryDialogOpen(false);
    if (newCategory) {
      setCategories((prevCategories) => {
        const updatedCategories = [...prevCategories, newCategory];
        methods.setValue("customerCategoryId", newCategory.id);
        return updatedCategories;
      });
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await DataFetcher.getData<Category[]>(
        `${API_URLS.BASE_URL}${API_URLS.CUSTOMER_CATEGORY}`,
        CUSTOMERCAT_STORAGE_KEY
      );
      if (response) {
        setCategories(response);
      }
    };
    fetchCategories();

    if (id) {
      localStorage.setItem("customerId", id);
    }

    if (mode === "edit" && id) {
      const fetchCustomer = async () => {
        try {
          const customerData = await DataFetcher.getData<Customer>(
            `${API_URLS.BASE_URL}${API_URLS.CUSTOMER}/${id}`,
            CUSTOMER_STORAGE_KEY
          );

          if (customerData) {
            const formattedDateOfBirth = new Date(customerData.dateOfBirth)
              .toISOString()
              .split("T")[0];

            const idTypeAsNumber = parseInt(customerData.idType, 10);

            methods.reset({
              ...customerData,
              idType: isNaN(idTypeAsNumber) ? undefined : idTypeAsNumber,
              dateOfBirth: formattedDateOfBirth,
            });
          } else {
            throw new Error("Customer data not found");
          }
        } catch (error) {
          console.error("Error fetching customer:", error);
        }
      };

      fetchCustomer();
    }
  }, [id, mode, methods]);

  const updateLocalStorage = (updatedCustomer: Customer) => {
    // Parse the existing customers from localStorage or set it to an empty array if it doesn't exist
    let existingCustomers = JSON.parse(
      localStorage.getItem("customerData") || "[]"
    );

    // Ensure the parsed data is actually an array
    if (!Array.isArray(existingCustomers)) {
      console.warn(
        "Invalid customer data in localStorage, resetting to an empty array."
      );
      existingCustomers = [];
    }

    // Map through the existing customers and update the matching customer by ID
    const updatedCustomers = existingCustomers.map((customer: Customer) =>
      customer.id === updatedCustomer.id ? updatedCustomer : customer
    );

    // Update localStorage with the modified array of customers
    localStorage.setItem("customerData", JSON.stringify(updatedCustomers));
  };

  // const handleResetPassword = () => {
  //   navigate(t("/changepassword"));
  // };

  const idTypes = [
    { value: "1", label: t("NUIS") },
    { value: "2", label: t("Karte Identiteti") },
    { value: "3", label: t("Pasaporte") },
    { value: "4", label: t("VAT Number") },
    { value: "5", label: t("TAX Number") },
    { value: "6", label: t("Social Security Number") },
  ];
  const handleValidation = () => {
    const country = methods.getValues("country");
    const idType = methods.getValues("idType");

    const albanianIdTypes = ["1", "2", "3"];
    const foreignIdTypes = ["4", "5", "6"];

    const idTypeString = idType.toString();

    if (country === "ALB" && foreignIdTypes.includes(idTypeString)) {
      toast.error(t("error.albanianIdTypeRequired"));
      return false;
    }

    if (country !== "ALB" && albanianIdTypes.includes(idTypeString)) {
      toast.error(t("error.foreignIdTypeRequired"));
      return false;
    }

    return true;
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (!handleValidation()) return;
    setLoading(true); // Show loading spinner

    try {
      console.log("Submitted data:", data); // Log the submitted form data

      const customerData = new CustomerDto({
        ...data,
        email: data.email === "" ? null : data.email, // Add this line to ensure email can be null
        username: data.username === "" ? null : data.username,
        idType: data.idType ?? null,
        idNumber: data.idNumber === "" ? null : data.idNumber,
        // loyaltyCard:data.loyaltyCard === "" ? null : data.loyaltyCard,
        phoneNumber: data.phoneNumber === "" ? null : data.phoneNumber,
      });

      let successMessage = "";

      if (mode === "create") {
        const response = await axiosInstance.post(
          `${API_URLS.BASE_URL}${API_URLS.CUSTOMER}`,
          customerData
        );
        console.log("Customer created successfully:", response.data);
        successMessage = t("Customer created successfully");

        const existingCustomers = JSON.parse(
          localStorage.getItem("customerData") || "[]"
        );
        existingCustomers.push(response.data);
        localStorage.setItem("customerData", JSON.stringify(existingCustomers));

        if (inDialog && onClose) {
          onClose(response.data);
        }
      } else if (mode === "edit" && id) {
        const response = await axiosInstance.put(
          `${API_URLS.BASE_URL}${API_URLS.CUSTOMER}/${id}`,
          customerData
        );
        console.log("Customer updated successfully:", response.data);
        successMessage = t("Customer updated successfully");

        updateLocalStorage(response.data);

        if (inDialog && onClose) {
          onClose();
        }
      }

      methods.reset();
      localStorage.removeItem("customerId");

      if (!inDialog) {
        navigate(t("/listcustomerpath"));
      }

      // Show toast after navigation
      setTimeout(() => {
        if (successMessage) {
          toast.success(successMessage);
        }
      }, 250);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;

        if (status === 403) {
          toast.error(t("You do not have permission to perform this action."));
        } else if (status === 500) {
          toast.error(t("A server error occurred. Please try again later."));
        } else if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage =
              t(`errorCustomer.${err.errorMessage}`) ||
              t("errorCustomer.An error occurred");
            toast.error(translatedMessage);
          });
        } else {
          toast.error(t("An error occurred"));
        }
      } else {
        toast.error(t("An unexpected error occurred"));
      }
      console.error("Error submitting customer:", error);
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  const handleDialogSubmit = () => {
    methods.handleSubmit(onSubmit)();
  };
  const { setValue } = methods;

  useEffect(() => {
    // Register English locale for countries
    countries.registerLocale(enLocale);
    const countryData = Object.entries(
      countries.getNames("en", { select: "official" })
    ).map(([code, name]) => ({
      name,
      code: countries.alpha2ToAlpha3(code) || code,
    }));

    setCountriesList(countryData);
  }, []);

  return (
    <FormProvider {...methods}>
      <div className="flex justify-center items-center">
        {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
        <div className="w-full max-w-5xl p-6 rounded shadow-md">
          <h1 className="text-xl font-bold mb-6">
            {mode === "create" ? t("createcustomer") : t("editcustomer")}
          </h1>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleDialogSubmit();
            }}
          >
            <div className="grid grid-cols-2 gap-6">
              <fieldset className="border p-4 rounded mb-4">
                <FormItem>
                  <FormLabel>
                    {t("name")}
                    <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("name")}
                      type="search"
                      placeholder={t("putname")}
                      className="w-full"
                    />
                  </FormControl>
                  {methods.formState.errors.name && (
                    <FormMessage>
                      {methods.formState.errors.name.message}
                    </FormMessage>
                  )}
                </FormItem>
                <FormItem>
                  <FormLabel>{t("telnumber")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("phoneNumber")}
                      type="number"
                      pattern="\d*"
                      placeholder={t("puttelnumber")}
                      className="w-full"
                    />
                  </FormControl>
                </FormItem>
                <FormItem>
                  <FormLabel>Email </FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("email")}
                      type="email"
                      placeholder={t("putemail")}
                      className="w-full"
                    />
                  </FormControl>
                </FormItem>
                <div className="flex items-center gap-2">
                  <FormItem className="flex-1">
                    <FormLabel>{t("category")}</FormLabel>
                    <FormControl>
                      <Controller
                        name="customerCategoryId"
                        control={methods.control}
                        render={({ field }) => (
                          <Select
                            onValueChange={(value) =>
                              field.onChange(parseInt(value))
                            }
                            value={
                              field.value ? field.value.toString() : undefined
                            }
                          >
                            <SelectTrigger>
                              <SelectValue placeholder={t("putcategory")} />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                <SelectLabel>{t("category")}</SelectLabel>
                                {categories.map((category) => (
                                  <SelectItem
                                    key={category.id}
                                    value={category.id.toString()}
                                  >
                                    {category.name}
                                  </SelectItem>
                                ))}
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        )}
                      />
                    </FormControl>
                  </FormItem>
                  <Dialog
                    open={isCustomerCategoryDialogOpen}
                    onOpenChange={setIsCustomerCategoryDialogOpen}
                  >
                    <DialogTrigger asChild>
                      <Button
                        type="button"
                        className="bg-blue-500 text-white  rounded"
                        style={{
                          marginTop: "31px",
                          height: "35px",
                          width: "35px",
                        }} // Adds space above the button
                      >
                        +
                      </Button>
                    </DialogTrigger>
                    <DialogContent
                      style={
                        window.innerWidth <= 768
                          ? {
                              maxWidth: "300px", // Style for screens 768px or less
                              maxHeight: "80vh",
                              overflowY: "auto",
                            }
                          : window.innerWidth <= 999
                          ? {
                              maxWidth: "600px", // Style for screens between 769px and 999px
                              maxHeight: "80vh",
                              overflowY: "auto",
                            }
                          : {}
                      }
                    >
                      <CustomerCategoryForm
                        mode="create"
                        inDialog={true}
                        onClose={handleCloseCustomerCategoryDialog}
                      />
                    </DialogContent>
                  </Dialog>
                </div>
                <FormItem>
                  <FormLabel>{t("datebirthday")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("dateOfBirth")}
                      type="date"
                      className="w-full"
                    />
                  </FormControl>
                </FormItem>
                <FormItem>
                  <FormLabel>{t("username")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("username")}
                      type="search"
                      placeholder={t("putusername")}
                      className="w-full"
                    />
                  </FormControl>
                </FormItem>
              </fieldset>
              {/* <fieldset className="border p-4 rounded mb-4">
                <FormItem>
                  <FormLabel>{t("loyaltycard")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("loyaltyCard")}
                      type="search"
                      placeholder={t("putloyaltycard")}
                      className="w-full"
                    />
                  </FormControl>
                </FormItem>
                <FormItem>
                  <FormLabel>{t("disscount")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("discount", {
                        valueAsNumber: true,
                      })}
                      type="number"
                      placeholder={t("putdisscount")}
                      className="w-full"
                    />
                  </FormControl>
                </FormItem>
                <FormItem>
                  <FormLabel>{t("loyaltypoints")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("loyaltyPoints", {
                        valueAsNumber: true,
                      })}
                      type="number"
                      placeholder={t("putloyaltypoints")}
                      className="w-full"
                    />
                  </FormControl>
                </FormItem>
              </fieldset> */}
              <fieldset className="border p-4 rounded mb-4">
                <FormItem>
                  <FormLabel>{t("ID Type")}</FormLabel>
                  <div className="flex ">
                    <FormControl>
                      <Controller
                        name="idType"
                        control={methods.control}
                        render={({ field }) => (
                          <Select
                            onValueChange={(value) =>
                              field.onChange(Number(value))
                            } // Convert to number
                            value={field.value?.toString() ?? ""}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder={t("Select ID Type")} />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                {idTypes.map((idType) => (
                                  <SelectItem
                                    key={idType.value}
                                    value={idType.value}
                                  >
                                    {idType.label}
                                  </SelectItem>
                                ))}
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        )}
                      />
                    </FormControl>
                    <a className="tooltip">
                      <InformationCircleIcon className="h-9 w-9 pl-1" />
                      <span className="tooltiptext">
                        {t("tooltip.idTypeInfo")}
                      </span>
                    </a>
                  </div>
                  {methods.formState.errors.idType && (
                    <FormMessage>
                      {methods.formState.errors.idType.message}
                    </FormMessage>
                  )}
                </FormItem>
                <FormItem>
                  <FormLabel>{t("id")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("idNumber")}
                      type="search"
                      placeholder={t("putid")}
                      className="w-full"
                    />
                  </FormControl>
                </FormItem>
                <FormItem>
                  <FormLabel>{t("adresss")} </FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("address")}
                      type="search"
                      placeholder={t("putadresss")}
                      className="w-full"
                    />
                  </FormControl>
                </FormItem>
                <FormItem>
                  <FormLabel>{t("city")} </FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("city")}
                      type="search"
                      placeholder={t("putcity")}
                      className="w-full"
                    />
                  </FormControl>
                </FormItem>
                <FormItem>
                  <FormLabel>{t("Country")}</FormLabel>
                  <FormControl>
                    <Controller
                      name="country"
                      control={methods.control}
                      render={({ field }) => (
                        <Select
                          onValueChange={(value) => {
                            field.onChange(value);
                            setValue("idType", 0);
                          }}
                          value={field.value ?? ""}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder={t("Select Country")} />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {countriesList.map((country) => (
                                <SelectItem
                                  key={country.code}
                                  value={country.code}
                                >
                                  {country.name} ({country.code})
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      )}
                    />
                  </FormControl>
                </FormItem>
              </fieldset>
            </div>
            <div className="flex justify-center gap-4 mt-6">
              <Button
                type="button"
                onClick={handleDialogSubmit}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                {t("savelbtn")}
              </Button>
              <Button
                type="button"
                onClick={() =>
                  inDialog ? onClose?.() : navigate(t("/listcustomerpath"))
                }
                className="bg-gray-500 text-white px-4 py-2 rounded"
              >
                {t("canclelbtn")}
              </Button>
              {/* {mode === "edit" && <Button type="button">Reset Password</Button>} */}
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </FormProvider>
  );
};

export default CustomerForm;
