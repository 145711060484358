import React, { useEffect, useState } from "react";
import "@/css/thermalPrint.css";
import API_URLS from "@/config";
import { useTranslation } from "react-i18next";
// import axios from "axios";
import { useAuth } from "@/provider/authProvider";
import { useNavigate } from "react-router-dom";
import DataFetcher from "@/provider/DataFetcher";
import QRCode from "react-qr-code";
import { UoM } from "@/interfaces/UomInterfaces";

interface CompanyData {
  name: string;
  id: number;
  tin: string;
  employees: number;
  additionalInfo: string;
  city: string;
  country: string;
  phoneNumber: string;
  email: string;
  fieldOfActivity: string;
  fiscalizationCertificate: string;
  isSeasonal: boolean;
  subscriptionStartDateUtc: string;
  subscriptionEndDateUtc: string;
}

type Product = {
  id: string;
  barcode: string;
  price: number;
  uoMId: number;
  vatRateId: number;
  categoryId: string;
  vatRate: number;
  isRawMaterial: boolean;
  productId: number;
  name: string;
  quantity: number;
  unitPrice: number;
  total: number; // This is
};
interface Employee {
  id: string;
  name: string;
}

interface ExchangeRate {
  currency1Id: number;
  currency2Id: number;
  rate: number;
}

type InvoiceProps = {
  orderProducts: Product[];
  totalAmountExVat: number;
  vatAmount: number;
  totalAmountIncVat: number;
  getVATRate: (vatRateId: number) => number;
  getVATRateName: (vatRateId: number) => string;
  calculateVATPerType: () => Record<number, number>;
  tableId: string; // Add tableId to InvoiceProps
  // employeeID: string;
  employeeId: string;
  tablename: string; // Add tableId to InvoiceProps
  orderNo: undefined;
  fiscInvOrdNum: number | undefined;
  buCode: string | undefined;
  opCode: string | undefined;
  tcrCode: string | undefined;
  iic: string | undefined;
  fic: string | undefined;
};
interface BusinessConfigs {
  allowNegativeInventory: boolean;
  baseCurrencyId: number;
  secondCurrencyId: number; // Added second currency ID
  printerType: number;
}

type VatRate = {
  id: number;
  rate: number;
};

// Define TypeScript interface for a Currency
interface Currency {
  id: number;
  name: string;
  symbol: string;
}

const LocalPrint = React.forwardRef<HTMLDivElement, InvoiceProps>(
  (props, ref) => {
    const {
      orderProducts,
      totalAmountExVat,
      vatAmount,
      totalAmountIncVat,
      getVATRateName,
      calculateVATPerType = () => ({}),
      tablename,
      employeeId,
      orderNo,
      fiscInvOrdNum,
      buCode,
      opCode,
      tcrCode,
      iic,
      fic,
    } = props;

    const ExchangeRate_STORAGE_KEY = "exchangerateData";
    const EMPLOYEE_STORAGE_KEY = "employeeSalesData";
    const Currency_STORAGE_KEY = "currencyData";
    const COMPANY_DATA_STORAGE_KEY = "businessData";

    const [productsMap, setProductsMap] = useState<{ [key: string]: string }>(
      {}
    );
    const VAT_STORAGE_KEY = "vatData";
    const PRODUCT_STORAGE_KEY = "productData";
    const UOM_STORAGE_KEY = "uomData";
    const [, setVatRates] = useState<VatRate[]>([]);
    const [, setProductList] = useState<Product[]>([]);
    const [businessData, setBusinessData] = useState<CompanyData | null>(null);
    const [uoms, setUoms] = useState<UoM[]>([]);
    const [exchangeRates, setExchangeRates] = useState<ExchangeRate[]>([]);
    const [employee, setEmployee] = useState<Employee | null>(null);
    const [printerType, setPrinterType] = useState();
    const { setToken, token } = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [baseCurrencyName, setBaseCurrencyName] = useState("Lekë"); // Default currency
    const [baseCurrencySymbol, setBaseCurrencySymbol] = useState("L"); // Default currency symbol
    const [currencies, setCurrencies] = useState<Currency[]>([]); // Holds list of currencies
    const storedCertificate = JSON.parse(
      localStorage.getItem("certificate") || "{}"
    );
    useEffect(() => {
      const fetchCompanyData = async () => {
        try {
          // Fetch data directly from the API
          const response = await DataFetcher.getData<CompanyData>(
            `${API_URLS.BASE_URL}${API_URLS.LOGGEDBUSINESS}`,
            COMPANY_DATA_STORAGE_KEY
          );
          // Set the fetched data directly to state
          if (response && typeof response === "object") {
            setBusinessData(response);
          } else {
            console.warn("Unexpected data format received:", response);
          }
        } catch (error) {
          console.error("Error during fetchCompanyData", error);
        }

        // Fetch avatar from localStorage
      };

      const fetchEmployeeData = async () => {
        const employeeData = await DataFetcher.getData<Employee>(
          `${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}/${employeeId}`,
          EMPLOYEE_STORAGE_KEY
        );

        if (employeeData) {
          setEmployee(employeeData);
        } else {
          throw new Error("Failed to fetch employee data");
        }
      };
      const fetchVatRatesAndProducts = async () => {
        // First, fetch VAT rates
        const vatRatesResponse = await DataFetcher.getData<VatRate[]>(
          `${API_URLS.BASE_URL}${API_URLS.VAT}`,
          VAT_STORAGE_KEY
        );
        if (vatRatesResponse) {
          setVatRates(vatRatesResponse);

          // After VAT rates are fetched, fetch products
          const productsResponse = await DataFetcher.getData<Product[]>(
            `${API_URLS.BASE_URL}${API_URLS.PRODUCT}`,
            PRODUCT_STORAGE_KEY
          );
          if (productsResponse) {
            const filteredProducts = productsResponse.filter(
              (product) => !product.isRawMaterial
            );

            if (filteredProducts) {
              setProductList(
                filteredProducts.map((product) => ({
                  ...product,
                  quantity: 0,
                  vatRate:
                    vatRatesResponse.find(
                      (rate) => rate.id === product.vatRateId
                    )?.rate || 0,
                }))
              );
              //   //  // Create a map of productId to productName for easy lookups
              const productsById: { [key: string]: string } = {};
              filteredProducts.forEach((product) => {
                productsById[product.id] = product.name;
              });
              setProductsMap(productsById);
            }
          }
        }
      };

      fetchCompanyData();
      fetchEmployeeData();
      fetchVatRatesAndProducts();
    }, [token, setToken, navigate, employeeId]);

    useEffect(() => {
      // Fetch available currencies using DataFetcher with caching
      const fetchCurrencyData = async () => {
        try {
          const response = await DataFetcher.getData<Currency[]>(
            `${API_URLS.BASE_URL}${API_URLS.Currency}`, // API endpoint
            Currency_STORAGE_KEY // LocalStorage key
          );

          setCurrencies(response || []); // Set fetched currencies, default to an empty array if none

          // Retrieve business configs from localStorage
          const businessConfigs: BusinessConfigs = JSON.parse(
            localStorage.getItem("businessConfigs") || "{}"
          );

          const baseCurrencyId = businessConfigs.baseCurrencyId ?? 1; // Default to 1

          const baseCurrency = response?.find(
            (currency) => currency.id === baseCurrencyId
          );

          setBaseCurrencyName(baseCurrency?.name || "Leke"); // Default to "Leke"
          setBaseCurrencySymbol(baseCurrency?.symbol || "L"); // Default to "L"

          // Fetch exchange rates and find the correct one
          const exchangeRatesResponse = await DataFetcher.getData<
            ExchangeRate[]
          >(
            `${API_URLS.BASE_URL}${API_URLS.ExchangeRates}`,
            ExchangeRate_STORAGE_KEY
          );

          if (exchangeRatesResponse && exchangeRatesResponse.length > 0) {
            // Set all exchange rates
            setExchangeRates(exchangeRatesResponse);
          } else {
            console.error("Exchange rates data is empty or null.");
          }
        } catch (error) {
          console.error("Error fetching currencies or exchange rates:", error);
        }
      };

      fetchCurrencyData();
    }, [t]);
    const calculateTotalInCurrency = (rate: number) => {
      const totalInLek = parseFloat(totalAmountIncVat.toString());
      return (totalInLek / rate).toFixed(2);
    };

    const getCurrencyNameById = (currencyId: number) => {
      const currency = currencies.find(
        (currency) => currency.id === currencyId
      );
      return currency ? currency.name : "Unknown Currency";
    };
    useEffect(() => {
      const fetchUoms = async () => {
        try {
          const response = await DataFetcher.getData<UoM[]>(
            `${API_URLS.BASE_URL}${API_URLS.UOM}`,
            UOM_STORAGE_KEY
          );
          setUoms(response || []);
        } catch (error) {
          console.error("Error fetching UoMs:", error);
        }
      };

      fetchUoms();
    }, []);

    const getUoMName = (uoMId: number) => {
      const uom = uoms.find((u) => u.id === uoMId);
      return uom ? uom.name : t("Unknown UoM");
    };

    const truncateString = (str: string, num: number): string => {
      if (str.length > num) {
        return str.slice(0, num) + "...";
      }
      return str;
    };

    const toFiscDateTimeString = (): string => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");
      const timezoneOffsetHours = -(now.getTimezoneOffset() / 60);
      const timezoneFormatted = `%20${String(
        Math.abs(timezoneOffsetHours)
      ).padStart(2, "0")}:00`;

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezoneFormatted}`;
    };

    const qrString =
      storedCertificate.isDemo === false
        ? `$@"https://efiskalizimi-app.tatime.gov.al/invoice-check/#/verify?iic=${iic}&tin=${
            businessData?.tin
          }&crtd=${toFiscDateTimeString()}&prc=${totalAmountIncVat.toFixed(2)}"`
        : `$@"https://efiskalizimi-app-test.tatime.gov.al/invoice-check/#/verify?iic=${iic}&tin=J61912013U&crtd=${toFiscDateTimeString()}&prc=${totalAmountIncVat.toFixed(
            2
          )}"`;

    useEffect(() => {
      // Fetch the businessConfigs from localStorage
      const businessConfigs = JSON.parse(
        localStorage.getItem("businessConfigs") || "{}"
      );
      const storedPrinterType = businessConfigs?.printerType;
      setPrinterType(storedPrinterType);
      console.log(storedPrinterType);
    }, []);
    const invoiceClass =
      printerType === 1 ? "invoice-print-a4" : "invoice-print-thermal";
    const hasVat =
      JSON.parse(localStorage.getItem("businessConfigs") || "{}").hasVat ??
      false;
    const printOthersExchRat =
      JSON.parse(localStorage.getItem("printerConfig") || "{}")
        .displayExchangeRates ?? false;
    const showCustomerInfo =
      JSON.parse(localStorage.getItem("printerConfig") || "{}")
        .displayCustomerInfo ?? false;
    // const showAllCashAccount =
    //   JSON.parse(localStorage.getItem("businessConfigs") || "{}")
    //     .displayBankAccounts ?? false;
    const displayEmployeeName =
      JSON.parse(localStorage.getItem("printerConfig") || "{}")
        .displayEmployeeName ?? false;
    const invoiceHeader = JSON.parse(
      localStorage.getItem("printerConfig") || "{}"
    ).invoiceHeader;
    const invoiceFooter = JSON.parse(
      localStorage.getItem("printerConfig") || "{}"
    ).invoiceFooter;
    const displayHeader = true;
    const displayFooter = true;
    return (
      <div ref={ref} className={invoiceClass}>
        {printerType === 1 ? (
          <>
            <div className="invoice-header-a4">
              {displayHeader && <h3>{invoiceHeader}</h3>}
              <div className="company-details-a4">
                <h2>{businessData ? businessData.name : t("Company Name")}</h2>
                <p>{businessData ? businessData.tin : t("tin")}</p>
                <p>
                  {businessData ? businessData.city : t("AdrressCity")},{" "}
                  {businessData ? businessData.country : t("AdrressCountry")}
                </p>
                <p>{businessData ? businessData.email : t("Email")}</p>
                <p>
                  {businessData ? businessData.phoneNumber : t("phoneNumber")}
                </p>
              </div>
              <div className="invoice-details-a4">
                <p>
                  {t("INVOICENo")}: {orderNo}
                </p>
                <p>
                  {fiscInvOrdNum}/{new Date().getFullYear()}/{tcrCode}
                </p>
                <p>
                  {t("BusinessUnit")}: {buCode}
                </p>
                <p>
                  {t("OpCode")}: {opCode}
                </p>
                <p>
                  {t("Date")} : {new Date().toLocaleDateString()}
                </p>
              </div>
            </div>

            <div className="customer-details-a4">
              {showCustomerInfo && <p>{t("customer")}: Rasti</p>}
              {tablename &&
                tablename !== "Unknown Table" && ( // Hide if tablename is null, undefined, or equals "Unknown Table"
                  <p>
                    {t("Table")}: {tablename}
                  </p>
                )}
              {displayEmployeeName && (
                <p>
                  {t("employeeName")}: {employee ? employee.name : t("loading")}
                </p>
              )}
            </div>

            <table className="invoice-items-a4">
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>{t("ITEM")}</th>
                  <th style={{ width: "10%" }}>{t("QUANTITY")}</th>
                  <th style={{ width: "10%" }}>{t("uom")}</th>
                  <th style={{ width: "10%" }}>{t("UNITCOST")}</th>
                  <th style={{ width: "10%" }}>{t("VAT Amount")}</th>
                  <th style={{ width: "10%" }}>{t("Price no vat Amount")}</th>
                  <th style={{ width: "10%" }}>{t("Value no Vat")}</th>
                  <th style={{ width: "10%" }}>{t("VAT Amount Total")}</th>
                  {/* {hasDiscount && (
                  <th style={{ width: "5%" }}>{t("DISCOUNT")}</th>
                )} */}
                  <th style={{ width: "15%" }}>{t("TOTAL")}</th>
                </tr>
              </thead>
              <tbody>
                {orderProducts.map((product) => {
                  const vatRate = props.getVATRate(product.vatRateId) || 0; // Get VAT rate
                  const vat =
                    product.unitPrice - product.unitPrice / (1 + vatRate / 100); // Calculate VAT for one unit
                  const priceExclVAT = product.unitPrice - vat; // Price excluding VAT for one unit
                  const valueExclVAT = priceExclVAT * product.quantity; // Total value excluding VAT
                  const vatAmount = vat * product.quantity; // Total VAT amount
                  const totalValue = product.unitPrice * product.quantity; // Total value including VAT

                  return (
                    <tr key={product.productId}>
                      <td className="invoice-td">
                        {truncateString(
                          productsMap[product.productId] || "Unknown Product",
                          15
                        )}
                      </td>
                      <td className="invoice-td">{product.quantity}</td>
                      <td className="invoice-td">
                        {getUoMName(product.uoMId)}
                      </td>
                      <td className="invoice-td">
                        {product.unitPrice} {baseCurrencySymbol}
                      </td>
                      <td className="invoice-td">
                        {vat.toFixed(2)} {baseCurrencySymbol}
                      </td>
                      <td className="invoice-td">
                        {priceExclVAT.toFixed(2)} {baseCurrencySymbol}
                      </td>
                      <td className="invoice-td">
                        {valueExclVAT.toFixed(2)} {baseCurrencySymbol}
                      </td>
                      <td className="invoice-td">
                        {vatAmount.toFixed(2)} {baseCurrencySymbol}
                      </td>
                      <td className="invoice-td">
                        {totalValue.toFixed(2)} {baseCurrencySymbol}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="invoice-row">
              <div className="invoice-code-section">
                <div className="qr-code-section-a4">
                  <div className="qr-code-text">
                    <p>NSLF: {iic}</p>
                    <p>NIVF: {fic}</p>
                  </div>
                  <QRCode value={qrString} size={128} />
                </div>

                {displayFooter && (
                  <div className="invoice-footer-a4">
                    <p>{invoiceFooter}</p>
                    <p>
                      {businessData ? businessData.name : t("Company Name")}
                    </p>
                  </div>
                )}
              </div>
              <div className="invoice-summary-section">
                {hasVat && (
                  <table className="invoice-vats-a4">
                    <thead className="invoice-head-vats-a4">
                      <tr>
                        <th>{t("Vat Type")}</th>
                        <th>{t("Items")}</th>
                        <th>{t("Value no Vat")}</th>
                        <th>{t("VAT")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(calculateVATPerType()).map(
                        ([vatRateId, vatSum]) => {
                          const vatRateName =
                            getVATRateName(Number(vatRateId)) ||
                            t("Unknown VAT");
                          const vatItemCount = orderProducts.filter(
                            (product) => product.vatRateId === Number(vatRateId)
                          ).length;

                          const valueNoVatSum = orderProducts
                            .filter(
                              (product) =>
                                product.vatRateId === Number(vatRateId)
                            )
                            .reduce((sum, product) => {
                              const priceExclVAT =
                                product.unitPrice -
                                (product.unitPrice -
                                  product.unitPrice /
                                    (1 +
                                      props.getVATRate(product.vatRateId) /
                                        100));
                              return sum + priceExclVAT * product.quantity;
                            }, 0);

                          return (
                            <tr key={vatRateId}>
                              <th>
                                {t("VAT")} {vatRateName}
                              </th>
                              <td>{vatItemCount}</td>
                              <td>
                                {valueNoVatSum.toFixed(2)} {baseCurrencySymbol}
                              </td>
                              <td>
                                {Number(vatSum).toFixed(2)} {baseCurrencySymbol}
                              </td>
                            </tr>
                          );
                        }
                      )}
                      <tr>
                        <th>{t("VATTotal")}</th>
                        <td></td>
                        <td></td>
                        <td>
                          {vatAmount.toFixed(2)}
                          {baseCurrencySymbol}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
                <table className="invoice-totals-a4">
                  <tbody>
                    {hasVat && (
                      <tr>
                        <th>{t("Subtotal")}:</th>
                        <td>
                          {totalAmountExVat.toFixed(2)} {baseCurrencySymbol}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th> {t("TOTALlek")}</th>{" "}
                      <td>
                        {totalAmountIncVat.toFixed(2)} {baseCurrencySymbol}
                      </td>
                    </tr>
                    {exchangeRates.map((rate, index) => {
                      const currency1Name = getCurrencyNameById(
                        rate.currency1Id
                      );
                      const calculatedTotal = calculateTotalInCurrency(
                        rate.rate
                      );
                      const currency1Symbol = currencies.find(
                        (currency) => currency.name === currency1Name
                      )?.symbol;

                      return (
                        <tr key={index}>
                          <th>
                            {t("TOTALlek")}
                            {currency1Name}
                            {`( Exch.rate:${rate.rate})`}
                          </th>
                          <td>
                            {Number(calculatedTotal).toFixed(2)}{" "}
                            {currency1Symbol}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="invoice-header-thermal">
              {displayHeader && <h3>{invoiceHeader}</h3>}
              <div className="company-details-thermal">
                <h2>{businessData ? businessData.name : t("Company Name")}</h2>
                <p>{businessData ? businessData.tin : t("tin")}</p>
                <p>
                  {businessData ? businessData.city : t("AdrressCity")},
                  {businessData ? businessData.country : t("AdrressCountry")}
                </p>
                <p>{businessData ? businessData.email : t("Email")}</p>
                <p>
                  {businessData ? businessData.phoneNumber : t("phoneNumber")}
                </p>
              </div>
              <div className="invoice-details-thermal">
                <p>
                  {t("INVOICENo")} :{orderNo}
                </p>
                <p>
                  {fiscInvOrdNum}/{new Date().getFullYear()}/{tcrCode}
                </p>
                <p>
                  {t("BusinessUnit")}: {buCode}
                </p>
                <p>
                  {t("OpCode")}: {opCode}
                </p>
                <p>
                  {t("Date")} : {new Date().toLocaleDateString()}
                </p>
                <h2>{t("Fature Permbledhese")}</h2>
              </div>
            </div>
            <div className="customer-details-thermal">
              {showCustomerInfo && <p>{t("customer")}: Rasti</p>}
              {tablename &&
                tablename !== "Unknown Table" && ( // Hide if tablename is null, undefined, or equals "Unknown Table"
                  <p>
                    {t("Table")}: {tablename}
                  </p>
                )}
              {displayEmployeeName && (
                <p>
                  {t("employeeName")}: {employee ? employee.name : t("loading")}
                </p>
              )}
            </div>

            <div className="invoice-items-thermal">
              {/* <div className="invoice-item-thermal header">
                <span>{t("ITEMSales")}</span>
                <span>{t("QUANTITY")}</span>
                <span>{t("UNITCOST")}</span>
                <span>{t("TOTAL")}</span>
              </div> */}
              {orderProducts.map((product) => (
                <React.Fragment key={product.id}>
                  <div className="invoice-item-therma flex items-center justify-between">
                    <div className="flex items-center">
                      <span className="text-left">{product.quantity}</span>
                      <span className="text-left pl-1">
                        {getUoMName(product.uoMId)}
                      </span>
                      <span className="pl-1">x</span>
                      <span className="text-right pl-1 w-2/5">
                        {product.unitPrice.toFixed(2)}
                        {baseCurrencySymbol}
                      </span>
                    </div>
                    <div className="text-right w-3/5">
                      {(product.unitPrice * product.quantity).toFixed(2)}
                      {baseCurrencySymbol}
                    </div>
                  </div>
                  <div className="invoice-product-discount flex w-full">
                    <span className="w-1/2 product-name pl-3 text-left">
                      <td>
                        {truncateString(
                          productsMap[product.productId] || "Unknown Product",
                          15
                        )}
                      </td>
                    </span>
                  </div>
                </React.Fragment>
              ))}
            </div>
            <div className="invoice-totals-thermal">
              {hasVat && (
                <div className="totals-row-thermal">
                  <span>{t("Subtotal")}</span>
                  <span>
                    {totalAmountExVat.toFixed(2)}
                    {baseCurrencySymbol}
                  </span>
                </div>
              )}
              {hasVat &&
                Object.entries(calculateVATPerType()).map(
                  ([vatRateId, vatSum]) => {
                    console.log({ vatRateId, vatSum });

                    const vatRateName =
                      getVATRateName(Number(vatRateId)) || t("Unknown VAT");
                    return (
                      <div className="totals-row-thermal" key={vatRateId}>
                        <span>
                          <span>{t("VAT")}:</span>
                          {vatRateName}
                        </span>
                        <span>
                          {Number(vatSum).toFixed(2)} {baseCurrencySymbol}
                        </span>
                      </div>
                    );
                  }
                )}
              {hasVat && (
                <div className="totals-row-thermal">
                  <span>{t("VATTotal")}</span>
                  <span>
                    {vatAmount.toFixed(2)}
                    {baseCurrencySymbol}
                  </span>
                </div>
              )}
              <div className="totals-row-thermal">
                <span>
                  <strong>
                    {t("TOTALlek")}
                    {baseCurrencyName}
                  </strong>
                </span>
                <span>
                  <strong>
                    {totalAmountIncVat.toFixed(2)}
                    {baseCurrencySymbol}
                  </strong>
                </span>
              </div>
              {printOthersExchRat &&
                exchangeRates.map((rate, index) => {
                  const currency1Name = getCurrencyNameById(rate.currency1Id);
                  const calculatedTotal = calculateTotalInCurrency(rate.rate);
                  const currency1Symbol = currencies.find(
                    (currency) => currency.name === currency1Name
                  )?.symbol;

                  return (
                    <div className="totals-row-thermal" key={index}>
                      <span>
                        <strong>
                          {t("TOTALlek")}
                          {currency1Name}
                        </strong>{" "}
                        {`( Exch.rate:${rate.rate})`}
                      </span>
                      <span>
                        <strong>
                          {Number(calculatedTotal).toFixed(2)} {currency1Symbol}
                        </strong>
                      </span>
                    </div>
                  );
                })}
            </div>
            <div className="qr-code-section-thermal">
              <QRCode value={qrString} size={128} />
              <div className="qr-code-text">
                <p>NSLF: {iic}</p>
                <p>NIVF: {fic}</p>
              </div>
            </div>
            {displayFooter && (
              <div className="invoice-footer-thermal">
                <p>{invoiceFooter}</p>
                <p>{businessData ? businessData.name : t("Company Name")}</p>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
);
LocalPrint.displayName = "LocalPrint";

export default LocalPrint;
