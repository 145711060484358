import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ArrowUpDown, ChevronDown, Filter, MoreHorizontal } from "lucide-react";
import { Square3Stack3DIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@/css/loading.css";
import API_URLS from "@/config";
import { CashAccount } from "@/types/CashAccountType";
import { CurrencyType } from "@/types/CurrencyType";
import * as ExcelJS from "exceljs";
import DataFetcher from "@/provider/DataFetcher";
import { EmployeeType } from "@/types/EmployeeType";
import { axiosInstance } from "@/lib/utils";

const CashAccount_STORAGE_KEY = "cashAccountData";
const CURRENCY_STORAGE_KEY = "currencyData";
const EMPLOYEE_STORAGE_KEY = "employeeData";

const CashAccountList: React.FC = () => {
  const [data, setData] = useState<CashAccount[]>([]);
  const [loading, setLoading] = useState(true);
  const [sorting, setSorting] = useState<SortingState>([
    { id: "id", desc: true },
  ]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    id: false,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  type RowSelection = Record<string, boolean>;

  const [rowSelection, setRowSelection] = useState<RowSelection>({});
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCashAccounts = async () => {
      try {
        const cashAccountResponse = await DataFetcher.getData<CashAccount[]>(
          `${API_URLS.BASE_URL}${API_URLS.CashAccount}`,
          CashAccount_STORAGE_KEY,
          true
        );
        const cashAccounts: CashAccount[] = cashAccountResponse || [];
        localStorage.setItem(
          CashAccount_STORAGE_KEY,
          JSON.stringify(cashAccounts)
        );
        console.log(cashAccounts);
        return cashAccounts;
      } catch (error) {
        console.error("Error fetching cash accounts:", error);
        return [];
      }
    };

    const fetchCurrencies = async (): Promise<CurrencyType[]> => {
      try {
        return (
          (await DataFetcher.getData<CurrencyType[]>(
            `${API_URLS.BASE_URL}${API_URLS.Currency}`,
            CURRENCY_STORAGE_KEY
          )) || []
        );
      } catch (error) {
        console.error("Error fetching currencies:", error);
        return [];
      }
    };

    const fetchEmployees = async (): Promise<EmployeeType[]> => {
      try {
        return (
          (await DataFetcher.getData<EmployeeType[]>(
            `${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}`,
            EMPLOYEE_STORAGE_KEY
          )) || []
        );
      } catch (error) {
        console.error("Error fetching employees:", error);
        return [];
      }
    };

    const updateStateWithCurrencies = (
      cashAccounts: CashAccount[],
      currencies: CurrencyType[],
      employees: EmployeeType[]
    ) => {
      const currencyMap = currencies.reduce<Record<number, string>>(
        (acc, currency) => {
          acc[currency.id] = currency.name;
          return acc;
        },
        {}
      );

      const employeeMap = employees.reduce<Record<number, string>>(
        (acc, employee) => {
          acc[employee.id] = employee.name;
          return acc;
        },
        {}
      );

      const updatedCashAccounts = cashAccounts.map((account) => ({
        ...account,
        currencyName: currencyMap[account.currencyId] || "",
        employeeName: Array.isArray(account.owners)
          ? account.owners
              .map((owner) => employeeMap[owner.id] || "[Unknown]")
              .join(", ")
          : "",
        createdByName: account.createdBy
          ? employeeMap[account.createdBy] || "[Unknown]"
          : "",
        updatedByName: account.updatedBy
          ? employeeMap[account.updatedBy] || "[Unknown]"
          : "",
      }));
      setData(updatedCashAccounts);
    };

    const fetchData = async () => {
      try {
        const [cashAccounts, currencies, employees] = await Promise.all([
          fetchCashAccounts(),
          fetchCurrencies(),
          fetchEmployees(),
        ]);

        updateStateWithCurrencies(cashAccounts, currencies, employees);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleEdit = (id: string) => {
    navigate(t("/editcashaccount"), { state: { id: id } });
  };

  const handleDelete = async (id: string) => {
    try {
      await axiosInstance.delete(`${API_URLS.BASE_URL}${API_URLS.CashAccount}/${id}`);
      const updatedData = data.filter((cashaccount) => cashaccount.id !== id);
      setData(updatedData);
      localStorage.setItem(
        CashAccount_STORAGE_KEY,
        JSON.stringify(updatedData)
      );
      toast.success(t("CashAccount deleted successfully"));
    } catch (error) {
      console.error("Error deleting CashAccount:", error);
      toast.error(t("Error deleting cash account"));
    }
  };
  const handleDeleteSelected = async (): Promise<void> => {
  // Get selected row IDs from rowSelection
  const selectedRowIds = Object.keys(rowSelection).filter(
    (key) => rowSelection[key]
  );

  // Extract cash account IDs to delete based on selected rows
  const cashAccountToDelete: string[] = data
    .filter((_item, index) => selectedRowIds.includes(index.toString()))
    .map((item) => item.id); // Assuming `item.id` is the cash account ID

  // Check if any records are selected for deletion
  if (cashAccountToDelete.length === 0) {
    toast.error(t("Please select records to delete."));
    return;
  }

  // Delete selected cash accounts and handle results
  const deleteResults = await Promise.all(
    cashAccountToDelete.map(async (id: string) => {
      try {
        await axiosInstance.delete(`${API_URLS.BASE_URL}${API_URLS.CashAccount}/${id}`);
        return { id, success: true }; // Return success for this ID
      } catch (error) {
        console.error(`Failed to delete record with ID ${id}:`, error);
        return { id, success: false }; // Return failure for this ID
      }
    })
  );

  // Separate successful and failed deletions
  const successfullyDeletedIds = deleteResults
    .filter(result => result.success)
    .map(result => result.id);

  const failedToDeleteIds = deleteResults
    .filter(result => !result.success)
    .map(result => result.id);

  // Update state and local storage only for successfully deleted records
  const updatedData = data.filter(
    (item) => !successfullyDeletedIds.includes(item.id)
  );

  setData(updatedData);
  setRowSelection({});

  // Update local storage with the updated data
  localStorage.setItem(
    CashAccount_STORAGE_KEY,
    JSON.stringify(updatedData)
  );

  // Show success messages for successfully deleted records
  if (successfullyDeletedIds.length > 0) {
    toast.success(t("Selected records deleted successfully."));
  }

  // Optionally show error messages for failed deletions
  if (failedToDeleteIds.length > 0) {
  }
};


  const handleAdd = () => {
    navigate(t("/createcashaccount"));
  };

  const exportToExcel = async () => {
    // Get the selected rows or use the full data if no rows are selected
    const selectedRows = table
      .getFilteredSelectedRowModel()
      .rows.map((row) => row.original);
    const rowsToExport = selectedRows.length > 0 ? selectedRows : data;

    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("CashAccount");

    // Define columns based on the data structure
    worksheet.columns = [
      { header: t("name"), key: "name", width: 30 },
      { header: t("iban"), key: "iban", width: 30 },
      { header: t("swift"), key: "swift", width: 30 },
      { header: t("balance"), key: "balance", width: 30 },
      { header: t("currency"), key: "currencyName", width: 30 },
      { header: t("Bank or Cash"), key: "isBank", width: 30 },
            { header: t("accountOwner"), key: "employeeName", width: 30 },


    ];

    // Map rowsToExport to match the worksheet columns
    const worksheetData = rowsToExport.map((cashaccount) => ({
      name: cashaccount.name,
      iban: cashaccount.iban,
      swift: cashaccount.swift,
      balance: cashaccount.balance,
      currencyName: cashaccount.currencyName,
      isBank:cashaccount.isBank,
      employeeName:cashaccount.employeeName
    }));

    // Add rows to worksheet
    worksheet.addRows(worksheetData);

    // Generate Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const url = window.URL.createObjectURL(
      new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "cashaccount.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columns: ColumnDef<CashAccount>[] = [
   {
    id: "select",
    header: ({ table }) => {
        const isAllSelected = table.getIsAllPageRowsSelected();
        const isSomeSelected = table.getIsSomePageRowsSelected();

        // Check if there are rows to select
        const totalRows = table.getRowModel().rows.length;
        const rowsSelectable = totalRows > 1; // Enable select all only if more than 3 rows exist

        return (
            <Checkbox
                checked={rowsSelectable && (isAllSelected || (isSomeSelected && "indeterminate"))}
                onCheckedChange={(value) => {
                    if (rowsSelectable) {
                        table.toggleAllPageRowsSelected(!!value);
                    }
                }}
                aria-label="Select all"
                disabled={!rowsSelectable} // Disable checkbox if there are 3 or fewer rows
            />
        );
    },
    cell: ({ row }) => {
        const rowIndex = row.index; // Get the current row index

        // Remove checkbox for the first three rows
        if (rowIndex < 1) {
            return <div />; // Render an empty div for the first three rows
        }

        return (
            <Checkbox
                checked={row.getIsSelected()}
                onCheckedChange={(value) => row.toggleSelected(!!value)}
                aria-label="Select row"
            />
        );
    },
    enableSorting: false,
    enableHiding: false,
},
    {
      accessorKey: "id",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("ID")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("id")}</div>,
    },
    {
      accessorKey: "name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("nameaccount")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("name")}</div>,
    },
    {
      accessorKey: "iban",
      header: t("IBAN"),
      cell: ({ row }) => <div>{row.getValue("iban")}</div>,
    },
    {
      accessorKey: "swift",
      header: t("SWIFT"),
      cell: ({ row }) => <div>{row.getValue("swift")}</div>,
    },
    {
      accessorKey: "balance",
      header: t("Balance"),
      cell: ({ row }) => <div>{row.getValue("balance")}</div>,
    },
    {
      accessorKey: "tcrCode",
      header: t("TCRCODE"),
      cell: ({ row }) => <div>{row.getValue("tcrCode")}</div>,
    },

    
    {
      accessorKey: "isBank",
      header: t("Bank or Cash"),
      cell: ({ row }) => (
        <div>{row.original.isBank ? t("Bank") : t("Cash")}</div>
      ),
    },
    {
      accessorKey: "currencyName",
      header: t("currency"),
      cell: ({ row }) => <div>{row.getValue("currencyName")}</div>,
    },
    {
      accessorKey: "employeeName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("accountOwner")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("employeeName")}</div>,
    },
    {
      accessorKey: "createdByName",
      header: t("createdBy"),
      cell: ({ row }) => <div>{row.getValue("createdByName")}</div>,
    },
    {
      accessorKey: "updatedByName",
      header: t("updatedBy"),
      cell: ({ row }) => <div>{row.getValue("updatedByName")}</div>,
    },
    {
      id: "actions",
      enableHiding: false,
cell: ({ row }) => {
  const rowIndex = row.index; // Get the current row index

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="h-8 w-8 p-0">
          <span className="sr-only">Open menu</span>
          <MoreHorizontal className="h-4 w-4 icon" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{t("action")}</DropdownMenuLabel>
        <DropdownMenuItem onClick={() => handleEdit(row.original.id)}>
          {t("edit")}
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        {rowIndex >= 1 && (
          <DropdownMenuItem onClick={() => handleDelete(row.original.id)}>
            {t("delete")}
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
},

  },
];

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="flex flex-wrap items-center py-4">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
               <Button variant="outline" className="mr-10 flex items-center">
  <Square3Stack3DIcon className="h-5 w-5" />
  <span className="hidden sm:flex sm:items-center">
    {t("action")} <ChevronDown className="ml-2 h-4 w-4" />
  </span>
</Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={exportToExcel}>
              {t("export")}
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleDeleteSelected}>
              {t("delete")}
            </DropdownMenuItem>{" "}
          </DropdownMenuContent>
        </DropdownMenu>
        <Input
          placeholder={t("filtername")}
          value={(table.getColumn("name")?.getFilterValue() as string) ?? ""}
          onChange={(event) =>
            table.getColumn("name")?.setFilterValue(event.target.value)
          }
          style={{
            width: window.innerWidth <= 999 ? "45%" : "100%",
             fontSize: "16px"
          }}
          className="max-w-sm"
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto flex items-center justify-center">
  <span className="hidden md:block ">{t("columns")}</span>
  <Filter className="md:ml-2 h-4 w-4 mx-auto" />
</Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => (
                <DropdownMenuCheckboxItem
                  key={column.id}
                  className="capitalize"
                  checked={column.getIsVisible()}
                  onCheckedChange={(value) => column.toggleVisibility(!!value)}
                >
                  {column.id}
                </DropdownMenuCheckboxItem>
              ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                     <TableRow
  key={row.id}
  data-state={row.getIsSelected() && "selected"}
  onDoubleClick={() => {
    // Check if the row index is less than 2
   
      handleEdit(row.original.id);
    
  }}
  style={{ cursor: "pointer" }}
>
  {row.getVisibleCells().map((cell) => (
    <TableCell key={cell.id}>
      {flexRender(
        cell.column.columnDef.cell,
        cell.getContext()
      )}
    </TableCell>
  ))}
</TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  {t("noresult")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of{" "}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {t("previous")}
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {t("next")}
          </Button>
        </div>
      </div>
      <div className="fixed bottom-4 right-4 md:bottom-4 md:right-4">
        <Button
          type="button"
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={handleAdd}
        >
          +
        </Button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CashAccountList;
