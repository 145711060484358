import React, { createContext, useContext, useState, useCallback } from "react";
import DataFetcher from "@/provider/DataFetcher";
import API_URLS from "@/config";

interface DashboardData {
  salesByMonths: { month: number; amount: number }[];
  purchasesByMonths: { month: number; amount: number }[];
  fiveMostSoldProductIdsAllTime: {
    productId: number;
    totalQuantitySold: number;
  }[];
  yearlyRevenue: number;
  monthlyRevenue: number;
  weeklyRevenue: number;
  todayRevenue: number;
  yearlyExpenseOnProductPurchases: number;
  monthlyExpenseOnProductPurchases: number;
  weeklyExpenseOnProductPurchases: number;
  monthlyCostumerIds: number[];
  outOfStockProductIdsBasedOnWarehouse: number[];
}

interface DashboardContextProps {
  data: DashboardData | null;
  loading: boolean;
  error: string | null;
  refresh: () => void;
}

const DashboardContext = createContext<DashboardContextProps | undefined>(
  undefined
);

export const DashboardProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [data, setData] = useState<DashboardData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchDashboardData = async () => {
    setLoading(true);
    try {
      const response = await DataFetcher.getData<DashboardData>(
        `${API_URLS.BASE_URL}${API_URLS.Dashboard}`,
        "dashboardData",
        true
      );
      setData(response || null);
    } catch (err) {
      console.error("Error fetching dashboard data:", err);
      setError("Failed to load dashboard data");
    } finally {
      setLoading(false);
    }
  };
  const refresh = useCallback(() => {
    fetchDashboardData();
  }, []);

  return (
    <DashboardContext.Provider value={{ data, loading, error, refresh }}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error("useDashboard must be used within a DashboardProvider");
  }
  return context;
};
