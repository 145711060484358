class CreateEmployeeDto {
  name: string;
  email: string | null;
  roleId: number | null;
  shopId: number | null;
  username: string;
  salary: number;
  password?: string | null;
  phoneNumber?: string | null;
  id: any;
  opCode?: string | null;

  constructor(employee: {
    name?: string | null;
    email: string | null ;
    roleId?: number | null;
    shopId?: number | null;
    username?: string;
    salary?: number;
      opCode?: string | null;
    password?: string | null;
    phoneNumber?: string | null;
  }) {
    this.name = employee.name || "";
    this.email = employee.email ?? null;
    this.roleId = employee.roleId ?? null;  // Use nullish coalescing operator to default to null
    this.shopId = employee.shopId ?? null;  // Use nullish coalescing operator to default to null
    this.opCode = employee.opCode ?? null;  // Use nullish coalescing operator to default to null

    this.username = employee.username || "";
    this.salary = employee.salary || 0;
    this.password = employee.password || null;
    this.phoneNumber = employee.phoneNumber || null;
  }
}

export default CreateEmployeeDto;
