import React, { useEffect, useState } from "react";
import { useDashboard } from "@/provider/DashboardContext";
import { useTranslation } from "react-i18next";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import DataFetcher from "@/provider/DataFetcher";
import API_URLS from "@/config";

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#8884d8",
  "#82ca9d",
  "#a4de6c",
  "#d0ed57",
];

interface Product {
  id: number;
  name: string;
}

const MostSoldProducts: React.FC = () => {
  const { data: dashboardData, loading } = useDashboard();
  const { t } = useTranslation();
  const [chartData, setChartData] = useState<{ name: string; value: number }[]>(
    []
  );

  useEffect(() => {
    if (!loading && dashboardData) {
      const fetchChartData = async () => {
        try {
          const mostSoldProducts = dashboardData.fiveMostSoldProductIdsAllTime;
          const products = await fetchProductsFromServer();

          const chartData = mostSoldProducts.map((soldProduct: any) => {
            const product = products.find(
              (p: Product) => p.id === soldProduct.productId
            );
            return {
              name: product ? product.name : `Product ${soldProduct.productId}`,
              value: soldProduct.totalQuantitySold,
            };
          });

          setChartData(chartData);
        } catch (error) {
          console.error("Error fetching chart data:", error);
        }
      };

      fetchChartData();
    }
  }, [loading, dashboardData]);

  const fetchProductsFromServer = async (): Promise<Product[]> => {
    try {
      const response = await DataFetcher.getData<Product[]>(
        `${API_URLS.BASE_URL}${API_URLS.PRODUCT}`,
        "productData"
      );
      return response || [];
    } catch (error) {
      console.error("Error fetching products:", error);
      return [];
    }
  };

  if (loading) return <p>{t("Loading...")}</p>;
  if (!dashboardData) return <p>{t("No data available")}</p>;

  return (
    <div style={{ width: "100%", height: 300 }}>
      <h2>{t("Most Sold Products")}</h2>
      <ResponsiveContainer>
        <PieChart>
          <Pie data={chartData} dataKey="value" outerRadius={100} label>
            {chartData.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MostSoldProducts;
