import React, { useState, useEffect } from "react";
import CheckoutDialog from "./DialogBoxCheckout";
import DialogBoxCheckoutBank from "./DialogBoxCheckoutBank";
import { useTranslation } from "react-i18next";
import { Product } from "@/types/ProductType";
import { useReactToPrint } from "react-to-print";
import InvoiceThermalPrint from "@/componentsPos/InvocieThermalPrint";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/provider/authProvider";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import DataFetcher from "@/provider/DataFetcher";
import API_URLS from "@/config";
import InvoicePrint from "./InvoicePrint";
import { toast } from "react-toastify";

interface CheckoutPanelProps {
  totalAmount: number;
  onCheckout: (
    amountReceived: number,
    cashAccountId: number,
    paymentTermId?: number,
    exchangeRate?: number,
    selectedCurrencyDetails?: {
      id: number;
      name: string;
      symbol: string;
    },
    calculatedChangeAmount?: number,
    printInvoice?: () => void
  ) => void;
  onVoidInvoice: () => void;
  orderProducts: {
    product: Product;
    quantity: number;
    discount?: number;
    discountType?: "value" | "percentage";
  }[];
  customers: {
    id: string;
    name: string;
    idNumber: string;
    email: string;
    phoneNumber: string;
    address: string;
    city: string;
    country: string;
  }[];
  selectedCustomer: string;

  calculateSubtotalAndDiscount: () => {
    subtotal: string;
    totalOrderDiscount: string;
    totalProductDiscounts: string;
    totalDiscountSum: string;
  };
  calculateVAT: () => string;
  calculateTotal: () => string;
  getVATRateName: (vatRateId: number) => string;
  getVATRate: (vatRateId: number) => number;
  openDialogOnCheckout: boolean;
  disabled?: boolean;
  employeeID: string | null;
  selectedPaymentMethod: number;
  selectedCashAccount: number | null;
  onTotalDiscountChange: (
    discount: number,
    discountType: "value" | "percentage"
  ) => void;
  orderType: "salesOrder" | "purchaseOrder";
}

const CheckoutPanel: React.FC<CheckoutPanelProps> = ({
  totalAmount,
  onCheckout,
  onVoidInvoice,
  orderProducts,
  customers,
  selectedCustomer,
  calculateSubtotalAndDiscount,
  calculateVAT,
  calculateTotal,
  getVATRateName,
  getVATRate,
  openDialogOnCheckout,
  disabled = false,
  selectedPaymentMethod,
  selectedCashAccount,
  onTotalDiscountChange,
  orderType,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { employeeId } = useAuth();
  const invoiceRef = React.useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);
  const [isDiscountDialogOpen, setIsDiscountDialogOpen] = useState(false);
  const [discountValue, setDiscountValue] = useState(0);
  const [discountType, setDiscountType] = useState<"value" | "percentage">(
    "value"
  );
  const [exchangeRate, setExchangeRate] = useState<number>(1);
  const [isCashPayment, setIsCashPayment] = useState(true);
  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
  });
  const storedCertificate = JSON.parse(
    localStorage.getItem("certificate") || "{}"
  );
  const expiryDate = new Date(storedCertificate?.expiryDate);
  const today = new Date();

  const PAYMENT_METHOD_STORAGE_KEY = "paymentMethodData";

  const handleCheckout = () => {
    if (!selectedPaymentMethod || (isCashPayment && !selectedCashAccount)) {
      toast.error(
        t(
          "Please select a payment method and cash account before proceeding with checkout."
        )
      );
      return;
    }

    if (expiryDate < today) {
      navigate(t("/companycertificate"), {
        state: {
          toastMessage: t(
            "Your certificate has expired, please upload a new one."
          ),
        },
      });
      return;
    }

    if (openDialogOnCheckout) {
      setOpen(true);
    } else {
      onCheckout(
        totalAmount,
        0,
        undefined,
        exchangeRate,
        undefined,
        undefined,
        handlePrint
      );
    }
  };

  const handleProduct = () => {
    navigate(t("/prodlistpath"), { state: { fromPage: orderType } });
    console.log("Order Type:", orderType);
  };

  const handleApplyDiscount = () => {
    onTotalDiscountChange(discountValue, discountType);
    setIsDiscountDialogOpen(false);
    setDiscountValue(0);
  };

  const handleSaveBank = async (
    cashAccountId: number,
    paymentTermId: number
  ) => {
    onCheckout(
      totalAmount,
      cashAccountId,
      paymentTermId,
      undefined,
      undefined,
      undefined,
      handlePrint
    );
  };

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      const response = await DataFetcher.getData<any[]>(
        `${API_URLS.BASE_URL}${API_URLS.PaymentMethod}`,
        PAYMENT_METHOD_STORAGE_KEY
      );
      if (response) {
        const selectedPayment = response.find(
          (method) => method.id === selectedPaymentMethod
        );
        if (selectedPayment) {
          setIsCashPayment(selectedPayment.isCash);
        }
      }
    };
    if (selectedPaymentMethod) {
      fetchPaymentMethods();
    }
  }, [selectedPaymentMethod]);

  return (
    <div className="checkout-panel">
      <button
        className="btn btn-checkout"
        onClick={handleCheckout}
        disabled={disabled}
      >
        {t("Checkout")}
      </button>
      {openDialogOnCheckout && (
        <>
          {!isCashPayment ? (
            <DialogBoxCheckoutBank
              open={open}
              onClose={() => setOpen(false)}
              employeeID={employeeId}
              onSave={handleSaveBank}
              onPrint={handlePrint}
            />
          ) : (
            <CheckoutDialog
              open={open}
              onOpenChange={setOpen}
              totalAmount={totalAmount}
              onSubmit={(
                amountReceived,
                exchangeRate,
                selectedCurrencyDetails,
                handlePrint,
                calculatedChangeAmount
              ) => {
                setExchangeRate(exchangeRate); // Set the exchange rate when dialog closes
                onCheckout(
                  amountReceived,
                  0,
                  undefined,
                  exchangeRate,
                  selectedCurrencyDetails,
                  calculatedChangeAmount,
                  handlePrint // Use this for printing
                );
              }}
              orderProducts={orderProducts}
              customers={customers}
              selectedCustomer={selectedCustomer}
              calculateSubtotalAndDiscount={calculateSubtotalAndDiscount}
              calculateVAT={calculateVAT}
              calculateTotal={calculateTotal}
              getVATRateName={getVATRateName}
              getVATRate={getVATRate}
              onTotalDiscountChange={onTotalDiscountChange}
            />
          )}
        </>
      )}
      <button className="btn btn-lookup" onClick={handleProduct}>
        {t("ProductLookup")}
      </button>

      {orderType === "salesOrder" && (
        <button
          className="btn btn-discount"
          onClick={() => setIsDiscountDialogOpen(true)}
        >
          {t("ApplyDiscount")}
        </button>
      )}

      <Dialog
        open={isDiscountDialogOpen}
        onOpenChange={setIsDiscountDialogOpen}
      >
        <DialogContent>
          <DialogTitle>{t("Add Discount")}</DialogTitle>
          <DialogDescription>
            {t("Select discount type and value")}
          </DialogDescription>
          <div className="mb-4">
            <label className="mr-4">
              <input
                type="radio"
                value="value"
                checked={discountType === "value"}
                onChange={() => setDiscountType("value")}
              />
              {t("Value")}
            </label>
            <label>
              <input
                type="radio"
                value="percentage"
                checked={discountType === "percentage"}
                onChange={() => setDiscountType("percentage")}
              />
              {t("Percentage")}
            </label>
          </div>
          <Input
            type="number"
            placeholder={
              discountType === "value"
                ? t("Enter value")
                : t("Enter percentage")
            }
            value={discountValue}
            onChange={(e) => setDiscountValue(Number(e.target.value))}
            className="mb-4"
            autoFocus
          />
          <Button onClick={handleApplyDiscount} className="mr-2">
            {t("Apply")}
          </Button>
          <Button
            variant="ghost"
            onClick={() => setIsDiscountDialogOpen(false)}
          >
            {t("canclelbtn")}
          </Button>
        </DialogContent>
      </Dialog>

      <button className="btn btn-void" onClick={onVoidInvoice}>
        {t("VoidInvoice")}
      </button>
      <div style={{ display: "none" }}>
        <InvoiceThermalPrint
          ref={invoiceRef}
          orderProducts={orderProducts}
          customers={customers}
          selectedCustomer={selectedCustomer}
          calculateSubtotalAndDiscount={calculateSubtotalAndDiscount}
          calculateVAT={calculateVAT}
          calculateTotal={calculateTotal}
          getVATRateName={getVATRateName}
          getVATRate={getVATRate}
          orderNo={undefined}
          tableId={""}
          tablename={""}
          employeeName={""}
          selectedCurrencyDetails={null}
          totalInSelectedCurrency={""}
          exchangeRate={0}
          printerType={0}
          date={""}
          calculateVATPerType={() => ({})}
          selectedCashAccount={selectedCashAccount}
          fiscInvOrdNum={undefined}
          buCode={undefined}
          opCode={undefined}
          tcrCode={undefined}
          iic={undefined}
          fic={undefined}
        />
      </div>
      <div style={{ display: "none" }}>
        <InvoicePrint
          printerType={0}
          ref={invoiceRef}
          orderProducts={orderProducts}
          customers={[]}
          selectedCustomer={""}
          calculateSubtotalAndDiscount={calculateSubtotalAndDiscount}
          calculateVAT={calculateVAT}
          calculateTotal={calculateTotal}
          getVATRateName={getVATRateName}
          getVATRate={getVATRate}
          selectedCurrencyDetails={null}
          exchangeRate={exchangeRate} // employeeId={employeeId || ""}
          date={""}
          calculateVATPerType={() => ({})}
          selectedCashAccount={selectedCashAccount}
        />
      </div>
    </div>
  );
};

export default CheckoutPanel;
