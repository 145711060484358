import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { axiosInstance } from "@/lib/utils";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import "@/css/loading.css";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ArrowUpDown, ChevronDown, Filter, MoreHorizontal } from "lucide-react";
import { Square3Stack3DIcon } from "@heroicons/react/24/outline";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import { useReactToPrint } from "react-to-print";
import InvoiceThermalPrint from "@/componentsPos/InvocieThermalPrint";
// import SalesStatus from "@/models/SalesStatus";
import { isSameDay, isWithinInterval } from "date-fns";
import { DateRange } from "react-day-picker";
import { DatePickerWithRange } from "@/components/ui/DatePickerWithRange";
import NumericKeypad from "@/componentsPos/numericKeyboard";
import { SalesOrder } from "@/types/SalesOrderType";
import { ProductType } from "@/types/ProductType";
import { ShopType } from "@/types/ShopType";
import { EmployeeType } from "@/types/EmployeeType";
import { CustomerType } from "@/types/CustomerType";
import * as ExcelJS from "exceljs";
import DataFetcher from "@/provider/DataFetcher";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import "@/css/payCss.css";
import { useLocation } from "react-router-dom";
import FastOrder from "@/componentsPos/FastOrderprint";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";

const SALESORDER_STORAGE_KEY = "salesOrderData";
const SHOP_STORAGE_KEY = "shopData";
const TABLE_STORAGE_KEY = "tableData";
const EMPLOYEE_STORAGE_KEY = "employeeData";
const CUSTOMER_STORAGE_KEY = "customerData";
const PRODUCT_STORAGE_KEY = "productData";
const Currency_STORAGE_KEY = "currencyData";

export type Table = {
  id: number;
  name: string;
};
interface BusinessConfigs {
  allowNegativeInventory: boolean;
  baseCurrencyId: number;
  secondCurrencyId: number; // Added second currency ID
  printerType: number;
}

// Define TypeScript interface for a Currency
interface Currency {
  id: number;
  name: string;
  symbol: string;
}
interface RowData {
  amountDue: string | number;
  // Include other properties as needed based on your data structure
}

const SalesOrderList = () => {
  const [data, setData] = useState<SalesOrder[]>([]);
  const [originalData, setOriginalData] = useState<SalesOrder[]>([]);
  const [products, setProducts] = useState<ProductType[]>([]);
  const [loading, setLoading] = useState(true);
  const [sorting, setSorting] = useState<SortingState>([
    { id: "createDateUtc", desc: true },
  ]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  type RowSelection = Record<string, boolean>;
  const [, setTables] = useState<Table[]>([]);
  const [take, setTake] = useState(10); // Rows per page
  const [pageNumber, setPageNumber] = useState(1); // Current page number
  const [totalCount, setTotalCount] = useState(0); // Total number of records
  const [rowSelection, setRowSelection] = useState<RowSelection>({});
  const [selectedInvoice, setSelectedInvoice] = useState<SalesOrder | null>(
    null
  );
  const [selectedCurrencyDetails, setSelectedCurrencyDetails] = useState<{
    id: number;
    name: string;
    symbol: string;
  } | null>(null);
  const [currencies, setCurrencies] = useState<Currency[]>([]); // Holds list of currencies
  const [, setBaseCurrencyName] = useState("Lekë"); // Default currency
  const [baseCurrencySymbol, setBaseCurrencySymbol] = useState("L"); // Default currency symbol
  const { t } = useTranslation();
  const printRef = useRef<HTMLDivElement>(null);
  const [baseCurrencyId, setBaseCurrencyId] = useState<number>(1); // Default base currency
  const [locale] = useState<string>("sq-AL"); // Default locale
  const [amountReceived, setAmountReceived] = useState<string>(""); // Amount entered by the user
  const [showDialog, setShowDialog] = useState<boolean>(false); // Toggle NumericKeypad visibility
  const [isPayDisabled, setIsPayDisabled] = useState<boolean>(true);
  const [selectedSalesOrder, setSelectedSalesOrder] =
    useState<SalesOrder | null>(null);
  const [showUnpaidOnly, setShowUnpaidOnly] = useState(false);
  const location = useLocation();
  const selectedOrderNo = location.state?.selectedOrderNo;
  const handlePayClick = (salesOrder: SalesOrder) => {
    setSelectedSalesOrder(salesOrder);
    setShowDialog(true); // Show NumericKeypad when Pay is clicked
  };
  console.log(selectedOrderNo);

  const handleNumericInput = (value: string) => {
    const newAmount = amountReceived + value;
    const amountAsNumber = parseFloat(newAmount);

    if (selectedSalesOrder && amountAsNumber <= selectedSalesOrder.amountDue) {
      setAmountReceived(newAmount);
      setIsPayDisabled(false);
    } else {
      toast.error("The entered amount cannot exceed the due amount");
    }
  };

  const handleClear = () => {
    setAmountReceived("");
    setIsPayDisabled(true);
  };

  const handleBackspace = () => {
    setAmountReceived((prev) => prev.slice(0, -1));
    if (amountReceived.length <= 1) {
      setIsPayDisabled(true);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    const currentDate = new Date();
    const firstDayOfCurrentMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );

    setDateRange({ from: firstDayOfCurrentMonth, to: currentDate });
  }, []);
  useEffect(() => {
    const fetchSalesOrders = async (
      take: number,
      pageNumber: number,
      fromDate: Date,
      toDate: Date
    ) => {
      try {
        const formattedFromDate = format(fromDate, "MM-dd-yyyy");
        const formattedToDate = format(toDate, "MM-dd-yyyy");

        const response = await DataFetcher.getData<{
          salesOrders: SalesOrder[];
          totalCount: number;
        }>(
          `${API_URLS.BASE_URL}${API_URLS.SALESORDER}?pageNumber=${pageNumber}&take=${take}&fromDate=${formattedFromDate}&toDate=${formattedToDate}`,
          SALESORDER_STORAGE_KEY,
          true
        );
        console.log(response);

        if (response) {
          const salesOrders = response.salesOrders || [];
          setTotalCount(response.totalCount);
          return salesOrders;
        } else {
          return [];
        }
      } catch (error) {
        console.error("Error fetching sales orders:", error);
        toast.error(t("Failed to fetch sales orders."));
        return [];
      }
    };
    const fetchShops = async (): Promise<ShopType[]> => {
      try {
        return (
          (await DataFetcher.getData<ShopType[]>(
            `${API_URLS.BASE_URL}${API_URLS.SHOP}`,
            SHOP_STORAGE_KEY
          )) || []
        );
      } catch (error) {
        console.error("Error fetching shops:", error);
        return [];
      }
    };

    const fetchCustomers = async (): Promise<CustomerType[]> => {
      try {
        return (
          (await DataFetcher.getData<CustomerType[]>(
            `${API_URLS.BASE_URL}${API_URLS.CUSTOMER}`,
            CUSTOMER_STORAGE_KEY
          )) || []
        );
      } catch (error) {
        console.error("Error fetching customers:", error);
        return [];
      }
    };

    const fetchEmployees = async (): Promise<EmployeeType[]> => {
      try {
        return (
          (await DataFetcher.getData<EmployeeType[]>(
            `${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}`,
            EMPLOYEE_STORAGE_KEY
          )) || []
        );
      } catch (error) {
        console.error("Error fetching employees:", error);
        return [];
      }
    };
    const fetchTables = async (): Promise<Table[]> => {
      try {
        // Fetch table data from the API
        const tableResponse = await DataFetcher.getData<Table[]>(
          `${API_URLS.BASE_URL}${API_URLS.Tables}`, // API URL
          TABLE_STORAGE_KEY // Cache key for localStorage
        );

        // Handle the response
        const tables = tableResponse; // Assign the fetched data
        setTables(tables || []); // Update state with fetched tables or empty array

        // Return the tables
        return tables || []; // Return tables or empty array if null/undefined
      } catch (error) {
        console.error("Error fetching tables:", error);
        setTables([]); // Set state to an empty array in case of an error
        return []; // Return an empty array in case of error
      }
    };

    const fetchProductsFromServer = async (): Promise<ProductType[]> => {
      const productResponse = await DataFetcher.getData<ProductType[]>(
        `${API_URLS.BASE_URL}${API_URLS.PRODUCT}`,
        PRODUCT_STORAGE_KEY
      );
      const products = productResponse;
      setProducts(products || []);
      return products || [];
    };

    const updateStateWithSalesOrders = (
      salesOrders: SalesOrder[],
      shops: ShopType[],
      customers: CustomerType[],
      employees: EmployeeType[],
      _products: ProductType[],
      tables: Table[]
    ) => {
      const customerMap = customers.reduce<Record<number, string>>(
        (acc, customer) => {
          acc[customer.id] = customer.name;
          return acc;
        },
        {}
      );
      const employeeMap = Array.isArray(employees)
        ? employees.reduce<Record<number, string>>((acc, employee) => {
            acc[employee.id] = employee.name;
            return acc;
          }, {})
        : {};

      const shopMap = shops.reduce<Record<number, string>>((acc, shop) => {
        acc[shop.id] = shop.name;
        return acc;
      }, {});
      const tableMap = tables.reduce<Record<number, string>>((acc, table) => {
        acc[table.id] = table.name;
        return acc;
      }, {});

      const updatedSalesOrders = salesOrders.map((salesOrder) => ({
        ...salesOrder,
        shopName: salesOrder.shopId ? shopMap[salesOrder.shopId] || "" : "",
        tablename: salesOrder.tableId ? tableMap[salesOrder.tableId] || "" : "",

        customerName: salesOrder.customerId
          ? customerMap[salesOrder.customerId] || ""
          : "",
        employeeName: salesOrder.employeeId
          ? employeeMap[salesOrder.employeeId] || ""
          : "",
        createdByName: salesOrder.createdBy
          ? employeeMap[salesOrder.createdBy] || ""
          : "",
        updatedByName: salesOrder.updatedBy
          ? employeeMap[salesOrder.updatedBy] || ""
          : "",
      }));

      setData(updatedSalesOrders);
      setOriginalData(updatedSalesOrders);
    };

    const fetchData = async () => {
      if (!dateRange || !dateRange.from || !dateRange.to) return;
      try {
        const [salesOrders, shops, customers, employees, products, tables] =
          await Promise.all([
            fetchSalesOrders(take, pageNumber, dateRange.from, dateRange.to),
            fetchShops(),
            fetchCustomers(),
            fetchEmployees(),
            fetchProductsFromServer(),
            fetchTables(),
          ]);

        updateStateWithSalesOrders(
          salesOrders,
          shops,
          customers,
          employees,
          products,
          tables
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [take, pageNumber, dateRange, t]);
  useEffect(() => {
    // Fetch available currencies using DataFetcher with caching
    const fetchCurrencyData = async () => {
      try {
        const response = await DataFetcher.getData<Currency[]>(
          `${API_URLS.BASE_URL}${API_URLS.Currency}`, // API endpoint
          Currency_STORAGE_KEY // LocalStorage key
        );
        setCurrencies(response || []); // Set fetched currencies, default to an empty array if none

        // Find and set base currency name and symbol
        const businessConfigs: BusinessConfigs = JSON.parse(
          localStorage.getItem("businessConfigs") || "{}"
        );
        const baseCurrencyId = businessConfigs.baseCurrencyId ?? 1; // Default to 1 if not set
        const baseCurrency = response?.find(
          (currency) => currency.id === baseCurrencyId
        );

        setBaseCurrencyName(baseCurrency?.name || "Leke"); // Set base currency name, default to "Leke"
        setBaseCurrencySymbol(baseCurrency?.symbol || "L"); // Set base currency symbol, default to "L"
        setBaseCurrencyId(businessConfigs.baseCurrencyId ?? 1);
      } catch (error) {
        console.error("Error fetching currencies:", error);
        toast.error(t("Failed to load currencies"));
      }
    };

    fetchCurrencyData();
  }, [t]);

  const handleTakeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTake(Number(e.target.value));
    setPageNumber(1); // Reset to first page when rows per page is changed
  };

  const handleNextPage = () => {
    if (pageNumber * take < totalCount) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  useEffect(() => {
    if (dateRange) {
      const filteredData = originalData.filter((order) => {
        const orderDate = new Date(order.createDateUtc);

        if (dateRange.from && dateRange.to) {
          // Handle range filtering
          return isWithinInterval(orderDate, {
            start: dateRange.from,
            end: dateRange.to,
          });
        } else if (dateRange.from) {
          // Handle single day filtering
          return isSameDay(orderDate, dateRange.from);
        } else {
          // No date range provided, return all data
          return true;
        }
      });

      setData(filteredData);
    } else {
      setData(originalData);
    }
  }, [dateRange, originalData]);
  // const handleDeleteSelected = async () => {
  //   // Get selected row IDs from rowSelection
  //   const selectedRowIds = Object.keys(rowSelection).filter(
  //     (key) => rowSelection[key]
  //   );

  //   // Extract employee IDs to delete based on selected rows
  //   const saleorderIdsToDelete = data
  //     .filter((_item, index) => selectedRowIds.includes(index.toString())) // Adjust this if rowSelection keys are different
  //     .map((item) => item.id); // Assuming `item.id` is the employee ID

  //   if (saleorderIdsToDelete.length === 0) {
  //     toast.error(t("Please select records to delete."));
  //     return;
  //   }

  //   try {
  //     // Delete selected employees from the API
  //     await Promise.all(
  //       saleorderIdsToDelete.map((id) =>
  //         axios.delete(`${API_URLS.BASE_URL}${API_URLS.SALESORDER}/${id}`)
  //       )
  //     );

  //     // Remove deleted employees from the state
  //     const updatedData = data.filter(
  //       (item) => !saleorderIdsToDelete.includes(item.id)
  //     );
  //     setData(updatedData);
  //     setRowSelection({});

  //     // Update local storage with the updated data
  //     localStorage.setItem(SALESORDER_STORAGE_KEY, JSON.stringify(updatedData));

  //     toast.success(t("Selected records deleted successfully."));
  //   } catch (error) {
  //     console.error("Failed to delete records:", error);
  //     toast.error(t("Failed to delete selected records."));
  //   }
  // };
  const totalAmountSum = data.reduce(
    (acc, order) => acc + order.totalAmount,
    0
  );

  const totalAmountDueSum = data.reduce(
    (acc, order) => acc + order.amountDue,
    0
  );

  const exportToExcel = async () => {
    // Get the selected rows or use the full data if no rows are selected
    const selectedRows = table
      .getFilteredSelectedRowModel()
      .rows.map((row) => row.original);
    const rowsToExport = selectedRows.length > 0 ? selectedRows : data;

    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("SaleOrderList");

    // Define columns based on the data structure
    worksheet.columns = [
      // { header: 'ID', key: 'id', width: 10 },
      { header: t("orderNo"), key: "orderNo", width: 30 },
      { header: t("customerName"), key: "customerName", width: 30 },
      { header: t("employeeName"), key: "employeeName", width: 30 },
      { header: t("totalcost"), key: "totalAmount", width: 30 },
      { header: t("amountDue"), key: "amountDue", width: 30 },
    ];
    // const orderStatusMap = {
    //   0: "Completed",
    //   1: "Processing",
    //   2: "Shipped",
    //   3: "Delivered",
    //   4: "Canceled",
    // };

    // Map rowsToExport to match the worksheet columns
    const worksheetData = rowsToExport.map((saleorderlist) => ({
      customerName: saleorderlist.customerName,
      orderNo: saleorderlist.orderNo,
      shopName: saleorderlist.shopName,
      employeeName: saleorderlist.employeeName,
      totalAmount: saleorderlist.totalAmount,
      amountDue: saleorderlist.amountDue,

      // orderStatus: orderStatusMap[saleorderlist.orderStatus] || "Unknown", // Default to 'Unknown' if status is not in map
    }));

    // Add rows to worksheet
    worksheet.addRows(worksheetData);

    // Generate Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const url = window.URL.createObjectURL(
      new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "saleorderlist.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // const handleDelete = async (id: string) => {
  //   try {
  //     await axios.delete(`${API_URLS.BASE_URL}${API_URLS.SALESORDER}/${id}`);
  //     const updatedData = originalData.filter(
  //       (salesOrder) => salesOrder.id !== id
  //     );
  //     setOriginalData(updatedData);
  //     setData(updatedData);
  //     localStorage.setItem(SALESORDER_STORAGE_KEY, JSON.stringify(updatedData));
  //     toast.success(t("SalesOrder deleted successfully"));
  //   } catch (error) {
  //     console.error("Error deleting salesOrder:", error);
  //     toast.error(t("Error deleting salesOrder"));
  //   }
  // };

  // const handleAdd = () => {
  //   navigate(t("/salesorderpath"));
  // };

  const handlePrintClick = (invoice: SalesOrder) => {
    setSelectedInvoice(invoice);
    console.log(invoice);
    setTimeout(() => {
      if (hasTables) {
        // Use FastOrder for printing
        handlePrint();
      } else {
        // Use InvoiceThermalPrint for non-table invoices
        handlePrint();
      }
    }, 500); // Ensure the component is rendered
  };
  useEffect(() => {
    if (selectedInvoice && selectedInvoice.paymentCurrencyId) {
      // Find the currency based on PaymentCurrencyId
      const selectedCurrency = currencies.find(
        (currency) => currency.id === selectedInvoice.paymentCurrencyId
      );
      console.log(selectedInvoice.paymentCurrencyId);
      console.log(selectedCurrency);
      if (selectedCurrency) {
        // Pass the currency details to selectedCurrencyDetails
        setSelectedCurrencyDetails({
          id: selectedCurrency.id,
          name: selectedCurrency.name,
          symbol: selectedCurrency.symbol,
        });
      }
    }
  }, [selectedInvoice, currencies]);
  console.log(selectedCurrencyDetails);
  const handlePay = async () => {
    if (selectedSalesOrder && amountReceived) {
      try {
        const amountToPay = parseFloat(amountReceived);

        const cashAccountResponse = await axiosInstance.get(
          `${API_URLS.BASE_URL}${API_URLS.CashAccount}/${selectedSalesOrder.cashAccountId}`
        );
        const cashAccount = cashAccountResponse.data;
        const currentBalance = cashAccount.balance;
        const newBalance = currentBalance + amountToPay;

        const cashLedgerData = {
          transactionType: 3,
          amount: amountToPay,
          cashAccountId: selectedSalesOrder.cashAccountId,
          salesOrderId: selectedSalesOrder.id,
          note: `Marre pagese per shitjen me , Nr Prosie: ${selectedSalesOrder.orderNo}`,
        };

        await axiosInstance.post(
          `${API_URLS.BASE_URL}${API_URLS.CASHLEGDER}`,
          cashLedgerData
        );

        await axiosInstance.put(
          `${API_URLS.BASE_URL}${API_URLS.CashAccount}/${selectedSalesOrder.cashAccountId}`,
          {
            ...cashAccount,
            balance: newBalance,
          }
        );
        const updatedAccount = { ...cashAccount, balance: newBalance };
        localStorage.setItem(
          "selectedCashAccount",
          JSON.stringify(updatedAccount)
        );

        toast.success(
          "Payment processed and cash account balance updated successfully"
        );
        setShowDialog(false);
        setAmountReceived("");
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } catch (error) {
        console.error("Error processing payment and updating balance:", error);
        toast.error(
          "Failed to process payment and update cash account balance"
        );
      }
    }
  };

  // const getSalesStatusName = (status: SalesStatus) => {
  //   switch (status) {
  //     case SalesStatus.Draft:
  //       return "Draft";
  //     case SalesStatus.Proccessing:
  //       return "Proccessing";
  //     case SalesStatus.Delivered:
  //       return "Delivered";
  //     case SalesStatus.Canceled:
  //       return "Canceled";
  //     default:
  //       return "Unknown";
  //   }
  // };

  // const handleStatusChange = async (id: string, newStatus: SalesStatus) => {
  //   try {
  //     const salesOrder = data.find((order) => order.id === id);
  //     if (!salesOrder) {
  //       throw new Error("sales order not found");
  //     }

  //     const updatedSalesOrder = {
  //       ...salesOrder,
  //       orderStatus: newStatus,
  //     };

  //     const response = await axios.put(
  //       `${API_URLS.BASE_URL}${API_URLS.SALESORDER}/${id}`,
  //       updatedSalesOrder
  //     );

  //     if (response.status === 200) {
  //       setData((prevData) =>
  //         prevData.map((order) => (order.id === id ? updatedSalesOrder : order))
  //       );

  //       toast.success(t("Sales order status updated successfully"));
  //       window.location.reload();
  //     } else {
  //       console.error("Error updating sales order status:", response.data);
  //       toast.error(t("Error updating sales order status"));
  //     }
  //   } catch (error) {
  //     console.error("Error updating sales order status:", error);
  //     toast.error(t("Error updating sales order status"));
  //   }
  // };

  const handleUnpaidFilterChange = () => {
    setShowUnpaidOnly(!showUnpaidOnly);
  };

  useEffect(() => {
    const filteredData = showUnpaidOnly
      ? originalData.filter((order) => order.amountDue > 0)
      : originalData;

    setData(filteredData);
  }, [showUnpaidOnly, originalData]);
  const hasTables =
    JSON.parse(localStorage.getItem("businessConfigs") || "{}").hasTables ??
    false;
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    createDateUtc: false,
    updatedByName: false,
    createdByName: false,
    amountDue: !hasTables, // Set amountDue to false only if hasTables is true
  });

  const columns: ColumnDef<SalesOrder>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "orderNo",
      header: t("orderNo"),
      cell: ({ row }) => <div>{row.getValue("orderNo")}</div>,
    },
    ...(hasTables
      ? [
          {
            accessorKey: "tablename",
            header: ({
              column,
            }: {
              column: {
                toggleSorting: (isSorted: boolean) => void;
                getIsSorted: () => string | false;
              };
            }) => (
              <Button
                variant="ghost"
                onClick={() =>
                  column.toggleSorting(column.getIsSorted() === "asc")
                }
              >
                {t("Table")}
                <ArrowUpDown className="ml-2 h-4 w-4 icon" />
              </Button>
            ),
            cell: ({
              row,
            }: {
              row: { getValue: (key: string) => any; original: RowData };
            }) => {
              return <div>{row.getValue("tablename")}</div>;
            },
          },
        ]
      : []),

    {
      accessorKey: "totalAmount",
      header: t("totalcost"),
      cell: ({ row }) => {
        const totalcost = parseFloat(row.getValue("totalAmount"));

        // Find the currency name from the currencies array based on baseCurrencyId
        const baseCurrency = currencies.find(
          (currency) => currency.id === baseCurrencyId
        );

        const formattedPrice = new Intl.NumberFormat(locale, {
          style: "decimal", // Format as a regular number (without currency symbol)
        }).format(totalcost);

        return (
          <div>
            {formattedPrice} {baseCurrency?.name || t("Unknown Currency")}{" "}
            {/* Append currency name */}
          </div>
        );
      },
    },

    {
      accessorKey: "amountDue",
      header: t("amountDue"),
      cell: ({
        row,
      }: {
        row: { getValue: (key: string) => any; original: RowData };
      }) => {
        const amountDue = parseFloat(row.getValue("amountDue"));

        // Check if amountDue exists and is a valid number
        if (isNaN(amountDue) || amountDue === 0) {
          return null; // If amountDue doesn't exist or is zero, hide the cell
        }

        // Find the currency name from the currencies array based on baseCurrencyId
        const baseCurrency = currencies.find(
          (currency) => currency.id === baseCurrencyId
        );

        const formattedPrice = new Intl.NumberFormat(locale, {
          style: "decimal", // Format as a regular number (without currency symbol)
        }).format(amountDue);

        return (
          <div>
            {formattedPrice} {baseCurrency?.name || t("Unknown Currency")}
          </div>
        );
      },
    },

    // If 'hasTables' is true, this column is excluded

    ...(!hasTables
      ? [
          {
            accessorKey: "customerName",
            header: ({
              column,
            }: {
              column: {
                toggleSorting: (isSorted: boolean) => void;
                getIsSorted: () => string | false;
              };
            }) => (
              <Button
                variant="ghost"
                onClick={() =>
                  column.toggleSorting(column.getIsSorted() === "asc")
                }
              >
                {t("customername")}
                <ArrowUpDown className="ml-2 h-4 w-4 icon" />
              </Button>
            ),
            cell: ({
              row,
            }: {
              row: { getValue: (key: string) => any; original: RowData };
            }) => {
              return <div>{row.getValue("customerName")}</div>;
            },
          },
        ]
      : []),
    {
      accessorKey: "employeeName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("employeeName")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("employeeName")}</div>,
    },
    {
      accessorKey: "createdByName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("createdBy")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("createdByName")}</div>,
    },
    {
      accessorKey: "updatedByName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("updatedBy")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("updatedByName")}</div>,
    },
    {
      accessorKey: "fic",
      header: t("Statusi Fiskalizimt"),
      cell: ({ row }) => {
        const value = row.getValue("fic");
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            {value ? (
              <CheckCircleIcon className="text-green-500 h-6 w-6" /> // Success icon
            ) : (
              <XCircleIcon className="text-red-500 h-6 w-6" /> // Error icon
            )}
            {/* <span>{value ? t("Yes") : t("No")}</span> */}
          </div>
        );
      },
    },
    // {
    //   accessorKey: "shopName",
    //   header: t("shopname"),
    //   cell: ({ row }) => <div>{row.getValue("shopName")}</div>,
    // },
    // {
    //   accessorKey: "orderStatus",
    //   header: ({ column }) => (
    //     <Button
    //       variant="ghost"
    //       onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
    //     >
    //       {t("Status")}
    //       <ArrowUpDown className="ml-2 h-4 w-4 icon" />
    //     </Button>
    //   ),
    //   cell: ({ row }) => (
    //     <select
    //       value={row.getValue("orderStatus")}
    //       onChange={(e) =>
    //         handleStatusChange(
    //           row.original.id,
    //           parseInt(e.target.value, 10) as SalesStatus
    //         )
    //       }
    //     >
    //       <option value={SalesStatus.Completed}>{t("Completed")}</option>
    //       <option value={SalesStatus.Proccessing}>{t("Proccessing")}</option>
    //       <option value={SalesStatus.Delivered}>{t("Delivered")}</option>
    //       <option value={SalesStatus.Canceled}>{t("Canceled")}</option>
    //     </select>
    //   ),
    // },
    {
      accessorKey: "createDateUtc",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("createdate")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => {
        const date = new Date(row.getValue("createDateUtc"));
        const formattedDate = date.toLocaleDateString();
        const formattedTime = date.toLocaleTimeString();
        return (
          <div>
            {formattedDate} {formattedTime}
          </div>
        );
      },
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4 icon" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>{t("action")}</DropdownMenuLabel>

            {row.original.amountDue > 0 && !hasTables && (
              <DropdownMenuItem onClick={() => handlePayClick(row.original)}>
                {t("Pay")}
              </DropdownMenuItem>
            )}
            <DropdownMenuItem onClick={() => handlePrintClick(row.original)}>
              {t("Print")}
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            {/* <DropdownMenuItem onClick={() => handleDelete(row.original.id)}>
              {t("delete")}
            </DropdownMenuItem> */}
          </DropdownMenuContent>
        </DropdownMenu>
      ),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pagination: {
        pageIndex: 0,
        pageSize: take,
      },
    },
  });

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }
  const isMobile = window.innerWidth <= 999; // Adjust the breakpoint if needed
  const isDesktop = window.innerWidth > 999; // Adjust the breakpoint if needed

  const getProductById = (id: number) => {
    return (
      products.find((product) => product.id === id) || {
        name: "",
        price: 0,
        uoMId: 0,
        barcode: "",
      }
    );
  };

  return (
    <div className="w-full">
      {!isDesktop && (
        <DatePickerWithRange
          className="pb-8"
          onSelect={setDateRange}
          selectedRange={dateRange}
        />
      )}{" "}
      <div className="flex flex-col sm:flex-row">
        <span className="p-2 rounded-lg text-white bg-gradient-to-r from-cyan-950 to-cyan-500">
          {t("Total Amount")}: {totalAmountSum.toFixed(2)}
          {baseCurrencySymbol}
        </span>
        {!hasTables && (
          <span className="p-2 rounded-lg text-white bg-gradient-to-r from-cyan-950 to-cyan-500 sm:ml-4 sm:mt-0 mt-2">
            {t("Total Amount Due")}: {totalAmountDueSum.toFixed(2)}
            {baseCurrencySymbol}
          </span>
        )}
      </div>
      <div className="flex flex-wrap items-center py-4">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="mr-10 flex items-center">
              <Square3Stack3DIcon className="h-5 w-5" />
              <span className="hidden sm:flex sm:items-center">
                {t("action")} <ChevronDown className="ml-2 h-4 w-4" />
              </span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={exportToExcel}>
              {t("export")}
            </DropdownMenuItem>{" "}
            {/* <DropdownMenuItem onClick={handleDeleteSelected}>
              {t("delete")}
            </DropdownMenuItem>{" "} */}
            {!hasTables && (
              <DropdownMenuItem
                className=" max-[1000px]:block hidden min-[1001px]:hidden" /* Visible below 1000px, hidden on 1000px and larger */
                onClick={handleUnpaidFilterChange}
              >
                {showUnpaidOnly ? t("Show All Sales") : t("Show Unpaid Sales")}
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
        {!hasTables && (
          <Input
            placeholder={t("filtercustomername")}
            value={
              (table.getColumn("customerName")?.getFilterValue() as string) ??
              ""
            }
            onChange={(event) =>
              table
                .getColumn("customerName")
                ?.setFilterValue(event.target.value)
            }
            style={{
              width: window.innerWidth <= 999 ? "45%" : "100%",
              fontSize: "16px", // Ensures the font size is 16px to prevent zoom on mobile
            }}
            className="max-w-sm"
          />
        )}
        {hasTables && (
          <Input
            placeholder={t("Filter Tabel Name")}
            value={
              (table.getColumn("tablename")?.getFilterValue() as string) ?? ""
            }
            onChange={(event) => {
              const filterValue = event.target.value;
              table.getColumn("tablename")?.setFilterValue(filterValue); // Update filter value
            }}
            style={{
              width: window.innerWidth <= 999 ? "45%" : "100%",
              fontSize: "16px", // Prevent zoom on mobile
            }}
            className="max-w-sm"
          />
        )}

        {!isMobile && (
          <DatePickerWithRange
            className="ml-4"
            onSelect={setDateRange}
            selectedRange={dateRange}
          />
        )}
        {!hasTables && (
          <Checkbox
            className="ml-8 hidden min-[1000px]:block" /* Hidden until 999px, visible from 1000px and above */
            id="unpaidFilter"
            checked={showUnpaidOnly}
            onCheckedChange={handleUnpaidFilterChange}
          />
        )}
        {!hasTables && (
          <label
            htmlFor="unpaidFilter"
            className="ml-4 hidden min-[1000px]:block"
          >
            {t("Show Unpaid Sales")}
          </label>
        )}
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              className="ml-auto flex items-center justify-center"
            >
              <span className="hidden md:block ">{t("columns")}</span>
              <Filter className="md:ml-2 h-4 w-4 mx-auto" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => (
                <DropdownMenuCheckboxItem
                  key={column.id}
                  className="capitalize"
                  checked={column.getIsVisible()}
                  onCheckedChange={(value) => column.toggleVisibility(!!value)}
                >
                  {column.id}
                </DropdownMenuCheckboxItem>
              ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div
        className={`rounded-md border ${
          take > 10 ? "max-h-[800px] overflow-y-auto" : ""
        }`}
      >
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>

          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => {
                const isSelected =
                  row.getValue("orderNo") === Number(selectedOrderNo);
                const rowClassName = isSelected ? "bg-blue-400" : "";
                return (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    style={{ cursor: "pointer" }}
                    className={rowClassName}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  {t("noresult")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex justify-end mb-4 mt-5">
        <label className="mr-2">{t("Rows per page")}:</label>
        <select
          value={take}
          onChange={handleTakeChange}
          className="border rounded-md p-1 bg-white text-black dark:bg-black dark:text-white"
        >
          <option
            value={10}
            className="bg-white text-black dark:bg-black dark:text-white"
          >
            10
          </option>
          <option
            value={20}
            className="bg-white text-black dark:bg-black dark:text-white"
          >
            20
          </option>
          <option
            value={50}
            className="bg-white text-black dark:bg-black dark:text-white"
          >
            50
          </option>
          <option
            value={100}
            className="bg-white text-black dark:bg-black dark:text-white"
          >
            100
          </option>
        </select>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-between space-y-2 md:space-y-0 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {t("Showing")} {table.getRowModel().rows.length} {t("of")}{" "}
          {totalCount} {t("records")}.
        </div>

        <div className="flex-1 text-sm text-muted-foreground text-center md:text-left">
          {t("Page")} {pageNumber} {t("of")} {Math.ceil(totalCount / take)}
        </div>

        <div className="flex space-x-2">
          <Button
            variant="outline"
            onClick={handlePreviousPage}
            disabled={pageNumber === 1} // Disable button on the first page
          >
            {t("Previous")}
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={handleNextPage}
            disabled={pageNumber * take >= totalCount} // Disable button when on the last page
          >
            {t("Next")}
          </Button>
        </div>
      </div>
      {/* <div className="relative">
        <div className="fixed bottom-4 right-4 md:bottom-4 md:right-4">
          <Button
            type="button"
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={handleAdd}
          >
            +
          </Button>
        </div>
      </div> */}
      <Dialog open={showDialog} onOpenChange={setShowDialog}>
        <DialogContent className="w-auto">
          {selectedSalesOrder && (
            <div className="amount-due-section">
              <p className="amount-due-text">
                {t("amountDue")} {selectedSalesOrder.amountDue}{" "}
                {baseCurrencySymbol}
              </p>{" "}
            </div>
          )}

          <NumericKeypad
            amountReceived={amountReceived}
            onInput={handleNumericInput}
            onClear={handleClear}
            onBackspace={handleBackspace}
            onEnter={handlePay}
            isPayDisabled={isPayDisabled}
          />
        </DialogContent>
      </Dialog>
      <div style={{ display: "none" }}>
        {selectedInvoice &&
          (hasTables ? (
            <FastOrder
              ref={printRef}
              orderProducts={selectedInvoice.salesOrderLines.map((line) => ({
                id: line.productId.toString(),
                name: getProductById(line.productId).name,
                price: line.unitPrice,
                uoMId: line.uoMId,
                quantity: line.quantity,
                vatRateId: line.vatRate,
              }))}
              totalAmountExVat={
                selectedInvoice.totalAmount - selectedInvoice.vatAmount
              }
              vatAmount={selectedInvoice.vatAmount ?? 0}
              calculateVATPerType={() => ({})}
              getVATRateName={() => ""}
              getVATRate={() => 0}
              totalAmountIncVat={selectedInvoice.totalAmount}
              employeeId={(selectedInvoice.employeeId || "").toString()}
              tablename={selectedInvoice.tablename || "Unknown Table"}
              orderNo={selectedInvoice.orderNo}
              fiscInvOrdNum={selectedInvoice.fiscInvOrdNum}
              tcrCode={selectedInvoice.tcrCode}
              opCode={selectedInvoice.opCode}
              buCode={selectedInvoice.buCode}
              iic={selectedInvoice.iic}
              fic={selectedInvoice.fic}
              tableId={""}
            />
          ) : (
            <InvoiceThermalPrint
              ref={printRef}
              orderProducts={selectedInvoice.salesOrderLines.map((line) => {
                const hasDiscount =
                  line.discountAmount !== null &&
                  line.discountAmount !== undefined &&
                  line.discountAmount !== 0;

                return {
                  product: {
                    id: line.productId.toString(),
                    name: getProductById(line.productId).name,
                    price: line.unitPrice,
                    uoMId: line.uoMId,
                    barcode: getProductById(line.productId).barcode,
                    ...(hasDiscount && {
                      discount: line.discountAmount,
                      discountType:
                        line.discountType === "value" ||
                        line.discountType === "percentage"
                          ? line.discountType
                          : undefined, // Ensure it's either 'value', 'percentage', or undefined
                    }),
                    vatRateId: line.vatRate,
                  },
                  quantity: line.quantity,
                  ...(hasDiscount && {
                    discount: line.discountAmount,
                    discountType:
                      line.discountType === "value" ||
                      line.discountType === "percentage"
                        ? line.discountType
                        : undefined, // Ensure it's either 'value', 'percentage', or undefined
                  }),
                };
              })}
              customers={data.map((order) => ({
                id: order.customerId || 0,
                name: order.customerName || "Unknown Customer",
                idNumber: "",
                address: "",
                city: "",
                country: "",
                phoneNumber: "",
                email: "",
              }))}
              selectedCustomer={(selectedInvoice.customerId || "").toString()}
              calculateSubtotalAndDiscount={() => {
                let totalProductDiscounts = 0;
                selectedInvoice.salesOrderLines.forEach((line) => {
                  const price = line.unitPrice * line.quantity;
                  let discountedPrice = price;

                  if (line.discountAmount) {
                    discountedPrice = price - line.discountAmount;
                  }

                  totalProductDiscounts += price - discountedPrice;
                });

                return {
                  subtotal: (
                    selectedInvoice.totalAmount - selectedInvoice.vatAmount
                  ).toFixed(2),
                  totalOrderDiscount:
                    selectedInvoice.discountAmount?.toFixed(2) || "0.00",
                  totalProductDiscounts: totalProductDiscounts.toFixed(2),
                  totalDiscountSum: (
                    parseFloat(
                      selectedInvoice.discountAmount?.toString() || "0"
                    ) + totalProductDiscounts
                  ).toFixed(2),
                };
              }}
              calculateVAT={() => (selectedInvoice.vatAmount ?? 0).toFixed(2)}
              calculateTotal={() => selectedInvoice.totalAmount.toFixed(2)}
              getVATRateName={() => ""}
              getVATRate={() => 0}
              calculateVATPerType={() => ({})}
              employeeName={selectedInvoice.employeeName}
              orderNo={selectedInvoice.orderNo}
              fiscInvOrdNum={selectedInvoice.fiscInvOrdNum}
              tcrCode={selectedInvoice.tcrCode}
              opCode={selectedInvoice.opCode}
              buCode={selectedInvoice.buCode}
              iic={selectedInvoice.iic}
              fic={selectedInvoice.fic}
              date={new Date(selectedInvoice.createDateUtc).toLocaleString()}
              tablename={selectedInvoice.tablename}
              tableId={undefined}
              selectedCurrencyDetails={selectedCurrencyDetails}
              totalInSelectedCurrency={""}
              exchangeRate={selectedInvoice.paymentExchangeRate}
              printerType={0}
              selectedCashAccount={selectedInvoice.cashAccountId}
            />
          ))}
      </div>
      <ToastContainer />
    </div>
  );
};
export default SalesOrderList;
