import React, { useEffect } from "react";
import SalesChart from "./SalesChart";
import Statistics from "./Statistics";
import ProfitChart from "./ProfitChart";
import MostSoldProducts from "./MostSoldProducts";
import PurchaseChart from "./PurchaseChart";
import { Grid, Box } from "@mui/material";
import { useDashboard } from "@/provider/DashboardContext";

const Dashboard: React.FC = () => {
  const { refresh } = useDashboard();

  useEffect(() => {
    refresh();
  }, [refresh]);
  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <SalesChart />
        </Grid>
        <Grid item xs={12} md={6}>
          <Statistics />
        </Grid>
        <Grid item xs={12} md={6}>
          <PurchaseChart />
        </Grid>
        <Grid item xs={12} md={6}>
          <MostSoldProducts />
        </Grid>
        <Grid item xs={12} md={6}>
          <ProfitChart />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
