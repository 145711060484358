import React, { useEffect, useState } from "react";
import "@/css/thermalPrint.css";
import API_URLS from "@/config";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/provider/authProvider";
import { useNavigate } from "react-router-dom";
import DataFetcher from "@/provider/DataFetcher";
import QRCode from "react-qr-code";
import { UoM } from "@/interfaces/UomInterfaces";
import { ExchangeRate } from "@/types/ExchangeRateType";

interface CompanyData {
  name: string;
  tin: string;
  id: number;
  employees: number;
  additionalInfo: string;
  city: string;
  country: string;
  phoneNumber: string;
  email: string;
  fieldOfActivity: string;
  fiscalizationCertificate: string;
  isSeasonal: boolean;
  subscriptionStartDateUtc: string;
  subscriptionEndDateUtc: string;
}

interface Employee {
  id: string;
  name: string;
}

// interface ExchangeRate {
//   currency1Id: number;
//   currency2Id: number;
//   rate: number;
// }
interface BusinessConfigs {
  allowNegativeInventory: boolean;
  baseCurrencyId: number;
  secondCurrencyId: number; // Added second currency ID
  printerType: number;
}
interface Currency {
  id: number;
  name: string;
  symbol: string;
}

interface Props {
  orderProducts: {
    product: {
      id: string;
      name: string;
      price: number;
      uoMId: number;
      barcode: string;
      vatRateId: number;
    };
    quantity: number;
    discount?: number;
    discountType?: "value" | "percentage";
  }[];
  customers: {
    id: string | number;
    name: string;
    idNumber: string;
    email: string;
    phoneNumber: string;
    address: string;
    city: string;
    country: string;
  }[];
  selectedCustomer: string;
  calculateSubtotalAndDiscount: () => {
    subtotal: string;
    totalOrderDiscount: string;
    totalProductDiscounts: string;
    totalDiscountSum: string;
  };
  calculateVAT: () => string;
  calculateVATPerType: () => Record<number, number>;
  calculateTotal: () => string;
  getVATRateName: (vatRateId: number) => string;
  getVATRate: (vatRateId: number) => number;
  selectedCurrencyDetails: { id: number; name: string; symbol: string } | null;
  selectedCashAccount: number | null;
  totalInSelectedCurrency: string;
  employeeName: string;
  orderNo: string | undefined;
  fiscInvOrdNum: number | undefined;
  buCode: string | undefined;
  opCode: string | undefined;
  tcrCode: string | undefined;
  iic: string | undefined;
  fic: string | undefined;
  date: string | number | boolean;
  tableId: string | undefined;
  tablename: string | undefined;
  exchangeRate: number | null;
  printerType: number;
}

const InvoiceThermalPrint = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const {
      orderProducts,
      customers,
      selectedCustomer,
      calculateSubtotalAndDiscount,
      getVATRateName,
      calculateVAT,
      calculateVATPerType = () => ({}),
      calculateTotal,
      selectedCurrencyDetails,
      selectedCashAccount,
      orderNo,
      fiscInvOrdNum,
      buCode,
      opCode,
      tcrCode,
      iic,
      fic,
      tablename,
      exchangeRate,
      printerType,
    } = props;

    const EMPLOYEE_STORAGE_KEY = "employeeSalesData";
    const Currency_STORAGE_KEY = "currencyData";
    const UOM_STORAGE_KEY = "uomData";
    const COMPANY_DATA_STORAGE_KEY = "businessData";
    const ExchangeRate_STORAGE_KEY = "exchangerateData";
    const CashAccount_STORAGE_KEY = "cashaccountData";

    const { subtotal, totalOrderDiscount, totalProductDiscounts } =
      calculateSubtotalAndDiscount();
    const [exchangeRates, setExchangeRates] = useState<ExchangeRate[]>([]);
    const [employee, setEmployee] = useState<Employee | null>(null);
    const [finalPrinterType, setFinalPrinterType] = useState<number>(() => {
      const storedPrinterType = JSON.parse(
        localStorage.getItem("businessConfigs") || "{}"
      )?.printerType;
      return printerType || storedPrinterType || 1;
    });
    const { setToken, token, employeeId } = useAuth();
    const [baseCurrencyName, setBaseCurrencyName] = useState("Lekë"); // Default currency
    const [baseCurrencySymbol, setBaseCurrencySymbol] = useState("L"); // Default currency symbol
    const [currencies, setCurrencies] = useState<Currency[]>([]); // Holds list of currencies
    const [uoms, setUoms] = useState<UoM[]>([]);
    const [businessData, setBusinessData] = useState<CompanyData | null>(null);
    const printOthersExchRat =
      JSON.parse(localStorage.getItem("printerConfig") || "{}")
        .displayExchangeRates ?? false;
    const showCustomerInfo =
      JSON.parse(localStorage.getItem("printerConfig") || "{}")
        .displayCustomerInfo ?? false;
    const showAllCashAccount =
      JSON.parse(localStorage.getItem("printerConfig") || "{}")
        .displayBankAccounts ?? false;
    const displayEmployeeName =
      JSON.parse(localStorage.getItem("printerConfig") || "{}")
        .displayEmployeeName ?? false;
    const invoiceHeader = JSON.parse(
      localStorage.getItem("printerConfig") || "{}"
    ).invoiceHeader;
    const invoiceFooter = JSON.parse(
      localStorage.getItem("printerConfig") || "{}"
    ).invoiceFooter;
    const displayHeader = true;
    const displayFooter = true;
    const storedCertificate = JSON.parse(
      localStorage.getItem("certificate") || "{}"
    );
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [cashAccounts, setCashAccounts] = useState<
      {
        id: number;
        name: string;
        isBank: boolean;
        iban: string;
        swift: string;
        currencyId: number;
      }[]
    >([]);
    const selectedPaymentMethod = JSON.parse(
      localStorage.getItem("selectedPaymentMethod") || "{}"
    );

    // const customerName = customers.find(
    //   (c) => c.id.toString() === selectedCustomer
    // )?.name;
    useEffect(() => {
      if (printerType !== undefined) {
        setFinalPrinterType(printerType);
      }
    }, [printerType]);

    useEffect(() => {
      // Fetch cash accounts from API or DataFetcher
      const fetchCashAccounts = async () => {
        try {
          const response = await DataFetcher.getData<
            {
              id: number;
              name: string;
              isBank: boolean;
              iban: string;
              swift: string;
              currencyId: number;
            }[]
          >(
            `${API_URLS.BASE_URL}${API_URLS.CashAccount}`,
            CashAccount_STORAGE_KEY
          );
          setCashAccounts(response || []);
          console.log(response || []);
        } catch (error) {
          console.error("Error fetching cash accounts:", error);
        }
      };

      fetchCashAccounts();
    }, []);

    const filteredCashAccounts = cashAccounts.filter(
      (account) => account.isBank
    );

    let selectedAccount = cashAccounts.find(
      (account) => account.id === selectedCashAccount
    );

    const currencyDetails =
      selectedCurrencyDetails ||
      JSON.parse(localStorage.getItem("selectedCurrencyDetails") || "{}");
    console.log(currencyDetails);

    useEffect(() => {
      const fetchCompanyData = async () => {
        try {
          // Fetch data directly from the API
          const response = await DataFetcher.getData<CompanyData>(
            ` ${API_URLS.BASE_URL}${API_URLS.LOGGEDBUSINESS}`,
            COMPANY_DATA_STORAGE_KEY
          );
          // Set the fetched data directly to state
          if (response && typeof response === "object") {
            setBusinessData(response);
          } else {
            console.warn("Unexpected data format received:", response);
          }
        } catch (error) {
          console.error("Error during fetchCompanyData", error);
        }

        // Fetch avatar from localStorage
      };
      const fetchEmployeeData = async () => {
        const employeeData = await DataFetcher.getData<Employee>(
          `${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}/${employeeId}`,
          EMPLOYEE_STORAGE_KEY
        );

        if (employeeData) {
          setEmployee(employeeData);
        } else {
          throw new Error("Failed to fetch employee data");
        }
      };

      fetchCompanyData();
      fetchEmployeeData();
    }, [token, setToken, navigate, employeeId]);

    const calculateTotalInSelectedCurrency = () => {
      const totalInLek = parseFloat(calculateTotal());
      if (exchangeRate) {
        return (totalInLek / exchangeRate).toFixed(2);
      }
      return "N/A";
    };

    useEffect(() => {
      // Fetch available currencies using DataFetcher with caching
      const fetchCurrencyData = async () => {
        try {
          const response = await DataFetcher.getData<Currency[]>(
            `${API_URLS.BASE_URL}${API_URLS.Currency}`, // API endpoint
            Currency_STORAGE_KEY // LocalStorage key
          );
          setCurrencies(response || []); // Set fetched currencies, default to an empty array if none

          // Find and set base currency name and symbol
          const businessConfigs: BusinessConfigs = JSON.parse(
            localStorage.getItem("businessConfigs") || "{}"
          );
          const baseCurrencyId = businessConfigs.baseCurrencyId ?? 1; // Default to 1 if not set
          const baseCurrency = response?.find(
            (currency) => currency.id === baseCurrencyId
          );

          setBaseCurrencyName(baseCurrency?.name || "Leke"); // Set base currency name, default to "Leke"
          setBaseCurrencySymbol(baseCurrency?.symbol || "L"); // Set base currency symbol, default to "L"

          const exchangeRatesResponse = await DataFetcher.getData<
            ExchangeRate[]
          >(
            `${API_URLS.BASE_URL}${API_URLS.ExchangeRates}`,
            ExchangeRate_STORAGE_KEY
          );

          if (exchangeRatesResponse && exchangeRatesResponse.length > 0) {
            // Set all exchange rates
            setExchangeRates(exchangeRatesResponse);
          } else {
            console.error("Exchange rates data is empty or null.");
          }
        } catch (error) {
          console.error("Error fetching currencies or exchange rates:", error);
        }
      };

      fetchCurrencyData();
    }, [t]);

    const calculateTotalInCurrency = (rate: number) => {
      const totalInLek = Number(calculateTotal());
      return (totalInLek / rate).toFixed(2);
    };

    const getCurrencyNameById = (currencyId: number) => {
      const currency = currencies.find(
        (currency) => currency.id === currencyId
      );
      return currency ? currency.name : "Unknown Currency";
    };

    const hasVat =
      JSON.parse(localStorage.getItem("businessConfigs") || "{}").hasVat ??
      false;

    useEffect(() => {
      const fetchUoms = async () => {
        try {
          const response = await DataFetcher.getData<UoM[]>(
            `${API_URLS.BASE_URL}${API_URLS.UOM}`,
            UOM_STORAGE_KEY
          );
          setUoms(response || []);
        } catch (error) {
          console.error("Error fetching UoMs:", error);
        }
      };

      fetchUoms();
    }, []);

    const getUoMName = (uoMId: number) => {
      const uom = uoms.find((u) => u.id === uoMId);
      return uom ? uom.name : t("Unknown UoM");
    };
    const hasDiscount = orderProducts.some(
      ({ discount }) => discount && discount !== 0
    );

    const toFiscDateTimeString = (): string => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");

      const timezoneOffsetHours = -(now.getTimezoneOffset() / 60);
      const timezoneFormatted = `%20${String(
        Math.abs(timezoneOffsetHours)
      ).padStart(2, "0")}:00`;

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezoneFormatted}`;
    };

    const qrString =
      storedCertificate.isDemo === false
        ? `$@"https://efiskalizimi-app.tatime.gov.al/invoice-check/#/verify?iic=${iic}&tin=${
            businessData?.tin
          }&crtd=${toFiscDateTimeString()}&prc=${calculateTotal()}"`
        : `$@"https://efiskalizimi-app-test.tatime.gov.al/invoice-check/#/verify?iic=${iic}&tin=J61912013U&crtd=${toFiscDateTimeString()}&prc=${calculateTotal()}"`;

    const invoiceClass =
      finalPrinterType === 1 ? "invoice-print-a4" : "invoice-print-thermal";
    return (
      <div ref={ref} className={invoiceClass}>
        {finalPrinterType === 1 ? (
          <>
            {/* A4 Invoice Layout */}
            <div className="invoice-details-a4">
              <p>
                {t("INVOICENo")} {orderNo}
              </p>
              <p>
                {fiscInvOrdNum}/{new Date().getFullYear()}/{tcrCode}
              </p>
              <p>
                {t("BusinessUnit")}: {buCode}
              </p>
              <p>
                {t("OpCode")}: {opCode}
              </p>
              <p>
                {t("Date")} : {new Date().toLocaleDateString()}
              </p>
            </div>
            <div className="invoice-header-a4">
              {displayHeader && <h3>{invoiceHeader}</h3>}
              <div className="company-details-a4">
                <h3>{t("Sales Details")}</h3>
                <h2>{businessData ? businessData.name : t("companyName")}</h2>
                <p>{businessData ? businessData.tin : t("tin")}</p>
                <p>{businessData ? businessData.email : t("email")}</p>
                <p>
                  {businessData ? businessData.phoneNumber : t("phoneNumber")}
                </p>
                <p>
                  {businessData ? businessData.city : t("AdrressCity")},{" "}
                  {businessData ? businessData.country : t("AdrressCountry")}
                </p>
              </div>
              <div className="customer-details-a4">
                <h3>{t("Customer Details")}</h3>
                {selectedCustomer ? (
                  (() => {
                    const customer = customers.find(
                      (c) => c.id.toString() === selectedCustomer
                    );
                    if (!customer) {
                      return <p>{t("Customer not found")}</p>;
                    }
                    return (
                      <>
                        <h2>{customer.name || t(" ")}</h2>
                        <p>{customer.idNumber || t(" ")}</p>
                        <p>{customer.phoneNumber || t(" ")}</p>
                        <p>{customer.email || t(" ")}</p>
                        <p>{customer.address || t(" ")}</p>
                        {customer.city && customer.country && (
                          <p>
                            {customer.city}, {customer.country}
                          </p>
                        )}
                      </>
                    );
                  })()
                ) : (
                  <p>{t("No customer selected")}</p>
                )}
              </div>
            </div>
            {displayEmployeeName && (
              <div className="supplier-details-a4">
                <p>
                  {t("employeeName")}: {employee ? employee.name : t("loading")}
                </p>
              </div>
            )}
            <table className="invoice-items-a4">
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>{t("ITEM")}</th>
                  <th style={{ width: "5%" }}>{t("QUANTITY")}</th>
                  <th style={{ width: "10%" }}>{t("uom")}</th>
                  <th style={{ width: "10%" }}>{t("UNITCOST")}</th>
                  <th style={{ width: "10%" }}>{t("VAT Amount")}</th>
                  <th style={{ width: "10%" }}>{t("Price no vat Amount")}</th>
                  <th style={{ width: "10%" }}>{t("Value no Vat")}</th>
                  <th style={{ width: "10%" }}>{t("VAT Amount Total")}</th>
                  {hasDiscount && (
                    <th style={{ width: "5%" }}>{t("DISCOUNT")}</th>
                  )}
                  <th style={{ width: "15%" }}>{t("TOTAL")}</th>
                  {/* <th>{t("TAX")}</th> */}
                </tr>
              </thead>
              <tbody>
                {orderProducts.map(
                  ({ product, quantity, discount, discountType }) => {
                    const vatRate = props.getVATRate(product.vatRateId) || 0;
                    const vatAmount =
                      (product.price - product.price / (1 + vatRate / 100)) *
                      quantity;
                    const priceExclVAT =
                      product.price -
                      (product.price - product.price / (1 + vatRate / 100));
                    const vat =
                      product.price - product.price / (1 + vatRate / 100);
                    const valueExclVAT = quantity * priceExclVAT;
                    const priceWithDiscount =
                      discountType === "percentage"
                        ? product.price * quantity * (1 - (discount || 0) / 100)
                        : product.price * quantity - (discount || 0);

                    return (
                      <tr key={product.id}>
                        <td className="invoice-td">{product.name}</td>
                        <td className="invoice-td">{quantity}</td>
                        <td className="invoice-td">
                          {getUoMName(product.uoMId)}
                        </td>
                        <td className="invoice-td">
                          {product.price.toFixed(2)} {baseCurrencySymbol}
                        </td>
                        <td className="invoice-td">
                          {vat.toFixed(2)} {baseCurrencySymbol}
                        </td>
                        <td className="invoice-td">
                          {priceExclVAT.toFixed(2)} {baseCurrencySymbol}
                        </td>
                        <td className="invoice-td">
                          {valueExclVAT.toFixed(2)} {baseCurrencySymbol}
                        </td>
                        <td className="invoice-td">
                          {vatAmount.toFixed(2)} {baseCurrencySymbol}
                        </td>
                        {hasDiscount && (
                          <td className="invoice-td">
                            {discount !== undefined
                              ? `${discount} ${
                                  discountType === "percentage"
                                    ? "%"
                                    : baseCurrencySymbol
                                }`
                              : "0 " + baseCurrencySymbol}
                          </td>
                        )}
                        <td className="invoice-td">
                          {priceWithDiscount.toFixed(2)} {baseCurrencySymbol}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
            <div className="invoice-row">
              <div className="invoice-code-section">
                <div className="qr-code-section-a4">
                  <div className="qr-code-text">
                    <p>NSLF: {iic}</p>
                    <p>NIVF: {fic}</p>
                  </div>
                  <QRCode value={qrString} size={128} />
                </div>
                {displayFooter && (
                  <div className="invoice-footer-a4">
                    <p>{invoiceFooter}</p>
                    <p>
                      {businessData ? businessData.name : t("Company Name")}
                    </p>
                  </div>
                )}
              </div>

              <div className="invoice-summary-section">
                {hasVat && (
                  <table className="invoice-vats-a4">
                    <thead className="invoice-head-vats-a4">
                      <tr>
                        <th>{t("Vat Type")}</th>
                        <th>{t("Items")}</th>
                        <th>{t("Value no Vat")}</th>
                        <th>{t("VAT")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(calculateVATPerType()).map(
                        ([vatRateId, vatSum]) => {
                          const vatRateName =
                            getVATRateName(Number(vatRateId)) ||
                            t("Unknown VAT");
                          const vatItemCount = orderProducts.filter(
                            ({ product }) =>
                              product.vatRateId === Number(vatRateId)
                          ).length;

                          const valueNoVatSum = orderProducts
                            .filter(
                              ({ product }) =>
                                product.vatRateId === Number(vatRateId)
                            )
                            .reduce((sum, { product, quantity }) => {
                              const priceExclVAT =
                                product.price -
                                (product.price -
                                  product.price /
                                    (1 +
                                      props.getVATRate(product.vatRateId) /
                                        100));
                              return sum + priceExclVAT * quantity;
                            }, 0);

                          return (
                            <tr key={vatRateId}>
                              <th>
                                {t("VAT")} {vatRateName}
                              </th>
                              <td>{vatItemCount}</td>
                              <td>
                                {valueNoVatSum.toFixed(2)} {baseCurrencySymbol}
                              </td>
                              <td>
                                {Number(vatSum).toFixed(2)} {baseCurrencySymbol}
                              </td>
                            </tr>
                          );
                        }
                      )}
                      <tr>
                        <th>{t("VATTotal")}</th>
                        <td></td>
                        <td></td>
                        <td>
                          {calculateVAT()}
                          {baseCurrencySymbol}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
                <table className="invoice-totals-a4">
                  <tbody>
                    {parseFloat(totalProductDiscounts) > 0 && (
                      <tr>
                        <th>{t("INLINE DISCOUNT")}</th>
                        <td>
                          {totalProductDiscounts}
                          {baseCurrencySymbol}
                        </td>
                      </tr>
                    )}

                    {parseFloat(totalOrderDiscount) > 0 && (
                      <tr>
                        <th>{t("DISCOUNT FROM TOTAL PRICE")}</th>
                        <td>
                          {totalOrderDiscount}
                          {baseCurrencySymbol}
                        </td>
                      </tr>
                    )}

                    {parseFloat(
                      calculateSubtotalAndDiscount().totalDiscountSum
                    ) > 0 && (
                      <tr>
                        <th>{t("TOTAL DISCOUNT")}</th>
                        <td>
                          {calculateSubtotalAndDiscount().totalDiscountSum}
                          {baseCurrencySymbol}
                        </td>
                      </tr>
                    )}
                    {hasVat && (
                      <tr>
                        <th>{t("Subtotal")}:</th>
                        <td>
                          {subtotal} {baseCurrencySymbol}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th>
                        {t("TOTALlek")} {baseCurrencyName}:
                      </th>
                      <td>
                        {calculateTotal()} {baseCurrencySymbol}
                      </td>
                    </tr>
                    {/* {currencyDetails.name !== "Lekë" && (
                      <tr>
                        <th>{t("ExchangeRate")}</th>
                        <td>{exchangeRate}</td>
                      </tr>
                    )} */}
                    {currencyDetails.name !== "Lekë" && (
                      <tr>
                        <th>
                          {t("TOTAL")} {currencyDetails.name}{" "}
                          <span className="font-thin">
                            {t(`(Exch.rate ${exchangeRate})`)}
                          </span>
                        </th>
                        <td>
                          {calculateTotalInSelectedCurrency()}{" "}
                          {currencyDetails.symbol}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {selectedPaymentMethod?.isCash === false && (
              <div className="bank-details-a4">
                <h3>{t("Bank Accounts")}</h3>
                {showAllCashAccount ? (
                  <ul>
                    {filteredCashAccounts.map((account) => {
                      const currencyName = getCurrencyNameById(
                        account.currencyId
                      );
                      return (
                        <li key={account.id} className="bank-account-inline-A4">
                          <strong>{t("Account Name")}: </strong>
                          {account.name} &nbsp;&nbsp;
                          <strong>{t("Currency")}: </strong>
                          {currencyName} &nbsp;&nbsp;
                          <strong>{t("IBAN")}: </strong>
                          {account.iban} &nbsp;&nbsp;
                          <strong>{t("SWIFT")}: </strong>
                          {account.swift} &nbsp;&nbsp;
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  (() => {
                    selectedAccount = cashAccounts.find(
                      (account) => account.id === selectedCashAccount
                    );

                    if (selectedAccount) {
                      const currencyName = getCurrencyNameById(
                        selectedAccount.currencyId
                      );
                      return (
                        <ul>
                          <li
                            key={selectedAccount.id}
                            className="bank-account-inline-A4"
                          >
                            <strong>{t("Account Name")}: </strong>
                            {selectedAccount.name} &nbsp;&nbsp;
                            <strong>{t("Currency")}: </strong>
                            {currencyName} &nbsp;&nbsp;
                            <strong>{t("IBAN")}: </strong>
                            {selectedAccount.iban} &nbsp;&nbsp;
                            <strong>{t("SWIFT")}: </strong>
                            {selectedAccount.swift} &nbsp;&nbsp;
                          </li>
                        </ul>
                      );
                    }
                    return <p>{t("No selected bank account available")}</p>;
                  })()
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <div className="invoice-header-thermal">
              {displayHeader && <h3>{invoiceHeader}</h3>}
              <div className="company-details-thermal">
                <h2>{businessData ? businessData.name : t("companyName")}</h2>
                <p>{businessData ? businessData.tin : t("tin")}</p>
                <p>{businessData ? businessData.email : t("email")}</p>
                <p>
                  {businessData ? businessData.phoneNumber : t("phoneNumber")}
                </p>
                <p>
                  {businessData ? businessData.city : t("AdrressCity")},{" "}
                  {businessData ? businessData.country : t("AdrressCountry")}
                </p>
              </div>
              <div className="invoice-details-thermal">
                <p>
                  {t("INVOICENo")}: {orderNo}
                </p>
                <p>
                  {fiscInvOrdNum}/{new Date().getFullYear()}/{tcrCode}
                </p>
                <p>
                  {t("BusinessUnit")}: {buCode}
                </p>
                <p>
                  {t("OpCode")}: {opCode}
                </p>
                <p>
                  {t("Date")} : {new Date().toLocaleDateString()}
                </p>
              </div>
            </div>
            <div className="customer-details-thermal">
              {/* <p>
                {t("customer")}: {customerName}
              </p> */}
              {tablename &&
                tablename !== "Unknown Table" && ( // Hide if tablename is null, undefined, or equals "Unknown Table"
                  <p>
                    {t("Table")}: {tablename}
                  </p>
                )}
              <p></p>
              {displayEmployeeName && (
                <p>
                  {t("employeeName")}: {employee ? employee.name : t("loading")}
                </p>
              )}
            </div>
            <div className="invoice-items-thermal">
              {/* <div className="invoice-item-thermal header">
                <span>{t("ITEMSales")}</span>
                <span>{t("QUANTITY")}</span>
                <span className="pr-2">{t("UNITCOST")}</span>
                <span>{t("TOTAL")}</span>
              </div> */}
              {orderProducts.map(
                ({ product, quantity, discount, discountType }) => (
                  <React.Fragment key={product.id}>
                    <div className="invoice-item-therma flex items-center justify-between">
                      <div className="flex items-center">
                        <span className="text-left">{quantity}</span>
                        <span className="text-left pl-1">
                          {getUoMName(product.uoMId)}
                        </span>
                        <span className="pl-1">x</span>
                        <span className="text-right pl-1 w-2/5">
                          {product.price.toFixed(2)}
                          {baseCurrencySymbol}
                        </span>
                      </div>
                      <div className="text-right w-3/5">
                        {(product.price * quantity).toFixed(2)}
                        {baseCurrencySymbol}
                      </div>
                    </div>
                    <div className="invoice-product-discount flex w-full">
                      <span className="w-1/2 product-name pl-3 text-left">
                        {product.name}
                      </span>
                      {discount && discount > 0 && (
                        <div className="invoice-item-thermal  w-1/2">
                          <span>
                            -
                            {discount !== undefined
                              ? `${discount} ${
                                  discountType === "percentage"
                                    ? "%"
                                    : baseCurrencySymbol
                                }`
                              : "0"}
                          </span>
                          <span className="pl-12">
                            {discountType === "percentage"
                              ? (
                                  product.price *
                                  quantity *
                                  (1 - (discount || 0) / 100)
                                ).toFixed(2)
                              : (
                                  product.price * quantity -
                                  (discount || 0)
                                ).toFixed(2)}
                            {baseCurrencySymbol}
                          </span>
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                )
              )}
            </div>
            <div className="invoice-totals-thermal">
              {hasVat && (
                <div className="totals-row-thermal">
                  <span>{t("Subtotal")}</span>
                  <span>
                    {subtotal}
                    {baseCurrencySymbol}
                  </span>
                </div>
              )}
              {parseFloat(totalProductDiscounts) > 0 && (
                <div className="totals-row-thermal">
                  <span>{t("INLINE DISCOUNT")}</span>
                  <span>
                    {totalProductDiscounts}
                    {baseCurrencySymbol}
                  </span>
                </div>
              )}

              {parseFloat(totalOrderDiscount) > 0 && (
                <div className="totals-row-thermal">
                  <span>{t("DISCOUNT FROM TOTAL PRICE")}</span>
                  <span>
                    {totalOrderDiscount}
                    {baseCurrencySymbol}
                  </span>
                </div>
              )}

              {parseFloat(calculateSubtotalAndDiscount().totalDiscountSum) >
                0 && (
                <div className="totals-row-thermal">
                  <span>{t("TOTAL DISCOUNT")}</span>
                  <span>
                    {calculateSubtotalAndDiscount().totalDiscountSum}
                    {baseCurrencySymbol}
                  </span>
                </div>
              )}
              {hasVat &&
                Object.entries(calculateVATPerType()).map(
                  ([vatRateId, vatSum]) => {
                    const vatRateName =
                      getVATRateName(Number(vatRateId)) || t("Unknown VAT");
                    return (
                      <div className="totals-row-thermal" key={vatRateId}>
                        <span>
                          <span>{t("VAT")}:</span>
                          {vatRateName}
                        </span>
                        <span>
                          {Number(vatSum).toFixed(2)} {baseCurrencySymbol}
                        </span>
                      </div>
                    );
                  }
                )}

              {hasVat && (
                <div className="totals-row-thermal">
                  <span>{t("VATTotal")}</span>
                  <span>
                    {calculateVAT()}
                    {baseCurrencySymbol}
                  </span>
                </div>
              )}
              <div className="totals-row-thermal">
                <span>
                  <strong>
                    {t("TOTALlek")} {baseCurrencyName}
                  </strong>
                </span>
                <span>
                  <strong>
                    {calculateTotal()}
                    {baseCurrencySymbol}
                  </strong>
                </span>
              </div>
              {printOthersExchRat &&
                exchangeRates.map((rate, index) => {
                  const currency1Name = getCurrencyNameById(rate.currency1Id);
                  const calculatedTotal = calculateTotalInCurrency(rate.rate);
                  const currency1Symbol = currencies.find(
                    (currency) => currency.name === currency1Name
                  )?.symbol;

                  return (
                    <div className="totals-row-thermal" key={index}>
                      <span>
                        <strong>
                          {t("TOTALlek")}
                          {currency1Name}
                        </strong>{" "}
                        {`( Exch.rate:${rate.rate})`}
                      </span>
                      <span>
                        <strong>
                          {Number(calculatedTotal).toFixed(2)} {currency1Symbol}
                        </strong>
                      </span>
                    </div>
                  );
                })}
              {currencyDetails.name !== "Lekë" && !printOthersExchRat && (
                <div className="totals-row-thermal">
                  <span>{t("ExchangeRate")}</span>
                  <span>{exchangeRate}</span>
                </div>
              )}
              {currencyDetails.name !== "Lekë" && !printOthersExchRat && (
                <div className="totals-row-thermal">
                  <span>
                    <strong>
                      {t("TOTAL")} in {currencyDetails.name}
                    </strong>
                  </span>
                  <span>
                    <strong>
                      {calculateTotalInSelectedCurrency()}{" "}
                      {currencyDetails.symbol}
                    </strong>
                  </span>
                </div>
              )}
            </div>
            {showCustomerInfo && (
              <div className="invoice-customer-thermal">
                <div className="separator-line"></div>
                <p>{t("Customer Details")}</p>
                {selectedCustomer ? (
                  (() => {
                    const customer = customers.find(
                      (c) => c.id.toString() === selectedCustomer
                    );
                    if (!customer) {
                      return <p>{t("Customer not found")}</p>;
                    }
                    return (
                      <>
                        <p>{customer.name || t(" ")}</p>
                        <p>{customer.idNumber || t(" ")}</p>
                        <p>{customer.phoneNumber || t(" ")}</p>
                        <p>{customer.email || t(" ")}</p>
                        <p>{customer.address || t(" ")}</p>
                        {customer.city && customer.country && (
                          <p>
                            {customer.city}, {customer.country}
                          </p>
                        )}
                      </>
                    );
                  })()
                ) : (
                  <p>{t("No customer selected")}</p>
                )}
                <div className="separator-line"></div>
              </div>
            )}
            {selectedPaymentMethod?.isCash === false && (
              <div className="bank-details-thermal">
                <h3>{t("Bank Accounts")}</h3>
                {showAllCashAccount ? (
                  <ul>
                    {filteredCashAccounts.map((account) => {
                      const currencyName = getCurrencyNameById(
                        account.currencyId
                      );
                      return (
                        <li key={account.id} className="bank-account-inline">
                          <strong>{t("Account Name")}: </strong>
                          {account.name}
                          <br />
                          <strong>{t("Currency")}: </strong>
                          {currencyName}
                          <br />
                          <strong>{t("IBAN")}: </strong>
                          {account.iban}
                          <br />
                          <strong>{t("SWIFT")}: </strong>
                          {account.swift}
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  (() => {
                    const selectedAccount = cashAccounts.find(
                      (account) => account.id === selectedCashAccount
                    );

                    if (selectedAccount) {
                      const currencyName = getCurrencyNameById(
                        selectedAccount.currencyId
                      );
                      return (
                        <ul>
                          <li
                            key={selectedAccount.id}
                            className="bank-account-inline"
                          >
                            <strong>{t("Account Name")}: </strong>
                            {selectedAccount.name}
                            <br />
                            <strong>{t("Currency")}: </strong>
                            {currencyName}
                            <br />
                            <strong>{t("IBAN")}: </strong>
                            {selectedAccount.iban}
                            <br />
                            <strong>{t("SWIFT")}: </strong>
                            {selectedAccount.swift}
                          </li>
                        </ul>
                      );
                    }
                    return <p>{t("No selected bank account available")}</p>;
                  })()
                )}
              </div>
            )}

            <div className="qr-code-section-thermal">
              <QRCode value={qrString} size={128} />
              <div className="qr-code-text">
                <p>NSLF: {iic}</p>
                <p>NIVF: {fic}</p>
              </div>
            </div>
            {displayFooter && (
              <div className="invoice-footer-thermal">
                <p>{invoiceFooter}</p>
                <p>{businessData ? businessData.name : t("Company Name")}</p>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
);

InvoiceThermalPrint.displayName = "InvoiceThermalPrint";

export default InvoiceThermalPrint;
