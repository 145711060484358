import React, { useState, ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import "@/css/MobileScreen.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import "@/css/LoadingCreate.css";

import UploadCertifikateDto from "@/models/UploadCertificateDto";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosInstance } from "@/lib/utils";

const baseFormSchema2 = z.object({
  CertPassword: z.string().min(1, "Certificate password is required"),
  Certificate: z.union([z.instanceof(File), z.null()]), // Accept File or null
});

const formSchema2 = baseFormSchema2;

type FormValues2 = z.infer<typeof formSchema2>;

const Certificate: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const methods2 = useForm<FormValues2>({
    resolver: zodResolver(formSchema2),
    defaultValues: {
      CertPassword: "",
      Certificate: undefined, // Set default value to undefined
    },
  });

  const { t } = useTranslation();

  // State to manage form steps

  // Function to handle verification logic

  const handleFileChange =
    (onChange: (file: File | null) => void) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0] || null;
      onChange(file); // Pass the file object or null to the form field
    };

  // const updateLocalStorage = (updatedBusiness: CompanyData) => {
  //     // Retrieve existing business data from localStorage
  //     const existingBusinesses = JSON.parse(localStorage.getItem("businessData") || "[]");

  //     // Ensure existingBusinesses is an array
  //     const businessesArray = Array.isArray(existingBusinesses)
  //         ? existingBusinesses
  //         : [];

  //     // Update the specific business or keep the existing ones
  //     const updatedBusinesses = businessesArray.map((business: CompanyData) =>
  //         business.id === updatedBusiness.id ? updatedBusiness : business
  //     );

  //     // Save the updated array back to localStorage
  //     localStorage.setItem("businessData", JSON.stringify(updatedBusinesses));
  // };

  const handleUploadCertificate = async (data: FormValues2) => {
    setLoading(true);

    try {
      // Retrieve the subdomain from the hostname
      const hostname = window.location.hostname.split(".")[0]; // Get the subdomain

      // Check if the subdomain exists
      if (!hostname) {
        toast.error("No BusinessSubdomain found in the hostname.");
        return;
      }

      // 1. Create an instance of the DTO with form data
      const uploadData = new UploadCertifikateDto({
        CertPassword: data.CertPassword,
        BusinessSubdomain: hostname, // Use the subdomain from the hostname
        Certificate: data.Certificate, // Ensure it's a file or null
      });

      // 2. Create FormData to handle file upload
      const formData = new FormData();
      formData.append("CertPassword", uploadData.CertPassword);
      formData.append("BusinessSubdomain", uploadData.BusinessSubdomain); // Use the value from the hostname
      if (uploadData.Certificate) {
        formData.append("Certificate", uploadData.Certificate); // Append file only if it exists
      }

      // 3. Make the API request with FormData
      const response = await axiosInstance.post(
        `${API_URLS.BASE_URL}${API_URLS.UploadCert}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Required for file upload
          },
        }
      );

      // 4. Handle success
      if (response) {
           const expiryDate = response.data.expiryDate;
           const existingCertificate = JSON.parse(localStorage.getItem("certificate") || "{}");

      // Update the certificate with expiryDate and set isDemo to false
      const updatedCertificate = {
        ...existingCertificate,
        expiryDate,
        isDemo: false, // Update isDemo
      };

      // Save the updated certificate back to localStorage
      localStorage.setItem("certificate", JSON.stringify(updatedCertificate));

      // Save expiryDate to localStorage
      localStorage.setItem("certificateExpiryDate", expiryDate);
                    navigate(t("/companyinfo"));

          setTimeout(() => {
        toast.success(t("Certificate uploaded successfully."));

        // Navigate to a new path after showing the toast
      }, 300)

      }
    } catch (error) {
  console.error("Upload error:", error); // Log the error details

 if (axios.isAxiosError(error)) {
  if (error.response) {
    const errorMessage = error.response.data; // Directly get the error message

    // Check for specific error messages and display appropriate toast notifications
    if (errorMessage === "Certificate password is wrong") {
      toast.error(t("Certificate password is wrong.")); // Specific toast message
    } else if (errorMessage === "Loaded certificate doesn't belong to your business") {
      toast.error(t("Loaded certificate doesn't belong to your business")); // Specific toast message
    } else if (errorMessage === "The certificate has expired") {
      toast.error(t("The certificate has expired.")); // Translated error for expired certificate
    } else {
      toast.error(errorMessage || t("Failed to upload certificate.")); // Generic error message
    }
  } else {
    toast.error(t("An unexpected error occurred.")); // Network or unknown error
  }
}
 else {
    toast.error("An unexpected error occurred."); // Non-Axios error
  }
}
finally {
      setLoading(false); // Stop loading in any case
    }
  };

  useEffect(() => {
    if (location.state?.toastMessage) {
      toast.error(location.state.toastMessage);
    }
  }, [location.state]);

  // Function to move to the next step

  const { setValue: setValue2 } = methods2;

  // Watch the "name" field to detect changes

  // Automatically fill "subdomain" when the user types the first word in "name"

  // Handles email and code verification

  const Cancel = () => {
    navigate(t("/companyinfo"));
  };

  return (
    <FormProvider {...methods2}>
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}

      <form onSubmit={methods2.handleSubmit(handleUploadCertificate)}>
        <h1 className="text-xl font-bold mb-6 text-center text-bold pt-10">
          {t("Upload Certificate")}
        </h1>

        <fieldset className="w-full grid grid-cols-1 gap-4 md:grid-cols-2 pt-5">
          {/* CertPassword Field */}
          <FormItem>
            <FormLabel className="block text-sm font-medium text-gray-700 pl-12 sm:pl-16">
              {t("Certificate Code")}
            </FormLabel>
            <FormControl>
              <div className="flex justify-center items-center">
                <Input
                  {...methods2.register("CertPassword")} // Use register to bind to the input
                  type="search"
                  placeholder={t("Enter Certificate Code")}
                  className="mt-1 block w-3/4 md:w-5/6 px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                />
              </div>
            </FormControl>
            {methods2.formState.errors.CertPassword && (
              <FormMessage>
                {methods2.formState.errors.CertPassword.message}
              </FormMessage>
            )}
          </FormItem>

          {/* BusinessSubdomain Field */}

          {/* Certificate File Upload Field */}
          <FormItem>
            <FormLabel className="block text-sm font-medium text-gray-700 pl-12 sm:pl-16">
              {t("Certificate")}
            </FormLabel>
            <FormControl>
              <div className="flex justify-center items-center">
                <input
                  type="file"
                  accept=".p12,.pem,.crt" // Adjust accepted file types
                  onChange={handleFileChange((file) =>
                    setValue2("Certificate", file)
                  )} // Pass the selected file to the form
                  className="mt-1 block w-3/4 md:w-5/6 border border-gray-300 rounded-md shadow-sm"
                />
              </div>
            </FormControl>
            {methods2.formState.errors.Certificate && (
              <FormMessage>
                {methods2.formState.errors.Certificate.message}
              </FormMessage>
            )}
          </FormItem>
        </fieldset>

        <div className="flex justify-center mt-4 space-x-4">
          <Button
            type="button"
            onClick={methods2.handleSubmit(handleUploadCertificate)} // Calls the upload function on click
            className="bg-blue-500 text-white px-4 py-2 rounded"
            // Full width on mobile, auto width on larger screens
          >
            {t("Upload Certificate")}
          </Button>

          <Button
            onClick={Cancel}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            {t("canclelbtn")}
          </Button>
        </div>
      </form>

      <ToastContainer />
    </FormProvider>
  );
};

export default Certificate;
